import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { S3Data } from "../../../api-types/s3";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { ImagePreview } from "../../client/job-requests/utils";
import { useResponsiveness } from "../hooks/utils/useResponsiveness";

type ShowImagesProps = {
  selectedFiles?: S3Data[];
};

const useStyles = makeStyles({
  down: {
    transform: "rotate(90deg)",
    marginLeft: 5,
  },
  accordion: {
    "&.Mui-expanded": {
      margin: 0,
      marginBottom: 16,
    },
    border: "none",
    backgroundColor: `${BLUE} !important`,

    "&:before": {
      backgroundColor: BLUE,
    },
  },
  accordionSummary: {
    minHeight: "24px",
    "&.Mui-expanded": {
      minHeight: "24px",
      marginTop: 0,
    },
    margin: 0,
    marginBottom: 8,
    transition: "none",
  },

  accordionDetails: {
    padding: 0,
  },
  accordionSummaryContent: {
    marginTop: 0,
    marginBottom: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
});

export const ShowImages: React.FC<ShowImagesProps> = ({
  selectedFiles = [],
}) => {
  const styles = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const { isSmall } = useResponsiveness();

  return (
    <Accordion
      expanded={isExpanded}
      elevation={0}
      classes={{
        root: styles.accordion,
      }}
    >
      <AccordionSummary
        onClick={() => setIsExpanded(!isExpanded)}
        classes={{
          root: styles.accordionSummary,
          content: styles.accordionSummaryContent,
        }}
      >
        <Box display="flex" alignItems="center" ml={-1.75}>
          <IconButton
            edge="start"
            style={{
              width: 32,
              transform: "scale(0.8)",
            }}
            size="large"
          >
            <ArrowForwardIosIcon
              sx={{
                color: YELLOW,
              }}
              className={isExpanded ? styles.down : undefined}
            />
          </IconButton>
          <Typography
            style={{
              lineHeight: "32px",
            }}
            component="span"
            variant="body1"
            color="primary"
          >
            See images included in the job request
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        <Box display="center" justifyContent="center" width="100%" height={350}>
          <ImagePreview
            width={isSmall ? 325 : 650}
            color="secondary"
            isLocalImages={false}
            selectedFiles={selectedFiles}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
