import { Box, Typography } from "@mui/material";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { BLUE } from "../../../../../themes/components/utils";
import { Section } from "../../../../common/components";
import {
  Collaborator,
  useCollaboratorsHistory,
} from "../../../../common/hooks/projects/project/collaborations/useCollaboratorsHistory";
import { PaginationFooter } from "../../../../common/professionals-search/PaginationFooter";
import { PortfolioSkeleton } from "../portfolio/PortfolioSkeleton";
import { CollaboratorItem } from "./collaborator-item";

const defaultRowsPerPage = 2;
const itemsPerRow = 1;

export const CollaboratorsHistory: React.FC = () => {
  const { data: collaboratorsHistory, isLoading } = useCollaboratorsHistory();

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(
    () =>
      collaboratorsHistory
        ? Math.round(
            collaboratorsHistory.length / (defaultRowsPerPage * itemsPerRow)
          )
        : 0,
    [collaboratorsHistory]
  );

  const currentPageFilteredItems = useMemo(() => {
    const itemsCount = defaultRowsPerPage * itemsPerRow;
    const endIndex = currentPage * itemsCount;

    return collaboratorsHistory
      ? collaboratorsHistory.slice(endIndex - itemsCount, endIndex)
      : [];
  }, [currentPage, collaboratorsHistory]);

  const handleChangePage = useCallback((event: Object, page: number) => {
    setCurrentPage(page);
  }, []);

  const errorMessage = useMemo(() => {
    return !isLoading && collaboratorsHistory?.length ? (
      ""
    ) : (
      <Fragment>
        <Typography variant="body1">
          You can invite other professionals to collaborate with you once you
          start working on a project.
        </Typography>
        <Typography variant="body1">
          Similarly, you can be invited to collaborate by other professionals.
          This section will list all your collaborators, past and present.
        </Typography>
      </Fragment>
    );
  }, [collaboratorsHistory?.length, isLoading]);

  return (
    <Box height="100%">
      <Section
        isLoading={isLoading}
        errorMessage={errorMessage}
        loadingSkeleton={
          <Box display="flex" flexDirection="column" rowGap={6} pb={10}>
            <PortfolioSkeleton />
            <PortfolioSkeleton />
          </Box>
        }
      >
        <Box py={6} height="100%">
          <Box display="flex" flexDirection="column" height="100%">
            {currentPageFilteredItems.map((collaborator: Collaborator) => {
              return (
                <Box key={collaborator.professional._id} width="100%">
                  <Box
                    key={collaborator.professional._id}
                    bgcolor={BLUE}
                    py={4}
                  >
                    <CollaboratorItem collaborator={collaborator} />
                  </Box>
                </Box>
              );
            })}
          </Box>
          <PaginationFooter
            rowsPerPage={defaultRowsPerPage}
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handleChangePage}
            totalCount={collaboratorsHistory?.length}
            objectNamePlural="collaborations"
            objectNameSingular="collaboration"
          />
        </Box>
      </Section>
    </Box>
  );
};
