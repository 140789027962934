import { Box, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { YELLOW } from "../../../../themes/components/utils";

const useStyles = makeStyles(() => ({
  root: {
    textDecoration: "none",
    color: YELLOW,
  },
}));

export const JobRequestsSkeleton: React.FC = () => {
  const styles = useStyles();

  return (
    <Box pt={12.5}>
      <Box width="100%" display="flex" flexDirection="column" rowGap={3}>
        <Box justifyContent="space-between" display="flex" columnGap={3}>
          <Skeleton
            classes={{
              wave: styles.root,
            }}
            variant="rectangular"
            animation="wave"
            width="50%"
            height={30}
          />
          <Box width={"25%"} display="flex" columnGap={2}>
            <Skeleton
              classes={{
                wave: styles.root,
              }}
              variant="rectangular"
              animation="wave"
              width="30%"
              height={40}
            />
            <Skeleton
              classes={{
                wave: styles.root,
              }}
              variant="rectangular"
              animation="wave"
              width="30%"
              height={40}
            />
            <Skeleton
              classes={{
                wave: styles.root,
              }}
              variant="rectangular"
              animation="wave"
              width="30%"
              height={40}
            />
          </Box>
        </Box>
        <Skeleton
          classes={{
            wave: styles.root,
          }}
          variant="rectangular"
          animation="wave"
          height={30}
        />
        <Skeleton
          classes={{
            wave: styles.root,
          }}
          variant="rectangular"
          animation="wave"
          height={30}
        />
        <Skeleton
          classes={{
            wave: styles.root,
          }}
          variant="rectangular"
          animation="wave"
          height={30}
        />
        <Skeleton
          classes={{
            wave: styles.root,
          }}
          variant="rectangular"
          animation="wave"
          height={30}
        />
      </Box>
    </Box>
  );
};
