import { Box, Button } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { Loader } from "../../../../common/elements/Loader";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import {
  useRemoveFromSavedJobPostMutation,
  useSavedJobPosts,
  useSaveJobPostMutation,
} from "../../../job-posts/hooks";

type JobPostItemActionsProps = {
  jobPostId: string;
  onOpenPreview: () => void;
  label: string;
  onWithdraw?: () => void;
  withdrawLabel?: string;
};

type JobPostItemActionsFC = React.FC<JobPostItemActionsProps>;

export const JobPostItemActions: JobPostItemActionsFC = ({
  jobPostId,
  onOpenPreview,
  label,
  onWithdraw,
  withdrawLabel,
}) => {
  const { mutate: saveJobPost, isLoading: isSavingJobPost } =
    useSaveJobPostMutation();

  const { isSmall } = useResponsiveness();

  const {
    mutate: removeFromSavedJobPost,
    isLoading: isRemovingFromSavedJobPost,
  } = useRemoveFromSavedJobPostMutation();

  const { data: savedJobPosts, isLoading: isLoadingJobPosts } =
    useSavedJobPosts();

  const onSaveJobPostHandler = useCallback(() => {
    saveJobPost(jobPostId);
  }, [jobPostId, saveJobPost]);

  const onRemoveJobPostFromSavedHandler = useCallback(() => {
    removeFromSavedJobPost(jobPostId);
  }, [jobPostId, removeFromSavedJobPost]);

  const saveButton = useMemo(() => {
    const isJobPostSaved = savedJobPosts?.find(
      (savedJobPost) => savedJobPost._id === jobPostId
    );

    return !isJobPostSaved ? (
      <Button
        variant="outlined"
        onClick={onSaveJobPostHandler}
        endIcon={isSavingJobPost ? <Loader size={12} /> : null}
        disabled={isLoadingJobPosts}
        data-cy-btn="save-job"
      >
        Save
      </Button>
    ) : (
      <Button
        variant="outlined"
        onClick={onRemoveJobPostFromSavedHandler}
        endIcon={isRemovingFromSavedJobPost ? <Loader size={12} /> : null}
        disabled={isLoadingJobPosts}
        data-cy-btn="remove-job"
      >
        Remove
      </Button>
    );
  }, [
    isLoadingJobPosts,
    isRemovingFromSavedJobPost,
    isSavingJobPost,
    jobPostId,
    onRemoveJobPostFromSavedHandler,
    onSaveJobPostHandler,
    savedJobPosts,
  ]);

  return (
    <Box display="flex" columnGap={2}>
      {onWithdraw && (
        <Button variant="outlined" size="medium" onClick={onWithdraw}>
          {withdrawLabel}
        </Button>
      )}
      {!isSmall && !onWithdraw && saveButton}
      <Button variant="contained" onClick={onOpenPreview} data-cy-btn="details">
        {label}
      </Button>
    </Box>
  );
};
