import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useHeadlineText,
  useMediumItalic,
  useStepBStyles,
} from "../../../../../../themes/style-hooks";
import { AccountTypeSwitch } from "../../../client/parallax/AccountTypeSwitch";
import { MainActions } from "../../../MainActions";

export const LandingStepB = () => {
  const isSmallView = useMediaQuery("(max-width: 1030px)");
  const styles = useStepBStyles();
  const mediumItalic = useMediumItalic();
  const { transparent, skewedText } = useHeadlineText({
    primary: COLOR.PINK,
    secondary: COLOR.WHITE_GREEN,
    background: COLOR.WHITE_GREEN,
  });
  const { root, common } = useCommonMainButtonsStyles({
    background: COLOR.WHITE_GREEN,
    color: COLOR.PINK,
    hover: COLOR.WHITE_GREEN,
  });

  const descriptionText = useDescriptionText({
    color: COLOR.WHITE_PINK,
  });

  return (
    <React.Fragment>
      <Box textAlign="center" width="75%">
        <Box textAlign="left">
          <Box
            className={mediumItalic.root}
            display="flex"
            style={{
              whiteSpace: isSmallView ? "pre-wrap" : "nowrap",
            }}
          >
            <Box className={`${transparent} ${styles.headline} `}>
              <span>&nbsp;Apply to&nbsp;</span>
            </Box>
            <Box className={`${skewedText} ${styles.headline}`}>
              <span>&nbsp;jobs&nbsp;</span>
            </Box>
            <Box className={`${transparent} ${styles.headline}`}>
              <span>&nbsp;and&nbsp;</span>
            </Box>
            {/*{isSmallView ? (*/}
            {/*<Box className={`${skewedText} ${styles.headline}`}>*/}
            {/*  <span>&nbsp;freelance projects.&nbsp;</span>*/}
            {/*</Box>*/}
            {/*) : (*/}
            {/*  <Box className={`${skewedText} ${styles.headline}`}>*/}
            {/*    <span>&nbsp;freelance&nbsp;</span>*/}
            {/*  </Box>*/}
            {/*)}*/}
          </Box>

          {/*<Box*/}
          {/*  className={mediumItalic.root}*/}
          {/*  style={{*/}
          {/*    whiteSpace: "nowrap",*/}
          {/*  }}*/}
          {/*>*/}
          {/*{isSmallView ? null : (*/}
          {/*  <Box className={`${skewedText} ${styles.headline} `}>*/}
          {/*    <span>&nbsp;projects.&nbsp;</span>*/}
          {/*  </Box>*/}
          {/*)}*/}
          {/*</Box>*/}
          <Box className={mediumItalic.root}>
            <Box className={`${skewedText} ${styles.headline}`}>
              <span>&nbsp;freelance projects.&nbsp;</span>
            </Box>
            <Box className={`${transparent} ${styles.headline}`}>
              <span>&nbsp;All in one place.&nbsp;</span>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box width="75%" pl={8}>
        <span>&nbsp;</span>
        <Box textAlign="left" color={COLOR.WHITE_PINK}>
          <Box className={descriptionText.root}>
            Work on freelance projects, or apply to jobs. All in once place!{" "}
          </Box>
        </Box>
      </Box>

      <MainActions classNames={`${common} ${root}`} />
      {/*<AccountTypeSwitch defaultChecked="professional" />*/}
    </React.Fragment>
  );
};
