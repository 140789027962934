import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Box, Input, TableCell, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Field, useFormikContext } from "formik";
import { forwardRef, Fragment, PropsWithChildren } from "react";
import { COLOR, RED } from "../../../../themes/components/utils";
import { CustomTooltip } from "../../../common/components";

type TableCellWithInputProps = {
  betweenLabel?: string;
  cellWidth?: string | number;
  error?: string;
  isMinMaxInput?: boolean;
  inputWidth?: string | number;
  labelAfter?: string;
  labelBefore?: string;
  maxError?: string;
  maxNameSuffix?: string;
  maxValue?: number;
  minError?: string;
  minNameSuffix?: string;
  minValue?: number;
  name: string;
  type?: string;
  value?: string | number;
};

const useStyles = makeStyles({
  input: {
    backgroundColor: "transparent",
    border: "1px solid",
    borderRadius: 2,
    color: COLOR.WHITE,
    fontSize: 16,
    height: 28,
    "& input": {
      padding: "0px 12px",
    },

    /* Chrome, Safari, Edge, Opera */
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    /* Firefox */
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  inputError: {
    border: `2px solid ${RED}`,
  },
});

export const TableCellWithInput = forwardRef<
  HTMLInputElement,
  PropsWithChildren<TableCellWithInputProps>
>(
  (
    {
      betweenLabel,
      cellWidth,
      children,
      error,
      isMinMaxInput,
      inputWidth = "100%",
      labelAfter,
      labelBefore,
      maxError,
      maxNameSuffix,
      maxValue,
      minError,
      minNameSuffix,
      minValue,
      name,
      type = "text",
      value,
    },
    ref
  ) => {
    const styles = useStyles();
    const { handleBlur, handleChange } = useFormikContext();

    return (
      <TableCell width={cellWidth}>
        <Box alignItems="center" display="flex" columnGap={2}>
          {!isMinMaxInput ? (
            <Fragment>
              {labelBefore && (
                <Typography variant="body2">{labelBefore}</Typography>
              )}

              <Field
                as={Input}
                disableUnderline
                className={`${styles.input} ${!error ? "" : styles.inputError}`}
                name={name}
                onBlur={handleBlur}
                onChange={handleChange}
                type={type}
                value={value}
                style={{ width: inputWidth }}
                inputProps={{
                  ref,
                }}
                data-cy={name}
                endAdornment={
                  error && (
                    <CustomTooltip title={error} type="error">
                      <ErrorRoundedIcon
                        color="error"
                        data-cy-error={name}
                        style={{ cursor: "initial" }}
                        fontSize="small"
                      />
                    </CustomTooltip>
                  )
                }
              />

              {labelAfter && (
                <Typography variant="body2">{labelAfter}</Typography>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {labelBefore && (
                <Typography variant="body2">{labelBefore}</Typography>
              )}

              <Field
                disableUnderline
                as={Input}
                className={`${styles.input} ${
                  !minError ? "" : styles.inputError
                }`}
                name={`${name}.${minNameSuffix}`}
                onBlur={handleBlur}
                onChange={handleChange}
                type={type}
                value={minValue}
                style={{ width: inputWidth }}
                inputProps={{
                  ref,
                }}
                data-cy={`${name}.${minNameSuffix}`}
                endAdornment={
                  minError && (
                    <CustomTooltip title={minError} type="error">
                      <ErrorRoundedIcon
                        color="error"
                        data-cy-error={`${name}.${minNameSuffix}`}
                        style={{ cursor: "initial" }}
                        fontSize="small"
                      />
                    </CustomTooltip>
                  )
                }
              />

              {betweenLabel && (
                <Typography variant="body2">{betweenLabel}</Typography>
              )}

              <Field
                as={Input}
                disableUnderline
                className={`${styles.input} ${
                  !maxError ? "" : styles.inputError
                }`}
                name={`${name}.${maxNameSuffix}`}
                onBlur={handleBlur}
                onChange={handleChange}
                type={type}
                value={maxValue}
                style={{ width: inputWidth }}
                data-cy={`${name}.${maxNameSuffix}`}
                endAdornment={
                  maxError && (
                    <CustomTooltip title={maxError} type="error">
                      <ErrorRoundedIcon
                        color="error"
                        data-cy-error={`${name}.${maxNameSuffix}`}
                        style={{ cursor: "initial" }}
                        fontSize="small"
                      />
                    </CustomTooltip>
                  )
                }
              />

              {labelAfter && (
                <Typography variant="body2">{labelAfter}</Typography>
              )}
            </Fragment>
          )}
          {children}
        </Box>
      </TableCell>
    );
  }
);
