import { Box, Divider, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { FC } from "react";
import { useStepAStyles } from "../../../../../../themes/style-hooks";

export const CardsCollection: FC = () => {
  const mobileView = useMediaQuery("(max-width: 600px)");
  const styles = useStepAStyles();

  return mobileView ? (
    <Box
      width="100%"
      className={`${styles.mobileCardWrapper}`}
      height="25%"
      display="flex"
      alignItems="flex-end"
    >
      <Box
        rowGap={2}
        display="grid"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Box width="100%">
          <Box pt={4}>
            <Typography className={styles.cardText}>
              Apply to jobs and freelance projects. All in once place.
            </Typography>
          </Box>
        </Box>
        <Box width="100%">
          <Divider orientation="horizontal" />
          <Box pt={4}>
            <Typography className={styles.cardText}>
              Start with unlimited free bids. Sign up only when you work on a
              project.
            </Typography>
          </Box>
        </Box>
        <Box width="100%">
          <Divider orientation="horizontal" />
          <Box pt={4}>
            <Typography className={styles.cardText}>
              Keep all the money you earn. No commission.
            </Typography>
          </Box>
        </Box>
        <Box width="100%">
          <Divider orientation="horizontal" />
          <Box pt={4}>
            <Typography className={styles.cardText}>
              Refer professionals and collaborate on projects
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box display="flex" className={`${styles.cardWrapper}`} height={140}>
      <Box
        display="flex"
        justifyContent="space-evenly"
        height="100%"
        alignSelf="flex-end"
      >
        <Divider orientation="vertical" />
        <Box width="20%" className={styles.cardText}>
          Apply to jobs and freelance projects. All in once place.
        </Box>{" "}
        <Divider orientation="vertical" />
        <Box width="20%" className={styles.cardText}>
          Start with unlimited free bids. Sign up only when you work on a
          project.
        </Box>
        <Divider orientation="vertical" />
        <Box width="20%" className={styles.cardText}>
          Keep all the money you earn. <br /> No commission.
        </Box>
        <Divider orientation={"vertical"} />
        <Box width="20%" className={styles.cardText}>
          Refer professionals and collaborate on projects
        </Box>
      </Box>
    </Box>
  );
};
