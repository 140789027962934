import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  HeaderDropdown,
  LoadingTypography,
} from "../../../../../../../common/components";
import { useNavigationHandlers } from "../../../../../../../common/hooks/navigation";
import { useProjectQuery } from "../../../../../../../common/hooks/projects/project/useProjectQuery";
import { useProjectsQuery } from "../../../../../../../common/hooks/projects/useProjectsQuery";
import { useProjectsOptions } from "../../../../../../bids/hooks/useProjectOptions";

type ContentProps = {};

export const CurrentProjectHeaderContent: React.FC<ContentProps> = () => {
  const { projectId, professionalId } = useParams();

  const navigate = useNavigate();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const { data: projects, isLoading: isLoadingProjects } =
    useProjectsQuery(professionalId);

  const isLoading = isLoadingProject || isLoadingProjects;

  const collectionConfig = {
    type: `professionals/${professionalId}/projects/`,
    items: projects,
  };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoading,
    projectId
  );

  const handleSelectCurrentProject = useCallback(
    (index: number) => {
      if (!projects) return;

      navigate(
        `/professionals/${professionalId}/projects/${projects[index]._id}`
      );
    },
    [navigate, professionalId, projects]
  );

  const projectsOptions = useProjectsOptions();

  if (isLoading) return null;

  return (
    <Box width="60%" pt={10}>
      <Box>
        <Box pb={10}>
          <LoadingTypography
            typographyProps={{
              variant: "subtitle2",
            }}
          >
            Current Project ({index + 1} of {projects?.length})
          </LoadingTypography>
        </Box>
        <HeaderDropdown
          selectedOptionId={projectId}
          options={projectsOptions}
          onSelect={handleSelectCurrentProject}
          placeholder={project?.jobRequest?.title}
        />
      </Box>
    </Box>
  );
};
