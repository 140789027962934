import MenuIcon from "@mui/icons-material/Menu";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Badge, Button, IconButton } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountType } from "../../../api-types/common";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { NotificationsMenu } from "../../professional/navbar/NotificationsMenu";
import { useMetadataQuery } from "../hooks/metadata/useMetadataQuery";
import { useReadNotificationMutation } from "../hooks/notifications/useReadNotificationMutation";
import { useResponsiveness } from "../hooks/utils/useResponsiveness";
import { TheTalentedLogo } from "../logo";
import { MobileDrawer } from "./MobileDrawer";
import { UserMenu } from "./UserMenu";
import { UserNavbar } from "./UserNavbar";

type NavbarProps = {
  accountType?: AccountType | null;
  inverse?: boolean;
};

export const getMenuItemLabel = (
  baseLabel: string,
  isLoading: boolean,
  count?: number
) => {
  if (isLoading) return baseLabel;

  return `${baseLabel} (${count ?? 0})`;
};

export const Navbar: React.FC<NavbarProps> = ({
  accountType,
  inverse = true,
}) => {
  const theme = useTheme();

  const { isSmall, isLarge } = useResponsiveness();

  const { data: metadata } = useMetadataQuery({
    enabled: !!accountType,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { mutate: readNotification } = useReadNotificationMutation();

  const onOptionsDropdownClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      readNotification("all");
    },
    [readNotification]
  );

  const onDropdownClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const notificationsCount = metadata?.notificationsCount ?? 0;

  const navigate = useNavigate();

  return (
    <Box
      bgcolor={theme.palette.primary.main}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={5}
      px={isLarge ? 5 : 2.5}
    >
      {isLarge && (
        <IconButton onClick={openDrawer}>
          <MenuIcon color="secondary" />
        </IconButton>
      )}
      {!isSmall && <TheTalentedLogo inverse={inverse} />}

      {!isLarge && accountType && <UserNavbar accountType={accountType} />}

      {!accountType && (
        <Box display="flex" columnGap={4}>
          <Button
            variant="text"
            color={inverse ? "secondary" : "primary"}
            size="medium"
            onClick={() => navigate("/sign-up")}
            data-cy-sign-up="top-bar"
            style={{
              fontWeight: "medium",
            }}
          >
            Sign Up
          </Button>
          <Button
            size="medium"
            variant="contained"
            onClick={() => navigate("/sign-in")}
            data-cy-sign-in="top-bar"
            style={{
              backgroundColor: BLUE,
              color: YELLOW,
              fontWeight: "medium",
            }}
          >
            Sign In
          </Button>
        </Box>
      )}
      {accountType && (
        <Box display="flex-center" alignItems="center" columnGap={2}>
          <Box height={44} pt={2}>
            <Badge badgeContent={notificationsCount} color="primary" max={9}>
              <IconButton
                size="small"
                onClick={onOptionsDropdownClick}
                disabled={notificationsCount === 0}
              >
                <NotificationsOutlinedIcon color="secondary" />
              </IconButton>
            </Badge>
            <NotificationsMenu anchorEl={anchorEl} onClose={onDropdownClose} />
          </Box>

          <Box pt={2}>
            <UserMenu accountType={accountType} />
          </Box>
        </Box>
      )}
      <MobileDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        accountType={accountType}
      />
    </Box>
  );
};
