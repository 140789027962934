import { CircularProgress, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMetadataQuery } from "../../common/hooks/metadata/useMetadataQuery";
import { useResponsiveness } from "../../common/hooks/utils/useResponsiveness";
import { getMenuItemLabel } from "../../common/navbar/Navbar";

type MoreMenuProps = {
  anchorEl?: Element | null;
  handleClose?: () => void;
};

export const MoreMenu: React.FC<MoreMenuProps> = ({
  anchorEl,
  handleClose,
}) => {
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const { isLarge, isExtraLarge } = useResponsiveness();

  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      {!isLarge && isExtraLarge && (
        <MenuItem
          onClick={() => {
            navigate(`/bids/`);
            handleClose?.();
          }}
        >
          {getMenuItemLabel(
            "Bids",
            isLoadingMetadata,
            metadata?.accountBidsCount
          )}
          {isLoadingMetadata && (
            <CircularProgress
              color="inherit"
              style={{
                marginLeft: 8,
                width: 16,
                height: 16,
              }}
            />
          )}
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          navigate(`/inquiries/`);
          handleClose?.();
        }}
      >
        {getMenuItemLabel(
          "Job Request Inquiries",
          isLoadingMetadata,
          metadata?.accountInquiriesCount
        )}
        {isLoadingMetadata && (
          <CircularProgress
            color="inherit"
            style={{
              marginLeft: 8,
              width: 16,
              height: 16,
            }}
          />
        )}
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/professional-referrals/");
          handleClose?.();
        }}
      >
        {getMenuItemLabel(
          "Referrals From Pros",
          isLoadingMetadata,
          metadata?.professionalReferralsCount
        )}
        {isLoadingMetadata && (
          <CircularProgress
            color="inherit"
            style={{
              marginLeft: 8,
              width: 16,
              height: 16,
            }}
          />
        )}
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/invitations/");
          handleClose?.();
        }}
      >
        {getMenuItemLabel(
          "Client Invitations",
          isLoadingMetadata,
          metadata?.clientInvitationsCount
        )}
        {isLoadingMetadata && (
          <CircularProgress
            color="inherit"
            style={{
              marginLeft: 8,
              width: 16,
              height: 16,
            }}
          />
        )}
      </MenuItem>
    </Menu>
  );
};
