import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-undef
    const token = window.localStorage.getItem("access_token");
    let parsed = "";

    try {
      parsed = JSON.parse(token ?? "");
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (token && config?.headers) {
      config.headers["Authorization"] = "Bearer " + parsed;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axios;
