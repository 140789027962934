import { Box, Divider } from "@mui/material";
import React from "react";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { InvitationHeaderContent } from "./InvitationHeaderContent";
import { InvitationHeaderSummary } from "./InvitationHeaderSummary";

export const InvitationHeader: React.FC = () => {
  const { isMedium } = useResponsiveness();
  return (
    <Box
      display="flex"
      flexDirection={isMedium ? "column" : "row"}
      columnGap={isMedium ? 0 : 10}
      py={10}
    >
      <InvitationHeaderContent />
      {!isMedium && <Divider orientation="vertical" flexItem />}
      <InvitationHeaderSummary />
      {isMedium && (
        <Divider
          style={{ marginTop: isMedium ? 12 : 0 }}
          orientation={!isMedium ? "horizontal" : "vertical"}
          flexItem
        />
      )}
    </Box>
  );
};
