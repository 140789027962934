import { Box, Button, Divider, Link, Typography } from "@mui/material";
import { format } from "date-fns";
import { capitalize, noop } from "lodash";
import React, { Fragment, useState } from "react";
import { JobApplication } from "../../../api-types/job-applications";
import { Status } from "../../../api-types/job-applications/JobApplication";
import { COLOR } from "../../../themes/components/utils";
import { ConfirmationDialog } from "../../common/components";
import { formatShortDate } from "../../common/components/LoadingTypography";
import { useProfessional } from "../../common/hooks/professionals/professional/useProfessional";
import { useResponsiveness } from "../../common/hooks/utils/useResponsiveness";
import { ProfessionalsSkeleton } from "../../common/professionals-search/professionals/ProfessionalsSkeleton";
import { ProfileSummaryDescription } from "../../common/professionals-search/professionals/ProfileSummaryDescription";
import { ProfileSummaryInfo } from "../../common/professionals-search/professionals/ProfileSummaryInfo";
import { useUpdateJobApplication } from "../../professional/job-posts/hooks/useUpdateJobApplication";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../utils/useToast";
import { useStyles } from "./JobPostsDashboard";
import { PreviewPdfDialog } from "./PreviewPdfDialog";

type JobPostApplicantDetailsProps = {
  application: JobApplication;
};

export const JobPostApplicantDetails = ({
  application,
}: JobPostApplicantDetailsProps) => {
  const styles = useStyles();
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const { mutate: updateJobApplication, isLoading: isUpdatingJobApplication } =
    useUpdateJobApplication();

  const { data: applicant, isLoading } = useProfessional(
    application.createdBy._id
  );
  const { notifySuccess } = useToast();
  const { isSmall } = useResponsiveness();

  const onRejectApplicant = () => {
    updateJobApplication(
      {
        _id: application._id,
        jobPost: String(application?.jobPost) ?? "",
        status: Status.REJECTED,
      },
      {
        onSuccess() {
          notifySuccess(
            feedbackMessage("candidate", FEEDBACK_TYPE.JOB_APPLICATION_REJECTED)
          );
        },
      }
    );

    setOpenRejectDialog(false);
  };

  const handleShortlist = () => {
    updateJobApplication(
      {
        _id: application._id,
        jobPost: String(application?.jobPost) ?? "",
        status: Status.SHORTLISTED,
      },
      {
        onSuccess() {
          notifySuccess(
            feedbackMessage(
              "candidate",
              FEEDBACK_TYPE.JOB_APPLICATION_SHORTLISTED
            )
          );
        },
      }
    );
  };

  const [open, setOpen] = useState(false);

  const onPreviewResume = () => {
    setOpen(true);
  };

  if (isLoading) {
    return <ProfessionalsSkeleton />;
  }

  return (
    <Box
      className={styles.border}
      display="flex"
      columnGap={8}
      key={application._id}
      py={8}
      flexDirection={isSmall ? "column" : "row"}
    >
      <Box
        display="flex"
        columnGap={8}
        width="70%"
        alignItems={isSmall ? "flex-start" : "center"}
        flexDirection={isSmall ? "column" : "row"}
      >
        <ProfileSummaryInfo
          _id={application?._id ?? ""}
          {...applicant}
          hideActionButton
          isLoading={isLoading}
        />
        <ProfileSummaryDescription
          onClick={noop}
          profileInformation={applicant?.profileInformation}
          accountInformation={applicant?.accountInformation}
        />
      </Box>

      {isSmall ? (
        <Box py={8}>
          <Divider orientation="vertical" flexItem />
        </Box>
      ) : (
        <Divider orientation="vertical" flexItem />
      )}

      <Box
        display="flex"
        flexDirection="column"
        rowGap={3}
        style={{
          flexBasis: "340px",
          maxWidth: "340px",
        }}
      >
        <Box display="flex" columnGap={1}>
          <Typography
            component="span"
            variant="body2"
            style={{
              fontWeight: 500,
            }}
          >
            Status
          </Typography>
          <Typography
            component="span"
            variant="body2"
            style={{
              fontWeight: 600,
              color:
                // eslint-disable-next-line no-nested-ternary
                application.status === Status.REJECTED
                  ? COLOR.RED
                  : application.status === Status.SHORTLISTED
                  ? COLOR.GREEN
                  : COLOR.YELLOW,
            }}
          >
            {capitalize(application.status)}
          </Typography>
        </Box>

        <Box display="flex" columnGap={1}>
          <Typography
            component="span"
            variant="body2"
            style={{
              fontWeight: 500,
            }}
          >
            Applied
          </Typography>
          <Typography component="span" variant="body2">
            {format(new Date(application.createdAt), formatShortDate)}
          </Typography>
        </Box>

        {application.resume && (
          <Fragment>
            <Box display="flex" columnGap={1}>
              <Typography
                component="span"
                variant="body2"
                style={{
                  fontWeight: 500,
                }}
              >
                Resume
              </Typography>
              <Typography
                color="primary"
                component="span"
                variant="body2"
                noWrap
              >
                {application.resume.match(/\/([\w-]+\.pdf)$/)?.[1]}
              </Typography>
            </Box>

            <Box
              display="flex"
              columnGap={2}
              justifyContent={isSmall ? "flex-start" : "flex-end"}
            >
              <Link href={application.resume} target="_blank">
                <Button color="primary" size="small">
                  Download
                </Button>
              </Link>
              <Button
                color="primary"
                onClick={onPreviewResume}
                size="small"
                variant="contained"
              >
                Preview
              </Button>
            </Box>
          </Fragment>
        )}

        <Box
          display="flex"
          columnGap={2}
          mt="auto"
          justifyContent={isSmall ? "flex-start" : "flex-end"}
        >
          <Button
            color="primary"
            disabled={
              isUpdatingJobApplication || application.status === Status.REJECTED
            }
            onClick={() => setOpenRejectDialog(true)}
            size="small"
          >
            Reject
          </Button>
          <Button
            color="primary"
            disabled={
              isUpdatingJobApplication ||
              application.status === Status.SHORTLISTED
            }
            size="small"
            variant="contained"
            onClick={handleShortlist}
          >
            Shortlist
          </Button>
        </Box>
      </Box>

      <ConfirmationDialog
        confirmLabel="Reject"
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        onConfirm={onRejectApplicant}
        title="Are you sure you want to reject this applicant?"
      />

      <PreviewPdfDialog
        open={open}
        onClose={() => setOpen(false)}
        resumeUrl={application.resume}
      />
    </Box>
  );
};
