import { Box, Button, MenuItem, Popover, Skeleton } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccountType } from "../../../../api-types/common";
import { ProfileInformation } from "../../../../api-types/profile-information";
import { ACCOUNT_EDIT_ROUTE_URL } from "../../../../routes/professional";
import { useUser } from "../../../state/hooks";
import { useInvitableJobRequests } from "../../hooks/job-requests/useInvitableJobRequests";
import { useMetadataQuery } from "../../hooks/metadata/useMetadataQuery";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { SkeletonCollection } from "../../professionals-search/browse-mainbar/SkeletonCollection";
import { ProfessionalSelect } from "../../professionals-search/professionals/select/ProfessionalSelect";
import { ProfilePicture } from "../../summary-profile";

type ProfessionalAccountSummaryInfoProps = {
  profileInformation?: ProfileInformation;
  isLoading?: boolean;
};

export const ProfessionalAccountSummaryInfo: React.FC<
  ProfessionalAccountSummaryInfoProps
> = ({ profileInformation, isLoading }) => {
  const { accountType } = useScopedDowngradedStateValue(useUser());
  const { professionalId } = useParams();
  const navigate = useNavigate();

  const { jobRequests } = useInvitableJobRequests();

  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();

  const onEditHandler = useCallback(() => {
    navigate(ACCOUNT_EDIT_ROUTE_URL);
  }, [navigate]);

  const isActionButtonDisabled = useMemo(() => {
    return isLoadingMetadata || accountType === AccountType.CLIENT
      ? !metadata?.jobRequestsCount
      : !metadata?.projectsCount;
  }, [
    accountType,
    isLoadingMetadata,
    metadata?.jobRequestsCount,
    metadata?.projectsCount,
  ]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(open ? null : event.currentTarget);
    },
    [open]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const action = useMemo(() => {
    if (location.pathname.includes("account")) {
      return (
        <Button size="medium" variant="contained" onClick={onEditHandler}>
          Edit Profile
        </Button>
      );
    }

    if (accountType === AccountType.CLIENT) {
      return (
        <Box>
          <Button
            size="medium"
            disabled={!jobRequests?.length || isActionButtonDisabled}
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            Invite Pro
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: { minWidth: 160 },
              }}
            >
              {jobRequests.map((jobRequest) => (
                <MenuItem
                  key={jobRequest._id}
                  value={jobRequest._id}
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(`/invite/${professionalId}`, {
                      state: {
                        jobRequestId: jobRequest._id,
                        jobRequestTitle: jobRequest.title,
                      },
                    });
                  }}
                >
                  {jobRequest.title}
                </MenuItem>
              ))}
            </Popover>
          </Button>
        </Box>
      );
    }

    if (professionalId?.length) {
      return <ProfessionalSelect professionalId={professionalId} />;
    }

    return (
      <>
        <Button size="medium" variant="contained" onClick={onEditHandler}>
          Edit Profile
        </Button>
      </>
    );
    // eslint-disable-next-line
  }, [
    accountType,
    anchorEl,
    handleClick,
    isActionButtonDisabled,
    jobRequests,
    navigate,
    onEditHandler,
    open,
    professionalId,
  ]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" py={10}>
      <ProfilePicture
        width={168}
        isLoading={isLoading}
        profileInformation={profileInformation}
      />
      <Box pt={4}>
        <SkeletonCollection
          isLoading={isLoading}
          skeleton={
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={100}
              height={40}
            />
          }
        >
          {action}
        </SkeletonCollection>
      </Box>
    </Box>
  );
};
