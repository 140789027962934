import {
  Box,
  Button,
  FormControlLabel,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC, useMemo, useState } from "react";
import { BLUE, YELLOW } from "../../../../../themes/components/utils";
import { Radio, TextOverflow } from "../../../../common/components";

type PackagesCardsProps = {
  selectLabel?: string;
  onSelect: (option: string) => void;
  inverse?: boolean;
  buttonStyles?: string;
  radioColor?: string;
  isLoading?: boolean;
  onClick?: () => void;
};

export const PackagesCards: FC<PackagesCardsProps> = ({
  onSelect,
  selectLabel = "Select",
  inverse,
  buttonStyles,
  radioColor,
  isLoading,
  onClick,
}) => {
  const [selectedJobPackage, setSelectedJobPackage] = useState("3-jobs");

  const isSmall = useMediaQuery("(max-width: 720px)");
  const isExtraSmall = useMediaQuery("(max-width: 520px)");

  const boxWidth = useMemo(() => {
    if (isExtraSmall) {
      return 260;
    }

    if (isSmall) {
      return 340;
    }

    return "fit-content";
  }, [isExtraSmall, isSmall]);

  const innerWidth = useMemo(() => {
    if (isExtraSmall) {
      return 180;
    }

    if (isSmall) {
      return 240;
    }

    return 300;
  }, [isExtraSmall, isSmall]);

  return (
    <Box
      display="flex"
      columnGap={3}
      height="100%"
      gap={5}
      flexDirection={isSmall ? "column" : "row"}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="flex"
        alignItems={"center"}
        flexDirection="column"
        justifyContent="space-around"
        width={boxWidth}
        px={5}
        py={isSmall ? 5 : 8}
        height={isSmall ? "75%" : "100%"}
        style={{
          border: `1px solid ${inverse ? BLUE : YELLOW}`,
        }}
      >
        <Box
          height={30}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <TextOverflow
            width={"100%"}
            style={{
              height: "100%",
              fontWeight: 600,
              color: radioColor,
              fontSize: isSmall ? 18 : 21,
            }}
          >
            Single Job Post
          </TextOverflow>
        </Box>
        <Box
          height="60%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography
            variant={isSmall ? "subtitle2" : "subtitle1"}
            color={inverse ? "secondary" : "primary"}
            style={{
              fontWeight: 600,
            }}
          >
            $99
          </Typography>
        </Box>
        <Box
          height="20%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Button
            data-cy-btn="credits-package-single"
            disabled={isLoading}
            variant="contained"
            size={isSmall ? "small" : "medium"}
            className={buttonStyles}
            onClick={() => {
              onSelect("1-job");
              onClick?.();
            }}
          >
            {selectLabel}
          </Button>
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems={"center"}
        flexDirection="column"
        justifyContent="space-around"
        width={boxWidth}
        px={5}
        py={isSmall ? 5 : 8}
        height={isSmall ? "75%" : "100%"}
        style={{
          border: `1px solid ${inverse ? BLUE : YELLOW}`,
        }}
      >
        <Box
          height={30}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <TextOverflow
            width={"100%"}
            style={{
              height: "100%",
              fontWeight: 600,
              color: radioColor,
              fontSize: isSmall ? 18 : 21,
            }}
          >
            Job Posts Packages
          </TextOverflow>
        </Box>

        <Box
          height={isSmall ? "70%" : "60%"}
          width={"80%"}
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <RadioGroup
            defaultValue={selectedJobPackage}
            onChange={(e) => {
              setSelectedJobPackage(e.target.value);
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Radio
                    radioColor={radioColor}
                    value={"3-jobs"}
                    data-cy="3-jobs"
                  />
                }
                label={
                  <Box
                    display="flex"
                    width={innerWidth}
                    justifyContent="space-between"
                  >
                    <Typography
                      variant={isSmall ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      3 Job Posts
                    </Typography>
                    <Typography
                      variant={isSmall ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                    >
                      $85/Job Post
                    </Typography>
                  </Box>
                }
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Radio
                    radioColor={radioColor}
                    value={"5-jobs"}
                    data-cy="5-jobs"
                  />
                }
                label={
                  <Box
                    display="flex"
                    width={innerWidth}
                    justifyContent="space-between"
                  >
                    <Typography
                      variant={isSmall ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      5 Job Posts
                    </Typography>
                    <Typography
                      variant={isSmall ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                    >
                      $75/Job Post
                    </Typography>
                  </Box>
                }
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Radio
                    radioColor={radioColor}
                    value="10-jobs"
                    data-cy="10-jobs"
                  />
                }
                label={
                  <Box
                    display="flex"
                    width={innerWidth}
                    justifyContent="space-between"
                  >
                    <Typography
                      variant={isSmall ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      10 Job Posts
                    </Typography>
                    <Typography
                      variant={isSmall ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                    >
                      $65/Job Post
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </RadioGroup>
        </Box>
        <Box
          height="20%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Button
            disabled={isLoading}
            data-cy-btn="credits-package"
            variant="contained"
            className={buttonStyles}
            size={isSmall ? "small" : "medium"}
            onClick={() => {
              onSelect(selectedJobPackage);
              onClick?.();
            }}
          >
            {selectLabel}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
