import { Components } from "@mui/material";

export const MuiSelect: Components["MuiSelect"] = {
  styleOverrides: {
    select: {
      display: "block",
      textOverflow: "ellipsis",
      textAlignLast: "start",
      paddingLeft: 16,
    },
    icon: {
      top: "calc(50% - 18px)",
      width: 32,
      height: 32,
      marginTop: 4,
      paddingLeft: 2,
    },
  },
};
//
// export const MuiSelect: MuiComponentThemeDefinition<
//   SelectClassKey,
//   SelectProps
// > = {
//   props: {},
//   override: () => ({
//     root: {
//       display: "block",
//       textOverflow: "ellipsis",
//       textAlignLast: "start",
//       paddingLeft: 16,
//     },
//     icon: {
//       top: "calc(50% - 18px)",
//       width: 32,
//       height: 32,
//       marginTop: 4,
//       paddingLeft: 2,
//     },
//   }),
// };
