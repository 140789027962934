import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Field } from "formik";
import { range } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { SkillV2 } from "../../../api-types/job-requests/common";
import { BLUE } from "../../../themes/components/utils";
import { useKnownSkills } from "../hooks/skills/useKnownSkills";
import { useResponsiveness } from "../hooks/utils/useResponsiveness";
import { AddedSkill } from "./AddedSkill";
import { CustomTooltip } from "./CustomTooltip";

type SkillsInputProps = {
  initialSkills?: SkillV2[];
  handleChange: (newSkills: SkillV2[]) => void;
  width?: number | string;
  error?: string;
};

const useStyles = makeStyles({
  buttonAdd: {
    border: "none",
    borderRadius: 10,
    alignSelf: "center",
    backgroundColor: BLUE,
    color: "white",
    "&:hover": {
      backgroundColor: BLUE,
    },
  },
});

const COUNT = 6;
const MAX_SKILLS_LIMIT = 15;

export const SkillsInput: React.FC<SkillsInputProps> = ({
  initialSkills = [],
  handleChange,
  width = 830,
  error,
}) => {
  const { palette } = useTheme();
  const [skills, setSkills] = useState<Set<SkillV2>>(new Set(initialSkills));
  const [value, setValue] = useState("");
  const { data: knownSkills } = useKnownSkills();
  const styles = useStyles();
  const { isMedium } = useResponsiveness();

  const suggestions = useMemo(() => {
    return knownSkills?.filter((knownSkill) =>
      knownSkill?.toLowerCase()?.startsWith(value?.toLowerCase())
    );
  }, [knownSkills, value]);

  const rangeFilteredSuggestions = useCallback(
    (index) => {
      return suggestions
        ? suggestions.slice(index * COUNT, index * COUNT + COUNT)
        : [];
    },
    [suggestions]
  );

  const columns = Math.min(Math.ceil(suggestions?.length ?? 0 / COUNT), 5);

  const onRemoveSkill = useCallback(
    (skill: SkillV2) => {
      const updated = new Set(skills);

      updated.delete(skill);

      setSkills(updated);
      handleChange(Array.from(updated));
    },
    [handleChange, skills]
  );

  const onAddNewSkill = useCallback(
    (skill: SkillV2) => {
      if (skills.size === MAX_SKILLS_LIMIT) return;
      const updated = new Set(skills.add(skill));
      setSkills(updated);
      handleChange(Array.from(updated));
      setValue("");
    },
    [handleChange, skills]
  );

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.keyCode == 13 && value.length) {
        event.preventDefault();
        onAddNewSkill(value);
      }
    },
    [onAddNewSkill, value]
  );

  return (
    <Box display="flex" flexDirection="column" mb={isMedium ? 8 : 0}>
      <Box>
        <Typography
          variant="subtitle2"
          style={{
            alignItems: "center",
            color: !error ? undefined : palette.error.main,
            display: "flex",
            gap: 8,
          }}
        >
          <Box
            color="inherit"
            component="span"
            style={{
              fontWeight: "bold",
            }}
          >
            Selected Skills
          </Box>
          ({skills.size} out of {MAX_SKILLS_LIMIT})
          {error && (
            <CustomTooltip title={error} type="error">
              <ErrorRoundedIcon
                color="error"
                data-cy-error="skills"
                style={{ cursor: "initial" }}
              />
            </CustomTooltip>
          )}
        </Typography>
      </Box>
      <Box width={width} pt={2} pb={4} minHeight={64}>
        <Grid container data-cy="skills-list" spacing={4}>
          {Array.from(skills).map((skill) => (
            <Grid item key={skill}>
              <AddedSkill skillName={skill} onRemove={onRemoveSkill} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box width={width}>
        <Field
          component={TextField}
          fullWidth
          variant="outlined"
          onChange={(event: any) => {
            setValue(event.target.value);
          }}
          value={value}
          onKeyDown={onKeyDown}
          placeholder="Search and Add Skills"
          name="search"
          InputProps={{
            endAdornment: value.length ? (
              <Button
                variant="contained"
                onClick={() => value.length && onAddNewSkill(value)}
                className={styles.buttonAdd}
                data-cy-btn="add"
              >
                Add
              </Button>
            ) : null,
          }}
          data-cy="skills-input"
        />
      </Box>
      {Boolean(value) && (
        <Box pt={5} width={width} display="flex" justifyContent="end">
          {range(columns).map((index) => (
            <Box key={index} width="100%" display="flex">
              <Divider orientation="vertical" />
              <Box pl={4} display="flex" flexDirection="column">
                {rangeFilteredSuggestions(index).map((skill) => (
                  <Typography
                    key={skill}
                    style={{
                      fontSize: 12,
                      paddingBottom: 8,
                      cursor: "pointer",
                      color: "white",
                    }}
                    onClick={() => skill.length && onAddNewSkill(skill)}
                    color={skill === value ? "primary" : "initial"}
                  >
                    {skill}
                  </Typography>
                ))}
              </Box>
            </Box>
          ))}
          {(suggestions?.length ?? 0) === 0 && (
            <Box justifySelf="end">
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: "bold",
                }}
              >
                Skill is not listed. Would you like to add it?
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
