import { Box, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ProfileIcon } from "../../../icons/ProfileIcon";

type ProfessionalsProps = {
  list: any[];
};

const useStyles = makeStyles({
  smallIcon: {
    width: 64,
    height: 64,
  },
});

export const Professionals: React.FC<ProfessionalsProps> = ({ list }) => {
  const styles = useStyles();

  return (
    <Box display="flex" columnGap={6} py={4}>
      {list.map(({ name, position }) => (
        <Box display="flex" key={`${name}-${position}`}>
          <IconButton className={styles.smallIcon} size="large">
            <ProfileIcon />
          </IconButton>
          <Box>
            <Typography variant="h2" color="secondary">
              {name}
            </Typography>
            <Typography variant="h2" color="secondary">
              {position}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
