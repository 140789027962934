import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { PageWrapper } from "../../../../app/container/PageWrapper";
import { Section } from "../../../../common/components";
import { InfoBox } from "../../../../common/components/InfoBox";
import { CollectionNavigationActions } from "../../../../common/elements/CollectionNavigationActions";
import {
  Collection,
  useNavigationHandlers,
} from "../../../../common/hooks/navigation";
import { useProjectQuery } from "../../../../common/hooks/projects/project/useProjectQuery";
import { useUnpublishedProjectsQuery } from "../../../../common/hooks/projects/useUnpublishedProjectsQuery";
import { Details } from "../details";
import { UnpublishedPastProjectHeader } from "./header";
import { useDefaultUnpublishedPastProjectPage } from "./hooks";
import { PublishProject } from "./PublishProject";
import { UnpublishedSkeleton } from "./UnpublishedSkeleton";

export const UnpublishedPastProject: React.FC = () => {
  useDefaultUnpublishedPastProjectPage();

  const { projectId } = useParams();
  const { data: projects, isLoading: isLoadingProjects } =
    useUnpublishedProjectsQuery();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const collectionConfig = {
    type: Collection.UNPUBLISHED_PROJECTS,
    items: projects,
  };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(collectionConfig, isLoadingProjects, projectId);

  const errorMessage = useMemo(() => {
    return !projectId && !isLoadingProjects
      ? "All completed or canceled projects that haven’t been included in your portfolio projects will be shown here."
      : "";
  }, [isLoadingProjects, projectId]);

  return (
    <PageWrapper>
      <Section
        isLoading={isLoadingProjects || isLoadingProject}
        errorMessage={errorMessage}
        loadingSkeleton={<UnpublishedSkeleton />}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <UnpublishedPastProjectHeader />
          {project?.closingRequester === "client" && (
            <Box py={5}>
              <InfoBox
                hideNumbers
                alertTitle="The client has canceled the project."
                messages={[
                  "The funds for the milestone you were working on are being transferred to your account.There are no ratings or reviews associated with a canceled project.",
                  "A notice of cancellation will be indicated in the `Timeframe` section of the project once published.",
                ]}
              />
            </Box>
          )}
          <Box pb={10}>
            <PublishProject />
          </Box>
          <Box flexGrow={1}>
            <Details />
          </Box>
          <CollectionNavigationActions
            label={"Project"}
            index={index}
            totalCount={projects?.length ?? 0}
            handleSelectNext={handleSelectNext}
            handleSelectPrevious={handleSelectPrevious}
          />
        </Box>
      </Section>
    </PageWrapper>
  );
};
