import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ButtonBase, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useState } from "react";
import { YELLOW } from "../../../themes/components/utils";
import { useToast } from "../../utils/useToast";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  text: {
    marginRight: theme.spacing(2),
    color: "white",
    transition: "color 0.3s",
    "&:hover": {
      color: YELLOW,
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
    color: "white",
    width: "32px",
    "&:hover": {
      color: YELLOW,
    },
  },
}));

const CopyToClipboardButton = () => {
  const [copied, setCopied] = useState(false);
  const { notifySuccess } = useToast();
  const classes = useStyles();

  const handleClick = useCallback(() => {
    const tempInput = document.createElement("input");
    tempInput.value = window.location.href;
    document.body.appendChild(tempInput);

    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    setCopied(true);
    notifySuccess("URL copied to clipboard!");

    setTimeout(() => {
      setCopied(false);
    }, 10000);
  }, [notifySuccess]);

  return (
    <ButtonBase
      onClick={handleClick}
      disabled={copied}
      sx={{
        cursor: "pointer",
      }}
    >
      <ContentCopyIcon className={classes.icon} />
      {/*<Typography className={classes.text}>Copy URL</Typography>*/}
    </ButtonBase>
  );
};

export default CopyToClipboardButton;
