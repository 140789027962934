import { Box, Button, MenuItem, Popover } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInvitableJobRequests } from "../../../hooks/job-requests/useInvitableJobRequests";

type ClientSelectProps = { professionalId: string };

export const ClientSelect: React.FC<ClientSelectProps> = ({
  professionalId,
}) => {
  const navigate = useNavigate();
  const { jobRequests } = useInvitableJobRequests(professionalId);

  // eslint-disable-next-line no-undef
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    // eslint-disable-next-line no-undef
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(open ? null : event.currentTarget);
    },
    [open]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasJobRequest = (jobRequests?.length ?? 0) > 0;

  return (
    <Box>
      <Button
        size="medium"
        disabled={!hasJobRequest}
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        Invite Pro
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: { minWidth: 160 },
          }}
        >
          {jobRequests?.map((jobRequest) => (
            <MenuItem
              key={jobRequest._id}
              value={jobRequest._id}
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/invite/${professionalId}`, {
                  state: {
                    jobRequestId: jobRequest._id,
                    jobRequestTitle: jobRequest.title,
                  },
                });
              }}
            >
              {jobRequest.title}
            </MenuItem>
          ))}
        </Popover>
      </Button>
    </Box>
  );
};
