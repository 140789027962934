import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { usePointerStyles } from "../../../../../themes/style-hooks";
import { LoadingTypography } from "../../../../common/components";
import { useMetadataQuery } from "../../../../common/hooks/metadata/useMetadataQuery";
import { useProjectQuery } from "../../../../common/hooks/projects/project/useProjectQuery";

export const PastProjectHeaderSummary: React.FC = () => {
  const pointerStyles = usePointerStyles();

  const { isLoading: isLoadingProject } = useProjectQuery();
  const { data: metadata } = useMetadataQuery();

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      py={10}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
        }}
      >
        Unpublished Past Projects
      </Typography>
      <Box>
        <LoadingTypography
          isLoading={isLoadingProject}
          typographyProps={{ variant: "subtitle2" }}
        >
          {metadata?.unpublishedPastProjectsCount ?? 0} Past Projects aren't
          published
        </LoadingTypography>
      </Box>
      <Box className={pointerStyles.root}>
        <Link
          variant="subtitle2"
          href={"/projects/unpublished"}
          underline="none"
        >
          View unpublished past projects
        </Link>
      </Box>
    </Box>
  );
};
