import { Box, Button, Divider, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useCallback, useMemo } from "react";
import { ShowImages, TextOverflow } from "../../../../common/components";
import {
  formatNominativeMonth,
  LoadingTypography,
} from "../../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { useProjectQuery } from "../../../../common/hooks/projects/project/useProjectQuery";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { Description } from "../../../../common/professionals-search/projects/project/sections/Description";
import { usePublishPastProject } from "../unpublished-projects/hooks/usePublishPastProject";

export const Details: React.FC = () => {
  const { isMedium } = useResponsiveness();
  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const skills = useMemo(() => {
    if (!project?.skills || isLoadingProject) {
      return [];
    }

    return project?.skills.map((skill) => skill.name);
  }, [isLoadingProject, project?.skills]);

  const hasImages = Boolean(project?.images?.length ?? 0 > 0);

  const { mutate: publishProject } = usePublishPastProject();

  const onPublishProjectHandler = useCallback(() => {
    publishProject();
  }, [publishProject]);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={isMedium ? "column" : "row"}
        alignItems={isMedium ? "flex-start" : "center"}
        justifyContent={isMedium ? "center" : "space-between"}
      >
        <LoadingTypography
          width={400}
          isLoading={isLoadingProject}
          typographyProps={{
            variant: "subtitle1",
          }}
        >
          <Box marginRight={8} marginBottom={4}>
            {project?.title}
          </Box>
        </LoadingTypography>
        <Box
          display="flex"
          width={isMedium ? "95%" : 0}
          flexDirection={isMedium ? "row" : "column"}
          alignItems={isMedium ? "center" : "flex-start"}
          justifyContent={isMedium ? "space-between" : "flex-start"}
        >
          {isMedium && (
            <Box>
              {project?.createdAt && (
                <Typography>
                  {`Started ${format(
                    new Date(project?.createdAt),
                    formatNominativeMonth
                  )}`}
                </Typography>
              )}
              {project?.client && (
                <Typography mr={8}>
                  {`Posted by ${project?.client.accountInformation?.firstName} ${project?.client.accountInformation?.lastName}`}
                </Typography>
              )}
            </Box>
          )}

          {isMedium && (
            <Box justifySelf="flex-end">
              <Button
                size="medium"
                variant="outlined"
                onClick={onPublishProjectHandler}
              >
                Publish this project
              </Button>
            </Box>
          )}
        </Box>

        {!isMedium && project?.createdAt && (
          <Typography marginRight={4}>
            {`Started ${format(
              new Date(project?.createdAt),
              formatNominativeMonth
            )}`}
          </Typography>
        )}
        {!isMedium && project?.client && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography display="inline">Posted by</Typography>
            <TextOverflow width="100%">
              {`${project?.client.accountInformation?.firstName} ${project?.client.accountInformation?.lastName}`}
            </TextOverflow>
          </Box>
        )}
        <Box justifySelf="flex-end" marginLeft={8}>
          {!isMedium && (
            <Button
              size="medium"
              variant="outlined"
              onClick={onPublishProjectHandler}
            >
              Publish this project
            </Button>
          )}
        </Box>
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>
      <Description description={project?.description} />
      <SkillsOutput skills={skills} />
      {hasImages && (
        <>
          <ShowImages selectedFiles={project?.images} />
          <Box py={2}>
            <Divider />
          </Box>
        </>
      )}
    </Box>
  );
};
