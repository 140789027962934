import React from "react";
import { AccountBalancePage } from "../../modules/common/account/account-balance";
import { ProfessionalAccount } from "../../modules/professional/account";
import { EditProfessionalAccount } from "../../modules/professional/account/edit-professional-account";
import { EditProfessionalAccountProjects } from "../../modules/professional/account/edit-professional-account/EditProfessionalAccountProjects";
import { PortfolioProject } from "../../modules/professional/account/sections/portfolio/portfolio-project";
import { BidDetails } from "../../modules/professional/bids";
import { InquiryDetails } from "../../modules/professional/inquiries";
import { ClientInvitationDetails } from "../../modules/professional/inquiries/client-invitations/";
import { ProfessionalReferralDetails } from "../../modules/professional/inquiries/professional-referrals";
import {
  JobPostApplications,
  JobPreviewPage,
  SavedJobPostsPage,
} from "../../modules/professional/job-posts";
import { SearchJobsPage } from "../../modules/professional/job-requests";
import { SavedJobRequestsPage } from "../../modules/professional/job-requests/SavedJobRequestsPage";
import {
  ProjectCollaborators,
  ProjectDetails,
} from "../../modules/professional/projects";
import { PastProject } from "../../modules/professional/projects/past-projects";
import { UnpublishedPastProject } from "../../modules/professional/projects/past-projects/unpublished-projects";
import { ProfessionalReferralInvitation } from "../../modules/professional/projects/professioal-referral-invitation";
import { PrivateRoute } from "../PrivateRoute";

export const ACCOUNT_EDIT_ROUTE_URL = "/account/edit";
// export const ACCOUNT_EDIT_ROUTE_URL = "/account/edit";

export const professionalRoutes = [
  <PrivateRoute path="/bids/:bidId" element={<BidDetails />} />,
  <PrivateRoute path="/bids/" element={<BidDetails />} />,
  <PrivateRoute path="/inquiries/:inquiryId/" element={<InquiryDetails />} />,
  <PrivateRoute path="/inquiries/" element={<InquiryDetails />} />,
  <PrivateRoute
    path="/projects/history/:projectId"
    element={<PastProject />}
  />,
  <PrivateRoute path="/projects/history" element={<PastProject />} />,
  <PrivateRoute path="/projects/:projectId" element={<ProjectDetails />} />,
  <PrivateRoute
    path="/projects/:projectId/collaborators"
    element={<ProjectCollaborators />}
  />,
  <PrivateRoute path="/projects/" element={<ProjectDetails />} />,
  <PrivateRoute path="/account" element={<ProfessionalAccount />} />,
  <PrivateRoute path="/account-balance" element={<AccountBalancePage />} />,
  <PrivateRoute
    path="/account/portfolio/:projectId"
    element={<ProjectDetails />}
  />,
  <PrivateRoute
    path="/projects/portfolio-project/:projectId"
    element={<PortfolioProject />}
  />,
  <PrivateRoute
    path="/projects/unpublished/:projectId"
    element={<UnpublishedPastProject />}
  />,
  <PrivateRoute
    path="/projects/unpublished/"
    element={<UnpublishedPastProject />}
  />,
  <PrivateRoute
    path="/professional-referrals/:professionalReferralId/"
    element={<ProfessionalReferralDetails />}
  />,
  <PrivateRoute
    path="/professional-referrals"
    element={<ProfessionalReferralDetails />}
  />,
  <PrivateRoute
    path="/invitations/:invitationId"
    element={<ClientInvitationDetails />}
  />,
  <PrivateRoute path="/invitations/" element={<ClientInvitationDetails />} />,
  <PrivateRoute path="/job-applications/" element={<JobPostApplications />} />,
  <PrivateRoute path="/job-requests/search" element={<SearchJobsPage />} />,
  <PrivateRoute
    path="/job-requests/search/:jobPostId"
    element={<JobPreviewPage />}
  />,
  <PrivateRoute
    path="/job-requests/saved"
    element={<SavedJobRequestsPage />}
  />,
  <PrivateRoute path="/job-posts/saved" element={<SavedJobPostsPage />} />,
  <PrivateRoute
    path="/professional-referral/:professionalId"
    element={<ProfessionalReferralInvitation />}
  />,
  <PrivateRoute
    path="/professional-referral/"
    element={<ProfessionalReferralInvitation />}
  />,
  <PrivateRoute
    path={ACCOUNT_EDIT_ROUTE_URL}
    element={<EditProfessionalAccount />}
  />,
  <PrivateRoute
    path={`${ACCOUNT_EDIT_ROUTE_URL}/projects`}
    element={<EditProfessionalAccountProjects />}
  />,
];
