import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Field, Formik } from "formik";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { SchemaOf, object, string } from "yup";
import { BLUE } from "../../../themes/components/utils";
import { useToast } from "../../utils/useToast";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../job-requests/hooks/feedbackErrors";
import { useCreateJobApplication } from "./hooks";
import { UploadResume } from "./UploadResume";

const useStyles = makeStyles({
  textArea: {
    background: "none",
    border: "1px solid",
    borderColor: BLUE,
    color: BLUE,
    fontFamily: "inherit",
    fontSize: 16,
    padding: 8,
    outline: "none",
    width: "100%",
    resize: "none",
  },
});

export type ApplyByEmailFormData = {
  message?: string;
  resume: string;
};

type ApplyByEmailValidationSchema = SchemaOf<ApplyByEmailFormData>;

const applyByEmailValidationSchema: ApplyByEmailValidationSchema = object({
  message: string().optional(),
  resume: string().required().min(1),
});

const initialValues: ApplyByEmailFormData = {
  resume: "",
};

type ApplyByEmailDialogProps = {
  jobPostId?: string;
  open: boolean;
  onClose: () => void;
};

export const ApplyByEmailDialog = ({
  jobPostId,
  open,
  onClose,
}: ApplyByEmailDialogProps) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const { notifyError } = useToast();

  const { mutate: createJobApplication } = useCreateJobApplication();

  const onSubmitHandler = useCallback(
    ({ message, resume }: ApplyByEmailFormData) => {
      if (!jobPostId) return;

      createJobApplication(
        {
          jobPost: jobPostId,
          message,
          resume,
        },
        {
          onSuccess: () => {
            navigate("/job-requests/search");
          },
          onError: () => {
            notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
          },
        }
      );
    },
    [createJobApplication, jobPostId, navigate, notifyError]
  );

  return (
    <Dialog
      open={open}
      PaperProps={{ style: { height: "auto", overflow: "unset" } }}
    >
      <Formik
        initialValues={initialValues}
        isInitialValid={false}
        onSubmit={onSubmitHandler}
        validateOnChange
        validationSchema={applyByEmailValidationSchema}
      >
        {({ handleBlur, handleChange, handleSubmit, values, isValid }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <Typography
                color="secondary"
                variant="subtitle1"
                style={{ fontWeight: 700 }}
              >
                Email Your Resume to the Client
              </Typography>
              <Typography color="secondary" variant="body2">
                Upload your resume below and send an optional message to the
                client.
              </Typography>
            </DialogTitle>
            <DialogContent style={{ overflowY: "unset" }}>
              <Field
                as={TextareaAutosize}
                placeholder="Enter message here (optional)"
                name="message"
                value={values.message}
                className={styles.textArea}
                onChange={handleChange}
                onBlur={handleBlur}
                minRows={3}
                maxRows={3}
              />
              <UploadResume jobPostId={jobPostId ?? ""} />
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                disabled={!isValid}
                type="submit"
                variant="contained"
              >
                Confirm
              </Button>
              <Button color="secondary" onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
