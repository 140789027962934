import { Box, Divider, Typography } from "@mui/material";
import { BLUE } from "../../../../themes/components/utils";

export const FixedPriceMoreInfo = () => {
  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Typography
        color="secondary"
        variant="subtitle2"
        style={{ fontWeight: 700 }}
      >
        Fixed Price Projects
      </Typography>
      <Divider style={{ borderColor: BLUE }} />
      <Typography color="secondary" variant="body2" style={{ fontWeight: 700 }}>
        Working with Milestones
      </Typography>
      <Typography color="secondary" variant="body2">
        Setting up project milestones is a useful way to break up a larger
        project into smaller parts.
      </Typography>
      <Box>
        <Typography color="secondary" variant="body2">
          When a milestone is completed, it is submitted for your review. You
          can request changes or close the completed milestone.
        </Typography>
        <Typography color="secondary" variant="body2">
          If you take no action on a submitted milestone, the money assigned to
          it will be automatically released to the Professional, 5 business days
          after it is submitted.
        </Typography>
      </Box>
      <Typography color="secondary" variant="body2">
        Once work begins on a milestone, it is no longer editable. All other
        future milestones are always editable.
      </Typography>
      <Divider style={{ borderColor: BLUE }} />
      <Typography color="secondary" variant="body2" style={{ fontWeight: 700 }}>
        Funding Your Project
      </Typography>
      <Typography color="secondary" variant="body2">
        By default, all fixed price projects have one milestone.
      </Typography>
      <Box>
        <Typography color="secondary" variant="body2">
          In order to begin a fixed price project, at least the first project
          milestone has to be funded.
        </Typography>
        <Typography color="secondary" variant="body2">
          If multiple milestones are set up, at least the upcoming milestone has
          to be funded for work to continue.
        </Typography>
      </Box>
      <Typography color="secondary" variant="body2">
        Once a Professional is hired, the amounts you specify in milestones are
        moved into an escrow account, to be released to the Professional on
        completion of the milestone.
      </Typography>
      <Typography color="secondary" variant="body2">
        All payments are enabled by Stripe. There is a 3.9% + $0.30 processing
        fee that Stripe charges for each transaction.
      </Typography>
      <Typography color="secondary" variant="body2">
        Invoices for all your projects will be available on the Payments Reports
        page.
      </Typography>
    </Box>
  );
};
