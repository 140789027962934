import { Components } from "@mui/material";

export const MuiOutlinedInput: Components["MuiOutlinedInput"] = {
  styleOverrides: {
    root: {
      borderRadius: 1,
    },
    adornedEnd: {
      paddingRight: 10,
    },
    sizeSmall: {
      height: 48,
    },
  },
};
