import { Box } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../app/container/PageWrapper";
import { PaymentForm } from "./PaymentForm";

export const PaymentDashboard: React.FC = () => {
  // this will load stripe.js only when PaymentDashboard is rendered
  // eslint-disable-next-line no-undef
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK ?? "");
  const [searchParams] = useSearchParams();

  const amount = Number(searchParams.get("amount")) ?? 0;

  return (
    <PageWrapper>
      <Box
        display="flex"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Elements stripe={stripePromise}>
          <PaymentForm amount={amount} />
        </Elements>
      </Box>
    </PageWrapper>
  );
};
