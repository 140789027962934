import jwt from "jwt-decode";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { signIn } from "../../../../api-services/sign-in";
import { AccountType } from "../../../../api-types/common";
import { SignInData } from "../../../../api-types/sign-in";
import { DecodedJWT } from "../../../../api-types/sign-up";
import { BROWSE_DEFAULT_URL } from "../../../../routes/utils";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useIsAuthed, useRedirectUrl, useUser } from "../../../state/hooks";
import { useToast } from "../../../utils/useToast";
import { useLocalState } from "../utils";
import { useScopedDowngradedStateValue } from "../utils/useScopedDowngradedStateValue";

const publicRoutes = ["sign-in", "sign-up", "password-reset", "/client"];

export const useSignInMutation = () => {
  const [, setToken] = useLocalState("access_token", "");
  const setUser = useUser().set;
  const setIsAuthed = useIsAuthed().set;
  const navigate = useNavigate();
  const { notifyError } = useToast();
  const redirectUrl = useScopedDowngradedStateValue(useRedirectUrl());
  const setRedirectUrl = useRedirectUrl().set;

  return useMutation((signInData: SignInData) => signIn(signInData), {
    onSuccess(response) {
      const token = (response?.data as any).accessToken;
      const decodedToken: DecodedJWT = jwt(token);
      setIsAuthed(true);

      setUser({
        name: decodedToken.username,
        id: decodedToken.userId,
        accountType: decodedToken.type,
        token,
      });

      setToken(token);
    },
    onError(error: { request: XMLHttpRequest }) {
      switch (error.request.status) {
        case 401:
          notifyError(feedbackMessage("", FEEDBACK_TYPE.WRONG_CREDENTIALS));
          return;
        case 403:
          notifyError(
            "User is not validated, please activate the user by following instructions received on email."
          );
          return;
        case 404:
          notifyError(feedbackMessage("", FEEDBACK_TYPE.ACCOUNT_NOT_FOUND));
          return;
        default:
          notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
      }
    },
    onSettled(response) {
      if (!response) {
        return;
      }

      const token = (response?.data as any).accessToken;
      const decodedToken: DecodedJWT = jwt(token);

      let path = "";

      const navigateTo = window.sessionStorage.getItem("navigateTo");

      if (redirectUrl) {
        setRedirectUrl(null);
        navigate(redirectUrl, { replace: true });
        return;
      }

      if (
        navigateTo &&
        !publicRoutes.includes(navigateTo) &&
        !navigateTo.includes("sign-in")
      ) {
        window.sessionStorage.setItem("navigateTo", "");
        navigate(navigateTo, { replace: true });
        return;
      }

      if (decodedToken.type === AccountType.PROFESSIONAL) {
        path = decodedToken.restricted ? "/account" : "/job-requests/search";
      } else {
        path = BROWSE_DEFAULT_URL;
      }

      navigate(path, { replace: true });
    },
  });
};
