import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { FC } from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import { useStepAStyles } from "../../../../../../themes/style-hooks";
import { useSubscribeModal } from "../../../../../state/hooks";
import { useScopedDowngradedStateValue } from "../../../../hooks/utils/useScopedDowngradedStateValue";
import { CardsCollection } from "./CardsCollection";
import { Typewriter } from "./Typewriter";

export const LandingStepA: FC = () => {
  const subscribeModalState = useScopedDowngradedStateValue(
    useSubscribeModal()
  );
  const styles = useStepAStyles();
  const smallMobileHeight = useMediaQuery("(max-height: 450px)");

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="flex-start"
      style={{
        background: `linear-gradient(180deg, ${COLOR.BLUE} 55%, ${COLOR.PINK} 90%)`,
      }}
    >
      <Box
        style={{
          visibility: subscribeModalState.open ? "hidden" : "visible",
        }}
        className={styles.container}
        maxWidth={1440}
        height="100%"
        margin="0 auto"
        display="flex"
        alignItems="flex-start"
        justifyContent={smallMobileHeight ? "space-evenly" : "space-between"}
        flexDirection="column"
      >
        <Box className={`${styles.headlineText} ${styles.mediumItalic}`}>
          <Typewriter />
        </Box>
        <Box width="90%">
          <Box>
            <Box className={styles.descriptionText}>
              We connect creative designers and developers to businesses and
              innovators.
            </Box>
            <Box className={styles.descriptionText}>
              It feels like home for the talented.
            </Box>
          </Box>
          <Box className={styles.descriptionWrapper}>
            <Box className={styles.descriptionText}>
              Build your app, your .com, or your brand.
            </Box>
            <Box className={styles.descriptionText}>Build with talent.</Box>
          </Box>
        </Box>
        <CardsCollection />
      </Box>
    </Box>
  );
};
