import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../app/container/PageWrapper";
import { Section } from "../../common/components";
import { CollectionNavigationActions } from "../../common/elements/CollectionNavigationActions";
import {
  Collection,
  useNavigationHandlers,
} from "../../common/hooks/navigation";
import { Communication } from "./communication";
import { Details } from "./details";
import { BidHeader } from "./header";
import { useDefaultAccountBidPage } from "./hooks";
import { useAccountBid } from "./hooks/useAccountBid";
import { useAccountBids } from "./hooks/useAccountBids";

export const BidDetails = () => {
  useDefaultAccountBidPage();

  const [searchParams] = useSearchParams();
  const { bidId } = useParams();

  const { data: accountBid, isLoading: isLoadingAccountBid } = useAccountBid();

  const { data: accountBids, isLoading: isLoadingAccountBids } =
    useAccountBids();

  const collectionConfig = { type: Collection.BIDS, items: accountBids };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(collectionConfig, isLoadingAccountBids, bidId);

  const isLoading = isLoadingAccountBids || isLoadingAccountBid;

  const errorMessage = useMemo(() => {
    return !isLoading && !accountBid
      ? "All your bids will be shown here. Search job requests to bid on opportunities that match your skills."
      : "";
  }, [accountBid, isLoading]);

  const content = useMemo(
    () =>
      searchParams.get("tab") === "replies" ? <Communication /> : <Details />,
    [searchParams]
  );

  const isNavigationHidden =
    searchParams.get("tab") !== "details" || isLoadingAccountBids;

  if (!bidId && !isLoading) {
    return (
      <PageWrapper>
        <Section errorMessage={errorMessage} />{" "}
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Box display="flex" height="inherit" flexDirection="column" flexGrow={1}>
        <BidHeader />
        <Box flexGrow={1}>{content}</Box>
        <CollectionNavigationActions
          label={"Bid"}
          index={index}
          totalCount={accountBids?.length ?? 0}
          handleSelectNext={handleSelectNext}
          handleSelectPrevious={handleSelectPrevious}
          hidden={isNavigationHidden}
        />
      </Box>
    </PageWrapper>
  );
};
