import { Box } from "@mui/material";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useMobileStyles,
} from "../../../../../../themes/style-hooks";
import { MainActions } from "../../../MainActions";

export const LandingStepBContent = () => {
  const { mobileContentWrapper, mobileDescriptionWrapper } = useMobileStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: COLOR.WHITE_GREEN,
    color: COLOR.DARK_BROWN,
    hover: COLOR.WHITE_GREEN,
  });

  const descriptionText = useDescriptionText({
    color: COLOR.WHITE_PINK,
  });
  return (
    <Box className={mobileContentWrapper}>
      <Box className={mobileDescriptionWrapper}>
        <Box textAlign="left" color={COLOR.WHITE_PINK}>
          <Box className={descriptionText.root}>
            Work on freelance projects, or apply to jobs. All in once place!{" "}
          </Box>
        </Box>
      </Box>
      <MainActions classNames={`${common} ${root}`} />
    </Box>
  );
};
