import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { BidData } from "../../../../api-types/job-requests/bids";

type PlacedBidDetailsProps = {
  bid: BidData;
};

export const PlacedBidDetails: React.FC<PlacedBidDetailsProps> = ({ bid }) => {
  const { fixedProjectFunding, hourlyProjectFunding } = bid;

  return (
    <Box display="flex" columnGap={10}>
      {Boolean(fixedProjectFunding?.totalPrice) && (
        <Fragment>
          <Box display="flex" columnGap={2} alignItems="baseline">
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              Fixed Price Budget
            </Typography>
            <Typography variant="body2">
              ${fixedProjectFunding?.totalPrice}
            </Typography>
          </Box>
          {/*<Box display="flex" columnGap={8} alignItems="baseline">*/}
          {/*  <Typography variant="body2" style={{ fontWeight: 700 }}>*/}
          {/*    TimeFrame*/}
          {/*  </Typography>*/}
          {/*  {(fixedProjectFunding?.dueDate?.weeks ?? 0) > 0 && (*/}
          {/*    <Typography variant="body2">*/}
          {/*      {`${fixedProjectFunding?.dueDate.weeks} week(s)`}*/}
          {/*    </Typography>*/}
          {/*  )}*/}
          {/*  {(fixedProjectFunding?.dueDate?.weeks ?? 0) > 0 &&*/}
          {/*    (fixedProjectFunding?.dueDate?.days ?? 0) > 0 && (*/}
          {/*      <Typography variant="body2">and</Typography>*/}
          {/*    )}*/}
          {/*  {(fixedProjectFunding?.dueDate?.days ?? 0) > 0 && (*/}
          {/*    <Typography variant="body2">*/}
          {/*      {`${fixedProjectFunding?.dueDate.days} day(s)`}*/}
          {/*    </Typography>*/}
          {/*  )}*/}
          {/*</Box>*/}
          {/*<Box display="flex" columnGap={8} alignItems="baseline">*/}
          {/*  <Typography variant="body2" style={{ fontWeight: 700 }}>*/}
          {/*    Milestone(s)*/}
          {/*  </Typography>*/}
          {/*  <Typography variant="body2">*/}
          {/*    {fixedProjectFunding?.milestones}*/}
          {/*  </Typography>*/}
          {/*</Box>*/}
        </Fragment>
      )}
      {Boolean(hourlyProjectFunding?.hourlyRate) && (
        <Fragment>
          {/*<Box display="flex" columnGap={8} alignItems="baseline">*/}
          {/*  <Typography variant="body2" style={{ fontWeight: 700 }}>*/}
          {/*    Hours/Week*/}
          {/*  </Typography>*/}
          {/*  {hourlyProjectFunding?.hours?.type === "fixed" ? (*/}
          {/*    <Typography variant="body2">*/}
          {/*      {hourlyProjectFunding?.hours.fixed}hrs/wk*/}
          {/*    </Typography>*/}
          {/*  ) : (*/}
          {/*    <Typography variant="body2">*/}
          {/*      {hourlyProjectFunding?.hours.estimate.min} -{" "}*/}
          {/*      {hourlyProjectFunding?.hours.estimate.max}hrs/wk*/}
          {/*    </Typography>*/}
          {/*  )}*/}
          {/*</Box>*/}
          <Box display="flex" columnGap={5} alignItems="baseline">
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              Hourly Rate
            </Typography>
            <Typography variant="body2">
              ${hourlyProjectFunding?.hourlyRate}
            </Typography>
          </Box>
          {/*<Box display="flex" columnGap={8} alignItems="baseline">*/}
          {/*  <Typography variant="body2" style={{ fontWeight: 700 }}>*/}
          {/*    Timeframe*/}
          {/*  </Typography>*/}
          {/*  {hourlyProjectFunding?.timeFrame?.type === "fixed" ? (*/}
          {/*    <Typography variant="body2">*/}
          {/*      {hourlyProjectFunding?.timeFrame?.fixed} week(s)*/}
          {/*    </Typography>*/}
          {/*  ) : (*/}
          {/*    <Typography variant="body2">*/}
          {/*      {hourlyProjectFunding?.timeFrame?.estimate?.min} -*/}
          {/*      {hourlyProjectFunding?.timeFrame?.estimate?.max} weeks*/}
          {/*    </Typography>*/}
          {/*  )}*/}
          {/*</Box>*/}
        </Fragment>
      )}
    </Box>
  );
};
