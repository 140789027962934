import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Link, Menu, MenuItem } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { COLOR } from "../../../themes/components/utils";
import { LoadingTypography } from "../../common/components";
import { useMetadataQuery } from "../../common/hooks/metadata/useMetadataQuery";
import { useLogout } from "../../common/hooks/sign-in";

export const ProfessionalMenu: React.FC = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { data: metadata, isLoading } = useMetadataQuery();

  const logout = useLogout();

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <React.Fragment>
      <Link
        color="secondary"
        underline="hover"
        component="button"
        variant="body1"
        onClick={handleClick}
      >
        <Box display="flex" alignItems="center">
          Hi,
          <LoadingTypography
            width={50}
            isLoading={isLoading}
            style={{
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: COLOR.BLUE,
            }}
          >
            &nbsp;<b>{metadata?.firstName ?? ""}</b>&nbsp;
          </LoadingTypography>
          <ExpandMoreIcon color="secondary" />
        </Box>
      </Link>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        // getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem
          onClick={() => {
            navigate("/account/");
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/account-balance");
          }}
        >
          Account Balance
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/account-information");
          }}
        >
          Account and Subscription
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/projects/unpublished");
          }}
        >
          Unpublished Projects
        </MenuItem>
        <MenuItem onClick={() => navigate("/contact")}>Contact Us</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );
};
