import { Box, Divider } from "@mui/material";
import React, { useMemo } from "react";
import { ShowImages } from "../../../common/components";
import { SkillsOutput } from "../../../common/components/SkillsOutput";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { Description } from "../../../common/professionals-search/projects/project/sections/Description";
import { AvailabilityCostBids } from "../../job-requests/search-job-requests-list/job-request-item/content";
import { Collaboration } from "../sections";
import { ProjectTitle } from "./ProjectTitle";

export const Details: React.FC = () => {
  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const skills = useMemo(() => {
    if (!project?.skills || isLoadingProject) {
      return [];
    }

    return project?.skills.map((skill) => skill.name);
  }, [isLoadingProject, project?.skills]);

  const hasImages = Boolean(project?.images?.length ?? 0 > 0);

  return (
    <Box>
      <ProjectTitle />
      <Box py={2.5}>
        <Divider />
      </Box>
      <Description description={project?.description} />
      <Box pt={2.5}>
        <AvailabilityCostBids
          budget={project?.jobRequest?.budget}
          fixedProjectFunding={project?.jobRequest?.fixedProjectFunding}
        />
      </Box>
      {Boolean(skills.length) && (
        <Box pt={2.5}>
          <SkillsOutput skills={skills} />
        </Box>
      )}
      {hasImages && (
        <Box pt={2.5}>
          <ShowImages selectedFiles={project?.images ?? []} />{" "}
        </Box>
      )}
      <Box py={2.5}>
        <Divider />
      </Box>
      <Collaboration />
    </Box>
  );
};
