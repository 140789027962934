import { Box, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { Project } from "../../../../../../api-types/projects";
import { YELLOW } from "../../../../../../themes/components/utils";
import {
  useDividerStyles,
  usePointerStyles,
} from "../../../../../../themes/style-hooks";
import { CustomTooltip } from "../../../../../common/components";
import { ProfessionalSummary } from "../../../../../common/components/ProfessionalSummary";
import { SkillsOutput } from "../../../../../common/components/SkillsOutput";
import { useResponsiveness } from "../../../../../common/hooks/utils/useResponsiveness";
import { useScopedDowngradedStateValue } from "../../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useWindowDimensions } from "../../../../../common/window";
import { useUser } from "../../../../../state/hooks";
import { PortfolioProject } from "../../../sections/portfolio/PortfolioProject";
import { PortfolioProjectAvailability } from "./PortfolioProjectAvailability";

type PortfolioRowProps = { project?: Project };

const useStyles = makeStyles(() => ({
  showMoreText: {
    textDecoration: "none",
    color: YELLOW,
  },
}));

export const PortfolioRow: React.FC<PortfolioRowProps> = ({ project }) => {
  const [isHovering, setIsHovering] = useState(false);
  const pointerStyles = usePointerStyles();
  const styles = useStyles();
  const { width } = useWindowDimensions();
  const { isMedium, isSmall } = useResponsiveness();

  const navigate = useNavigate();

  const onMouseEnterHandler = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeaveHandler = useCallback(() => {
    setIsHovering(false);
  }, []);

  const { id } = useScopedDowngradedStateValue(useUser());

  const skills = useMemo(() => {
    if (!project?.external) {
      return project?.skills.map((skill) => skill.name);
    }

    return project?.skills.map((skill) => skill.name);
  }, [project?.external, project?.skills]);

  const dividerStyles = useDividerStyles();

  const collaborators = useMemo(() => {
    return project?.professionals?.filter(
      ({ professional }) => professional?._id != id
    );
  }, [id, project?.professionals]);

  const hasCollaborators = Boolean(collaborators?.length);

  const handleProjectClick = useCallback(() => {
    if (project?._id) {
      navigate(`portfolio/projects/${project._id}/`, {});
    }
  }, [navigate, project?._id]);

  const baseWidth = useMemo(() => {
    if (isSmall) {
      return "100%";
    }

    if (isMedium) {
      return 320;
    }

    return 560;
  }, [isMedium, isSmall]);

  const imageWidth = useMemo(() => {
    if (width > 1440) {
      return 720;
    }

    const approximateWidth = width / 2 - 40;

    return Math.max(approximateWidth, Number(baseWidth));
  }, [width, baseWidth]);

  return (
    <Box
      display="flex"
      columnGap={5}
      className={pointerStyles.root}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onClick={handleProjectClick}
    >
      {!project?.images?.length ? (
        <Box display="flex" flexDirection="column" flexGrow={1} rowGap={3}>
          <Typography color="primary" variant="subtitle1">
            {project?.title}
          </Typography>
          <Divider />
          <Typography component="div" variant="body1">
            <ShowMoreText
              lines={5}
              more={
                <CustomTooltip
                  arrow
                  // interactive
                  placement="right"
                  title={
                    <Box>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        Description
                      </Typography>
                      <Typography
                        color="secondary"
                        variant="body1"
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {project?.description ?? ""}
                      </Typography>
                    </Box>
                  }
                >
                  <Typography component="span" variant="body2" color="primary">
                    More
                  </Typography>
                </CustomTooltip>
              }
              anchorClass={styles.showMoreText}
              expanded={false}
              // @ts-ignore: A bug in the library. The prop exists, but the interface is not updated.
              expandByClick={false}
            >
              {project?.description}
            </ShowMoreText>
          </Typography>
          {!project?.external ? (
            <Box mr="auto">
              <PortfolioProjectAvailability timeFrame={project?.timeFrame} />
            </Box>
          ) : (
            <Box display="flex" columnGap={1}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Timeframe:
              </Typography>
              <Typography variant="body2">
                {project?.timeFrame?.projectTimeFrame?.weeks} week(s)
              </Typography>
            </Box>
          )}
          {(skills?.length ?? 0) > 0 && (
            <Box
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <SkillsOutput skills={skills} isHovering={isHovering} />
            </Box>
          )}
          {Boolean(project?.url) && (
            <Typography
              color="primary"
              component="a"
              href={project?.url}
              variant="body1"
              noWrap
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              {project?.url}
            </Typography>
          )}
        </Box>
      ) : (
        <Fragment>
          <PortfolioProject
            project={project}
            height={520}
            width={imageWidth}
            hover={isHovering}
          />
          <Box>
            {project?.external ? (
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" columnGap={2.5}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                    component="div"
                    variant="body2"
                  >
                    Timeframe:
                  </Typography>
                  <Typography variant="body2">
                    {project?.timeFrame?.projectTimeFrame?.weeks} week(s)
                  </Typography>
                </Box>
              </Box>
            ) : (
              <PortfolioProjectAvailability timeFrame={project?.timeFrame} />
            )}
            {hasCollaborators && (
              <Box display="flex" alignItems="center" columnGap={12}>
                <Box py={3}>
                  <Divider className={dividerStyles.root} />
                </Box>
                <Typography variant="subtitle2">Collaborated with</Typography>
                <Box display="flex" columnGap={6}>
                  {collaborators?.map(({ professional }) => (
                    <ProfessionalSummary
                      key={professional._id}
                      professional={professional}
                      width={36}
                    />
                  ))}
                </Box>
              </Box>
            )}
            <Box pt={3}>
              <Divider className={dividerStyles.root} />
            </Box>
            <Box pr={5} pt={5}>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
                variant="subtitle1"
              >
                Description
              </Typography>
              <Typography component="div" variant="body1">
                <ShowMoreText
                  lines={5}
                  more={
                    <CustomTooltip
                      arrow
                      // interactive
                      placement="right"
                      title={
                        <Box>
                          <Typography
                            color="secondary"
                            variant="subtitle1"
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            Description
                          </Typography>
                          <Typography
                            color="secondary"
                            variant="body1"
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {project?.description ?? ""}
                          </Typography>
                        </Box>
                      }
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color="primary"
                      >
                        More
                      </Typography>
                    </CustomTooltip>
                  }
                  anchorClass={styles.showMoreText}
                  expanded={false}
                  // @ts-ignore: A bug in the library. The prop exists, but the interface is not updated.
                  expandByClick={false}
                >
                  {project?.description}
                </ShowMoreText>
              </Typography>
            </Box>
            <Box
              py={5}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <SkillsOutput skills={skills} isHovering={isHovering} />
            </Box>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};
