import { Box, Divider, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { Fragment, useMemo } from "react";
import { useTotalEstimatedCost } from "../create-job-request-wizard/hooks";
import {
  BudgetSchema,
  HourlyRateFundingSchema,
} from "../create-job-request-wizard/validation-schema";

export const HourlyRateFundingDetails = () => {
  const { values } = useFormikContext<HourlyRateFundingSchema & BudgetSchema>();

  const totalEstimatedCost = useTotalEstimatedCost();

  const timeFrameContent = useMemo(() => {
    const timeFrameType = values.budget.timeFrame.type;
    switch (timeFrameType) {
      case "fixed":
        return (
          <Typography variant="body2">
            {values.budget.timeFrame.fixed ?? 0} week(s)
          </Typography>
        );
      case "estimate":
      case "ongoing":
      case "notSure":
        return (
          <Typography variant="body2">
            Between {values.budget.timeFrame.estimate.min ?? 0} and{" "}
            {values.budget.timeFrame.estimate.max ?? 0} weeks
          </Typography>
        );
      default:
        return null;
    }
  }, [
    values.budget.timeFrame.estimate.max,
    values.budget.timeFrame.estimate.min,
    values.budget.timeFrame.fixed,
    values.budget.timeFrame.type,
  ]);

  return (
    <Fragment>
      <Box pb={5}>
        <Typography variant="subtitle2" sx={{ fontWeight: 650 }}>
          Planning and Paying for Your Project
        </Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="body2">
          By default, all hourly rate projects have one milestone.
        </Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="body2">
          Once all the work on a milestone is submitted and reviewed, payment is
          made for the actual number of hours completed in the time span of that
          milestone. Work can then begin on the next milestone.
        </Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="body2">
          All payments are enabled by Stripe. There is a 3.9% + $0.30 processing
          fee for each transaction.
        </Typography>
      </Box>

      <Box pb={5}>
        <Divider />

        <Box display="flex" flexWrap="wrap" columnGap={10} pt={5}>
          <Box display="flex" alignItems="center" columnGap={3}>
            <Typography sx={{ fontWeight: 650 }} variant="body2">
              Milestones for the Project:
            </Typography>
            <Typography variant="body2">
              {values.hourlyRateProjectFunding.milestones?.length ?? 0}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" columnGap={2}>
            <Box mr={4}>
              <Typography
                sx={{ fontWeight: 650 }}
                component="span"
                variant="body2"
              >
                Timeframe
              </Typography>
            </Box>
            {timeFrameContent}
          </Box>
          <Box display="flex" alignItems="center" columnGap={2}>
            <Typography
              sx={{ fontWeight: 650 }}
              component="span"
              variant="body2"
            >
              Estimated Cost:
            </Typography>
            <Typography variant="body2">
              {values?.budget?.timeFrame?.type === "fixed" &&
              values?.budget?.hours?.type === "fixed" &&
              values?.budget?.hourlyRate?.type === "fixed"
                ? `$${totalEstimatedCost?.min ?? 0}`
                : `$${totalEstimatedCost?.min ?? 0} - $${
                    totalEstimatedCost?.max ?? 0
                  }`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
