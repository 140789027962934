import { Box, Typography } from "@mui/material";
import React from "react";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";

export const PublishProject: React.FC = () => {
  const { isLarge } = useResponsiveness();
  return (
    <Box display="flex" flexDirection="column" rowGap={5}>
      {!isLarge && (
        <>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
            }}
          >
            Unpublished past project
          </Typography>
          <Typography variant="body1">
            Once a project is completed, it's listed here under Unpublished Past
            Projects. It will not be visible to potential Clients unless you
            review and publish it.
          </Typography>{" "}
        </>
      )}
    </Box>
  );
};
