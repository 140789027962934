import { Box, Button, Typography } from "@mui/material";
import { Formik, Field } from "formik";
import React from "react";
import * as Yup from "yup";
import { string } from "yup";
import { CustomInput } from "../elements";
import { useRequiredFieldLabel } from "../hooks/utils";

const initialValues = {
  password: "",
  confirmPassword: "",
};

type PasswordResetFormProps = {
  formRef: any;
  onSubmit?: (values: any) => void;
};

export const PasswordResetForm: React.FC<PasswordResetFormProps> = ({
  formRef,
  onSubmit,
}) => {
  const requiredFieldName = useRequiredFieldLabel();
  const validationSchema = Yup.object({
    password: string()
      .required("Password is required")
      .min(6, "Password must contain at least 6 characters")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d).{6,}[!@#$%^&]$/,
        "Password must contain at least 1 numeric and 1 alpha char, and may only end with special characters ( !@#$%^& )."
      ),
    confirmPassword: string()
      .required("Confirmation password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Password and confirmation password must match"
      ),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit?.(values);
      }}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        isValid,
        handleChange,
        handleBlur,
        values,
        errors,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="nope">
          <Box display="flex" flexDirection="column" rowGap={12.5}>
            <Field
              component={CustomInput}
              fullWidth
              variant="outlined"
              placeholder={requiredFieldName("Enter password")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              type="password"
              name="password"
              id="password"
            />
            <Field
              component={CustomInput}
              fullWidth
              variant="outlined"
              placeholder={requiredFieldName("Confirm password")}
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" py={5}>
            <Button variant="contained" type="submit" disabled={!isValid}>
              Submit
            </Button>
          </Box>
          {!isValid && (
            <Typography color="error">
              {errors.confirmPassword || errors.password}
            </Typography>
          )}
        </form>
      )}
    </Formik>
  );
};
