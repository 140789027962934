import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Card } from "../../elements/Card";
import { useNavigateBack } from "../../hooks/navigation";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";

export const SignUpAccountType: React.FC = () => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const { isSmall, isMedium } = useResponsiveness();

  useEffect(() => {
    navigate(
      {
        pathname: "/sign-up/",
        search: `?${createSearchParams({
          step: "1",
        })}`,
      },
      { replace: true, state: { from: "the-talented" } }
    );
  }, [navigate]);

  const handleChooseTypeHandler = useCallback(
    (type: string) => {
      navigate(
        {
          pathname: "/sign-up/",
          search: `?${createSearchParams({
            type,
            step: "2",
          })}`,
        },
        {
          state: { from: "the-talented" },
        }
      );
    },
    [navigate]
  );

  const cardWidth = useMemo(() => {
    if (isSmall) {
      return 300;
    }
    if (isMedium) {
      return 320;
    }
    return 380;
  }, [isSmall, isMedium]);

  return (
    <Box
      display="flex"
      flexDirection={isMedium ? "column" : "row"}
      justifyContent="space-between"
      alignItems={isMedium ? "center" : "flex-start"}
      flexGrow={1}
      pt={10}
    >
      <Box width={isSmall || isMedium ? "100%" : "30%"}>
        <Typography variant="subtitle1">
          Let’s start with the account type you want to set up.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent="space-between"
        alignItems="flex-end"
        height="100%"
      >
        <Box
          display="flex"
          flexDirection={isSmall ? "column" : "row"}
          alignItems="center"
          pt={isMedium ? 5 : 0}
          columnGap={5}
          rowGap={5}
        >
          <Card
            width={cardWidth}
            height={210}
            label="Client"
            onClick={() => handleChooseTypeHandler("client")}
          />
          <Card
            width={cardWidth}
            height={210}
            label="Professional"
            onClick={() => handleChooseTypeHandler("professional")}
          />
        </Box>
        <Box py={10}>
          <Button variant="outlined" size="medium" onClick={navigateBack}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
