import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { PackagesCards } from "../../../job-posts/jobs-packages/components/PackagesCards";
import { useJobPackages } from "../hooks/useJobPackages";
import { useJobPackage } from "../state/hooks";

type JobPackageProps = { onNext?: () => void };

export const JobPackage: React.FC<JobPackageProps> = ({ onNext }) => {
  const setJobPackage = useJobPackage().set;

  const { isEligibleForFreeTrial } = useJobPackages();

  const isExtraSmall = useMediaQuery("(max-width: 520px)");
  const { isMedium } = useResponsiveness();

  const gap = useMemo(() => {
    if (isMedium) {
      return 5;
    }

    return 50;
  }, [isMedium]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      pt={10}
    >
      <Box
        display="flex"
        columnGap={gap}
        alignItems="flex-start"
        flexDirection={isExtraSmall ? "column" : "row"}
        rowGap={2 * gap}
      >
        <Box
          width={isExtraSmall ? "100%" : 300}
          display="flex"
          flexDirection="column"
          rowGap={3}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
            }}
          >
            Select Your Plan{" "}
          </Typography>
          <Typography variant="subtitle2">
            Each job stays posted for 90 days
          </Typography>
          <Typography variant="subtitle2">Can post confidentially </Typography>
          <Typography variant="subtitle2">
            Unposted jobs never expire{" "}
          </Typography>
        </Box>
        <Box height={360}>
          <PackagesCards
            selectLabel={
              isEligibleForFreeTrial ? "Start 10-Day Free Trial" : "Select"
            }
            onSelect={(option) => {
              setJobPackage({
                package: option,
                type: isEligibleForFreeTrial ? "free-trial" : "effective",
              });
              onNext?.();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
