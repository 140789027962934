import { SchemaOf, array, boolean, object, string } from "yup";
import {
  RatePerHourFilterFormData,
  ratePerHourFilterSchema,
} from "./ratePerHourFilterSchema";
import {
  SalaryPerYearFilterFormData,
  salaryPerYearFilterSchema,
} from "./salaryPerYearFilterSchema";

export type SearchJobFiltersFormData = {
  jobLevel: string;
  jobLocation: string[];
  location: string;
  jobSponsorship: string[];
  jobSponsorshipOther: string;
  jobType: string[];
  ratePerHour: RatePerHourFilterFormData;
  salaryPerYear: SalaryPerYearFilterFormData;
  showAll: boolean;
};

export const searchJobFiltersSchema: SchemaOf<SearchJobFiltersFormData> =
  object({
    jobLevel: string().default(""),
    jobLocation: array().of(string().default("")),
    location: string().default(""),
    jobSponsorship: array().of(string().default("")),
    jobSponsorshipOther: string().default(""),
    jobType: array().of(string().default("")),
    ratePerHour: ratePerHourFilterSchema,
    salaryPerYear: salaryPerYearFilterSchema,
    showAll: boolean().default(true),
  });
