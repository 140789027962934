import { Chip, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FC } from "react";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    fontSize: 16,
    fontWeight: 400,

    "&.MuiChip-outlined": {
      alignItems: "center",
      display: "inline-flex",
      height: 22,
      justifyContent: "center",
      width: 22,
    },

    "& > .MuiChip-label": {
      lineHeight: 1.3,
      padding: 0,
    },
  },
  primary: {
    color: palette.primary.main,
    "&.MuiChip-outlined": {
      borderColor: palette.primary.main,
    },
  },
  secondary: {
    color: palette.secondary.main,
    "&.MuiChip-outlined": {
      borderColor: palette.secondary.main,
    },
  },
}));

type NumberChipProps = {
  label: string | number;
  tooltip?: string;
  color?: "primary" | "secondary";
};

export const NumberChip: FC<NumberChipProps> = ({
  label,
  tooltip,
  color = "primary",
}) => {
  const styles = useStyles();

  return (
    <Tooltip title={tooltip ?? ""} placement="right">
      <Chip
        className={`${styles.root} ${
          color === "primary" ? styles.primary : styles.secondary
        }`}
        label={label}
        variant="outlined"
      />
    </Tooltip>
  );
};
