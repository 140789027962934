import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/system";
import React, { useState } from "react";
import { BLUE } from "../../../../themes/components/utils";
import { FilterDropdownCore } from "../../../common/components";
import { useDialogStyles } from "../../../common/components/modals/subscribe-dialog/SubscribeDialog";

import "./styles.css";

const useStyles = makeStyles(({ palette }) => ({
  feeTextField: {
    height: 28,
    width: 52,
    "& .MuiOutlinedInput-input": {
      fontSize: 16,
      paddingLeft: 8,
      paddingRight: 0,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: palette.secondary.dark,
    },
  },
}));

interface SelectBudgetProps {
  label?: string;
  min: number;
  max: number;
  onSave: (values?: any) => any;
}

export const SelectBudget: React.FC<SelectBudgetProps> = ({
  label,
  min,
  max,
  onSave,
}) => {
  const styles = useStyles();
  const dialogStyles = useDialogStyles();
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [type, setType] = useState("hourly");

  const [values, setValues] = useState<{
    min: number;
    max: number;
  }>({
    min: min ?? 0,
    max: max ?? 0,
  });
  const isHourly = type === "hourly";

  return (
    <Box display="flex" data-cy="budget-select">
      <FilterDropdownCore
        buttonLabel={
          <Box display="flex">
            <Typography variant={isMd ? "body2" : "subtitle2"}>
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                {label}:{" "}
              </span>
            </Typography>
            <Box
              fontSize={isMd ? 14 : 18}
              display="flex"
              alignItems="center"
              style={{
                color: "white",
                fontSize: 18,
              }}
              px={2}
            >
              {values.min === 0 && (values.max === 0 || values.max === max) ? (
                <Typography variant={isMd ? "body2" : "subtitle2"}>
                  All
                </Typography>
              ) : (
                <React.Fragment>
                  <Typography variant={isMd ? "body2" : "subtitle2"} pr={1}>
                    ${values.min.toLocaleString()}
                    {isHourly ? "/hr" : ""}
                  </Typography>{" "}
                  -
                  <Typography pl={1} variant={isMd ? "body2" : "subtitle2"}>
                    ${values.max.toLocaleString()}
                    {isHourly ? "/hr" : ""}
                  </Typography>
                </React.Fragment>
              )}
            </Box>
          </Box>
        }
        onApply={() => onSave(values)}
        onClear={() => setValues({ min: 0, max: 0 })}
      >
        <Box display="flex" px={2.5} py={2}>
          <RadioGroup
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            defaultValue={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <FormControlLabel
              data-cy="hourly-rate"
              value="hourly"
              control={
                <Radio className={dialogStyles.radio} disableTouchRipple />
              }
              label={<Typography color="secondary">Hourly rate</Typography>}
            />{" "}
            <FormControlLabel
              value="fixed"
              control={
                <Radio className={dialogStyles.radio} disableTouchRipple />
              }
              label={<Typography color="secondary">Fixed price</Typography>}
            />
          </RadioGroup>
        </Box>

        <Divider style={{ borderColor: BLUE }} />

        <Box display="flex" columnGap={6} px={5} py={2.5} height={90}>
          <Box>
            <Typography color="secondary" variant="subtitle2">
              From
            </Typography>
            <Box display="flex" columnGap={1} alignItems="center">
              <Typography color="secondary" variant="body2">
                $
              </Typography>
              <TextField
                className={styles.feeTextField}
                onChange={(event) => {
                  const newMin = parseInt(event.target.value);

                  if (newMin >= 0 && newMin < 10000) {
                    setValues(({ max }) => ({
                      max: newMin > max ? newMin : max,
                      min: newMin,
                    }));
                  }
                }}
                type="number"
                value={values.min}
                data-cy="min-input"
              />
              {isHourly && (
                <Typography color="secondary" variant="body2">
                  /hr
                </Typography>
              )}
            </Box>
          </Box>
          <Box>
            <Typography color="secondary" variant="subtitle2">
              To
            </Typography>
            <Box display="flex" columnGap={1} alignItems="center">
              <Typography color="secondary" variant="body2">
                $
              </Typography>
              <TextField
                className={styles.feeTextField}
                onChange={(event) => {
                  const newMax = parseInt(event.target.value);
                  if (newMax >= 0 && newMax < 10000) {
                    setValues(({ min }) => ({
                      min: newMax < min ? newMax : min,
                      max: newMax,
                    }));
                  }
                }}
                type="number"
                value={values.max}
                data-cy="max-input"
              />
              {isHourly && (
                <Typography color="secondary" variant="body2">
                  /hr
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </FilterDropdownCore>
    </Box>
  );
};
