import { IMAGE_MAX_SIZE_MB } from "../../api-services/utils";

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const isImageSizeValid = (imageSize: number) => {
  const imageSizeMb = imageSize / 10 ** 6;
  return imageSizeMb < IMAGE_MAX_SIZE_MB;
};
