import { Box, Skeleton } from "@mui/material";
import React from "react";

export const PortfolioSkeleton: React.FC = () => {
  return (
    <Box>
      <Box display="flex" columnGap={6} justifyContent="space-between">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="60%"
          height={400}
        />
        <Box width="40%" display="flex" flexDirection="column" rowGap={2}>
          <Box display="flex" justifyContent="space-between">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="45%"
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="45%"
              height={30}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="45%"
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="45%"
              height={30}
            />
          </Box>
          <Box pt={5} display="flex" justifyContent="space-between">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="30%"
              height={25}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="40%"
              height={25}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="27%"
              height={25}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="27%"
              height={25}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="40%"
              height={25}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="30%"
              height={25}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="40%"
              height={25}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="27%"
              height={25}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="30%"
              height={25}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="20.5%"
              height={25}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="25%"
              height={25}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="25%"
              height={25}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="25%"
              height={25}
            />
          </Box>

          <Box pt={10} display="flex" columnGap={2} alignItems="center">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Box display="flex" columnGap={1} alignItems="center">
              <Skeleton
                variant="circular"
                animation="wave"
                width={16}
                height={16}
              />
              <Skeleton
                variant="circular"
                animation="wave"
                width={16}
                height={16}
              />
              <Skeleton
                variant="circular"
                animation="wave"
                width={16}
                height={16}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
