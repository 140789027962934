import { Box, Divider, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useMetadataQuery } from "../../../common/hooks/metadata/useMetadataQuery";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";
import { SearchBarSkeleton } from "../../../common/professionals-search/browse-mainbar/SearchBarSkeleton";
import { SkeletonCollection } from "../../../common/professionals-search/browse-mainbar/SkeletonCollection";
import { SavedJobRequestHighlights } from "./SavedJobRequestsHighlights";
import { SearchForm } from "./SearchForm";
import { SearchJobPostFiltersDialog } from "./SearchJobPostFiltersDialog";

export const SearchMainbar: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const { isSmall, isMedium } = useResponsiveness();
  const { isLoading } = useMetadataQuery();

  const headerHeight = useMemo(() => {
    if (isSmall) {
      return 300;
    }

    return 400;
  }, [isSmall]);

  return (
    <Box display="flex" columnGap={6}>
      <Box width={isMedium ? "100%" : 900} height={headerHeight}>
        <Box py={isSmall ? 5 : 10}>
          <Typography
            variant={isMedium ? "subtitle1" : "h1"}
            sx={{
              fontWeight: "bold",
            }}
          >
            Freelance Projects and Jobs
          </Typography>
        </Box>
        <SkeletonCollection
          isLoading={isLoading}
          skeleton={<SearchBarSkeleton />}
        >
          <SearchForm onOpenJobFiltersDialog={() => setOpenDialog(true)} />
        </SkeletonCollection>
      </Box>
      {!isSmall && (
        <>
          <Divider orientation="vertical" flexItem />
          <SavedJobRequestHighlights />
        </>
      )}
      <SearchJobPostFiltersDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </Box>
  );
};
