import { Components } from "@mui/material";
import { LIGHT_BLUE } from "./utils";

export const MuiDivider: Components["MuiDivider"] = {
  styleOverrides: {
    root: {
      borderColor: LIGHT_BLUE,
      opacity: 0.5,
      borderWidth: 1,
    },
  },
};
