import makeStyles from "@mui/styles/makeStyles";
import { BLUE, COLOR, YELLOW } from "../components/utils";

export const useYellowSelectStyles = makeStyles(() => ({
  root: {
    height: "40px !important",
    width: "150px !important",
    backgroundColor: `${YELLOW} !important`,
    textAlign: "center",
    textAlignLast: "center",
    verticalAlign: "center",
    display: "flex",
    alignItems: "center",
  },
}));
