import DownloadIcon from "@mui/icons-material/Download";
import { Fade, IconButton, Menu, MenuItem } from "@mui/material";
import { saveAs } from "file-saver";
import { Fragment, useCallback, useState } from "react";
import { useShareJobPostImageMutation } from "../../../../common/hooks/job-posts/job-post/useShareJobPostImageMutation";

type JobPostImageDownloadProps = {
  jobPostId: string;
};

export const JobPostImageDownload = ({
  jobPostId,
}: JobPostImageDownloadProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleOpen = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const { mutate: shareJobPostImage } = useShareJobPostImageMutation();
  const handleDownload = useCallback(
    (imageHeight: 1080 | 1920) => () => {
      shareJobPostImage(
        { jobPostId, imageHeight },
        {
          onSuccess: (dataUrl) => {
            saveAs(dataUrl, "preview.png");
            handleClose();
          },
        }
      );
    },
    [shareJobPostImage, jobPostId, handleClose]
  );

  return (
    <Fragment>
      <IconButton color="inherit" onClick={handleOpen} style={{ padding: 0 }}>
        <DownloadIcon color="inherit" style={{ height: 32, width: 32 }} />
      </IconButton>
      <Menu
        id="download-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem onClick={handleDownload(1080)}>1080x1080</MenuItem>
        <MenuItem onClick={handleDownload(1920)}>1080x1920</MenuItem>
      </Menu>
    </Fragment>
  );
};
