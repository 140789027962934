import { Box, Skeleton } from "@mui/material";
import React from "react";

export const InquiriesCommunicationSkeleton: React.FC = () => {
  return (
    <Box>
      <Box pt={2.5} display="flex" justifyContent="space-between">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="30%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="27%"
          height={25}
        />
      </Box>
      <Box pt={5} display="flex" justifyContent="flex-start">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="50%"
          height={40}
        />
      </Box>
      <Box pt={5} display="flex" justifyContent="flex-end">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="50%"
          height={40}
        />
      </Box>
      <Box pt={5} display="flex" justifyContent="flex-start">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="50%"
          height={40}
        />
      </Box>
      <Box pt={10} display="flex">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={50}
        />
      </Box>
    </Box>
  );
};
