import { Box, Table, TableBody } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LIGHT_BLUE } from "../../../themes/components/utils";
import { PageWrapper } from "../../app/container/PageWrapper";
import { ErrorWrapper, LoadingTypography } from "../../common/components";
import { useJobPosts } from "../../common/hooks/job-posts";
import { useResponsiveness } from "../../common/hooks/utils/useResponsiveness";
import { SkeletonCollection } from "../../common/professionals-search/browse-mainbar/SkeletonCollection";
import { JobPostsCreditSummary } from "./JobPostsCreditSummary";
import { JobPostsDashboardSkeleton } from "./JobPostsDashboardSkeleton";
import { JobPostsListSummary } from "./JobPostsListSummary";
import { JobPostsTableHead } from "./JobPostsTableHead";
import { JobPostTableRow } from "./JobPostTableRow";

export const useStyles = makeStyles(() => ({
  border: {
    borderTop: `0.5px solid ${LIGHT_BLUE}`,
  },
}));

export const JobPostsDashboard = () => {
  const { data: jobPosts, isLoading } = useJobPosts();
  const { isSmall, isMedium } = useResponsiveness();
  const hasJobs = (jobPosts?.length ?? 0) > 0;

  return (
    <PageWrapper>
      <Box
        alignItems={isMedium ? "center" : "start"}
        justifyContent="space-between"
        display="flex"
        flexDirection={isSmall ? "column" : "row"}
        py={10}
      >
        <Box width={isSmall ? "100%" : "20%"}>
          <LoadingTypography
            isLoading={isLoading}
            style={{
              fontWeight: 600,
              marginBottom: isSmall ? 24 : 0,
            }}
            typographyProps={{
              variant: "h2",
            }}
          >
            Job Posts
          </LoadingTypography>
        </Box>
        <Box width={isSmall ? "100%" : "65%"}>
          <JobPostsCreditSummary purchaseButton />
        </Box>
      </Box>

      <Box py={2.5}>
        <JobPostsListSummary />
      </Box>

      <SkeletonCollection
        isLoading={isLoading}
        skeleton={<JobPostsDashboardSkeleton />}
      >
        {hasJobs ? (
          <Box style={{ overflow: "auto" }}>
            <Table>
              <JobPostsTableHead />

              <TableBody>
                {jobPosts?.map((jobPost, index) => (
                  <JobPostTableRow
                    key={`${jobPost.jobTitle}-${index}`}
                    jobPost={jobPost}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        ) : (
          <Box py={10}>
            <ErrorWrapper errorMessage="Active job posts you publish will be shown here." />
          </Box>
        )}
      </SkeletonCollection>
    </PageWrapper>
  );
};
