import { Box, Button, Typography } from "@mui/material";
import { Formik, FormikErrors, FormikValues } from "formik";
import { debounce, omit } from "lodash";
import React, { ChangeEvent, useCallback, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { number, object, ref, string, InferType } from "yup";
import { SkillV2 } from "../../../../api-types/job-requests/common";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { Section } from "../../../common/components";
import { useProfileInformation } from "../../../common/hooks/profile-information/useProfileInformation";
import { useUpdateProfileInformationMutation } from "../../../common/hooks/profile-information/useUpdateProfileInformationMutation";
import {
  EditAccountProgressBar,
  ProfileInformation,
  Pricing,
  Skills,
} from "./steps";
import { Projects } from "./steps/Projects";

const profileInformationSchema = object({
  position: string().optional(),
  description: string(),
  profileImage: string(),
  pricing: object({
    availabilityPerWeek: object({
      type: string(),
      estimate: object().when("type", {
        is: "estimate",
        then: object({
          min: number()
            .nullable()
            .min(1, "Minimum availability must be greater than zero"),
          max: number().when("min", {
            is: (val: any) => val > 0,
            then: number().min(
              ref("min"),
              "Maximum availability must be greater or equal to minimum value"
            ),
            otherwise: number().nullable(),
          }),
        }),
      }),
      fixed: number().when("type", {
        is: "fixed",
        then: number()
          .nullable()
          .min(1, "Availability must be greater than zero"),
      }),
    }),
    hourlyRate: number()
      .nullable()
      .min(1, "Hourly rate must be greater than zero"),
  }),
});

export interface ProfileInformationInterface
  extends InferType<typeof profileInformationSchema> {}

export const EditProfessionalAccount: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: profileInformation, isLoading: isLoadingProfileInformation } =
    useProfileInformation();

  const { mutate: updateProfileInformation } =
    useUpdateProfileInformationMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("step")) return;

    searchParams.set("step", "1");
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  const next = () => {
    searchParams.set("step", String(Number(searchParams.get("step")) + 1));
    searchParams.delete("isEditing");
    setSearchParams(searchParams);
  };

  const previous = () => {
    navigate(-1);
  };

  const initialProfileInformationValues = {
    ...profileInformation,
    pricing: {
      ...profileInformation?.pricing,
      hourlyRate: profileInformation?.pricing?.hourlyRate,
      availabilityPerWeek: {
        ...profileInformation?.pricing?.availabilityPerWeek,
        type: profileInformation?.pricing?.availabilityPerWeek?.type ?? "fixed",
        estimate: {
          min: profileInformation?.pricing?.availabilityPerWeek?.estimate?.min,
          max: profileInformation?.pricing?.availabilityPerWeek?.estimate?.max,
        },
        fixed: profileInformation?.pricing?.availabilityPerWeek?.fixed,
      },
    },
  };

  const renderStep = (
    handleChange: (e: ChangeEvent) => void,
    // eslint-disable-next-file no-undef
    handleBlur: (e: FocusEvent) => void,
    values: FormikValues,
    errors: FormikErrors<ProfileInformationInterface>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => void,
    submitForm: () => void
  ) => {
    switch (Number(searchParams.get("step"))) {
      case 1:
        return (
          <ProfileInformation
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        );
      case 2:
        return (
          <Skills
            values={values}
            handleChange={(newSkills: SkillV2[]) => {
              setFieldValue(
                "skills",
                newSkills.map((skill) => ({
                  name: skill,
                }))
              );
            }}
            onBack={previous}
          />
        );
      case 3:
        return (
          <Projects
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            submitForm={submitForm}
            onNextStep={next}
            onSaveDraft={next}
            onBack={previous}
          />
        );
      case 4:
        return (
          <Pricing
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            errors={errors}
            onNextStep={next}
            onSaveDraft={next}
            onBack={previous}
          />
        );
      default:
        return null;
    }
  };

  const handleSubmitFormOnChange = debounce(
    useCallback((submitForm) => {
      submitForm?.();
    }, []),
    200
  );

  const onClose = useCallback(() => {
    navigate("/account");
  }, [navigate]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Box height="calc(100% - 100px)">
      <EditAccountProgressBar currentStep={Number(searchParams.get("step"))} />
      <PageWrapper hideNavbar>
        <Section isLoading={isLoadingProfileInformation}>
          <Formik
            initialValues={initialProfileInformationValues}
            onSubmit={(values) => {
              const updatedValues = omit(
                values,
                values?.pricing?.availabilityPerWeek?.type === "estimate"
                  ? "pricing.availabilityPerWeek.fixed"
                  : "pricing.availabilityPerWeek.estimate"
              );

              updateProfileInformation(updatedValues);
            }}
            validationSchema={profileInformationSchema}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              values,
              isValid,
              submitForm,
            }) => (
              <form
                onSubmit={handleSubmit}
                onChange={() => {
                  handleSubmitFormOnChange(submitForm);
                }}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box flexGrow={1} pb={5}>
                  {renderStep(
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    setFieldValue,
                    submitForm
                  )}
                </Box>
                {searchParams.get("isEditing") === "1" ? null : (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    py={10}
                    columnGap={2.5}
                  >
                    <Typography
                      sx={{
                        color: "gray",
                        fontStyle: "italic",
                        lineHeight: 1,
                      }}
                    >
                      Changes are auto saved
                    </Typography>
                    <Button
                      onClick={() => {
                        handleSubmitFormOnChange(submitForm);
                        onClose();
                      }}
                    >
                      Close
                    </Button>
                    {Number(searchParams.get("step")) < 4 && (
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleSubmitFormOnChange(submitForm);
                          next();
                        }}
                      >
                        Next Step
                      </Button>
                    )}
                  </Box>
                )}
              </form>
            )}
          </Formik>
        </Section>
      </PageWrapper>
    </Box>
  );
};
