import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogContent, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import React, { forwardRef, ReactNode, useCallback, useState } from "react";
import { BLUE, YELLOW } from "../../../../../themes/components/utils";
import { useHandleCloseDialog } from "../../../hooks/utils";
import { useResponsiveness } from "../../../hooks/utils/useResponsiveness";
import { useDialogStyles } from "../subscribe-dialog/SubscribeDialog";

export type PaymentRequestDialogProps = {
  open: boolean;
  title?: string;
  confirmLabel?: string;
  message?: ReactNode | string;
  onClose: () => void;
  onConfirm?: (
    dueDate: number,
    hoursPerWeek: number,
    costPerHour: number
  ) => void;
};

export const PaymentRequestDialog = forwardRef<
  HTMLDialogElement,
  PaymentRequestDialogProps
>((props, ref) => {
  const styles = useDialogStyles();
  const { title, onClose, onConfirm, confirmLabel, open } = props;

  const { isSmall } = useResponsiveness();

  const [dueDate, setDueDate] = useState(0);
  const [costPerHour, setCostPerHour] = useState(0);
  const [hoursPerWeek, setHoursPerWeek] = useState(0);

  const handleConfirm = () => {
    onConfirm?.(dueDate, hoursPerWeek, costPerHour);
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCloseDialog = useHandleCloseDialog(onClose);

  const blockInvalidChar = (e: any) => {
    if (
      [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
      ].includes(e.key)
    ) {
      return;
    }

    e.preventDefault();
  };

  return (
    <Dialog
      // @ts-ignore
      ref={ref}
      open={open}
      className={`${styles.completeProjectDialog} `}
      onClose={handleCloseDialog}
    >
      <Box onClick={handleClose} className={styles.closeIcon}>
        <CloseIcon />
      </Box>
      {title && (
        <DialogTitle>
          <Typography
            color="secondary"
            style={{
              fontSize: 24,
              fontWeight: 550,
            }}
          >
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          rowGap={1}
        >
          <Box
            width={isSmall ? 250 : 500}
            display="flex"
            alignItems="center"
            columnGap={2}
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 500, width: 200 }}
              color="secondary"
              component="span"
              noWrap
            >
              Actual Due Date
            </Typography>
            <Box width={150}>
              <TextField
                size="small"
                onKeyDown={blockInvalidChar}
                fullWidth
                value={dueDate}
                onChange={(e) => setDueDate(Number(e?.target?.value) ?? 0)}
              />
            </Box>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 500, width: 100 }}
              color="secondary"
              component="span"
            >
              weeks
            </Typography>
          </Box>
          <Box
            width={isSmall ? 250 : 500}
            display="flex"
            alignItems="center"
            columnGap={2}
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 500, width: 200 }}
              color="secondary"
              component="span"
            >
              Actual Hours/Week
            </Typography>
            <Box width={150}>
              <TextField
                size="small"
                onKeyDown={blockInvalidChar}
                fullWidth
                value={hoursPerWeek}
                onChange={(e) => setHoursPerWeek(Number(e?.target?.value) ?? 0)}
              />
            </Box>
            <Typography
              variant="subtitle2"
              color="secondary"
              style={{ fontWeight: 500, width: 100 }}
              component="span"
            >
              hours
            </Typography>
          </Box>
          <Box
            width={isSmall ? 250 : 500}
            display="flex"
            alignItems="center"
            columnGap={2}
          >
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 500, width: 200 }}
              color="secondary"
              component="span"
            >
              Actual Cost/Hour
            </Typography>
            <Box width={150}>
              <TextField
                fullWidth
                onKeyDown={blockInvalidChar}
                size="small"
                value={costPerHour}
                onChange={(e) => setCostPerHour(Number(e?.target?.value) ?? 0)}
              />
            </Box>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 500, width: 100 }}
              color="secondary"
              component="span"
            >
              $
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" columnGap={2.5}>
          <Button
            variant="contained"
            onClick={onClose}
            // className={buttonStyles.button}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: BLUE,
              color: YELLOW,
            }}
            // className={buttonStyles.button}
            onClick={handleConfirm}
            autoFocus
          >
            {confirmLabel}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});
