import { Box, Button, Divider, Typography } from "@mui/material";
import React, { Fragment, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../app/container/PageWrapper";
import { ProfileTabs } from "../../common/account/profile";
import { LoadingTypography } from "../../common/components";
import { InfoBox } from "../../common/components/InfoBox";
import { useMetadataQuery } from "../../common/hooks/metadata/useMetadataQuery";
import { useResponsiveness } from "../../common/hooks/utils/useResponsiveness";
import { ProfessionalAccountSummary } from "./account-summary";
import { Portfolio } from "./sections";
import { CollaboratorsHistory } from "./sections/collaborators";
import { CurrentAccountProjects } from "./sections/on-going-projects";

export const ProfessionalAccount: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { isMedium } = useResponsiveness();
  const navigate = useNavigate();

  const { data: metadata, isLoading } = useMetadataQuery();

  const content = useMemo(() => {
    if (searchParams.get("tab") === "collaborators")
      return <CollaboratorsHistory />;

    if (searchParams.get("tab") === "current")
      return <CurrentAccountProjects />;

    return <Portfolio />;
  }, [searchParams]);

  return (
    <PageWrapper>
      <Box display="flex" flexDirection="column" height="100%">
        <ProfessionalAccountSummary />
        <Box
          // height={isSmall ? 300 : 100}
          display="flex"
          alignItems="start"
          justifyContent="space-between"
        >
          <ProfileTabs />
          {!isMedium && !isLoading ? (
            <Box minWidth={100}>
              <Button
                variant="outlined"
                size="medium"
                onClick={() => {
                  navigate({
                    pathname: "edit/projects",
                  });
                }}
              >
                Upload Portfolio Projects
              </Button>
            </Box>
          ) : (
            <LoadingTypography
              lineHeight={3.5}
              width={200}
              isLoading={isLoading}
            />
          )}
        </Box>
        <Box py={4}>
          <Divider />
        </Box>
        {metadata?.restricted && (
          <Box pb={10}>
            <InfoBox
              messages={
                <Fragment>
                  <Typography color="inherit" variant="body2">
                    Your profile is currently not visible to searches by clients
                    and other professionals. Neither are you able to respond to
                    job requests.
                  </Typography>
                  <Typography color="inherit" variant="body2">
                    Please include at least a Title, and Description to your
                    profile - by going to “Edit Profile” under your picture - to
                    have access to these features.
                  </Typography>
                </Fragment>
              }
            />
          </Box>
        )}
        <Box flexGrow={1}>{content}</Box>
      </Box>
    </PageWrapper>
  );
};
