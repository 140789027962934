import { Box } from "@mui/material";
import React from "react";
import useResizeObserver from "use-resize-observer";
import { Project } from "../../../../api-types/projects";
import { ImagePreview } from "../../../client/job-requests/utils";

type FeaturedProjectsProps = { project?: Project; showEmpty?: boolean };

export const FeaturedProject: React.FC<FeaturedProjectsProps> = ({
  project,
  showEmpty,
}) => {
  // eslint-disable-next-file no-undef
  const { ref, width = 0 } = useResizeObserver<HTMLDivElement>();

  return (
    <Box
      // @ts-ignore
      ref={ref}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <ImagePreview
        arrowColor="secondary"
        width={width}
        isLocalImages={false}
        selectedFiles={project?.images ?? []}
        title={project?.jobRequest?.title ?? project?.title}
        name={project?.client?._id}
        showEmpty={showEmpty}
      />
    </Box>
  );
};
