import { Box, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import React, { Fragment, useMemo } from "react";
import { MilestoneInvoice } from "../../../../api-types/projects/milestone-invoices";
import { generateLocalId } from "../../../client/projects/payments/Payments";
import { DownloadableDataGrid, Section } from "../../components";
import { formatNominativeMonth } from "../../components/LoadingTypography";
import { useMilestonesInvoices } from "../../hooks/projects/project/milestone-invoices/useMilestonesInvoices";
import { PaymentReportsSkeleton } from "./PaymentReportsSkeleton";

export const MilestoneInvoices: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
      sortable: false,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      sortable: false,
    },
    {
      field: "professional",
      headerName: "Professional",
      width: 250,
      sortable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      sortable: false,
    },
    { field: "id", headerName: "Invoice ID", width: 150, sortable: false },
    { field: "type", headerName: "Type", width: 200, sortable: false },
  ];

  const { data: milestoneInvoices, isLoading: isLoadingMilestoneInvoices } =
    useMilestonesInvoices();

  const rows = useMemo(() => {
    return (
      milestoneInvoices?.map((milestoneInvoice: MilestoneInvoice, index) => ({
        amount: `$${milestoneInvoice.amount?.toFixed(2)}`,
        professional: milestoneInvoice.professional
          ? `${milestoneInvoice.professional?.accountInformation?.firstName} ${milestoneInvoice.professional?.accountInformation?.lastName}`
          : "---",
        createdAt: format(
          new Date(milestoneInvoice.createdAt),
          formatNominativeMonth
        ),
        description: milestoneInvoice.description,
        id: generateLocalId(milestoneInvoice.description, index),
        type: milestoneInvoice?.type?.toUpperCase(),
      })) ?? []
    );
  }, [milestoneInvoices]);

  return (
    <Section
      isLoading={isLoadingMilestoneInvoices}
      loadingSkeleton={<PaymentReportsSkeleton />}
    >
      <Box columnGap={6} pt={16} py={4}>
        {!rows.length ? (
          <Box pt={30} textAlign="center">
            <Typography variant="body1">
              All invoices for all your projects will be found here.
            </Typography>
          </Box>
        ) : (
          <Fragment>
            <DownloadableDataGrid
              isLoading={isLoadingMilestoneInvoices}
              columns={columns}
              data={rows}
              title="Payment Reports"
            />
          </Fragment>
        )}
      </Box>
    </Section>
  );
};
