import { AccordionClassKey, AccordionProps, Components } from "@mui/material";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiAccordion: Components["MuiAccordion"] = {
  styleOverrides: {
    root: {
      width: "100%",
    },
  },
};
