import makeStyles from "@mui/styles/makeStyles";
import { BLUE, COLOR, YELLOW } from "../components/utils";

export const useWaveStyles = makeStyles({
  root: {
    "&:after": {
      background:
        "linear-gradient(90deg, transparent, rgba(255, 228, 98, 0.13), transparent) !important",
    },
  },
});
