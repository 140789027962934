import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  LoadingTypography,
  HeaderDropdown,
} from "../../../../common/components";
import {
  Collection,
  useNavigationHandlers,
} from "../../../../common/hooks/navigation";
import { useProjectQuery } from "../../../../common/hooks/projects/project/useProjectQuery";
import { usePastProjectsOptions } from "../../../bids/hooks/usePastProjectOptions";

export const PastProjectHeaderContent: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const { data: pastProjects, isLoading: isLoadingPastProjects } =
    usePastProjectsOptions();

  const isLoading = isLoadingProject || isLoadingPastProjects;

  const collectionConfig = {
    type: Collection.PAST_PROJECTS,
    items: pastProjects,
  };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingPastProjects,
    projectId
  );

  const handleSelectCurrentPastProject = useCallback(
    (index: number) => {
      if (!pastProjects) return;

      navigate(`/projects/history/${pastProjects[index]._id}`);
    },
    [navigate, pastProjects]
  );

  return (
    <Box width="60%" py={10}>
      <Box pb={2}>
        <LoadingTypography
          typographyProps={{
            variant: "subtitle2",
          }}
        >
          Past project ({index + 1} of {pastProjects?.length})
        </LoadingTypography>
      </Box>
      <HeaderDropdown
        isLoading={isLoading}
        selectedOptionId={projectId}
        options={pastProjects}
        onSelect={handleSelectCurrentPastProject}
        placeholder={project?.jobRequest?.title}
      />
    </Box>
  );
};
