import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { YELLOW } from "../../../../themes/components/utils";
import { HourlyRateMilestone } from "../../../client/job-requests/create-job-request-wizard/validation-schema";
import { stringToTitleCase } from "../../../utils";

type HourlyRateTableRowProps = {
  actions?: ReactNode;
  estimatedCostPerWeek: {
    min: number;
    max: number;
  };
  hasBorder?: boolean;
  hoursLabel: string;
  hourlyRateLabel: string;
  milestone: HourlyRateMilestone;
  showAsDiff?: boolean;
};

export const HourlyRateTableRow: FC<HourlyRateTableRowProps> = ({
  actions,
  estimatedCostPerWeek,
  hasBorder,
  hoursLabel,
  hourlyRateLabel,
  milestone,
  showAsDiff,
}) => {
  const { description, dueDate, status } = milestone;

  return (
    <TableRow
      style={{
        backgroundColor: showAsDiff ? YELLOW : "inherit",
        border: hasBorder ? `1px solid ${YELLOW}` : "none",
        height: 64,
      }}
    >
      <TableCell />
      <TableCell>
        <Typography
          color={showAsDiff ? "secondary" : "inherit"}
          component="span"
          variant="body2"
        >
          {description}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={showAsDiff ? "secondary" : "inherit"}
          component="span"
          variant="body2"
        >
          {`Between ${dueDate.min} and ${dueDate.max} ${dueDate.type}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={showAsDiff ? "secondary" : "inherit"}
          component="span"
          variant="body2"
        >
          {hoursLabel}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={showAsDiff ? "secondary" : "inherit"}
          component="span"
          variant="body2"
        >
          {hourlyRateLabel}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={showAsDiff ? "secondary" : "inherit"}
          component="span"
          variant="body2"
        >
          {`$${(dueDate?.min ?? 0) * estimatedCostPerWeek.min} - $${
            (dueDate?.max ?? 0) * estimatedCostPerWeek.max
          }`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={showAsDiff ? "secondary" : "inherit"}
          component="span"
          variant="body2"
        >
          {stringToTitleCase(status ?? "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="flex-end" columnGap={2}>
          {actions}
        </Box>
      </TableCell>
    </TableRow>
  );
};
