import makeStyles from "@mui/styles/makeStyles";
import { BLUE, COLOR, YELLOW } from "../components/utils";

export const useWhiteSelectStyles = makeStyles(() => ({
  root: {
    height: 70,
    verticalAlign: "center",
    lineHeight: 3.5,
    paddingLeft: "20px !important",
    "& .MuiSvgIcon-root": {
      marginRight: 10,
    },
  },
}));
