import { Box, Divider } from "@mui/material";
import React from "react";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";
import { InquiryHeaderContent } from "./InquiryHeaderContent";
import { InquiryHeaderSummary } from "./InquiryHeaderSummary";

export const InquiryHeader: React.FC = () => {
  const { isSmall, isMedium } = useResponsiveness();
  return (
    <Box
      display="flex"
      flexDirection={isMedium ? "column" : "row"}
      alignItems="center"
      columnGap={isMedium ? 0 : 10}
    >
      <InquiryHeaderContent />
      <Divider orientation={isMedium ? "vertical" : "horizontal"} flexItem />
      <Box
        display="flex"
        justifyContent={isSmall ? "center" : "start"}
        alignItems="center"
      >
        <InquiryHeaderSummary />
      </Box>
    </Box>
  );
};
