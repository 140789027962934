import { Box, useMediaQuery } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { WithdrawalHistoryItem } from "../../../../api-types/projects/withdrawal-history";
import { DownloadableDataGrid } from "../../components";
import { formatNominativeMonth } from "../../components/LoadingTypography";
import { useWithdrawalHistory } from "../../hooks/projects/project/withdrawal-history/useWithdrawalHistory";

export const WithdrawalHistory: React.FC = () => {
  const smallWidth = useMediaQuery("(max-width: 1000px)", { noSsr: true });

  const generateColumnDef = (isSmallScreen: boolean): GridColDef[] => [
    {
      field: "createdAt",
      headerName: "Date",
      width: isSmallScreen ? 120 : 250,
      sortable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: isSmallScreen ? 100 : 250,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: isSmallScreen ? 100 : 250,
      sortable: false,
    },
    {
      field: "id",
      headerName: "Invoice ID",
      width: isSmallScreen ? 250 : 300,
      sortable: false,
    },
  ];

  const columns = generateColumnDef(smallWidth);
  const { data: withdrawalHistory, isLoading } = useWithdrawalHistory();

  const rows = useMemo(() => {
    return (
      withdrawalHistory?.map(
        (withdrawalHistoryItem: WithdrawalHistoryItem) => ({
          amount: withdrawalHistoryItem.amount,
          createdAt: format(
            new Date(withdrawalHistoryItem.createdAt),
            formatNominativeMonth
          ),
          id: withdrawalHistoryItem._id,
          status: withdrawalHistoryItem.status,
        })
      ) ?? []
    );
  }, [withdrawalHistory]);

  return (
    <Box columnGap={6}>
      <Box>
        <DownloadableDataGrid
          isLoading={isLoading}
          columns={columns}
          data={rows}
          title="Withdrawal History"
        />
      </Box>
    </Box>
  );
};
