import { useTypewriter } from "@chrisfieldsii/react-use-typewriter";
import { Box } from "@mui/material";
import { BLUE, YELLOW } from "../../../../../../themes/components/utils";

export const Typewriter = () => {
  const text = useTypewriter({
    words: [
      "start-up",
      "app",
      "website",
      "brand",
      "business",
      "team",
      "project",
      "clientele",
      "agency",
      "reach",
      "portfolio",
      ".com",
    ],
    loop: true,
    typeSpeed: 150,
    deleteSpeed: 100,
  });

  return (
    <>
      <span>Build your</span>
      <span>&nbsp;</span>
      <Box
        style={{
          display: "inline-block",
          width: "fit-content",
          transform: "skew(10deg)",
          background: YELLOW,
        }}
      >
        <Box
          style={{
            display: "inline-block",
            transform: "skew(-10deg)",
            color: BLUE,
          }}
        >
          <span>&nbsp;</span>
          {text}
          <span>&nbsp;</span>
        </Box>
      </Box>
    </>
  );
};
