import { Components } from "@mui/material";

export const MuiBadge: Components["MuiBadge"] = {
  styleOverrides: {
    colorPrimary: {
      height: 24,
      minWidth: 24,
      borderRadius: 20,
      fontSize: 16,
      fontWeight: 650,
      backgroundColor: "#f48fb1",
    },
  },
};
