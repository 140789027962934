import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../common/elements/BackButton";

export const SavedMainbar = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box pt={10}>
        <BackButton
          onClick={() => {
            navigate("/job-requests/search?tab=jobs");
          }}
          backLabel="Back to Search"
        />
      </Box>
      <Box py={10} pl={1}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: "bold",
          }}
        >
          Saved Job Post(s)
        </Typography>
      </Box>
    </Box>
  );
};
