import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, IconButton, SvgIconProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ChangeEvent, ReactNode, SyntheticEvent } from "react";
import { TextOverflow } from "../../../common/components";
import { CustomInput } from "../../../common/elements";
import { TextBox } from "./TextBox";

import "react-alice-carousel/lib/alice-carousel.css";

type ImagePreviewFooterProps = {
  children?: ReactNode;
  selectedFiles: any[];
  index?: number;
  name?: string;
  arrowColor?: SvgIconProps["color"];
  color?: string;
  handleImageDescriptionChange?: (e: SyntheticEvent, index: number) => void;
  hasImageDescription?: boolean;
  onNext?: (e: React.MouseEvent) => void;
  onPrev?: (e: React.MouseEvent) => void;
  hasAdditionalInfo?: boolean;
  handleFocus?: (field: string, value: string) => void;
};

const useStyles = makeStyles({
  arrowButton: {
    width: 24,
    height: 24,
    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
    },
  },
  additionalInfo: {
    justifyContent: "space-between",
    marginTop: 12,
  },
  additionalInfoWrapper: {
    color: "inherit",
    flex: 1,
    marginRight: 24,
  },
  additionalInfoArrows: {
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  textAreaNotice: {
    fontSize: 14,
  },
});

export const ImagePreviewFooter = ({
  index = 0,
  selectedFiles,
  name,
  arrowColor = "primary",
  color = "inherit",
  handleImageDescriptionChange,
  onNext,
  onPrev,
  hasAdditionalInfo,
  hasImageDescription,
}: ImagePreviewFooterProps) => {
  const styles = useStyles();

  return (
    <Box>
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
        className={hasAdditionalInfo ? styles.additionalInfo : ""}
      >
        {name && (
          <Box width="40%">
            <TextOverflow
              style={{
                color: color,
                fontWeight: 600,
                paddingLeft: 3,
              }}
              width="100%"
              wrapperClassName={styles.additionalInfoWrapper}
            >
              {name}
            </TextOverflow>
          </Box>
        )}
        <Box height={32} width="30%">
          {!name &&
            selectedFiles[index]?.description &&
            !handleImageDescriptionChange && (
              <Typography
                variant="body2"
                style={{ marginRight: "auto" }}
                noWrap
              >
                {selectedFiles[index].description}
              </Typography>
            )}
        </Box>
        {selectedFiles?.length > 1 && (
          <Box display="flex" alignItems="center">
            <Box width={24}>
              <IconButton
                onClick={onPrev}
                className={styles.arrowButton}
                data-cy-prev
                size="large"
              >
                <NavigateBeforeIcon color={arrowColor} fontSize="small" />
              </IconButton>
            </Box>
            <Box width={60} alignSelf="center">
              <Typography
                style={{
                  color: color,
                  fontWeight: "bold",
                  flexGrow: 1,
                  paddingLeft: 2,
                  textAlign: "center",
                }}
                variant={"body2"}
              >
                {index + 1} of {selectedFiles?.length}
              </Typography>
            </Box>
            <Box width={24}>
              <IconButton
                onClick={onNext}
                className={styles.arrowButton}
                data-cy-next
                size="large"
              >
                <NavigateNextIcon color={arrowColor} fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
      {hasImageDescription && (
        <Box display="flex" flexDirection="column">
          <Box width="100%" flexDirection="column" display="flex" mt={10}>
            <CustomInput
              value={selectedFiles[index]?.description ?? ""}
              placeholder="Description"
              handleChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                value: string
              ) => {
                if (value.length > 100) return;
                handleImageDescriptionChange?.(event, index);
              }}
            />
          </Box>
          <TextBox
            text={`${
              selectedFiles[index]?.description?.length ?? 0
            }/100 characters maximum`}
            variant="h4"
            classes={styles.textAreaNotice}
            boxProps={{ pt: 4, alignSelf: "flex-end" }}
          />
        </Box>
      )}
    </Box>
  );
};
