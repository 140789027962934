import makeStyles from "@mui/styles/makeStyles";

type HeadlineTextProps = {
  primary: string;
  secondary: string;
  background: string;
};

export const useHeadlineText = makeStyles(() => ({
  skewedText: {
    width: "fit-content",
    display: "inline-flex",
    color: (props: HeadlineTextProps) => props.primary,
    background: (props: HeadlineTextProps) => props.background,
    transform: "skew(10deg)",
    "& span": {
      display: "inline-block",
      transform: "skew(-10deg)",
    },
  },
  transparent: {
    width: "fit-content",
    display: "inline-flex",
    color: (props: HeadlineTextProps) => props.secondary,
  },
}));
