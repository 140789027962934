import { Box, Divider, Typography } from "@mui/material";
import React, { useMemo } from "react";
import useResizeObserver from "use-resize-observer";
import { YELLOW } from "../../../../../../../themes/components/utils";
import { ImagePreview } from "../../../../../../client/job-requests/utils";
import { SkillsOutput } from "../../../../../../common/components/SkillsOutput";
import { useProjectQuery } from "../../../../../../common/hooks/projects/project/useProjectQuery";
import { Description } from "../../../../../../common/professionals-search/projects/project/sections/Description";

export const Details: React.FC = () => {
  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  // eslint-disable-next-line no-undef
  const { ref, width = 0 } = useResizeObserver<HTMLDivElement>();

  const skills = useMemo(() => {
    if (!project?.skills || isLoadingProject) {
      return [];
    }

    return project?.skills.map((skill) => skill.name);
  }, [isLoadingProject, project?.skills]);

  const hasImage = Boolean(project?.images?.length);

  return (
    //@ts-ignore
    <Box ref={ref} width="100%">
      {hasImage && (
        <Box pb={8} color={YELLOW}>
          <ImagePreview
            width={width}
            height={600}
            name={project?.client?.name ?? ""}
            title={project?.title}
            isLocalImages={false}
            selectedFiles={project?.images ?? []}
          />
        </Box>
      )}
      <Description label="Description" description={project?.description} />
      {project?.external && project?.timeFrame?.projectTimeFrame?.weeks && (
        <Box pt={2.5}>
          <Divider />
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="subtitle1"
          >
            Timeframe
          </Typography>
          <Box display="flex" columnGap={1}>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="body2"
            >
              Timeframe:
            </Typography>
            <Typography variant="body2">
              {project.timeFrame.projectTimeFrame.weeks} week(s)
            </Typography>
          </Box>
        </Box>
      )}
      {/*<Box pt={6}>*/}
      {/*  <Divider />*/}
      {/*</Box>*/}
      {/*<TimeframeCost startDate={new Date()} endDate={new Date()} cost={5} />*/}
      {/*<Box pt={2}>*/}
      {/*  <Divider />*/}
      {/*</Box>*/}
      {/*<Rating rating={9} />*/}
      <Box pt={2.5}>
        <Divider />
      </Box>
      {skills.length > 0 && (
        <>
          <Box pt={2.5}>
            <SkillsOutput skills={skills} />
          </Box>
          <Box py={2.5}>
            <Divider />
          </Box>
        </>
      )}
      {Boolean(project?.url) && (
        <>
          <Box pt={2.5}>
            <Typography
              color="primary"
              component="a"
              href={project?.url}
              variant="body1"
              noWrap
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              {project?.url}
            </Typography>
          </Box>
          <Box py={2.5}>
            <Divider />
          </Box>
        </>
      )}
    </Box>
  );
};
