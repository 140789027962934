import { Box, Button, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountType } from "../../../api-types/common";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { useSubscribeModal, useUser } from "../../state/hooks";
import { SubscribeDialog } from "../components/modals/subscribe-dialog";
import { useSubscribe } from "../hooks/subscribe";
import { useResponsiveness } from "../hooks/utils/useResponsiveness";
import { useScopedDowngradedStateValue } from "../hooks/utils/useScopedDowngradedStateValue";
import { TheTalentedLogo } from "../logo";

type TopbarProps = {
  color?: string;
  height?: number;
  inverse?: boolean;
  containerProps?: any;
};

const useStyles = makeStyles({
  signUpEarly: {
    padding: 2,
    color: YELLOW,
    fontSize: 18,
    fontFamily: "inherit",
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: 15,
    },
    ["@media screen and (max-width: 850px)"]: {
      fontSize: 12,
    },
  },
  root: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  dialog: {
    marginTop: 56,
    ["@media screen and (max-height: 400px)"]: {
      marginTop: 42,
    },
  },
  link: {
    letterSpacing: 0.5,
    textStrokeWidth: 0.25,
    "&:hover": {
      letterSpacing: 0.5,
      textStrokeWidth: 1.25,
    },
  },
  button: {
    backgroundColor: BLUE,
    "&:hover": {
      backgroundColor: BLUE,
    },
  },
});

export const Topbar: React.FC<TopbarProps> = ({
  height = 100,
  inverse,
  color,
  containerProps,
}) => {
  const setSubscribeModal = useSubscribeModal().set;
  const subscribeModal = useScopedDowngradedStateValue(useSubscribeModal());
  const [isSubscribed, setIsSubscribed] = useState(false);
  const styles = useStyles();
  const { accountType } = useScopedDowngradedStateValue(useUser());
  const navigate = useNavigate();
  const { isSmall } = useResponsiveness();
  // const isMobileView = useIsMobileView();
  // const [open, setOpen] = useState(false);

  const { mutate: subscribe, isLoading } = useSubscribe({
    onSuccess: () => {
      setIsSubscribed(true);
    },
  });

  const onCloseModalHandler = useCallback(() => {
    setSubscribeModal({ open: false });
  }, [setSubscribeModal]);

  const onSignInHandler = useCallback(() => {
    // if (!isMobileView) {
    // eslint-disable-next-line no-undef
    window.history.pushState(undefined, "/");
    // eslint-disable-next-line no-undef
    window.location.replace("/sign-in");
    // return;
    // }
    // setOpen(true);
  }, []);

  const onSignUpHandler = useCallback(() => {
    // if (!isMobileView) {
    // eslint-disable-next-line no-undef
    window.history.pushState(undefined, "/");
    // eslint-disable-next-line no-undef
    window.location.replace("sign-up");
    // return;
    // }
    // setOpen(true);
  }, []);

  return (
    <Box
      {...containerProps}
      height={height}
      className={styles.root}
      display="flex"
      alignItems="center"
      position="fixed"
      justifyContent="space-between"
      width="100%"
      minWidth={280}
      zIndex={10}
      style={{
        opacity: 0.97,

        // eslint-disable-next-line no-nested-ternary
        backgroundColor: color ? color : inverse ? YELLOW : BLUE,
        transition: "0.3s",
      }}
    >
      <TheTalentedLogo inverse={inverse} />
      <Box style={{ pointerEvents: "auto" }} display="flex" columnGap={9}>
        {!isSmall && (
          <Button
            size="small"
            variant="outlined"
            color={inverse ? "secondary" : "primary"}
            sx={{ borderColor: inverse ? "secondary.main" : "primary.main" }}
            onClick={() => {
              navigate("/blog", { replace: true });
            }}
          >
            Our Blog
          </Button>
        )}
        {!accountType && (
          <Box display="flex" columnGap={4}>
            <Button
              variant="text"
              size="small"
              onClick={onSignUpHandler}
              data-cy-sign-up="top-bar"
              color={inverse ? "secondary" : "primary"}
            >
              Sign Up
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={onSignInHandler}
              data-cy-sign-in="top-bar"
              color={inverse ? "secondary" : "primary"}
            >
              Sign In
            </Button>
          </Box>
        )}
        {accountType === AccountType.PROFESSIONAL && (
          <Link
            color={inverse ? "secondary" : "primary"}
            className={styles.link}
            underline="hover"
            component="button"
            variant="body1"
            onClick={() => {
              navigate("/account", { replace: true });
            }}
          >
            My Account
          </Link>
        )}
        {accountType === AccountType.CLIENT && (
          <Link
            className={styles.link}
            color={inverse ? "secondary" : "primary"}
            underline="hover"
            component="button"
            variant="body1"
            onClick={() => {
              navigate("/browse/?type=professionals", { replace: true });
            }}
          >
            Browse Professionals
          </Link>
        )}
      </Box>
      <SubscribeDialog
        classNames={styles.dialog}
        isLoading={isLoading}
        subscribed={isSubscribed}
        title="Be part of The Talented community! Join us as an early adopter."
        subtitle="We’re launching very soon. Stay tuned for the product launch email
            invite. Looking forward to having you on board."
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        open={subscribeModal.open}
        onClose={onCloseModalHandler}
        onConfirm={(values) => {
          subscribe(values);
        }}
      />
      {/*<MobileNotSupportedDialog open={open} onClose={() => setOpen(false)} />*/}
    </Box>
  );
};
