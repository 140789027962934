import { Box, Divider } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageWrapper } from "../../../../../app/container/PageWrapper";
import { BackButton } from "../../../../../common/elements/BackButton";
import { CollectionNavigationActions } from "../../../../../common/elements/CollectionNavigationActions";
import {
  Collection,
  useNavigationHandlers,
} from "../../../../../common/hooks/navigation";
import { usePastProjectsQuery } from "../../../../../common/hooks/projects/usePastProjectsQuery";
import { ProfessionalAccountSummary } from "../../../account-summary";
import { PortfolioProjectHeader } from "./header";
import { useDefaultProjectPage } from "./hooks";
import { Details } from "./project-details";

export const PortfolioProject: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useDefaultProjectPage();
  const { projectId } = useParams();

  const { data: pastProjects, isLoading: isLoadingProjects } =
    usePastProjectsQuery();

  const collectionConfig = {
    type: Collection.PORTFOLIO_PROJECT,
    items: pastProjects,
  };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(collectionConfig, isLoadingProjects, projectId);

  const onBackHandler = useCallback(() => {
    navigate("/account?tab=portfolio");
  }, [navigate]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <PageWrapper>
      <Box display="flex" flexDirection="column" height="100%">
        <ProfessionalAccountSummary />
        <Divider />
        <Box pt={10}>
          <BackButton backLabel="Back To Profile" onClick={onBackHandler} />
        </Box>
        <Box pb={10}>
          <PortfolioProjectHeader />
        </Box>
        <Details />
        <CollectionNavigationActions
          label={"Project"}
          index={index}
          totalCount={pastProjects?.length ?? 0}
          handleSelectNext={handleSelectNext}
          handleSelectPrevious={handleSelectPrevious}
        />
      </Box>
    </PageWrapper>
  );
};
