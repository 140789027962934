import { Box, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Card } from "../../../elements/Card";
import { useResponsiveness } from "../../../hooks/utils/useResponsiveness";

type SwitchProps = {
  isLoading?: boolean;
};

export const Switch: React.FC<SwitchProps> = ({ isLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSmall, isMedium } = useResponsiveness();

  const onTabNavigationHandler = useCallback(
    (tab: string) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const cardWidth = useMemo(() => {
    if (isSmall) {
      return "100%";
    }

    if (isMedium) {
      return 220;
    }

    return 270;
  }, [isMedium, isSmall]);

  return (
    <Box>
      <Box pt={10}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: "bold",
          }}
        >
          Account and Subscription Information
        </Typography>
      </Box>
      <Box
        display="flex"
        py={10}
        columnGap={5}
        rowGap={5}
        sx={{
          fontWeight: "bold",
        }}
        flexDirection={isSmall ? "column" : "row"}
      >
        <Card
          width={cardWidth}
          label="Account Information"
          isLoading={isLoading}
          isSelected={
            searchParams.get("tab") === "account" || !searchParams.get("tab")
          }
          onClick={() => onTabNavigationHandler("account")}
        />
        <Card
          width={cardWidth}
          isLoading={isLoading}
          label="Subscription Information"
          isSelected={searchParams.get("tab") === "subscription"}
          onClick={() => onTabNavigationHandler("subscription")}
        />
      </Box>
    </Box>
  );
};
