import { Box, useMediaQuery } from "@mui/material";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { useMemo } from "react";
import { COLOR } from "../../../../../themes/components/utils";
import { useSubscribeModal } from "../../../../state/hooks";
import { useScopedDowngradedStateValue } from "../../../hooks/utils/useScopedDowngradedStateValue";
import { AccountTypeSwitch } from "../../client/parallax/AccountTypeSwitch";
import {
  MOBILE_MIN_HEIGHT,
  MOBILE_NAVBAR_HEIGHT,
  PROFESSIONAL_MOBILE_VIEW_CONFIG,
  SMALL_MOBILE_NAVBAR_HEIGHT,
} from "../../constants";
import { CurrentPageContent } from "../../CurrentPageContent";
import { useScrollEffects } from "../../hooks";
import { Topbar } from "../../Topbar";
import { LandingStepA } from "../steps";
import {
  professionalDesktopPages,
  professionalMobilePages,
} from "./ProfessionalLargeViewParallax";

export const ProfessionalSmallViewParallax = () => {
  const { backgroundColors, pagesCount } = PROFESSIONAL_MOBILE_VIEW_CONFIG;
  const smallMobileHeight = useMediaQuery("(max-height: 400px)", {
    noSsr: true,
  });
  const subscribeDialog = useScopedDowngradedStateValue(useSubscribeModal());
  const smallWidthView = useMediaQuery("(max-width: 600px)");

  const { isScrolling, currentVisibleView, lastVisitedView } = useScrollEffects(
    {
      pagesCount,
      backgroundColors,
    }
  );

  const height = useMemo(() => {
    if (smallMobileHeight) return SMALL_MOBILE_NAVBAR_HEIGHT;

    return MOBILE_NAVBAR_HEIGHT;
  }, [smallMobileHeight]);

  return (
    <Box height="100%" width="100%">
      <Topbar
        height={height * (isScrolling || subscribeDialog.open ? 0.7 : 1)}
        containerProps={{
          top: 0,
          position: "fixed",
        }}
      />
      <Parallax
        className={`parallax-container`}
        style={{
          backgroundColor: COLOR.PINK,
        }}
        pages={pagesCount}
      >
        <ParallaxLayer
          style={{
            minHeight: MOBILE_MIN_HEIGHT,
          }}
        >
          <LandingStepA />
          <AccountTypeSwitch defaultChecked="professional" />
        </ParallaxLayer>
        <ParallaxLayer
          sticky={{
            start: 1,
            end: pagesCount - 2,
          }}
          style={{
            display: "flex",
            minHeight: MOBILE_MIN_HEIGHT,
          }}
        >
          <CurrentPageContent
            desktopPages={professionalDesktopPages}
            mobilePages={professionalMobilePages}
            currentPage={currentVisibleView}
            previousPage={lastVisitedView}
          >
            {!smallWidthView && (
              <AccountTypeSwitch defaultChecked="professional" />
            )}
          </CurrentPageContent>
        </ParallaxLayer>
        <ParallaxLayer
          offset={pagesCount - 1}
          style={{
            minHeight: MOBILE_MIN_HEIGHT,
          }}
        >
          <LandingStepA />
        </ParallaxLayer>
      </Parallax>
    </Box>
  );
};
