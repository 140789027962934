import { Box, Button } from "@mui/material";
import { Formik, FormikValues } from "formik";
import { omit } from "lodash";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PageWrapper } from "../../../../app/container/PageWrapper";
import { useJobPostCreateMutation } from "../../../../common/hooks/job-posts";
import { useJobPackages } from "../hooks/useJobPackages";
import { parseJobPostForm } from "../utils";
import { JobPostSetUp } from "./JobPostSetUp";
import {
  initialJobPostValues,
  createJobPostValidationSchema,
} from "./JobPostWizard";

export const NewJobPost = () => {
  const navigate = useNavigate();
  const { jobPostsCredits } = useJobPackages();
  const hasJobPostsCredits = jobPostsCredits > 0;

  const { mutate: createJobPostMutation } = useJobPostCreateMutation();

  const createJobPost = useCallback(
    (values: FormikValues, isDraft: boolean = false) => {
      createJobPostMutation(parseJobPostForm({ ...values, isDraft }, true));
    },
    [createJobPostMutation]
  );

  return (
    <PageWrapper>
      <Formik
        initialValues={initialJobPostValues}
        enableReinitialize
        onSubmit={(values) => {
          createJobPost(omit(values, "_id"));
        }}
        validateOnMount
        validationSchema={createJobPostValidationSchema}
      >
        {({
          handleSubmit,
          setFieldValue,
          handleChange,
          handleBlur,
          values,
          errors,
          isValid,
          setValues,
        }) => (
          <form onSubmit={handleSubmit}>
            <JobPostSetUp
              handleUpdateSkills={(newSkills: string[]) => {
                setFieldValue(
                  "skills",
                  newSkills.map((skill) => ({ name: skill }))
                );
              }}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              isValid={isValid}
              setValues={setValues}
            />

            <Box
              py={10}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Box display="flex" columnGap={3}>
                <Button
                  size="medium"
                  variant="outlined"
                  onClick={() => {
                    navigate("/job-posts");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  variant="contained"
                  type="submit"
                  disabled={!hasJobPostsCredits || !isValid}
                  data-cy-btn="post"
                >
                  Post
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </PageWrapper>
  );
};
