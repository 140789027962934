import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, Fragment, ReactNode, useMemo } from "react";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";

type MilestonesTableProps = {
  onAddMilestone: () => void;
  TableHeadContent: ReactNode;
};

export const MilestonesTable: FC<MilestonesTableProps> = ({
  children,
  onAddMilestone,
  TableHeadContent,
}) => {
  const { isExtraSmall, isSmall, isMedium } = useResponsiveness();

  const tableWidth = useMemo(() => {
    if (isExtraSmall) return 200;

    if (isSmall) return 300;

    if (isMedium) return 600;

    return "100%";
  }, [isExtraSmall, isMedium, isSmall]);

  return (
    <Fragment>
      <TableContainer
        sx={{
          maxHeight: 400,
          width: tableWidth,
          overflow: "auto",
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>{TableHeadContent}</TableRow>
          </TableHead>

          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <Box py={2.5} pl={4}>
        <Button onClick={onAddMilestone} size="small" variant="contained">
          Add Milestone
        </Button>
      </Box>
    </Fragment>
  );
};
