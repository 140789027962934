import { Box } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  LoadingTypography,
  HeaderDropdown,
} from "../../../../common/components";
import { Card } from "../../../../common/elements/Card";
import { useJobRequestBids } from "../../../../common/hooks/job-requests/job-request/bids/useJobRequestBids";
import { useJobRequestInquires } from "../../../../common/hooks/job-requests/job-request/inquires/useJobRequestInquires";
import { useSentInvitations } from "../../../../common/hooks/job-requests/job-request/invites";
import { useJobRequestQuery } from "../../../../common/hooks/job-requests/job-request/useJobRequestQuery";
import {
  Collection,
  useNavigationHandlers,
} from "../../../../common/hooks/navigation";
import { useNavigateOnCondition } from "../../../../common/hooks/utils";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { useJobRequestsOptions } from "../../../../professional/bids/hooks/useJobRequestsOptions";

export const formatShortDate = "yyyy/MM/dd";

export const JobRequestHeaderContent: React.FC = () => {
  const navigate = useNavigate();
  const { jobRequestId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSmall } = useResponsiveness();

  const { data: jobRequest, isLoading: isLoadingJobRequest } =
    useJobRequestQuery();

  const { data: jobRequests, isLoading: isLoadingJobRequests } =
    useJobRequestsOptions();

  const collectionConfig = {
    type: Collection.JOB_REQUESTS,
    items: jobRequests,
  };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingJobRequests,
    jobRequestId
  );

  const isLoading = isLoadingJobRequest || isLoadingJobRequests;
  const noData = !isLoading && !jobRequest;

  useNavigateOnCondition("/job-requests/", noData);

  const { data: jobRequestsBids, isLoading: isLoadingJobRequestsBids } =
    useJobRequestBids();

  const {
    data: jobRequestsInquiries,
    isLoading: isLoadingJobRequestsInquiries,
  } = useJobRequestInquires();

  const { data: invitations, isLoading: isLoadingInvitations } =
    useSentInvitations();

  const jobRequestsBidsCount = useMemo(() => {
    if (isLoadingJobRequestsBids) return 0;

    return jobRequestsBids?.length ?? 0;
  }, [isLoadingJobRequestsBids, jobRequestsBids]);

  const jobRequestsInquiriesCount = useMemo(() => {
    if (isLoadingJobRequestsInquiries) return 0;

    return jobRequestsInquiries?.length ?? 0;
  }, [isLoadingJobRequestsInquiries, jobRequestsInquiries]);

  const sentInvitationsCount = useMemo(() => {
    if (isLoadingInvitations) return 0;

    return (
      invitations?.filter(
        (invitation) => invitation?.jobRequestId === jobRequestId
      ).length ?? 0
    );
  }, [invitations, isLoadingInvitations, jobRequestId]);

  const handleSelectCurrentProject = useCallback(
    (index: number) => {
      if (!jobRequests) return;

      navigate({
        pathname: `/job-requests/${jobRequests[index]._id}`,
        search: `?${createSearchParams({
          tab: "details",
        })}`,
      });
    },
    [jobRequests, navigate]
  );

  const onTabNavigationHandler = useCallback(
    (tab: string) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <Box>
      <Box display="flex" flexDirection="column" rowGap={2}>
        <LoadingTypography
          isLoading={isLoading}
          typographyProps={{
            variant: "subtitle2",
          }}
        >
          Job Request ({1 + index} of {jobRequests?.length})
        </LoadingTypography>
        <Box py={10}>
          <HeaderDropdown
            isLoading={isLoading}
            selectedOptionId={jobRequestId}
            options={jobRequests}
            onSelect={handleSelectCurrentProject}
            placeholder={jobRequest?.title}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        columnGap={5}
        flexDirection={isSmall ? "column" : "row"}
      >
        <Box height={108}>
          <Card
            width={isSmall ? "100%" : 106}
            variant="subtitle2"
            label="Details"
            isSelected={
              searchParams.get("tab") === "details" || !searchParams.get("tab")
            }
            onClick={() => onTabNavigationHandler("details")}
            isLoading={isLoading}
          />
        </Box>
        <Box height={108}>
          <Card
            width={isSmall ? "100%" : 106}
            variant="subtitle2"
            label={`${jobRequestsBidsCount} Bid(s)`}
            isSelected={searchParams.get("tab") === "bids"}
            onClick={() => onTabNavigationHandler("bids")}
            isLoading={isLoading || isLoadingJobRequestsBids}
          />
        </Box>
        <Box height={108}>
          <Card
            width={isSmall ? "100%" : 150}
            variant="subtitle2"
            label={`${jobRequestsInquiriesCount} Inquiry(ies)`}
            isSelected={searchParams.get("tab") === "inquiries"}
            onClick={() => onTabNavigationHandler("inquiries")}
            isLoading={isLoading || isLoadingJobRequestsInquiries}
          />
        </Box>
        <Box height={108}>
          <Card
            width={isSmall ? "100%" : 174}
            variant="subtitle2"
            label={`${sentInvitationsCount} Pro(s) Invited`}
            isSelected={searchParams.get("tab") === "invitations"}
            onClick={() => onTabNavigationHandler("invitations")}
            isLoading={isLoading || isLoadingInvitations}
          />
        </Box>
      </Box>
    </Box>
  );
};
