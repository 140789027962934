import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import {
  ProfessionalAccountSummaryDescription,
  ProfessionalAccountSummaryInfo,
} from "../../../../common/account/profile";
import { useProfessional } from "../../../../common/hooks/professionals/professional/useProfessional";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";

export const ProfessionalAccountSummary: React.FC = () => {
  const { professionalId } = useParams();
  const { isSmall } = useResponsiveness();
  const { data: professional, isLoading } = useProfessional(professionalId);

  return (
    <Box display="flex" columnGap={12} height={300} width="100%">
      {!isSmall && (
        <ProfessionalAccountSummaryInfo
          profileInformation={professional?.profileInformation}
          isLoading={isLoading}
        />
      )}
      <ProfessionalAccountSummaryDescription
        profileInformation={professional?.profileInformation}
        accountInformation={professional?.accountInformation}
        isLoading={isLoading}
        averageRating={professional?.averageRating}
        ratingsCount={professional?.ratingsCount}
      />
    </Box>
  );
};
