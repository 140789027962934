import { Box, useMediaQuery } from "@mui/material";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useHeadlineText,
  useMediumItalic,
  useMobileStyles,
  useStepDStyles,
} from "../../../../../../themes/style-hooks";
import { AccountTypeSwitch } from "../../../client/parallax/AccountTypeSwitch";

export const LandingStepEHeadline = () => {
  const styles = useStepDStyles();
  const mediumItalic = useMediumItalic();
  const mobileView = useMediaQuery("(max-width: 800px)");

  const { transparent, skewedText } = useHeadlineText({
    primary: COLOR.YELLOW,
    secondary: COLOR.DARK_BROWN,
    background: COLOR.DARK_BROWN,
  });

  const { mobileHeadlineWrapper } = useMobileStyles();

  return (
    <Box className={mobileHeadlineWrapper}>
      <Box className={mediumItalic.root}>
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;Professionals can&nbsp;</span>
        </Box>
      </Box>
      <Box className={mediumItalic.root}>
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;partner&nbsp;</span>
        </Box>
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;to&nbsp;</span>
        </Box>
      </Box>
      <Box className={mediumItalic.root}>
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;collaborate&nbsp;</span>
        </Box>
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;on&nbsp;</span>
        </Box>
      </Box>
      <Box className={mediumItalic.root}>
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;projects.&nbsp;</span>
        </Box>
      </Box>
      {!mobileView && <AccountTypeSwitch defaultChecked="professional" />}
    </Box>
  );
};
