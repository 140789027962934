import { Box, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import { Loader } from "../../../../common/elements/Loader";
import { useSentInvitations } from "../../../../common/hooks/job-requests/job-request/invites";
import { useJobRequestQuery } from "../../../../common/hooks/job-requests/job-request/useJobRequestQuery";
import { ProfilePicture } from "../../../../common/summary-profile";

const useStyles = makeStyles({
  root: {
    "& div.MuiDataGrid-row:hover": {
      cursor: "pointer",
      background: "initial",
    },
  },
});

const InvitationStatusMap = {
  PENDING: "Response Pending",
  ACCEPTED: "Placed Bid",
  REJECTED: "Placed Inquiry",
};

type ObjectKey = keyof typeof InvitationStatusMap;

export const Invited: React.FC = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const { jobRequestId } = useParams();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Professional",
      width: 350,
      renderCell: (params: any) => {
        const { accountInformation, profileInformation } = params.value;
        return (
          <Box display="flex" alignItems="center" columnGap={1.5}>
            <ProfilePicture
              width={42}
              profileInformation={profileInformation}
            />
            <Typography
              variant="body2"
              style={{
                fontWeight: "bold",
              }}
            >
              {accountInformation?.firstName} {accountInformation?.lastName}
            </Typography>
          </Box>
        );
      },
      sortable: false,
    },
    {
      field: "skillSet",
      headerName: "Skillset",
      flex: 1,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Invitation Date",
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Invitation Status",
      flex: 1,
      sortable: false,
    },
  ];

  const { isLoading: isLoadingJobRequest } = useJobRequestQuery();

  const { data: invitations, isLoading: isLoadingInvitations } =
    useSentInvitations();

  const rows = useMemo(() => {
    if (isLoadingInvitations || !invitations) return [];

    return invitations
      .filter((invitation) => invitation?.jobRequestId === jobRequestId)
      .map(({ professional, status, _id, createdAt }) => ({
        id: _id,
        skillSet:
          (professional?.profileInformation?.skills ?? [])
            .map((skill) => skill.name)
            .join(", ") ?? "",
        name: professional,
        createdAt: createdAt
          ? format(new Date(createdAt), formatNominativeMonth)
          : "-",
        status: InvitationStatusMap[status as ObjectKey],
        proId: professional._id,
      }))
      .reverse();
  }, [isLoadingInvitations, invitations, jobRequestId]);

  if (isLoadingInvitations || isLoadingJobRequest || !invitations)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
      >
        <Loader />
      </Box>
    );

  if (rows.length === 0) {
    return (
      <Box height="50%">
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
            }}
          >
            Invitations
          </Typography>
          <Box py={2.5}>
            <Divider />
          </Box>
        </Box>
        <Box textAlign="center">
          <Typography variant="body1">
            You haven't invited any professional to work on this project.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
          }}
        >
          Invitations
        </Typography>
        <Box py={2.5}>
          <Divider />
        </Box>
      </Box>
      <Box py={5} height={350} maxHeight={500}>
        <DataGrid
          className={styles.root}
          rows={rows}
          columns={columns}
          rowHeight={75}
          autoPageSize
          // pageSize={3}
          // rowsPerPageOptions={[3]}
          // disableSelectionOnClick
          hideFooterPagination={!(rows.length > 1)}
          disableColumnFilter
          disableColumnMenu
          onRowClick={({ row }) => navigate(`/professionals/${row.proId}`)}
        />
      </Box>
    </Box>
  );
};
