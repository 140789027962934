import { Box, Radio as RadioCore, RadioProps, useTheme } from "@mui/material";

export type Props = RadioProps & {
  radioColor?: string;
};

export const Radio = (props: Props) => {
  const { palette } = useTheme();
  const { radioColor = palette.primary.main } = props;

  return (
    <RadioCore
      {...props}
      icon={
        <Box
          component="span"
          border={2}
          borderColor={radioColor}
          borderRadius={50}
          width={32}
          height={32}
        />
      }
      checkedIcon={
        <Box
          alignItems="center"
          border={2}
          borderColor={radioColor}
          borderRadius={50}
          component="span"
          display="inline-flex"
          height={32}
          padding="2px"
          width={32}
        >
          <Box
            bgcolor={radioColor}
            borderRadius={50}
            component="span"
            flexGrow={1}
            height="100%"
          />
        </Box>
      }
    />
  );
};
