import { Box, Divider, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { AccountType } from "../../../../api-types/common";
import { CreateBidRequest } from "../../../../api-types/job-requests/bids";
import {
  ConfirmationDialog,
  Section,
  TextOverflow,
} from "../../../common/components";
import { formatNominativeMonth } from "../../../common/components/LoadingTypography";
import { useAcceptContractOfferMutation } from "../../../common/hooks/job-requests/job-request/bids/useAcceptContractOfferMutation";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useCurrentJobRequest, useUser } from "../../../state/hooks";
import { useToast } from "../../../utils/useToast";
import { NEW_ID } from "../../inquiries/hooks/useDefaultInquiryPage";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../job-requests/hooks/feedbackErrors";
import { useBidDataFunding } from "../hooks";
import { accountBidKey, useAccountBid } from "../hooks/useAccountBid";
import { useConfirmationModal } from "../state/hooks";
import { BidCommunicationSkeleton } from "./BidCommunicationSkeleton";
import { EditingForm } from "./EditingForm";
import {
  useAddBidMessageMutation,
  useBidUpdateMutation,
  useOnConfirmWithdrawalHandler,
} from "./hooks";
import { MessagesForm } from "./MessagesForm";
import { ProposedBidActions } from "./ProposedBidActions";

export const Communication: React.FC = () => {
  const { notifySuccess } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const { bidId } = useParams();
  const setConfirmationModalState = useConfirmationModal().set;
  const [startDate] = useState(new Date());
  const navigate = useNavigate();

  const { id } = useScopedDowngradedStateValue(useUser());

  const { open } = useScopedDowngradedStateValue(useConfirmationModal());

  const { mutate: updateBid, isLoading: isUpdating } = useBidUpdateMutation();

  const setCurrentJobRequest = useCurrentJobRequest().set;

  const { mutate: addBidMessage, isLoading: isAddingNew } =
    useAddBidMessageMutation();

  const { data: bidData, isLoading: isLoadingBid } = useAccountBid();

  const bidDataFunding = useBidDataFunding();

  const messages = useMemo(() => {
    if (isLoadingBid || !bidData) return [];

    return bidData?.messages ?? [];
  }, [bidData, isLoadingBid]);

  const onHandleUpdateBid = useCallback(
    (values: CreateBidRequest) => {
      updateBid({ ...values, id: bidId });
    },
    [bidId, updateBid]
  );

  const onNewMessageHandler = useCallback(
    (newMessage: string) => {
      if (isLoadingBid) return;
      addBidMessage({
        messages: [
          {
            content: newMessage,
            from: id ?? "",
            to: bidData?.jobRequest.createdBy._id ?? "",
          },
        ],
        accountType: AccountType.PROFESSIONAL,
        jobRequestId: String(bidData?.jobRequest._id),
      });
    },
    [addBidMessage, bidData?.jobRequest, id, isLoadingBid]
  );

  const onWithdrawHandler = useCallback(() => {
    setConfirmationModalState({ open: true });
  }, [setConfirmationModalState]);

  const onConfirmWithdrawHandler = useOnConfirmWithdrawalHandler(
    undefined,
    () => {
      notifySuccess(feedbackMessage("bid", FEEDBACK_TYPE.WITHDRAW));
      setCurrentJobRequest(null);
      setConfirmationModalState({ open: false });
      navigate(`/account`, { replace: true });
    }
  );

  const onPlaceBidHandler = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onCloseModalHandler = useCallback(() => {
    setConfirmationModalState({ open: false });
  }, [setConfirmationModalState]);

  const queryClient = useQueryClient();

  const { mutate: acceptOffer, isLoading: isAcceptingOffer } =
    useAcceptContractOfferMutation({
      onSuccess: () => {
        queryClient.invalidateQueries(accountBidKey(bidId));
      },
    });

  const handleAcceptOffer = useCallback(() => {
    acceptOffer(bidId);
  }, [acceptOffer, bidId]);

  return (
    <Section
      isLoading={isUpdating || isLoadingBid}
      loadingSkeleton={<BidCommunicationSkeleton />}
    >
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <TextOverflow
          width="30%"
          style={{
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          Introductory Response
        </TextOverflow>
        <Typography variant="body2">
          Placed bid {format(startDate, formatNominativeMonth)}
        </Typography>
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box pt={2.5}>
        {isEditing ? (
          <EditingForm
            setIsEditing={setIsEditing}
            onHandlePlaceBid={onHandleUpdateBid}
            initialValues={{
              introductoryMessage: bidData?.introductoryMessage ?? "",
              ...bidDataFunding,
            }}
            budgetType={bidData?.jobRequest.budget.type}
          />
        ) : (
          <React.Fragment>
            <Typography variant="body1">
              {bidData?.introductoryMessage}
            </Typography>
            <Box pt={2.5}>
              <ProposedBidActions
                bidData={bidData}
                isNewBid={bidId === NEW_ID}
                onAcceptOffer={handleAcceptOffer}
                onPlaceBid={onPlaceBidHandler}
                onWithdraw={onWithdrawHandler}
                isLoading={isAcceptingOffer}
              />
            </Box>
          </React.Fragment>
        )}
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>

      <Typography
        variant="subtitle2"
        style={{
          paddingTop: 10,
          fontWeight: 600,
        }}
      >
        Bids and Messages
      </Typography>
      <MessagesForm
        height={150}
        messages={messages}
        onNewMessage={onNewMessageHandler}
        isLoading={isAddingNew}
      />
      <Box py={2.5}>
        <Divider />
      </Box>
      <ConfirmationDialog
        open={open}
        onClose={onCloseModalHandler}
        onConfirm={onConfirmWithdrawHandler}
        title="Are you sure you want to withdraw this bid?"
        message="This bid will be permanently erased."
      />
    </Section>
  );
};
