import { Box, Typography } from "@mui/material";
import { FormikValues, FormikErrors } from "formik";
import React, { ChangeEvent, useMemo } from "react";
import { BackButton } from "../../elements/BackButton";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";
import { SignUpInfoForm } from "./SignUpInfoForm";

type SignUpInfoProps = {
  onBack?: () => void;
  handleChange: (e: ChangeEvent) => void;
  // eslint-disable-next-file no-undef
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
};

export const SignUpInfo: React.FC<SignUpInfoProps> = ({
  onBack,
  handleBlur,
  handleChange,
  values,
  errors,
}) => {
  const { isSmall, isMedium } = useResponsiveness();

  const inputWidth = useMemo(() => {
    if (isSmall) {
      return "100%";
    }
    if (isMedium) {
      return "70%";
    }
    return "35%";
  }, [isSmall, isMedium]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={isSmall ? "center" : "flex-start"}
      flexDirection="column"
      pt={10}
    >
      <Box alignSelf="flex-start">
        <BackButton onClick={() => onBack?.()} />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isSmall ? "column" : "row"}
        width={isMedium ? "100%" : "80%"}
        pt={10}
        rowGap={4}
      >
        <Box maxWidth={isSmall ? "100%" : "45%"} mb={isSmall ? 8 : 0}>
          <Typography variant="subtitle1">Welcome!</Typography>
          <br />
          <Typography variant="subtitle1">
            To get started, enter your email address and create a password.
            <br />
            You will receive a confirmation message.
          </Typography>
        </Box>
        <Box width={isSmall ? "100%" : inputWidth}>
          <SignUpInfoForm
            handleBlur={handleBlur}
            handleChange={handleChange}
            values={values}
            errors={errors}
          />
        </Box>
      </Box>
    </Box>
  );
};
