import { Box, Typography } from "@mui/material";
import React, { Fragment, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { Section } from "../../../common/components";
import { CollectionNavigationActions } from "../../../common/elements/CollectionNavigationActions";
import {
  Collection,
  useNavigationHandlers,
} from "../../../common/hooks/navigation";
import { useReceivedProfessionalReferralsProposals } from "../../../common/hooks/projects/project/collaborations";
import { useProfessionalReferralQuery } from "../../../common/hooks/projects/project/collaborations/useProfessionalReferralQuery";
import { Communication } from "./communication";
import { Details } from "./details";
import { useDefaultProfessionalReferralPage } from "./hooks";
import { ProfessionalReferralHeader } from "./professional-referral-header";

export const ProfessionalReferralDetails = () => {
  useDefaultProfessionalReferralPage();

  const [searchParams] = useSearchParams();
  const { professionalReferralId } = useParams();

  const {
    data: professionalReferrals,
    isLoading: isLoadingProfessionalReferrals,
  } = useReceivedProfessionalReferralsProposals();

  const { isLoading: isLoadingProfessionalReferral } =
    useProfessionalReferralQuery();

  const collectionConfig = {
    type: Collection.PROFESSIONAL_REFERRALS,
    items: professionalReferrals,
  };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(
      collectionConfig,
      isLoadingProfessionalReferrals,
      professionalReferralId
    );

  const errorMessage = useMemo(() => {
    return !professionalReferralId ? (
      <Fragment>
        <Typography>
          You can invite other professionals to collaborate with you once you
          start working on a project.
        </Typography>
        <Typography>
          Similarly, you can be invited to collaborate by other professionals.
          All invites from professionals will be found in this section.
        </Typography>
      </Fragment>
    ) : (
      ""
    );
  }, [professionalReferralId]);

  const content = useMemo(
    () =>
      searchParams.get("tab") === "replies" ? <Communication /> : <Details />,
    [searchParams]
  );

  const isNavigationHidden = searchParams.get("tab") !== "details";

  return (
    <PageWrapper>
      <Section
        isLoading={isLoadingProfessionalReferral}
        errorMessage={errorMessage}
      >
        <Box height="100%" display="flex" flexDirection="column">
          <ProfessionalReferralHeader />
          <Box flexGrow={1}>{content}</Box>
          <CollectionNavigationActions
            label={"Professional Referral"}
            index={index}
            totalCount={professionalReferrals?.length ?? 0}
            handleSelectNext={handleSelectNext}
            handleSelectPrevious={handleSelectPrevious}
            hidden={isNavigationHidden}
          />
        </Box>
      </Section>
    </PageWrapper>
  );
};
