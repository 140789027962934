import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { usePointerStyles } from "../../../themes/style-hooks";

type AddedSkillProps = {
  skillName: string;
  onRemove: (skillName: string) => void;
};

export const AddedSkill: React.FC<AddedSkillProps> = ({
  skillName,
  onRemove,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnterHandler = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeaveHandler = useCallback(() => {
    setIsHovering(false);
  }, []);

  const pointerStyles = usePointerStyles();

  return (
    <Box
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      width={132}
      height={40}
      bgcolor={YELLOW}
      display="flex"
      className={pointerStyles.root}
    >
      <Box
        width={32}
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: isHovering ? "#FFF2B1" : YELLOW,
        }}
        onClick={() => onRemove(skillName)}
        data-cy-btn="remove"
      >
        <CloseIcon color="secondary" />
      </Box>
      <Divider
        orientation="vertical"
        style={{
          opacity: 0.8,
          width: "2px",
          backgroundColor: BLUE,
          borderColor: BLUE,
        }}
      />
      <Box pl={2} display="flex" justifyContent="center" alignItems="center">
        <Box width={80}>
          <Typography
            style={{
              fontSize: 14,
              color: BLUE,
              overflowX: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {skillName}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
