import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useMetadataQuery } from "../../../common/hooks/metadata/useMetadataQuery";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";

export const CurrentProjectHeaderSummary: React.FC = () => {
  const { isLarge } = useResponsiveness();
  const { data: metadata } = useMetadataQuery();

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box pt={isLarge ? 8 : 0}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
          }}
        >
          Status of Your {metadata?.jobRequestsCount} Job Request(s)
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" rowGap={2.5}>
        <Typography variant="subtitle2">
          A total of{" "}
          {(metadata?.placedInquiriesCount ?? 0) +
            (metadata?.placedBidsCount ?? 0)}{" "}
          professional(s) responded
        </Typography>
        <Typography variant="subtitle2">
          {metadata?.placedBidsCount} professional(s) placed bid(s)
        </Typography>
        <Typography variant="subtitle2">
          Out of {metadata?.invitedCount} professional(s) invited, {0} responded
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2" color="primary">
          <Link
            href="/job-requests/"
            style={{
              cursor: "pointer",
            }}
            underline="none"
          >
            View Job Request(s)
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
