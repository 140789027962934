import { Box, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useMemo } from "react";
import { GearIcon } from "../../../../../../icons/GearIcon";
import { YELLOW } from "../../../../../../themes/components/utils";

const useStyles = makeStyles({
  icon: {
    "& svg": {
      width: 284,
      ["@media screen and (max-width: 1024px), screen and (max-height: 650px)"]:
        {
          width: 230,
        },
      ["@media screen and (max-width: 850px)"]: {
        height: 180,
      },
      ["@media screen and (max-width: 420px), screen and (max-height: 400px)"]:
        {
          width: 150,
          height: 150,
        },
    },
  },

  text: {
    fontSize: 48,
    paddingTop: 60,
    ["@media screen and (max-width: 1024px), screen and (max-height: 650px)"]: {
      paddingTop: 48,
      fontSize: 36,
    },
    ["@media screen and (max-width: 850px)"]: {
      fontSize: 26,
    },
    ["@media screen and (max-width: 420px), screen and (max-height: 400px)"]: {
      paddingTop: 24,
      fontSize: 20,
    },
  },
});

export const LandingStepF = () => {
  const styles = useStyles();
  const smallHeight = useMediaQuery("(max-height: 400px)");
  const mobileView = useMediaQuery("(max-width: 800px)");

  const heightStyles = useMemo(() => {
    if (smallHeight) return "76vh";

    if (mobileView) return "88vh";

    return "100%";
  }, [smallHeight, mobileView]);

  return (
    <Box
      height={heightStyles}
      display="flex"
      justifyContent={"center"}
      alignItems="center"
      flexDirection="column"
    >
      <Box className={styles.icon}>
        <GearIcon />
      </Box>
      <Box
        color={YELLOW}
        sx={{
          fontWeight: "bold",
        }}
        className={styles.text}
      >
        BUILD WITH TALENT
      </Box>
      {/*{!mobileView && <AccountTypeSwitch defaultChecked="professional" />}*/}
    </Box>
  );
};
