import makeStyles from "@mui/styles/makeStyles";

export const useMediumItalic = makeStyles({
  root: {
    transform: "skew(-10deg)",
    display: "inline-block",
    marginTop: -1,
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      width: "fit-content",
    },
  },
});
