import { Box, Button, Divider, Typography } from "@mui/material";
import axios from "axios";
import { round } from "lodash";
import React from "react";
import { BASE_URL } from "../../../../api-services/utils";
import { BLUE, YELLOW } from "../../../../themes/components/utils";
import { CustomTooltip, Section } from "../../components";
import { useMetadataQuery } from "../../hooks/metadata/useMetadataQuery";
import { useStripeAccount } from "../../hooks/stripe-account/useStripeAccount";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";
import { AccountBalanceSkeleton } from "./AccountBalanceSkeleton";
import { DepositInvoices } from "./DepositInvoices";
import { useWithdrawFundsModal } from "./state/hooks";
import { WithdrawalHistory } from "./WithdrawalHistory";
type AccountBalanceHeaderProps = {
  label?: string;
};

export const AccountBalance: React.FC<AccountBalanceHeaderProps> = () => {
  const { data: metadata, isLoading } = useMetadataQuery();
  const { data: stripeAccount, isLoading: isLoadingStripeAccount } =
    useStripeAccount();
  const setWithdrawFundsDialog = useWithdrawFundsModal().set;
  const isWithdrawDisabled = isLoadingStripeAccount || !stripeAccount?.url;
  const { isSmall, isMedium } = useResponsiveness();

  //TODO: Refactor with react query
  const handleSubmit = async (e: any) => {
    const response = await axios.post(`${BASE_URL}/stripe/connect`);

    if (response?.data?.url) {
      window.location = response.data.url;
    }
  };

  return (
    <Section
      isLoading={isLoading || isLoadingStripeAccount}
      loadingSkeleton={<AccountBalanceSkeleton />}
    >
      <Box pt={10}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: "bold",
          }}
        >
          Account Balance
        </Typography>
        {!isWithdrawDisabled ? (
          <Box display="flex" pt={10} rowGap={5} flexDirection="column">
            <Typography variant="subtitle2">
              All payments are processed by Stripe. Access the Payments
              Dashboard to manage your account: withdraw funds to your bank
              account or credit card.
            </Typography>
          </Box>
        ) : (
          <Box display="flex" pt={10} rowGap={5} flexDirection="column">
            <Typography variant="subtitle2">
              All payments are processed by Stripe. Set up your account with
              Stripe Connect in order to be able to withdraw funds to your bank
              account or debit card.
            </Typography>
            <Typography variant="subtitle2">
              Once you set up your account, access your Payments Dashboard to
              manage your account.
            </Typography>{" "}
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          alignItems="center"
          columnGap={isSmall ? 4 : 12}
          flexDirection={isSmall ? "column" : "row"}
        >
          <Box
            sx={{
              cursor: "pointer",
              "& :hover": {
                color: YELLOW,
              },
            }}
            display="flex"
            columnGap={2}
            mt={isSmall ? 4 : 0}
          >
            <Typography
              style={{
                fontWeight: "bold",
              }}
              width="180px"
            >
              Available Balance ${round(metadata?.balance?.available ?? 0, 2)}
            </Typography>
          </Box>
          {isSmall ? null : (
            <Box height="30px">
              <Divider
                style={{
                  backgroundColor: "white",
                }}
                orientation="vertical"
              />
            </Box>
          )}
          <Box
            display="flex"
            columnGap={2}
            flexDirection={isSmall ? "column" : "row"}
            sx={{
              cursor: "pointer",
              "& :hover": {
                color: YELLOW,
              },
            }}
          >
            <CustomTooltip
              arrow
              placement={isMedium ? "top-end" : "right"}
              title={
                <Box>
                  <Typography
                    style={{
                      color: BLUE,
                    }}
                    variant="body2"
                  >
                    In order to reduce risks and protect against fraud, payment
                    processor <b>Stripe</b> has a required review process for
                    transfers, lasting up to <b>5 business days</b> after the
                    client places money in escrow.
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: BLUE,
                    }}
                  >
                    Once the money is released from escrow by the client and the
                    transfer is reviewed by <b>Stripe</b>, the{" "}
                    <b>Pending Amount</b> listed here will be posted as{" "}
                    <b>Available Balance</b>, and you will be able to withdraw
                    it to your account.
                  </Typography>
                </Box>
              }
            >
              <Typography
                style={{
                  fontWeight: "bold",
                }}
                width="180px"
                component="span"
              >
                Total Pending Amount&nbsp;$
                {round(metadata?.balance?.pending ?? 0, 2)}
              </Typography>
            </CustomTooltip>{" "}
          </Box>
        </Box>

        {isMedium ? null : (
          <Box display="flex" justifyContent="flex-end" columnGap={3} py={2}>
            <Button
              disabled={isLoading || !stripeAccount?.url}
              onClick={() => {
                window.location = stripeAccount?.url;
              }}
              style={{ marginLeft: "14px" }}
              variant="contained"
              size="small"
            >
              Payments Dashboard
            </Button>
            {isWithdrawDisabled ? (
              <Button
                onClick={handleSubmit}
                disabled={isLoading}
                variant="outlined"
                size="small"
              >
                Connect Stripe
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="small"
                disabled={
                  isWithdrawDisabled || (metadata?.balance?.available ?? 0) <= 0
                }
                onClick={() => {
                  setWithdrawFundsDialog({ open: true });
                }}
              >
                Withdraw
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box width="100%" mt={isSmall ? 2 : 0} mb={isSmall ? 4 : 0}>
        <Divider />
      </Box>
      {isMedium ? (
        <Box display="flex" justifyContent="flex-end" columnGap={3} py={2}>
          <Button
            disabled={isLoading || !stripeAccount?.url}
            onClick={() => {
              window.location = stripeAccount?.url;
            }}
            style={{ marginLeft: "14px" }}
            variant="contained"
            size="small"
          >
            Payments Dashboard
          </Button>
          {isWithdrawDisabled ? (
            <Button
              onClick={handleSubmit}
              disabled={isLoading}
              variant="outlined"
              size="small"
            >
              Connect Stripe
            </Button>
          ) : (
            <Button
              variant="outlined"
              size="small"
              disabled={
                isWithdrawDisabled || (metadata?.balance?.available ?? 0) <= 0
              }
              onClick={() => {
                setWithdrawFundsDialog({ open: true });
              }}
            >
              Withdraw
            </Button>
          )}
        </Box>
      ) : null}

      <Box pb={30}>
        <Box pt={4}>
          <WithdrawalHistory />
        </Box>
        <Box pt={8}>
          <DepositInvoices />
        </Box>
      </Box>
    </Section>
  );
};
