import DeleteIcon from "@mui/icons-material/Delete";
import { TableCell, TableRow, ListItemIcon } from "@mui/material";
import { FieldArray, FormikErrors, useFormikContext } from "formik";
import { Fragment } from "react";
import { RED } from "../../../../themes/components/utils";
import { useRequiredFieldLabel } from "../../../common/hooks/utils";
import { parsePositiveNumber } from "../../../utils";
import { useEstimatedCostPerWeek } from "../create-job-request-wizard/hooks";
import {
  BudgetSchema,
  HourlyRateFundingSchema,
  HourlyRateMilestonesSchema,
} from "../create-job-request-wizard/validation-schema";
import { MilestonesTable } from "./MilestonesTable";
import { TableCellWithInput } from "./TableCellWithInput";

export const JobRequestHourlyRateMilestoneTable = () => {
  const { values, errors } = useFormikContext<
    HourlyRateFundingSchema & BudgetSchema
  >();
  const estimatedCostPerWeek = useEstimatedCostPerWeek();
  const requiredFieldLabel = useRequiredFieldLabel();

  return (
    <FieldArray name="hourlyRateProjectFunding.milestones">
      {({ push, remove }) => (
        <MilestonesTable
          onAddMilestone={() =>
            push({ description: "", dueDate: { min: 0, max: 0 } })
          }
          TableHeadContent={
            <Fragment>
              <TableCell variant="head">
                {requiredFieldLabel("Milestone Description")}
              </TableCell>
              <TableCell variant="head">
                {requiredFieldLabel("Due Date")}
              </TableCell>
              <TableCell variant="head">Hours/Week</TableCell>
              <TableCell variant="head">Dollars/Hour</TableCell>
              <TableCell variant="head">Estimated Cost</TableCell>
              <TableCell />
            </Fragment>
          }
        >
          {values.hourlyRateProjectFunding.milestones?.map(
            ({ description, dueDate }, index) => (
              <TableRow key={index} data-cy-row={index}>
                <TableCellWithInput
                  cellWidth="30%"
                  error={
                    (
                      errors.hourlyRateProjectFunding
                        ?.milestones as FormikErrors<HourlyRateMilestonesSchema>
                    )?.[index]?.description
                  }
                  name={`hourlyRateProjectFunding.milestones.${index}.description`}
                  value={description}
                />
                <TableCellWithInput
                  cellWidth="20%"
                  betweenLabel="and"
                  isMinMaxInput
                  inputWidth={80}
                  labelAfter="weeks"
                  labelBefore="Between"
                  maxNameSuffix="max"
                  maxValue={dueDate?.max ?? 0}
                  minNameSuffix="min"
                  minValue={dueDate?.min ?? 0}
                  name={`hourlyRateProjectFunding.milestones.${index}.dueDate`}
                  type="number"
                  minError={
                    (
                      errors.hourlyRateProjectFunding
                        ?.milestones as FormikErrors<HourlyRateMilestonesSchema>
                    )?.[index]?.dueDate?.min
                  }
                  maxError={
                    (
                      errors.hourlyRateProjectFunding
                        ?.milestones as FormikErrors<HourlyRateMilestonesSchema>
                    )?.[index]?.dueDate?.max
                  }
                />
                <TableCell>
                  {values.budget.hours.type === "fixed"
                    ? `x ${parsePositiveNumber(
                        values.budget.hours.fixed
                      )}hrs/wk`
                    : `x ${parsePositiveNumber(
                        values.budget.hours.estimate.min
                      )}- ${parsePositiveNumber(
                        values.budget.hours.estimate.max
                      )}hrs/wk`}
                </TableCell>
                <TableCell>
                  {values.budget.hourlyRate.type === "fixed"
                    ? `x $${parsePositiveNumber(
                        values.budget.hourlyRate.fixed
                      )}/hr`
                    : `x $${parsePositiveNumber(
                        values.budget.hourlyRate.estimate.min
                      )} - $${parsePositiveNumber(
                        values.budget.hourlyRate.estimate.max
                      )}hr`}
                </TableCell>
                <TableCell>
                  {`$${
                    parsePositiveNumber(dueDate?.min) * estimatedCostPerWeek.min
                  } - $${
                    parsePositiveNumber(dueDate?.max) * estimatedCostPerWeek.max
                  }`}
                </TableCell>
                <TableCell
                  style={{
                    cursor: index === 0 ? "not-allowed" : "pointer",
                  }}
                >
                  <ListItemIcon
                    style={{
                      marginRight: 8,
                      paddingTop: 8,
                      pointerEvents: index === 0 ? "none" : "all",
                      cursor: "pointer",
                    }}
                    sx={{
                      color: index === 0 ? "grey" : RED,
                    }}
                    onClick={() => remove(index)}
                  >
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                </TableCell>
              </TableRow>
            )
          )}
        </MilestonesTable>
      )}
    </FieldArray>
  );
};
