import createCache from "@emotion/cache";
import { CacheProvider, Global } from "@emotion/react";
import {
  CssBaseline,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material";
import React from "react";
import { AppStyles } from "../../../themes/app-styles";
import theme from "../../../themes/themes";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const APP_KEY = "the-talented";

const cache = createCache({
  key: APP_KEY,
  prepend: true,
});

export const ThemeContext: React.FC = ({ children }) => (
  <CacheProvider value={cache}>
    <Global styles={AppStyles} />
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  </CacheProvider>
);
