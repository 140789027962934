import { Box, Divider } from "@mui/material";
import React from "react";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { ProjectHeaderContent } from "./ProjectHeaderContent";
import { ProjectHeaderSummary } from "./ProjectHeaderSummary";

type ProjectHeaderProps = {
  variant?: "project" | "collaboration";
};

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({ variant }) => {
  const { isMedium } = useResponsiveness();
  return (
    <Box
      display="flex"
      flexDirection={isMedium ? "column" : "row"}
      alignItems={isMedium ? "flex-start" : "center"}
      columnGap={!isMedium ? 16 : 10}
    >
      <ProjectHeaderContent variant={variant} />
      {!isMedium && <Divider orientation={"vertical"} flexItem />}
      {!isMedium && <ProjectHeaderSummary variant={variant} />}
    </Box>
  );
};
