import { Box, Checkbox, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ChangeEvent, ReactNode } from "react";
import { LIGHT_BLUE, YELLOW } from "../../../themes/components/utils";

type CheckboxCardProps = {
  width?: number;
  label?: string;
  leftIcon?: ReactNode;
  checked?: boolean;
  checkedColor?: string;
  // eslint-disable-next-line no-undef
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

type Props = {
  width: number;
  checkedColor: string;
};

const useStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      color: LIGHT_BLUE,
      width: (props: Props) => props.width,
      height: (props: Props) => props.width,
    },
  },
  rootChecked: {
    "& .MuiSvgIcon-root": {
      color: (props: Props) => props.checkedColor,
      width: (props: Props) => props.width,
      height: (props: Props) => props.width,
    },
  },
});

export const CheckboxCard: React.FC<CheckboxCardProps> = ({
  width = 32,
  label,
  leftIcon,
  checked,
  checkedColor = YELLOW,
  onChange,
}) => {
  const styles = useStyles({ width, checkedColor });

  return (
    <Box
      display="flex"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="flex-start"
      pl={4}
      style={{
        cursor: "pointer",
      }}
    >
      <Checkbox
        name="checkbox-card"
        id={`checkbox-card-${label}`}
        className={checked ? styles.rootChecked : styles.root}
        checked={checked}
        onChange={onChange}
      />
      <Box ml={4} columnGap={4} display="flex" alignItems="center">
        {leftIcon}
        {label && (
          <label htmlFor={`checkbox-card-${label}`}>
            <Typography
              component="span"
              variant="subtitle2"
              style={{
                color: checked ? checkedColor : LIGHT_BLUE,
                cursor: "pointer",
              }}
            >
              {label}{" "}
            </Typography>
          </label>
        )}
      </Box>
    </Box>
  );
};
