import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Professional } from "../../../../api-types/professionals/professional";
import { BLUE } from "../../../../themes/components/utils";
import { ImagePreview } from "../../../client/job-requests/utils";
import { usePastProjectsQuery } from "../../hooks/projects/usePastProjectsQuery";

type ProfessionalFeaturedProjectProps = {
  height?: number;
  width?: number;
  bgColor?: string;
  professional?: Professional;
};

export const ProfessionalFeaturedProject: React.FC<
  ProfessionalFeaturedProjectProps
> = ({ height = 380, width = 480, bgColor, professional }) => {
  const navigate = useNavigate();

  const { data: pastProjects } = usePastProjectsQuery(professional?._id);

  const featuredProject: any = useMemo(() => {
    return (
      pastProjects?.find((project) => project.isFeatured) ??
      pastProjects?.[0] ??
      professional?.profileInformation?.projects?.[0]
    );
  }, [pastProjects, professional?.profileInformation?.projects]);

  return (
    <Box
      height={height}
      width={width}
      bgcolor={bgColor}
      onClick={() => {
        if (professional?._id) {
          navigate(`/professionals/${professional._id}`);
        }
      }}
    >
      <Box p={3}>
        <ImagePreview
          width={width * 0.95}
          height={height * 0.82}
          name={featuredProject?.title ?? featuredProject?.name ?? ""}
          selectedFiles={featuredProject?.images ?? []}
          color={BLUE}
          arrowColor="secondary"
        />
      </Box>
    </Box>
  );
};
