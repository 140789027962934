import { Box, Skeleton } from "@mui/material";
import React from "react";

export const BidsJobrequestSkeleton: React.FC = () => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" pt={8} width="100%">
        <Box width="50%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="40%"
            height={30}
          />
          <Box mt={12}>
            <Skeleton variant="rectangular" animation="wave" height={40} />
          </Box>
          <Box display="flex" mt={10}>
            <Box width="20%">
              <Skeleton variant="rectangular" animation="wave" height={100} />
            </Box>
            <Box ml={6} width="20%">
              <Skeleton variant="rectangular" animation="wave" height={100} />
            </Box>
            <Box ml={6} width="25%">
              <Skeleton variant="rectangular" animation="wave" height={100} />
            </Box>
            <Box ml={6} width="25%">
              <Skeleton variant="rectangular" animation="wave" height={100} />
            </Box>
          </Box>
        </Box>

        <Box mt={8} width="30%" justifyContent="space-between">
          <Box mb={8}>
            <Skeleton variant="rectangular" animation="wave" height={50} />
          </Box>
          <Box mb={4}>
            <Skeleton variant="rectangular" animation="wave" height={30} />
          </Box>
          <Box mb={4}>
            <Skeleton variant="rectangular" animation="wave" height={30} />
          </Box>
          <Box mb={4}>
            <Skeleton variant="rectangular" animation="wave" height={30} />
          </Box>
        </Box>
      </Box>

      <Box mt={16} width="10%">
        <Skeleton variant="rectangular" animation="wave" height={30} />
      </Box>

      <Box mt={8} display="flex" alignItems="center">
        <Skeleton variant="circular" animation="wave" width={100} height={90} />
        <Box
          width="100%"
          ml={4}
          gap={8}
          display="flex"
          justifyContent="space-between"
        >
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="15%"
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="13%"
            height={20}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="13%"
            height={30}
          />
        </Box>
      </Box>

      <Box width="100%" mt={8}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
      </Box>

      <Box width="100%" mt={4} display="flex" justifyContent="flex-end">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
      </Box>

      <Box width="100%" mt={4}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
      </Box>

      <Box width="100%" mt={4} display="flex" justifyContent="flex-end">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
      </Box>
    </Box>
  );
};
