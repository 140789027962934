import { Box, Button } from "@mui/material";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { YELLOW } from "../../../themes/components/utils";
import { LoadingTypography } from "../../common/components";
import { useMetadataQuery } from "../../common/hooks/metadata/useMetadataQuery";
import { useResponsiveness } from "../../common/hooks/utils/useResponsiveness";
import { useJobPackages } from "../job-requests/create-job-request-wizard/hooks/useJobPackages";

type JobPostsCreditSummaryProps = {
  purchaseButton?: boolean;
};

export const JobPostsCreditSummary: FC<JobPostsCreditSummaryProps> = ({
  purchaseButton,
}) => {
  const navigate = useNavigate();
  const { isLoading: isLoadingMetadata } = useMetadataQuery();
  const { jobPostsCredits } = useJobPackages();
  const { isSmall, isMedium } = useResponsiveness();
  const handlePostNow = useCallback(() => {
    navigate("/job-post/new");
  }, [navigate]);

  const handlePurchaseJobPoints = useCallback(() => {
    navigate("/jobs-packages?tab=packages");
  }, [navigate]);

  return (
    <Box
      alignItems={isSmall ? "flex-start" : "flex-end"}
      justifyContent="flex-end"
      display="flex"
      flexDirection={isMedium ? "column" : "row"}
      columnGap={3}
    >
      <Box width={300}>
        <LoadingTypography
          isLoading={isLoadingMetadata}
          typographyProps={{
            variant: "body1",
          }}
          style={{
            marginBottom: isMedium ? "4px" : "0px",
            whiteSpace: "nowrap",
            color: YELLOW,
          }}
        >
          You have <strong>{jobPostsCredits}</strong> unused Job Post credit(s)
        </LoadingTypography>
      </Box>
      <Box py={isSmall ? 4 : 0} whiteSpace="nowrap">
        <Button
          data-cy-btn="post-new-job"
          disabled={jobPostsCredits === 0}
          onClick={handlePostNow}
          size={isSmall ? "small" : "large"}
          variant="outlined"
        >
          Post Now
        </Button>
        {Boolean(purchaseButton) && (
          <Button
            data-cy-btn="purchase-job-points"
            onClick={handlePurchaseJobPoints}
            size={isSmall ? "small" : "large"}
            variant="contained"
            sx={{ marginLeft: 4 }}
          >
            Purchase More Job Posts
          </Button>
        )}
      </Box>
    </Box>
  );
};
