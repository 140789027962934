import { Box } from "@mui/material";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useMobileStyles,
} from "../../../../../../themes/style-hooks";
import { ClientMainActions } from "../../../ClientMainActions";

export const LandingStepCContent = () => {
  const { mobileContentWrapper, mobileDescriptionWrapper } = useMobileStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: COLOR.BLUE,
    color: COLOR.YELLOW,
    hover: COLOR.BLUE,
  });

  const descriptionText = useDescriptionText({
    color: COLOR.BLUE,
  });

  return (
    <Box className={mobileContentWrapper}>
      <Box className={mobileDescriptionWrapper}>
        <Box textAlign="left">
          <Box className={descriptionText.root}>
            It’s free to post your freelance project requests. Build your app,
            your .com, or your brand.{" "}
          </Box>
        </Box>
      </Box>
      <ClientMainActions classNames={`${common} ${root}`} />
    </Box>
  );
};
