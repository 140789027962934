import { Box } from "@mui/material";
import React, { FC } from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useMediumItalic,
  useHeadlineText,
  useMobileStyles,
  useStepDStyles,
} from "../../../../../../themes/style-hooks";

export const LandingStepDHeadline: FC = () => {
  const styles = useStepDStyles();
  const mediumItalic = useMediumItalic();
  const { mobileHeadlineWrapper } = useMobileStyles();
  const { skewedText, transparent } = useHeadlineText({
    primary: COLOR.GREEN,
    secondary: COLOR.DARK_BROWN,
    background: COLOR.RED,
  });

  return (
    <Box className={mobileHeadlineWrapper}>
      <Box className={mediumItalic.root}>
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;Keep all&nbsp;</span>
        </Box>
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;the money&nbsp;</span>
        </Box>
      </Box>
      <Box className={mediumItalic.root}>
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;you earn.&nbsp;</span>
        </Box>
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;No&nbsp;</span>
        </Box>
      </Box>

      <Box className={mediumItalic.root}>
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;commission.&nbsp;</span>
        </Box>
      </Box>
    </Box>
  );
};
