import { Box, CircularProgress } from "@mui/material";
import * as React from "react";

type LoaderProps = {
  size?: number;
};

export const Loader = ({ size }: LoaderProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={size} />
    </Box>
  );
};
