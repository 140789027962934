import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useJobPackages } from "../hooks/useJobPackages";

type JobPostWizardActionsProps = {
  submitStep?: number;
  onSubmit?: () => void;
  onSaveDraft?: () => void;
  onNext?: () => void;
  containerProps?: any;
  isDisabled?: boolean;
};

export const JobPostWizardActions: React.FC<JobPostWizardActionsProps> = ({
  onNext,
  onSubmit,
  submitStep = 4,
  isDisabled = false,
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentStep = Number(searchParams.get("step"));

  const { jobPostsCredits, isEligibleForFreeTrial } = useJobPackages();

  const hasJobPostsCredits = jobPostsCredits > 0;

  const isPreview = searchParams.get("isPreview") === "true";

  if (isPreview) return null;

  return (
    <Box
      py={10}
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      justifyContent="flex-end"
    >
      <Box display="flex" columnGap={3}>
        <Button
          size="medium"
          variant="outlined"
          onClick={() => {
            navigate("/job-posts");
            // navigate(BROWSE_DEFAULT_URL);
          }}
        >
          Cancel
        </Button>
        {/* {currentStep >= 3 && (
          <Button size="medium" variant="contained" disabled>
            Post Later
          </Button>
        )} */}
        {currentStep >= submitStep &&
          (isEligibleForFreeTrial || hasJobPostsCredits) && (
            <Button
              size="medium"
              variant="contained"
              onClick={onSubmit}
              disabled={isDisabled}
              data-cy-btn="post"
            >
              Post
            </Button>
          )}
        {currentStep > 2 && currentStep < submitStep && (
          <Button
            size="medium"
            variant="contained"
            onClick={onNext}
            disabled={isDisabled}
            data-cy-btn="next-step"
          >
            Next Step
          </Button>
        )}
      </Box>
    </Box>
  );
};
