import { Components } from "@mui/material";

export const MuiTablePaginationItem: Components["MuiTablePagination"] = {
  styleOverrides: {
    root: {
      color: "white",
    },
  },
};
//
// export const MuiTablePaginationItem: MuiComponentThemeDefinition<
//   TablePaginationClassKey,
//   TablePaginationProps
// > = {
//   props: {},
//   override: () => ({
//     root: {
//       color: "white",
//     },
//   }),
// };
