import { Box } from "@mui/material";
import { useCallback } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { LoadingTypography, HeaderDropdown } from "../../../common/components";
import { Card } from "../../../common/elements/Card";
import {
  Collection,
  useNavigationHandlers,
} from "../../../common/hooks/navigation";
import { useNavigateOnCondition } from "../../../common/hooks/utils";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";
import { useAccountInquiriesOptions } from "../../bids/hooks/useAccountInquiriesOptions";
import { useAccountInquiryQuery } from "../hooks/useAccountInquiry";

export const InquiryHeaderContent = () => {
  const navigate = useNavigate();
  const { inquiryId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSmall } = useResponsiveness();

  const { data: accountInquiries, isLoading: isLoadingAccountInquiries } =
    useAccountInquiriesOptions();

  const collectionConfig = {
    type: Collection.INQUIRIES,
    items: accountInquiries,
  };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingAccountInquiries,
    inquiryId
  );

  const { data: accountInquiry, isLoading: isLoadingInquiry } =
    useAccountInquiryQuery();

  const noData =
    !isLoadingAccountInquiries && !isLoadingInquiry && !accountInquiry;

  useNavigateOnCondition("/inquiries/", noData);

  const handleSelectInquiry = useCallback(
    (index: number) => {
      if (!accountInquiries?.length) return;

      navigate({
        pathname: `/inquiries/${accountInquiries[index].id}`,
        search: `?${createSearchParams({
          tab: "details",
        })}`,
      });
    },

    [accountInquiries, navigate]
  );

  const isLoading = isLoadingAccountInquiries || isLoadingInquiry;

  const onTabNavigationHandler = useCallback(
    (tab: string) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <Box width="65%" py={10}>
      <LoadingTypography
        isLoading={isLoading}
        typographyProps={{
          variant: "subtitle2",
        }}
      >
        Job Request Inquiry ({index + 1} of {accountInquiries?.length})
      </LoadingTypography>
      <Box py={10}>
        <HeaderDropdown
          isLoading={isLoading}
          selectedOptionId={inquiryId}
          options={accountInquiries ?? []}
          onSelect={handleSelectInquiry}
          placeholder={accountInquiry?.jobRequest?.title}
        />
      </Box>
      <Box display="flex" columnGap={5} py={1}>
        <Card
          label="Details"
          isSelected={searchParams.get("tab") === "details"}
          onClick={() => onTabNavigationHandler("details")}
          width={108}
          isLoading={isLoading}
        />
        <Card
          label="Place Bid and Inquiries"
          isSelected={searchParams.get("tab") === "replies"}
          isLoading={isLoading}
          width={isSmall ? 180 : 240}
          onClick={() => onTabNavigationHandler("replies")}
        />
      </Box>
    </Box>
  );
};
