import { Box } from "@mui/material";
import { FormikValues } from "formik";
import React, { useRef } from "react";
import { PageWrapper } from "../../app/container/PageWrapper";
import { usePasswordResetConfirmMutation } from "../hooks/sign-in/usePasswordResetConfirmMutation";
import { PasswordResetForm } from "./PasswordResetForm";
import { useAutoLogin } from "./useAutoLogin";

export const PasswordResetConfirmation: React.FC = () => {
  const formRef = useRef<FormikValues>();
  const { mutate: passwordResetConfirm } = usePasswordResetConfirmMutation();

  useAutoLogin();

  return (
    <PageWrapper>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Box width={400}>
          <PasswordResetForm
            formRef={formRef}
            onSubmit={passwordResetConfirm}
          />
        </Box>
      </Box>
    </PageWrapper>
  );
};
