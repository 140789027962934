import { Components } from "@mui/material";

export const MuiSkeleton: Components["MuiSkeleton"] = {
  styleOverrides: {
    root: {
      backgroundColor: "rgba(211, 211, 211, 0.21)",
    },
  },
};
//
// export const MuiSkeleton: MuiComponentThemeDefinition<
//   SkeletonClassKey,
//   SkeletonProps
// > = {
//   props: {},
//   override: () => ({
//     root: {
//       backgroundColor: "rgba(211, 211, 211, 0.21)",
//     },
//   }),
// };
