import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { Box, Menu, MenuItem } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AccountType } from "../../../api-types/common";
import {
  Notification,
  NotificationType,
  useNotifications,
} from "../../common/hooks/notifications/useNotifications";
import { useReadNotificationMutation } from "../../common/hooks/notifications/useReadNotificationMutation";
import { useScopedDowngradedStateValue } from "../../common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../../state/hooks";

type NotificationsMenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

const MAX_NOTIFICATIONS_PREVIEW_COUNT = 5;

export const NotificationsMenu: React.FC<NotificationsMenuProps> = ({
  anchorEl,
  onClose,
}) => {
  const open = Boolean(anchorEl);
  const { data: notifications } = useNotifications();
  const navigate = useNavigate();
  const { mutate: readNotification } = useReadNotificationMutation();
  const { accountType } = useScopedDowngradedStateValue(useUser());

  const prefix = accountType == AccountType.CLIENT ? "job-requests" : "bids";

  const suffix =
    accountType == AccountType.CLIENT ? "?tab=bids" : "?tab=replies";

  const prefixInquiry =
    accountType == AccountType.CLIENT ? "job-requests" : "inquiries";

  const suffixInquiry =
    accountType == AccountType.CLIENT ? "?tab=inquiries" : "?tab=replies";

  const buildNotificationUrl = useCallback(
    (notification: Notification) => {
      switch (notification.type) {
        case NotificationType.WORK_SUBMITTED:
          return `/projects/${notification.entity}/?tab=payments`;
        case NotificationType.CHANGES_DENIED:
          return `/projects/${notification.entity}/?tab=payments`;
        case NotificationType.CHANGES_ACCEPTED:
          return `/projects/${notification.entity}/?tab=payments`;
        case NotificationType.BID_ACCEPTED:
          return `/bids/${notification.entity}/?tab=details`;
        case NotificationType.BID_PLACED:
          return `/job-requests/${notification.entity}/?tab=bids`;
        case NotificationType.PROJECT_MESSAGE:
          return `/projects/${notification.entity}/?tab=replies`;
        case NotificationType.BID_MESSAGE:
          return `/${prefix}/${notification.entity}/${suffix}`;
        case NotificationType.BID_WITHDRAWN:
          return `/job-requests/${notification.entity}/?tab=bids`;
        case NotificationType.INQUIRY_MESSAGE:
          return `/${prefixInquiry}/${notification.entity}/${suffixInquiry}`;
        case NotificationType.INQUIRY_PLACED:
          return `/job-requests/${notification.entity}/?tab=inquiries`;
        case NotificationType.CLOSING_CONFIRMED:
          return `/projects/history/${notification.entity}/?tab=details`;
        case NotificationType.PAYMENT_PLACED:
          return `/projects/${notification.entity}/?tab=payments`;
        case NotificationType.CLIENT_INVITATION:
          return `/invitations/${notification.entity}/?tab=details`;
        case NotificationType.PROFESSIONAL_INVITATION:
          return `/professional-referrals/${notification.entity}/?tab=details`;
        default:
          return "";
      }
    },
    [prefix, prefixInquiry, suffix, suffixInquiry]
  );

  const handleSeeNotification = useCallback(
    (notification: Notification) => {
      readNotification(notification._id);
      // eslint-disable-next-line no-undef
      window.location.replace(buildNotificationUrl(notification));
    },
    [buildNotificationUrl, readNotification]
  );

  const handleCloseNotification = useCallback(
    // eslint-disable-next-line no-undef
    (event: React.MouseEvent<HTMLSpanElement>, notification: Notification) => {
      event.stopPropagation();
      readNotification(notification._id);
      if ((notifications?.length ?? 0) <= 1) {
        onClose();
      }
    },
    [notifications?.length, onClose, readNotification]
  );

  const buildNotification = useCallback(
    (notification: Notification) => {
      const { type, title = "" } = notification;

      switch (type) {
        case NotificationType.CHANGES_ACCEPTED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  Changes were accepted for the milestones on <b>project</b> -{" "}
                  <b>{title.substring(0, 24)}</b>...
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.CHANGES_DENIED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  Changes were denied for the milestones on <b>project</b> -{" "}
                  <b>{title.substring(0, 24)}</b>...
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.WORK_SUBMITTED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  Work has been submitted for <b>project</b> -{" "}
                  <b>{title.substring(0, 10)}</b>...
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.BID_ACCEPTED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>bid</b> you placed for <b>job request</b>,{" "}
                  <b>{title.substring(0, 24)}</b>... , was <b>accepted</b> by
                  the client
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );

        case NotificationType.BID_MESSAGE:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" fontSize="small" />
                {accountType === AccountType.PROFESSIONAL ? (
                  <Box
                    style={{
                      fontSize: 14,
                    }}
                  >
                    You received a new reply on your bid.{" "}
                  </Box>
                ) : (
                  <Box
                    style={{
                      fontSize: 14,
                    }}
                  >
                    You received a new reply on your Job Request's bid.
                  </Box>
                )}
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.INQUIRY_MESSAGE:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" fontSize="small" />
                {accountType === AccountType.PROFESSIONAL ? (
                  <Box
                    style={{
                      fontSize: 14,
                    }}
                  >
                    You received a new reply on your inquiry.{" "}
                  </Box>
                ) : (
                  <Box
                    style={{
                      fontSize: 14,
                    }}
                  >
                    You received a new reply on your Job Request's inquiry.
                  </Box>
                )}
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.BID_PLACED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>job request</b> you created,{" "}
                  <b>{title.substring(0, 24)}</b>..., received a new <b>bid</b>
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.BID_WITHDRAWN:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>bid</b> was withdrawn from a <b>job request</b> you
                  created, <b>{title.substring(0, 24)}</b>...
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.INQUIRY_PLACED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <MessageOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>job request</b> you created,{" "}
                  <b>{title.substring(0, 24)}</b>..., received a new{" "}
                  <b>inquiry</b>
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.CLOSING_CONFIRMED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <CancelPresentationOutlinedIcon
                  color="inherit"
                  fontSize="small"
                />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>project</b> closing, <b>{title.substring(0, 24)}</b>...,
                  was confirmed by the <b>professional</b>
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );

        case NotificationType.PAYMENT_PLACED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <ReceiptOutlinedIcon color="inherit" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  The <b>professional</b> working on your on-going{" "}
                  <b>project</b>, <b>{title.substring(0, 24)}</b>..., requested
                  a <b>payment</b>
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.CLIENT_INVITATION:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <GroupOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>client</b> has invited you to a <b>job request</b>,{" "}
                  <b>{title.substring(0, 24)}</b>...
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.PROFESSIONAL_INVITATION:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <GroupOutlinedIcon color="inherit" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>professional</b> has referred you to an on-going{" "}
                  <b>project</b>, <b>{title.substring(0, 24)}</b>...
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.PROJECT_MESSAGE:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <GroupOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  You received a new message on your on-going project,{" "}
                  <b>{title}</b>
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.JOB_REQUEST_DELETED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <GroupOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>job request</b> you have placed a bid/inquiry on,{" "}
                  <b>{title.substring(0, 24)}</b>..., has been withdrawn by the
                  client
                </Box>
                {/*<Icon*/}
                {/*  onClick={(event) =>*/}
                {/*    handleCloseNotification(event, notification)*/}
                {/*  }*/}
                {/*>*/}
                {/*  <CloseIcon color="inherit" fontSize="small" />*/}
                {/*</Icon>*/}
              </Box>
            </MenuItem>
          );
        case NotificationType.COMPANY_INVITATION_CANCELED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>user</b> has canceled the invitation to join his{" "}
                  <b>company</b>, <b>{title}</b>
                </Box>
              </Box>
            </MenuItem>
          );
        case NotificationType.COMPANY_INVITATION_REMOVED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>user</b> has removed you from his <b>company</b>,{" "}
                  <b>{title}</b>
                </Box>
              </Box>
            </MenuItem>
          );
        case NotificationType.COMPANY_INVITATION_ACCEPTED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>user</b> has accepted the invitation to join your{" "}
                  <b>company</b>, <b>{title}</b>
                </Box>
              </Box>
            </MenuItem>
          );
        case NotificationType.COMPANY_INVITATION_REJECTED:
          return (
            <MenuItem
              key={notification._id}
              style={{ whiteSpace: "normal", height: 80 }}
              onClick={() => handleSeeNotification(notification)}
            >
              <Box width={360} display="flex" alignItems="center" columnGap={4}>
                <BusinessCenterOutlinedIcon color="inherit" fontSize="small" />
                <Box
                  style={{
                    fontSize: 14,
                  }}
                >
                  A <b>user</b> has rejected the invitation to join your{" "}
                  <b>company</b>, <b>{title}</b>
                </Box>
              </Box>
            </MenuItem>
          );
        default:
          return "";
      }
    },
    [accountType, handleSeeNotification]
  );

  const notificationsPreview = notifications?.slice(
    0,
    MAX_NOTIFICATIONS_PREVIEW_COUNT
  );

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onClose}
      style={{
        width: 360,
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      {notificationsPreview?.map((notification) =>
        buildNotification(notification)
      )}
      {(notifications?.length ?? 0) > 5 && (
        <MenuItem
          onClick={() => {
            navigate("/notifications");
          }}
        >
          <Box
            style={{
              fontSize: 14,
            }}
          >
            <b>
              <u>See more</u>
            </b>
          </Box>
        </MenuItem>
      )}
    </Menu>
  );
};
