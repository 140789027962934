import { Box, Skeleton } from "@mui/material";
import React from "react";

export const SubscriptionInformationSkeleton: React.FC = () => {
  return (
    <Box
      display="flex"
      columnGap={6}
      rowGap={9}
      justifyContent="flex-end"
      flexDirection="column"
    >
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={40}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="25%"
        height={40}
      />
      <Box display="flex" flexDirection="column" rowGap={3}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
      </Box>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="25%"
        height={40}
      />
      <Box display="flex" flexDirection="column" rowGap={3}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
      </Box>
    </Box>
  );
};
