import { Components } from "@mui/material";
import { BLUE } from "./utils";

export const MuiSvgIcon: Components["MuiSvgIcon"] = {
  styleOverrides: {
    root: {
      width: 24,
      height: 24,
    },
    fontSizeLarge: {
      fontSize: 48,
    },
    fontSizeSmall: {
      fontSize: 24,
    },
    colorSecondary: {
      color: BLUE,
    },
  },
};
