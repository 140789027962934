import { Box, Divider } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { File } from "../../../../api-types/job-requests/common";
import { LIGHT_BLUE } from "../../../../themes/components/utils";
import { Files } from "../../../common/components/files";
import { Messages } from "../../../common/components/messages";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../../../state/hooks";
import { useAddProjectMessageMutation } from "../hooks";
import { useAddProjectFileMutation } from "../hooks/useAddProjectFileMutation";

export const Communication: React.FC = () => {
  const { data: project, isLoading } = useProjectQuery();

  const { mutate: addProjectMessage, isLoading: isAddingMessage } =
    useAddProjectMessageMutation();

  const { mutate: addProjectFile, isLoading: isAddingFile } =
    useAddProjectFileMutation();

  const { id } = useScopedDowngradedStateValue(useUser());

  const { isSmall } = useResponsiveness();

  const onNewMessageHandler = useCallback(
    (newMessage: string) => {
      addProjectMessage({
        professionalId: id ?? undefined,
        messages: [
          {
            content: newMessage,
            from: id ?? "",
            to: project?.client?._id ?? "",
          },
        ],
      });
    },
    [addProjectMessage, id, project?.client?._id]
  );

  const onNewFileHandler = useCallback(
    (files: File[]) => {
      addProjectFile({
        professionalId: id ?? undefined,
        files,
      });
    },
    [addProjectFile, id]
  );

  const professional = useMemo(() => {
    return project?.professionals.find(
      (professional) => id == professional?.professional?._id
    );
  }, [id, project?.professionals]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        {!isSmall && (
          <Box width="50%" borderRight={`1px solid ${LIGHT_BLUE}`}>
            <Files
              isLoading={isAddingFile || isLoading}
              files={professional?.files ?? []}
              onNewFile={(newFile: any[]) => {
                onNewFileHandler(newFile);
              }}
            />
          </Box>
        )}
        <Box width={isSmall ? "100%" : "50%"}>
          <Messages
            isAddingMessage={isAddingMessage}
            isLoading={isLoading}
            messages={professional?.messages ?? []}
            onNewMessage={(newMessage: string) => {
              onNewMessageHandler(newMessage);
            }}
          />
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};
