import { Box } from "@mui/material";
import React, { FC } from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useMobileStyles,
} from "../../../../../../themes/style-hooks";
import { MainActions } from "../../../MainActions";

export const LandingStepDContent: FC = () => {
  const { mobileContentWrapper, mobileDescriptionWrapper } = useMobileStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: COLOR.RED,
    color: COLOR.WHITE,
    hover: COLOR.RED,
  });

  const descriptionText = useDescriptionText({
    color: COLOR.DARK_BROWN,
  });
  return (
    <Box className={mobileContentWrapper}>
      <Box className={mobileDescriptionWrapper}>
        <Box textAlign="left">
          <Box className={descriptionText.root}>
            Keep all the money you earn. No commission, no payment tier model.
          </Box>
          <Box className={descriptionText.root}>
            No free work! Only do work that you get paid for.
          </Box>
        </Box>
      </Box>
      <MainActions classNames={`${common} ${root}`} />
    </Box>
  );
};
