import { Box, Button, Divider } from "@mui/material";
import { FormikValues } from "formik";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JobPreview } from "../../job-requests/create-job-request-wizard/job-post";
import { JobPostSetUpFormFields } from "../../job-requests/create-job-request-wizard/job-post/JobPostSetUpFormFields";

type EditJobPostFormProps = {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  handleUpdateSkills: (newSkills: string[]) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: any;
  isValid?: boolean;
};

export const EditJobPostForm: FC<EditJobPostFormProps> = ({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  handleUpdateSkills,
  errors,
  isValid,
}) => {
  const navigate = useNavigate();
  const [previewOpen, setPreviewOpen] = useState(false);

  const onBack = useCallback(() => {
    navigate("/job-posts");
  }, [navigate]);

  if (previewOpen) {
    return (
      <JobPreview
        values={values}
        onClose={() => {
          setPreviewOpen(false);
        }}
      />
    );
  }

  return (
    <Box>
      <JobPostSetUpFormFields
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        handleUpdateSkills={handleUpdateSkills}
        setFieldValue={setFieldValue}
        errors={errors}
      />

      <Divider />

      <Box
        pt={5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box pt={5}>
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              setPreviewOpen(true);
            }}
            disabled={!isValid}
            data-cy-btn="preview-post"
          >
            Preview Post
          </Button>
        </Box>
      </Box>

      <Box
        py={10}
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <Box display="flex" columnGap={3}>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              onBack();
            }}
          >
            Cancel
          </Button>
          <Button
            size="medium"
            variant="contained"
            type={"submit"}
            disabled={!isValid}
            data-cy-btn="save"
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
