import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider, Fade, Link, Menu, MenuItem } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { COLOR } from "../../../themes/components/utils";
import { LoadingTypography } from "../../common/components";
import { useMetadataQuery } from "../../common/hooks/metadata/useMetadataQuery";
import { useLogout } from "../../common/hooks/sign-in";

export const ClientMenu: React.FC = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { data: metadata, isLoading } = useMetadataQuery();

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const logout = useLogout();

  return (
    <React.Fragment>
      <Link
        color="secondary"
        underline="hover"
        component="button"
        variant="body1"
        onClick={handleClick}
      >
        <Box display="flex" alignItems="center">
          Hi,{" "}
          <LoadingTypography
            width={50}
            isLoading={isLoading}
            style={{
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: COLOR.BLUE,
            }}
          >
            &nbsp;<b>{metadata?.firstName ?? ""}</b>&nbsp;
          </LoadingTypography>
          <ExpandMoreIcon color="secondary" />
        </Box>
      </Link>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        // getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem
          onClick={() => {
            navigate("/account-information");
          }}
        >
          Account Information
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/payment-reports")}>
          Payment Reports
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/job-requests/drafts/")}>
          Job Request Drafts
        </MenuItem>
        <MenuItem onClick={() => navigate("/jobs-packages/")}>
          Jobs Packages
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            navigate("/projects/history/");
          }}
        >
          Past Projects
        </MenuItem>
        {/* @todo bring back companies later */}
        {/* <MenuItem onClick={() => navigate("/companies")}>
          Team Management
        </MenuItem> */}
        <MenuItem onClick={() => navigate("/contact")}>Contact Us</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );
};
