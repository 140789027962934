import { Box, Slider, TextareaAutosize, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { BLUE, COLOR, YELLOW } from "../../../../themes/components/utils";

type ReviewInputProps = {
  onChangeReview: (review: string) => void;
  onChangeRating: (rating: number) => void;
  review: string;
  rating: number;
};

const useStyles = makeStyles({
  radio: {
    "& .MuiSvgIcon-root": {
      width: 48,
      height: 48,
      fill: BLUE,
    },
  },
  leftLabel: {
    lineHeight: 2.5,
    paddingRight: 4,
  },
  textArea: {
    width: "100%",
    background: "none",
    border: "2px solid",
    borderColor: BLUE,
    outline: "none",
    fontFamily: "inherit",
    color: BLUE,
    padding: 8,
    fontSize: 16,
  },
  valueLabel: {
    "& span": {
      transform: "none",
      "& span": {
        // transform: "rotate(45deg)",
        fontSize: 14,
        fontWeight: 500,
      },
    },
    top: -20,
    width: 36,
    height: 36,
    color: YELLOW,
    border: `1px solid ${BLUE}`,
    // borderRadius: "50%", // Make it a circle
    // transform: "rotate(-45deg) scale(1) translateY(-15px) translateX(30px) !important",
  },
  slider: {
    height: 12,
    "& .MuiSlider-thumb": {
      height: 24,
      width: 12,
      color: BLUE,
      borderRadius: 0,
    },
    "& .MuiSlider-thumb:hover": {
      boxShadow: "none",
    },
    "& .MuiSlider-track": {
      height: 12,
      borderRadius: 4,
      color: BLUE,
    },
    "& .MuiSlider-markActive": {
      backgroundColor: "transparent",
    },
    "& .MuiSlider-rail": {
      height: 12,
      opacity: 1,
      color: "white",
      borderRadius: 4,
    },
    "& .MuiSlider-mark": {
      height: 12,
    },
    "& .MuiSlider-marked": {
      marginBottom: 0,
    },
    "& .MuiSlider-markLabel": {
      paddingTop: 6,
      color: COLOR.BLUE,
    },
  },
});

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
];

export const ReviewInput: React.FC<ReviewInputProps> = ({
  onChangeRating,
  onChangeReview,
  rating,
  review,
}) => {
  const styles = useStyles();

  const handleChangeReview = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChangeReview(e.target.value);
  };

  const handleChangeRating = (e: any, value: number | number[]) => {
    onChangeRating(Number(value));
  };

  return (
    <Box width="100%">
      <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
          }}
          color="secondary"
        >
          Write a review
        </Typography>
        <Box height="fit-content" pt={4} width="100%">
          <TextareaAutosize
            name="reviewMessage"
            value={review}
            onChange={handleChangeReview}
            minRows={4}
            maxRows={4}
            className={styles.textArea}
          />
        </Box>
      </Box>
      <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
          }}
          className={styles.leftLabel}
          color="secondary"
        >
          Enter rating{" "}
          <Typography component="span" variant="body1" color="secondary">
            (increments of .5)
          </Typography>
        </Typography>
        <Box pt={8}>
          <Slider
            valueLabelDisplay="auto"
            className={styles.slider}
            classes={{
              valueLabel: styles.valueLabel,
            }}
            value={rating}
            onChange={handleChangeRating}
            step={0.5}
            min={0}
            max={10}
            marks={marks}
            name="rating"
          />
        </Box>
      </Box>
    </Box>
  );
};
