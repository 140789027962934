import { Box } from "@mui/material";
import { FC, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Card } from "../../elements/Card";
import { useMetadataQuery } from "../../hooks/metadata/useMetadataQuery";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";

type ProfileTabsProps = {
  disableCollaboratorsTab?: boolean;
  disableCurrentProjectsTab?: boolean;
  disablePortfolioProjectsTab?: boolean;
};

export const ProfileTabs: FC<ProfileTabsProps> = ({
  disableCollaboratorsTab,
  disableCurrentProjectsTab,
  disablePortfolioProjectsTab,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSmall } = useResponsiveness();
  const { isLoading } = useMetadataQuery();

  const onTabNavigationHandler = useCallback(
    (tab: string) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if (searchParams.get("tab")) {
      return;
    }

    const enabledTabs = [];

    if (!disablePortfolioProjectsTab) {
      enabledTabs.push("portfolio");
    }

    if (!disableCurrentProjectsTab) {
      enabledTabs.push("current");
    }

    if (!disableCollaboratorsTab) {
      enabledTabs.push("collaborators");
    }

    if (enabledTabs.length) {
      searchParams.set("tab", enabledTabs[0]);
      setSearchParams(searchParams, { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams,
    disablePortfolioProjectsTab,
    disableCurrentProjectsTab,
    disableCollaboratorsTab,
  ]);

  return (
    <Box
      display={"flex"}
      flexWrap={isSmall ? "wrap" : "nowrap"}
      columnGap={isSmall ? 2 : 4}
      rowGap={isSmall ? 2 : 4}
    >
      <Card
        width={isSmall ? "100%" : 190}
        disabled={disablePortfolioProjectsTab}
        label="Portfolio Projects"
        isSelected={searchParams.get("tab") === "portfolio"}
        onClick={() => onTabNavigationHandler("portfolio")}
        isLoading={isLoading}
      />
      <Card
        width={isSmall ? "100%" : 184}
        isLoading={isLoading}
        disabled={disableCurrentProjectsTab}
        label={`Current Projects`}
        isSelected={searchParams.get("tab") === "current"}
        onClick={() => onTabNavigationHandler("current")}
      />
      <Card
        width={isSmall ? "100%" : 162}
        isLoading={isLoading}
        disabled={disableCollaboratorsTab}
        label={`Collaborators`}
        isSelected={searchParams.get("tab") === "collaborators"}
        onClick={() => onTabNavigationHandler("collaborators")}
      />
    </Box>
  );
};
