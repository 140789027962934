import { Box, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { SavedJobRequestHighlights } from "./SavedJobRequestsHighlights";
import { SearchForm } from "./SearchForm";

const useStyles = makeStyles({
  container: {
    width: "70%",
    ["@media screen and (max-width: 1300px)"]: {
      width: "75%",
    },
    ["@media screen and (max-width: 1200px)"]: {
      width: "80%",
    },
  },
});

export const SearchMainbar: React.FC = () => {
  const styles = useStyles();

  return (
    <Box
      display="flex"
      columnGap={6}
      px={15}
      style={{
        pointerEvents: "none",
      }}
      width="100%"
    >
      <Box height="340px" className={styles.container}>
        <Box py={10}>
          <Typography
            variant="h1"
            sx={{
              fontWeight: "bold",
            }}
          >
            Job Requests
          </Typography>
        </Box>
        <SearchForm />
      </Box>
      <Divider orientation="vertical" flexItem />
      <SavedJobRequestHighlights />
    </Box>
  );
};
