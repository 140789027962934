import { Components } from "@mui/material";
import { LIGHT_BLUE } from "./utils";

export const MuiPaper: Components["MuiPaper"] = {
  styleOverrides: {
    root: {
      p: 0,
      backgroundColor: LIGHT_BLUE,
    },
  },
};
// };
//
// export const MuiPaper: MuiComponentThemeDefinition<PaperClassKey, PaperProps> =
//   {
//     props: {},
//     override: () => ({
//       root: {
//         p: 0,
//         backgroundColor: LIGHT_BLUE,
//       },
//     }),
//   };
