import { Box } from "@mui/material";
import React from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useHeadlineText,
  useMediumItalic,
  useStepCStyles,
} from "../../../../../../themes/style-hooks";
import { AccountTypeSwitch } from "../../../client/parallax/AccountTypeSwitch";
import { MainActions } from "../../../MainActions";

export const LandingStepC = () => {
  const styles = useStepCStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: COLOR.YELLOW,
    color: COLOR.BROWN,
    hover: COLOR.YELLOW,
  });
  const mediumItalic = useMediumItalic();

  const { transparent, skewedText } = useHeadlineText({
    primary: COLOR.BROWN,
    secondary: COLOR.WHITE_BROWN,
    background: COLOR.YELLOW,
  });
  const descriptionText = useDescriptionText({
    color: COLOR.WHITE_BROWN,
  });

  return (
    <React.Fragment>
      <Box textAlign="left" width="100%">
        <Box className={mediumItalic.root}>
          <Box className={`${skewedText} ${styles.headline} `}>
            <span>&nbsp;Unlimited free bids&nbsp;</span>
          </Box>
        </Box>
        <br />
        <Box className={mediumItalic.root}>
          <Box className={`${skewedText} ${styles.headline}`}>
            <span>&nbsp;before&nbsp;</span>
          </Box>
          <Box className={`${transparent} ${styles.headline}`}>
            <span>&nbsp;and after&nbsp;</span>
          </Box>
          <Box
            style={{
              whiteSpace: "nowrap",
            }}
            className={`${skewedText} ${styles.headline}`}
          >
            <span>&nbsp;sign-up.&nbsp;</span>
          </Box>
        </Box>
      </Box>

      <Box width="100%" pl={8}>
        <span>&nbsp;</span>
        <Box textAlign="left" color={COLOR.WHITE_PINK}>
          <Box className={descriptionText.root}>
            All bids are free for professionals, even before signing up.
          </Box>
          <Box className={descriptionText.root}>
            Bid on any project! As a professional, you donʼt need to earn
            credits on the platform to become eligible to bid on projects.
          </Box>
        </Box>
        <Box pt={8} textAlign="left">
          <Box className={descriptionText.root}>
            Sign up for a monthly subscription of $25 per month only when you
            start working on a project.
          </Box>
        </Box>
      </Box>
      <MainActions classNames={`${common} ${root}`} />
      {/*<AccountTypeSwitch defaultChecked="professional" />*/}
    </React.Fragment>
  );
};
