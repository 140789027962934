import { Box, Skeleton } from "@mui/material";
import React, { useMemo } from "react";
import useResizeObserver from "use-resize-observer";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";

export const ProfessionalsSkeleton: React.FC = () => {
  const { isSmall, isMedium } = useResponsiveness();
  const { ref, width = 0 } = useResizeObserver<HTMLDivElement>();

  const profilePictureWidth = useMemo(() => {
    if (isSmall) {
      return 100;
    }

    if (isMedium) {
      return 140;
    }

    return 178;
  }, [isMedium, isSmall]);

  return (
    <Box
      pt={12.5}
      // @ts-ignore
      ref={ref}
    >
      <Box display="flex" columnGap={6} justifyContent="space-between">
        <Box
          width={profilePictureWidth}
          pt={15}
          display="flex"
          flexDirection="column"
          rowGap={6}
          alignItems="center"
        >
          <Skeleton
            animation="wave"
            variant="circular"
            width={profilePictureWidth}
            height={profilePictureWidth}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={100}
            height={40}
          />
        </Box>
        <Box
          width={width * (isSmall || isMedium ? 0.6 : 0.4)}
          display="flex"
          flexDirection="column"
          rowGap={3}
        >
          <Box pb={5}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="50%"
              height={30}
            />
          </Box>
          <Skeleton variant="rectangular" animation="wave" height={30} />
          <Skeleton variant="rectangular" animation="wave" height={30} />
          <Skeleton variant="rectangular" animation="wave" height={30} />
          <Skeleton variant="rectangular" animation="wave" height={30} />
          <Box pt={7.5} display="flex" columnGap={2.5} alignItems="center">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Box display="flex" columnGap={1} alignItems="center">
              <Skeleton
                variant="circular"
                animation="wave"
                width={16}
                height={16}
              />
              <Skeleton
                variant="circular"
                animation="wave"
                width={16}
                height={16}
              />
              <Skeleton
                variant="circular"
                animation="wave"
                width={16}
                height={16}
              />
            </Box>
          </Box>
        </Box>
        {isSmall || isMedium ? (
          <></>
        ) : (
          <Box width={width * 0.35}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={width * 0.35}
              height={350}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
