import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { filter, map } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { YELLOW } from "../../../themes/components/utils";
import { PageWrapper } from "../../app/container/PageWrapper";
import { useJobRequestQuery } from "../../common/hooks/job-requests/job-request/useJobRequestQuery";
import {
  DueDate,
  Milestone,
} from "../job-requests/create-job-request-wizard/validation-schema";
import { useOnAcceptBidHandler } from "../job-requests/current-job-request/hooks/useOnAcceptBidHandler";
import { FundingDetailsTopContainer } from "./FundingDetailsTopContainer";
import { MilestonesDetailsLeftContainer } from "./MilestonesDetailsLeftContainer";

export const FundingDashboard: React.FC = () => {
  const [searchParams] = useSearchParams();

  const { data: jobRequest, isLoading } = useJobRequestQuery();

  const [selectedMilestones, setSelectedMilestones] = useState<Milestone[]>([]);

  const milestonesToFund = useMemo(
    () => filter(selectedMilestones, ({ isFunded }) => !isFunded),
    [selectedMilestones]
  );

  const milestoneIdsToFund = useMemo(
    () => map(milestonesToFund, ({ _id }) => _id ?? ""),
    [milestonesToFund]
  );

  const handleSelectMilestone = (
    event: React.ChangeEvent<HTMLInputElement>,
    milestone: Milestone
  ) => {
    if (event.target.checked) {
      setSelectedMilestones([...selectedMilestones, milestone]);
    }

    if (!event.target.checked) {
      const updatedMilestones = [...selectedMilestones];

      const index = updatedMilestones.findIndex((m) => m._id == milestone._id);

      updatedMilestones.splice(index, 1);

      setSelectedMilestones(updatedMilestones);
    }
  };

  const navigate = useNavigate();

  const { pathname, search } = useLocation();

  useEffect(() => {
    if (isLoading) return;

    const [firstMilestone] = jobRequest?.fixedProjectFunding.milestones ?? [];

    setSelectedMilestones([firstMilestone]);
  }, [jobRequest, isLoading]);

  const isFunded =
    Boolean(searchParams.get("isFunded")) ||
    selectedMilestones.every((sM) => sM.isFunded);

  const isMilestoneSelected = (description: string, amount: number) => {
    return Boolean(
      selectedMilestones.find(
        (milestone) =>
          milestone.description === description && milestone.amount === amount
      )
    );
  };

  const onAcceptBidHandler = useOnAcceptBidHandler();

  const onConfirmProjectStart = useCallback(
    (bidId) => {
      onAcceptBidHandler(bidId);
    },
    [onAcceptBidHandler]
  );

  const dueDateType = useCallback((dueDate: DueDate) => {
    return dueDate.type.substring(0, dueDate.type.length - 1);
  }, []);

  const amount = selectedMilestones.reduce(
    (prev, curr) => prev + (curr.isFunded ? 0 : curr.amount),
    0
  );

  // https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
  const amountIncludingCommission = (amount + 0.3) / (1 - 0.029);
  // const amountIncludingCommission = amount + amount * 0.029 + 0.3;

  const roundedAmount = amount
    ? Math.round((amountIncludingCommission + Number.EPSILON) * 100) / 100
    : 0;

  return (
    <PageWrapper>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection="column"
        height="100%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          columnGap={5}
          pt={10}
          height="100%"
          width="100%"
        >
          <MilestonesDetailsLeftContainer />

          <Box height="100%" width="60%">
            <Box display="flex" flexDirection="column" gap={5}>
              <FundingDetailsTopContainer />
              <Divider />

              <Table>
                <TableHead>
                  <TableCell variant="head" />
                  <TableCell variant="head">Milestone Description</TableCell>
                  <TableCell variant="head">Milestone Amount</TableCell>
                  <TableCell variant="head">Due Date</TableCell>
                  <TableCell variant="head">Funded</TableCell>
                </TableHead>

                <TableBody>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    jobRequest?.fixedProjectFunding.milestones.map(
                      (
                        {
                          amount,
                          description,
                          dueDate,
                          isFunded,
                          ...milestone
                        },
                        index
                      ) => (
                        <TableRow key={`${milestone._id}-${description}`}>
                          <TableCell>
                            <Checkbox
                              disabled={index === 0 || isFunded}
                              sx={{
                                color: YELLOW,
                                "&.Mui-disabled": {
                                  color: YELLOW,
                                  opacity: 0.5,
                                },
                              }}
                              checked={
                                isMilestoneSelected(description, amount) ||
                                isFunded
                              }
                              onChange={(event) =>
                                handleSelectMilestone(event, {
                                  amount,
                                  description,
                                  dueDate,
                                  isFunded,
                                  ...milestone,
                                })
                              }
                            />
                          </TableCell>

                          <TableCell>
                            <Typography component="span">
                              {description}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography component="span">${amount}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography component="span">
                              {`${dueDate.count} ${dueDateType(
                                dueDate
                              )}(s) from start of ${
                                index === 0 ? "project" : "milestone"
                              }`}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography component="span">
                              {isFunded ? "Yes" : "No"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" py={10} width="100%">
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              navigate("/job-requests/");
            }}
          >
            Cancel
          </Button>
          <Box display="flex" justifySelf="flex-end" columnGap={2.5}>
            <Button
              size="medium"
              variant="outlined"
              disabled={
                selectedMilestones.length === 0 || Number(roundedAmount) < 1
              }
              onClick={() => {
                navigate(
                  {
                    pathname: `/payment/`,
                    search: `?${createSearchParams({
                      amount: String(roundedAmount),
                      jobRequestId: jobRequest?._id ?? "",
                      milestonesIds: JSON.stringify(milestoneIdsToFund),
                    })}`,
                  },
                  {
                    state: {
                      backRoute: pathname,
                      search,
                    },
                  }
                );
              }}
            >{`Pay $${roundedAmount} with card`}</Button>
            <Button
              size="medium"
              variant="contained"
              disabled={!isFunded}
              onClick={() =>
                onConfirmProjectStart(searchParams.get("bidId") ?? "")
              }
            >
              Send Contract Offer
            </Button>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};
