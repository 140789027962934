import { Box } from "@mui/material";
import { Formik, FormikValues } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { object, string, array, number, InferType } from "yup";
import { PageWrapper } from "../../../../app/container/PageWrapper";
import { useJobRequestCreateMutation } from "../../../../common/hooks/job-requests/job-request/useJobRequestCreateMutation";
import { useJobRequestQuery } from "../../../../common/hooks/job-requests/job-request/useJobRequestQuery";
import { useJobRequestUpdateMutation } from "../../../../common/hooks/job-requests/job-request/useJobRequestUpdateMutation";
import {
  useNavigateToJobRequestDrafts,
  useNavigateToSearchProfessionals,
} from "../../../../common/hooks/navigation";
import { parseJobRequestForm } from "../utils";
import {
  BudgetSchemaDefinition,
  FixedProjectFundingSchemaDefinition,
  HourlyRateFundingSchemaDefinition,
} from "../validation-schema";
import { initialBudgetValues } from "./BudgetStep";
import { CurrentJobRequestStep } from "./CurrentJobRequestStep";
import { initialJobDetailsValues } from "./JobRequestDetails";
import { JobRequestProgressBar } from "./JobRequestProgressBar";
import { JobRequestWizardActions } from "./JobRequestWizardActions";
import { initialProjectFundingValues } from "./ProjectFundingStep";
import { initialServicesValues } from "./Services";
import { initialTimeframeValues } from "./TimeframeCost";
import { initialVisibilityValues } from "./Visibility";

const validationSchema = object().shape({
  step: number(),
  description: string().when("step", {
    is: 2,
    then: string().required("Description is required"),
  }),
  title: string().when("step", {
    is: 2,
    then: string().required("Title is required"),
  }),
  skills: array().when("step", {
    is: 4,
    then: array().of(object().shape({ name: string().required() })),
  }),
  // visibility: string().when("step", {
  //   is: 5,
  //   then: string().required(),
  // }),
  budget: object().when("step", {
    is: 5,
    then: BudgetSchemaDefinition,
  }),
  fixedProjectFunding: object().when(["step", "budget.type"], {
    is: (step: number, budgetType: string) =>
      step === 6 && budgetType === "fixed",
    then: FixedProjectFundingSchemaDefinition,
  }),
  hourlyRateProjectFunding: object().when(["step", "budget.type"], {
    is: (step: number, budgetType: string) =>
      step === 6 && budgetType === "hourly",
    then: HourlyRateFundingSchemaDefinition,
  }),
});

export type JobRequestFormValues = InferType<typeof validationSchema>;

const initialSkillsValues = { skills: [] };

const initialImageValues = {
  images: [],
};

const initialValues = {
  step: 1,
  ...initialServicesValues,
  ...initialJobDetailsValues,
  ...initialImageValues,
  ...initialSkillsValues,
  ...initialTimeframeValues,
  ...initialVisibilityValues,
  ...initialBudgetValues,
  ...initialProjectFundingValues,
};

export const FreelanceJobRequestWizard: React.FC = () => {
  const { pathname, search } = useLocation();
  const { jobRequestId } = useParams() as { jobRequestId: string };

  const navigate = useNavigate();
  const { data: jobRequestDraft } = useJobRequestQuery(jobRequestId);
  const navigateToSearchProfessionals = useNavigateToSearchProfessionals();
  const navigateToJobRequestDrafts = useNavigateToJobRequestDrafts();

  const { mutate: createJobRequestMutation } = useJobRequestCreateMutation();
  const { mutate: updateJobRequestMutation } = useJobRequestUpdateMutation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    navigate(
      {
        pathname,
        search: `?${createSearchParams({
          step: "1",
        })}`,
      },
      { replace: true }
    );
  }, [navigate, pathname]);

  const createJobRequest = useCallback(
    (values: FormikValues, isDraft: boolean = false) => {
      createJobRequestMutation(
        parseJobRequestForm({ ...values, isDraft }, true)
      );
    },
    [createJobRequestMutation]
  );

  const updateJobRequest = useCallback(
    (values: FormikValues, isDraft: boolean = false) => {
      updateJobRequestMutation(
        parseJobRequestForm({
          ...values,
          isDraft,
          _id: jobRequestId!,
        }),
        {
          onSuccess(jobRequest) {
            if (isDraft) {
              navigateToJobRequestDrafts();
            } else {
              navigateToSearchProfessionals(jobRequest._id);
            }
          },
        }
      );
    },
    [
      jobRequestId,
      navigateToJobRequestDrafts,
      navigateToSearchProfessionals,
      updateJobRequestMutation,
    ]
  );

  const handleUpdateJobRequest = useCallback(
    (values: FormikValues, isDraft = false) => {
      if (!jobRequestId) {
        createJobRequest(values, isDraft);
      } else {
        updateJobRequest(values, isDraft);
      }
    },
    [createJobRequest, jobRequestId, updateJobRequest]
  );

  const onBackHandler = useCallback(
    (setFieldValue: (field: string, value: any) => void) => {
      setFieldValue("step", Number(searchParams.get("step")) - 1);
      history.back();
    },
    [searchParams]
  );

  const handleChangeFileUpload = useCallback(
    (
      uploadedFiles: any[],
      setFieldValue: (field: string, value: any) => void
    ) => {
      setFieldValue("images", uploadedFiles);
    },
    []
  );

  const mappedJobRequestDraft = useMemo(() => {
    if (!jobRequestDraft) return;

    const { serviceCategory } = jobRequestDraft;

    return {
      ...jobRequestDraft,
      step: 1,
      services: { ...initialServicesValues.services, [serviceCategory]: true },
      projectType: "existing",
    };
  }, [jobRequestDraft]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, [search]);

  return (
    <Box
      style={{
        height: "calc(100% - 100px)",
      }}
    >
      <JobRequestProgressBar />
      <PageWrapper hideNavbar>
        <Box height="100%">
          <Formik
            initialValues={mappedJobRequestDraft ?? initialValues}
            onSubmit={(values) => {
              handleUpdateJobRequest(values);
            }}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({
              submitForm,
              handleSubmit,
              setFieldValue,
              handleChange,
              handleBlur,
              values,
              errors,
              isValid,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <CurrentJobRequestStep
                  onBack={() => onBackHandler(setFieldValue)}
                  handleChange={handleChange}
                  handleUpdateSkills={(newSkills: string[]) => {
                    setFieldValue(
                      "skills",
                      newSkills.map((skill) => ({ name: skill }))
                    );
                  }}
                  handleChangeFileUpload={(files) =>
                    handleChangeFileUpload(files, setFieldValue)
                  }
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  handleFocus={(field: string, value: string) =>
                    setFieldValue(field, value)
                  }
                />
                <JobRequestWizardActions
                  isDisabled={!isValid}
                  submitStep={6}
                  onNext={(step) => {
                    setFieldValue("step", step);
                  }}
                  onSubmit={submitForm}
                  onSaveDraft={() => handleUpdateJobRequest(values, true)}
                />
              </form>
            )}
          </Formik>
        </Box>
      </PageWrapper>
    </Box>
  );
};
