import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
} from "@mui/material";
import { FieldArray, FormikErrors, useFormikContext } from "formik";
import { Fragment } from "react";
import { RED } from "../../../../themes/components/utils";
import { useTransparentSelectStyles } from "../../../../themes/style-hooks";
import { useRequiredFieldLabel } from "../../../common/hooks/utils";
import {
  FixedProjectFundingSchema,
  MilestonesSchema,
} from "../create-job-request-wizard/validation-schema";
import { MilestonesTable } from "./MilestonesTable";
import { TableCellWithInput } from "./TableCellWithInput";

export const JobRequestFixedPriceMilestoneTable = () => {
  const transparentSelectStyles = useTransparentSelectStyles();
  const requiredFieldLabel = useRequiredFieldLabel();

  const { values, setFieldValue, errors } =
    useFormikContext<FixedProjectFundingSchema>();

  return (
    <FieldArray name="fixedProjectFunding.milestones">
      {({ push, remove }) => (
        <MilestonesTable
          onAddMilestone={() =>
            push({
              amount: 0,
              description: "",
              dueDate: { type: "weeks", weeks: 0, days: 0 },
            })
          }
          TableHeadContent={
            <Fragment>
              <TableCell variant="head">
                {requiredFieldLabel("Milestone Description")}
              </TableCell>
              <TableCell variant="head">
                {requiredFieldLabel("Milestone Amount")}
              </TableCell>
              <TableCell variant="head">
                {requiredFieldLabel("Due Date")}
              </TableCell>
              <TableCell />
            </Fragment>
          }
        >
          {values.fixedProjectFunding.milestones?.map(
            ({ amount, description, dueDate }, index) => {
              const onDueDateTypeChange = (
                event: SelectChangeEvent<{
                  name?: string;
                  value: unknown;
                }>
              ) => {
                setFieldValue(
                  `fixedProjectFunding.milestones.${index}.dueDate.type`,
                  event.target.value
                );
              };

              return (
                <TableRow key={index} data-cy-row={index}>
                  <TableCellWithInput
                    cellWidth="40%"
                    error={
                      (
                        errors.fixedProjectFunding
                          ?.milestones as FormikErrors<MilestonesSchema>
                      )?.[index]?.description
                    }
                    name={`fixedProjectFunding.milestones.${index}.description`}
                    value={description}
                  />
                  <TableCellWithInput
                    error={
                      (
                        errors.fixedProjectFunding
                          ?.milestones as FormikErrors<MilestonesSchema>
                      )?.[index]?.amount
                    }
                    inputWidth={80}
                    labelBefore="$"
                    name={`fixedProjectFunding.milestones.${index}.amount`}
                    type="number"
                    value={amount}
                  />
                  <TableCellWithInput
                    error={
                      (
                        errors.fixedProjectFunding
                          ?.milestones as FormikErrors<MilestonesSchema>
                      )?.[index]?.dueDate?.count
                    }
                    inputWidth={80}
                    name={`fixedProjectFunding.milestones.${index}.dueDate.count`}
                    type="number"
                    value={dueDate.count}
                  >
                    <Select
                      className={transparentSelectStyles.root}
                      disableUnderline
                      IconComponent={KeyboardArrowDownIcon}
                      value={{
                        value:
                          values.fixedProjectFunding.milestones?.[index].dueDate
                            .type ?? "weeks",
                      }}
                      name={`fixedProjectFunding.milestones.${index}.dueDate.type`}
                      onChange={onDueDateTypeChange}
                      // defaultValue="weeks"
                      style={{
                        fontSize: 16,
                        width: 240,
                      }}
                    >
                      <MenuItem value="weeks">{`week(s) from start of ${
                        index === 0 ? "project" : "milestone"
                      }`}</MenuItem>
                      <MenuItem value="days">{`day(s) from start of ${
                        index === 0 ? "project" : "milestone"
                      }`}</MenuItem>
                      <MenuItem value="hours">{`hour(s) from start of ${
                        index === 0 ? "project" : "milestone"
                      }`}</MenuItem>
                    </Select>
                  </TableCellWithInput>
                  <TableCell
                    style={{
                      cursor: index === 0 ? "not-allowed" : "pointer",
                    }}
                  >
                    <ListItemIcon
                      style={{
                        marginRight: 8,
                        paddingTop: 8,
                        pointerEvents: index === 0 ? "none" : "all",
                        cursor: "pointer",
                      }}
                      sx={{
                        color: index === 0 ? "grey" : RED,
                      }}
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </MilestonesTable>
      )}
    </FieldArray>
  );
};
