import { Box, FormControlLabel, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { BLUE } from "../../../../themes/components/utils";
import { dummyCurrencies } from "../../../client/job-requests/create-job-request-wizard/job-post/JobPostSetUpForm";
import { CheckboxWithInput } from "../../../client/job-requests/utils/CheckboxWithInput";
import { HeaderDropdown } from "../../../common/components";
import { SearchJobFiltersFormData } from "../schemas/searchJobFiltersSchema";
import { useSearchJobPostFiltersStyles } from "./useSearchJobPostFiltersStyles";

export const currencies = ["EUR", "USD", "RON"];

export const RatePerHourFilter = () => {
  const { handleBlur, handleChange, setFieldValue, values } =
    useFormikContext<SearchJobFiltersFormData>();
  const styles = useSearchJobPostFiltersStyles();

  const handleCurrencySelect = (index: number) => {
    setFieldValue("ratePerHour.currency", currencies[index]);
  };

  const disabledCurrency =
    values.ratePerHour?.checked === false || values.showAll;

  return (
    <Box>
      <Typography
        className={`${styles.label} ${values.showAll && styles.disabledLabel}`}
        color="secondary"
        variant="subtitle1"
      >
        Rate Per Hour
      </Typography>

      <Box display="flex">
        <FormControlLabel
          control={
            <CheckboxWithInput
              betweenInputsLabel="and"
              checked={values.ratePerHour?.checked}
              checkboxValue="estimate"
              colorCheckbox
              colorInput
              disabled={values.showAll}
              isMinMaxInput
              leftLabel="Between"
              maxValue={values.ratePerHour?.estimate?.max}
              minValue={values.ratePerHour?.estimate?.min}
              name="ratePerHour"
              onBlur={handleBlur}
              onChange={handleChange}
            />
          }
          label=""
        />
        {values.ratePerHour?.checked && (
          <Box
            alignItems="center"
            display="flex"
            data-cy="rate-dropdown-container"
          >
            <Typography
              color="secondary"
              className={`${disabledCurrency && styles.disabledLabel}`}
            >
              Select Currency
            </Typography>
            <HeaderDropdown
              boxContainerClassName={`${styles.boxContainerReset} ${
                disabledCurrency && styles.disabledLabel
              }`}
              color={BLUE}
              disabled={disabledCurrency}
              dropdownIcon={styles.dropdownIcon}
              fontWeight={400}
              mainBox={`${styles.mainBoxContainer}  ${
                disabledCurrency && styles.disabledLabel
              }`}
              mainContainer={styles.boxContainer}
              minWidth="130px"
              onSelect={handleCurrencySelect}
              options={dummyCurrencies}
              selectedOptionId={values.ratePerHour?.currency ?? "USD"}
              titleClass={styles.selectLabel}
              variant="body1"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
