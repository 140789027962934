import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Divider, Typography, Skeleton, IconButton } from "@mui/material";
import React, { useMemo, Fragment, useState } from "react";
import ShowMoreText from "react-show-more-text";
import { AccountInformation } from "../../../../api-types/account-information";
import { ProfileInformation } from "../../../../api-types/profile-information";
import { BLUE } from "../../../../themes/components/utils";
import { CustomTooltip, TextOverflow } from "../../components";
import { SkillsOutput } from "../../components/SkillsOutput";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";
import { SkeletonCollection } from "../../professionals-search/browse-mainbar/SkeletonCollection";
import { ProfilePicture } from "../../summary-profile";
import { ProfessionalAccountSummaryContentSkeleton } from "./ProfessionalAccountSummaryContentSkeleton";

const MAX_LENGTH = 51;

type ProfessionalAccountSummaryContentProps = {
  accountInformation?: AccountInformation;
  profileInformation?: ProfileInformation;
  isLoading?: boolean;
};

export const ProfessionalAccountSummaryContent: React.FC<
  ProfessionalAccountSummaryContentProps
> = ({ profileInformation, accountInformation, isLoading }) => {
  const { isSmall, isMedium, isLarge, isExtraLarge } = useResponsiveness();
  const [showFullPosition, setShowFullPosition] = useState(false);

  const maxNameWidth = useMemo(() => {
    if (isSmall) {
      return 340;
    }
    if (isMedium) {
      return 550;
    }
    return 700;
  }, [isSmall, isMedium]);

  const content = useMemo(
    () =>
      accountInformation ? (
        <Box
          display="flex"
          alignItems="center"
          columnGap={3}
          width={maxNameWidth}
        >
          {isSmall && (
            <ProfilePicture
              profileInformation={profileInformation}
              width={64}
            />
          )}
          <TextOverflow
            width="100%"
            style={{
              fontSize: 36,
              fontWeight: 700,
              maxWidth: maxNameWidth,
            }}
          >
            {/*<Typography*/}
            {/*style={{*/}
            {/*  fontSize: 36,*/}
            {/*  fontWeight: 700,*/}
            {/*  maxWidth: maxNameWidth,*/}
            {/*  maxHeight: 36,*/}
            {/*}}*/}
            {/*>*/}
            {accountInformation?.firstName} {accountInformation?.lastName}
            {/*</Typography>*/}
          </TextOverflow>
          {accountInformation?.state && (
            <Box display="flex" columnGap={3} alignItems="baseline">
              <Typography variant="subtitle1">|</Typography>
              <Typography variant="subtitle1">
                {accountInformation?.city}, {accountInformation?.state}
              </Typography>
            </Box>
          )}
        </Box>
      ) : null,
    [accountInformation, isSmall, profileInformation, maxNameWidth]
  );

  const tooltipTitle = useMemo(() => {
    return (
      <Fragment>
        <Box display="flex" flexDirection="column" rowGap={3.5}>
          <Typography
            color="secondary"
            style={{
              fontWeight: 700,
            }}
            variant="subtitle2"
          >
            {accountInformation?.firstName} {accountInformation?.lastName}
          </Typography>
          <Divider
            sx={{
              borderColor: BLUE,
            }}
          />

          <Box display="flex" flexDirection="column" rowGap={7}>
            <Typography
              color="secondary"
              style={{
                fontWeight: 700,
              }}
              variant="subtitle2"
            >
              {profileInformation?.position}
            </Typography>
            <Typography
              color="secondary"
              variant="body1"
              style={{
                whiteSpace: "pre-line",
              }}
            >
              {profileInformation?.description}
            </Typography>
          </Box>
        </Box>
      </Fragment>
    );
  }, [
    accountInformation?.firstName,
    accountInformation?.lastName,
    profileInformation?.description,
    profileInformation?.position,
  ]);

  const togglePositionExpansion = () => {
    setShowFullPosition(!showFullPosition);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={3}
      width="100%"
      py={10}
      marginRight={4}
    >
      <Box display="flex" columnGap={3} alignItems="center" width="100%">
        <SkeletonCollection
          isLoading={isLoading}
          skeleton={
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="400px"
              height={30}
            />
          }
        >
          {content}
        </SkeletonCollection>
      </Box>

      <SkeletonCollection
        isLoading={isLoading}
        skeleton={<ProfessionalAccountSummaryContentSkeleton />}
      >
        <Box height={100}>
          {profileInformation?.position && (
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography
                variant="h5"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: 600,
                  marginRight: "1rem",
                }}
              >
                {profileInformation?.position.length > MAX_LENGTH &&
                !showFullPosition
                  ? `${profileInformation.position.slice(0, MAX_LENGTH)}...`
                  : profileInformation?.position}
              </Typography>
              {profileInformation?.position.length > MAX_LENGTH && (
                /*<Button onClick={togglePositionExpansion} color="primary">{showFullPosition ? "Less" : "More"}</Button>//button #1*/
                <IconButton
                  onClick={togglePositionExpansion}
                  disableRipple
                  color="primary"
                  size="small"
                  sx={{
                    transform: `rotate(${showFullPosition ? "90deg" : "0deg"})`,
                    transition: "transform 0.3s ease",
                    marginRight: "1.6rem",
                  }}
                >
                  <KeyboardArrowDown />
                </IconButton>
              )}
            </Box>
          )}

          <Box
            height={90}
            width={isMedium ? 300 : 600}
            pt={2}
            style={{
              overflow: "auto",
            }}
          >
            {profileInformation?.description && (
              <Typography
                component="div"
                variant="body1"
                sx={{
                  a: {
                    textDecoration: "none",
                  },
                }}
              >
                <ShowMoreText
                  lines={2}
                  more={
                    <CustomTooltip arrow placement="right" title={tooltipTitle}>
                      <Typography
                        component="span"
                        variant="body2"
                        color="primary"
                      >
                        More
                      </Typography>
                    </CustomTooltip>
                  }
                  // anchorClass={anchorStyles.root}
                  expanded={false}
                  // @ts-ignore: A bug in the library. The prop exists, but the interface is not updated.
                  expandByClick={false}
                >
                  {profileInformation?.description}
                </ShowMoreText>
              </Typography>
            )}
          </Box>

          <Box py={isSmall ? 0 : 2}>
            <SkillsOutput
              skills={profileInformation?.skills?.map((skill) => skill.name)}
              color="transparent"
              isHovering
            />
          </Box>
        </Box>
      </SkeletonCollection>
    </Box>
  );
};
