import { Box, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
  BLUE,
  MAX_VIEWPORT_WIDTH,
  YELLOW,
} from "../../../../themes/components/utils";
import { Step } from "../../elements/Step";
import { TheTalentedLogo } from "../../logo";

const clientSteps = [
  "Account Type",
  "Enter Sign Up Info",
  "Confirm Email Address",
];
const professionalSteps = [
  "Account Type",
  "Services",
  "Profile Information",
  "Enter Sign Up info",
  "Skills",
  "Confirm Email Address",
];

export const SignUpProgressBar: React.FC = () => {
  const [searchParams] = useSearchParams();
  const smallWidth = useMediaQuery("(max-width: 1440px)");

  const currentStepIndex = Number(searchParams.get("step"));

  const steps = useMemo(() => {
    if (searchParams.get("type") === "professional") {
      return professionalSteps;
    }

    return clientSteps;
  }, [searchParams]);

  const STEPS = useMemo(() => {
    return steps.map((step, index) => {
      const isFirstStep =
        index + 1 !== currentStepIndex && currentStepIndex === 1;

      return (
        <Step
          key={index}
          label={isFirstStep ? "" : step}
          isSelected={currentStepIndex === 1 + index}
          color={BLUE}
        />
      );
    });
  }, [currentStepIndex, steps]);

  return (
    <Box
      height={100}
      px={5}
      bgcolor={YELLOW}
      display="flex"
      alignItems="center"
      justifyContent={smallWidth ? "center" : "space-between"}
    >
      {!smallWidth && <TheTalentedLogo inverse />}
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="center"
        width={MAX_VIEWPORT_WIDTH - 450}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          {STEPS}
        </Box>
        <Box width="100%" height="12px" bgcolor="white">
          <Box
            width={`${(currentStepIndex / steps.length) * 100}%`}
            height="inherit"
            bgcolor={BLUE}
          />
        </Box>
      </Box>
      {!smallWidth && <Box width={180} />}
    </Box>
  );
};
