import { Box, Button } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type StepActionsProps = {
  submitStep: number;
  onSubmit?: () => void;
  onSaveDraft?: () => void;
  onNext?: (nextStep: number) => void;
  containerProps?: any;
  isDisabled?: boolean;
};

export const JobRequestWizardActions: React.FC<StepActionsProps> = ({
  onSaveDraft,
  submitStep,
  onSubmit,
  onNext,
  isDisabled = false,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const btnStyles = useMainButtonsStyles();
  const navigate = useNavigate();
  const currentStep = Number(searchParams.get("step"));

  const onNextHandler = useCallback(() => {
    const nextStep = Number(searchParams.get("step")) + 1;
    searchParams.set("step", String(nextStep));
    setSearchParams(searchParams);
    onNext?.(nextStep);
  }, [onNext, searchParams, setSearchParams]);

  if (currentStep < 1) return null;

  return (
    <Box display="flex" justifyContent="flex-end" columnGap={3} py={10}>
      <Button
        variant="outlined"
        size="medium"
        onClick={() =>
          navigate("/browse?type=professionals", { replace: true })
        }
        data-cy-btn="cancel"
      >
        Cancel
      </Button>
      {currentStep > 1 && (
        <Button
          variant="outlined"
          size="medium"
          onClick={onSaveDraft}
          // disabled={isDisabled}
          data-cy-btn="save-draft-and-exit"
        >
          Save Draft & Exit
        </Button>
      )}
      {currentStep == submitStep ? (
        <Button
          size="medium"
          variant="contained"
          onClick={onSubmit}
          disabled={isDisabled}
          data-cy-btn="post-job-request"
        >
          Post Job Request
        </Button>
      ) : (
        <Button
          size="medium"
          variant="contained"
          onClick={onNextHandler}
          disabled={isDisabled}
          data-cy-btn="next-step"
        >
          Next Step
        </Button>
      )}
    </Box>
  );
};
