import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Formik } from "formik";
import React, { useCallback } from "react";
import * as Yup from "yup";
import { JobRequestsSearchFilters } from "../../../../api-types/job-requests/common";
import { BLUE, COLOR, YELLOW } from "../../../../themes/components/utils";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { defaultJobRequestSearchFilters } from "../hooks/useSearchJobRequests";
import {
  useJobPostSearchFilters,
  useSearchFilters,
  useSelectedFilterTypes,
} from "../state/hooks";
import { SelectBids } from "./SelectBids";
import { SelectBudget } from "./SelectBudget";
import { SelectOption } from "./SelectOption";

const useStyles = makeStyles(({ palette }) => ({
  buttonClear: {
    border: "none",
    borderRadius: 10,
    alignSelf: "center",
    backgroundColor: BLUE,
    color: "white",
    "&:hover": {
      backgroundColor: BLUE,
    },
  },
  feeButton: {
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  filtersContainer: {
    width: 900,
    ["@media screen and (max-width: 1340px)"]: {
      width: 800,
    },
    ["@media screen and (max-width: 1280px)"]: {
      width: 700,
    },
    ["@media screen and (max-width: 1200px)"]: {
      width: 600,
    },
    ["@media screen and (max-width: 1140px)"]: {
      width: 400,
    },
    ["@media screen and (max-width: 800px)"]: {
      width: 350,
    },
    ["@media screen and (max-width: 600px)"]: {
      width: 300,
    },
  },
  searchInput: {
    "& .MuiInputBase-root": {
      height: 68,
    },
    width: 900,
    "& ::placeholder": {
      color: BLUE,
    },
    ["@media screen and (max-width: 1340px)"]: {
      width: 800,
    },
    ["@media screen and (max-width: 1280px)"]: {
      width: 700,
    },
    ["@media screen and (max-width: 1200px)"]: {
      width: 600,
    },
    ["@media screen and (max-width: 1140px)"]: {
      width: 400,
    },
    ["@media screen and (max-width: 800px)"]: {
      width: 350,
    },
    ["@media screen and (max-width: 600px)"]: {
      width: 300,
      "& .MuiInputBase-root": {
        height: 44,
      },
    },
  },
  dateInput: {
    width: "115px",
    ["@media screen and (max-width: 1300px)"]: {
      width: "90px",
    },
  },
  budgetInput: {
    width: "135px",
    ["@media screen and (max-width: 1300px)"]: {
      width: "100px",
    },
  },
  bidsInput: {
    width: "175px",
    ["@media screen and (max-width: 1300px)"]: {
      width: "140px",
    },
  },
  feeTextField: {
    height: 28,
    width: 52,

    "& .MuiOutlinedInput-input": {
      fontSize: 16,
      paddingLeft: 8,
      paddingRight: 0,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: palette.secondary.dark,
    },
  },
  checkboxDisabled: {
    pointerEvents: "none",
    color: YELLOW,
    opacity: 0.5,
  },
  largeCheckbox: {
    color: YELLOW,
    "& .MuiSvgIcon-root": {
      height: 32,
      width: 32,
      ["@media screen and (max-width: 1020px)"]: {
        height: 24,
        width: 24,
      },
      ["@media screen and (max-width: 600px)"]: {
        height: 16,
        width: 16,
      },
    },
  },
  disabledLabel: {
    opacity: 1,
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: "#fff !important",
      opacity: 1,
    },
  },
}));

type SearchFormProps = {
  onOpenJobFiltersDialog: () => void;
};

export const SearchForm = ({ onOpenJobFiltersDialog }: SearchFormProps) => {
  const styles = useStyles();
  const { isFreelanceProjectsSelected, isJobPostsSelected } =
    useScopedDowngradedStateValue(useSelectedFilterTypes());

  const { isSmall, isMedium } = useResponsiveness();
  const setIsFreelanceProjectsSelected =
    useSelectedFilterTypes().isFreelanceProjectsSelected.set;

  const setIsJobPostsSelected = useSelectedFilterTypes().isJobPostsSelected.set;
  const filters = useScopedDowngradedStateValue(useSearchFilters());
  const setFilters = useSearchFilters().set;
  const setJobPostSearchFilters = useJobPostSearchFilters().set;

  const validationSchema = Yup.object({});

  const onSaveHandler = useCallback(
    (selected) => {
      let min = 0;
      let max = 9999;

      if (selected.includes("< 10")) {
        max = 10;
      } else if (selected.includes("10 - 20")) {
        min = 10;
        max = 20;
      } else if (selected.includes("20+")) {
        min = 20;
      }

      setFilters({
        ...filters,
        bids: { min, max },
      });
    },
    [filters, setFilters]
  );

  return (
    <Box display="flex" columnGap={12} className={styles.filtersContainer}>
      <Formik
        initialValues={defaultJobRequestSearchFilters}
        onSubmit={(values: JobRequestsSearchFilters) => {
          setFilters(values);
          setJobPostSearchFilters((prev) => ({
            ...prev,
            searchKeywords: [values.search],
          }));
          window.sessionStorage.setItem("jobs-search--currentPage", "1");
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, handleChange, handleBlur, errors, values }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
            }}
          >
            <Box display="flex" flexDirection="column">
              <Box className={styles.searchInput}>
                <TextField
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.search}
                  name="search"
                  placeholder={"Search"}
                  InputProps={{
                    endAdornment: (
                      <Button
                        className={styles.buttonClear}
                        color="secondary"
                        type="submit"
                        variant="contained"
                      >
                        <SearchIcon style={{ color: COLOR.WHITE }} />
                      </Button>
                    ),
                  }}
                  data-cy="search-input"
                />
              </Box>
              <Box
                height={140}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    {isFreelanceProjectsSelected && (
                      <>
                        {!isSmall && (
                          <Typography
                            variant={"subtitle2"}
                            style={{ fontWeight: 600 }}
                          >
                            Freelance Projects:
                          </Typography>
                        )}
                        <SelectBudget
                          label="Budget"
                          min={0}
                          max={0}
                          onSave={(values) => {
                            setFilters({
                              ...filters,
                              budget: values,
                            });
                          }}
                        />
                        <SelectBids
                          label="No of Bids"
                          options={["< 10", "10 - 20", "20+", "All"]}
                          defaultSelected={"< 10"}
                          onSave={onSaveHandler}
                        />
                      </>
                    )}

                    {!isSmall && isJobPostsSelected && (
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="flex-end"
                        flexGrow={1}
                        py={3}
                      >
                        <Typography
                          variant={"subtitle2"}
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          Job Posts:
                        </Typography>

                        <Button
                          onClick={onOpenJobFiltersDialog}
                          size="medium"
                          variant="text"
                          data-cy="filter-job-posts"
                        >
                          Add/Edit Filters
                        </Button>
                      </Box>
                    )}
                  </Box>

                  <Divider />
                </Box>

                <Box>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexWrap="wrap"
                    columnGap={isSmall ? 1 : 7}
                    py={isSmall ? 1 : 3}
                  >
                    {!isSmall && (
                      <Typography
                        variant={"subtitle2"}
                        style={{ fontWeight: 600 }}
                      >
                        View:
                      </Typography>
                    )}

                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: isMedium ? 14 : 18,
                        },
                      }}
                      className={styles.disabledLabel}
                      style={{
                        pointerEvents: !isJobPostsSelected ? "none" : "all",
                      }}
                      control={
                        <Checkbox
                          checked={isFreelanceProjectsSelected}
                          className={`${styles.largeCheckbox} ${
                            !isJobPostsSelected ? styles.checkboxDisabled : ""
                          }`}
                          data-cy="freelance-projects"
                          name="freelanceProjects"
                          onChange={() => {
                            setIsFreelanceProjectsSelected((prev) => !prev);
                            window.sessionStorage.setItem(
                              "jobs-search--currentPage",
                              "1"
                            );
                          }}
                          size="medium"
                        />
                      }
                      label="Freelance Projects"
                      name="freelanceProjects"
                    />
                    <Box>
                      <FormControlLabel
                        className={styles.disabledLabel}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: isMedium ? 14 : 18,
                          },
                        }}
                        style={{
                          pointerEvents: !isFreelanceProjectsSelected
                            ? "none"
                            : "all",
                        }}
                        control={
                          <Checkbox
                            checked={isJobPostsSelected}
                            className={`${styles.largeCheckbox} ${
                              !isFreelanceProjectsSelected
                                ? styles.checkboxDisabled
                                : ""
                            }`}
                            data-cy="job-posts"
                            name="jobPosts"
                            onChange={() => {
                              setIsJobPostsSelected((prev) => !prev);
                              window.sessionStorage.setItem(
                                "jobs-search--currentPage",
                                "1"
                              );
                            }}
                            size="medium"
                          />
                        }
                        label="Jobs"
                        name="jobPosts"
                      />
                    </Box>
                    <Box ml={isSmall ? 8 : "auto"}>
                      <SelectOption
                        dataCy="select-date"
                        label="Date"
                        defaultSelected={filters.date}
                        options={[
                          "All",
                          "Last Month",
                          "Past 3 Months",
                          "Past 6 Months",
                        ]}
                        onSave={(selectedOption) => {
                          setFilters((prev) => ({
                            ...prev,
                            date: selectedOption,
                          }));
                          setJobPostSearchFilters((prev) => ({
                            ...prev,
                            date: selectedOption,
                          }));
                        }}
                      />
                    </Box>
                    <Box ml={isSmall ? 8 : "auto"}>
                      <SelectOption
                        label="Order"
                        defaultSelected={filters.orderBy}
                        options={["Most Recent", "Oldest First"]}
                        onSave={(selectedOption) => {
                          setFilters((prev) => ({
                            ...prev,
                            orderBy: selectedOption,
                          }));
                        }}
                      />
                    </Box>
                  </Box>

                  <Divider />
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
