import { Box, Button } from "@mui/material";
import FileSaver from "file-saver";
import { useCallback, useMemo, useState } from "react";
import { getAWSFiles } from "../../../api-services/upload-files/uploadFiles";
import { ConfirmationDialog, LoadingTypography } from "../../common/components";
import { useJobPosts } from "../../common/hooks/job-posts";
import { useResponsiveness } from "../../common/hooks/utils/useResponsiveness";
import { getZipFiles } from "../../utils";

export const JobPostsListSummary = () => {
  const [openRemoveAllDialog, setOpenRemoveAllDialog] = useState(false);
  const { data: jobPosts, isLoading } = useJobPosts();
  const { isSmall } = useResponsiveness();

  const applicationsCount = useMemo(
    () =>
      jobPosts?.reduce((acc, jobPost) => acc + jobPost.numberOfApplicants, 0) ??
      0,
    [jobPosts]
  );

  const resumesCount = useMemo(
    () =>
      jobPosts?.reduce((acc, jobPost) => acc + jobPost.resumes.length, 0) ?? 0,
    [jobPosts]
  );

  const onDownloadAllResumes = useCallback(async () => {
    if (!jobPosts?.length) return;

    const keys = jobPosts.flatMap((jobPost) =>
      jobPost.resumes.map((url) => url.match(/resumes\/.*$/)?.[0] ?? "")
    );
    const files = await getAWSFiles(keys);
    const mappedFiles = files.map(({ key, buffer }) => ({
      key: key.replace("resumes/", ""),
      buffer,
    }));

    const zippedFiles = await getZipFiles(mappedFiles);

    FileSaver.saveAs(
      zippedFiles,
      `resumes_${new Date(Date.now()).toISOString()}.zip`
    );
  }, [jobPosts]);

  const onRemoveAll = () => {
    // @todo implement mutation for removing all closed or expired job posts
    setOpenRemoveAllDialog(false);
  };

  return (
    <Box alignItems="center" display="flex" flexDirection="row">
      <Box width="45%">
        {isSmall ? (
          <LoadingTypography
            isLoading={isLoading}
            typographyProps={{
              variant: "subtitle2",
            }}
          >
            {jobPosts?.length ?? 0} Jobs Posted <br /> {applicationsCount}{" "}
            Application(s)
          </LoadingTypography>
        ) : (
          <LoadingTypography
            isLoading={isLoading}
            typographyProps={{
              variant: "subtitle2",
            }}
            style={{
              fontWeight: 600,
            }}
          >
            {jobPosts?.length ?? 0} Jobs Posted | {applicationsCount}{" "}
            Application(s)
          </LoadingTypography>
        )}
      </Box>
      <Box
        display="flex"
        alignItems="flex-end"
        flexDirection={isSmall ? "column" : "row"}
        ml="auto"
        columnGap={3}
      >
        {!isSmall && (
          <Button
            disabled // @todo add actual condition
            onClick={() => setOpenRemoveAllDialog(true)}
            size={isSmall ? "small" : "medium"}
            variant="text"
          >
            Remove closed and expired jobs
          </Button>
        )}
        <Button
          color="primary"
          disabled={resumesCount === 0}
          onClick={onDownloadAllResumes}
          size={isSmall ? "small" : "medium"}
          variant="contained"
        >
          Download All Resumes
        </Button>
      </Box>

      <ConfirmationDialog
        confirmLabel="Remove All"
        onClose={() => setOpenRemoveAllDialog(false)}
        onConfirm={onRemoveAll}
        open={openRemoveAllDialog}
        title="Are you sure you want to remove all closed or expired job posts?"
      />
    </Box>
  );
};
