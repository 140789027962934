import { Box, Divider } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageWrapper } from "../../../../../../app/container/PageWrapper";
import { Section } from "../../../../../../common/components";
import { BackButton } from "../../../../../../common/elements/BackButton";
import { CollectionNavigationActions } from "../../../../../../common/elements/CollectionNavigationActions";
import { useNavigationHandlers } from "../../../../../../common/hooks/navigation";
import { useProfessional } from "../../../../../../common/hooks/professionals/professional/useProfessional";
import { useProjectQuery } from "../../../../../../common/hooks/projects/project/useProjectQuery";
import { useProjectsQuery } from "../../../../../../common/hooks/projects/useProjectsQuery";
import {
  ProfessionalAccountSummaryDescription,
  ProfessionalAccountSummaryInfo,
} from "../../../account-summary";
import { Details } from "./details";
import { CurrentProjectHeader } from "./header";

type ProjectDetailsProps = {};

export const ProjectDetails: React.FC<ProjectDetailsProps> = () => {
  const { projectId, professionalId } = useParams();

  const { isLoading: isLoadingProject } = useProjectQuery();

  const { data: projects, isLoading: isLoadingProjects } =
    useProjectsQuery(professionalId);

  const isLoading = isLoadingProject || isLoadingProjects;

  const collectionConfig = {
    type: `professionals/${professionalId}/projects/`,
    items: projects,
  };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(collectionConfig, isLoading, projectId);

  const { data: professional } = useProfessional(professionalId);

  const navigate = useNavigate();

  const onBackHandler = useCallback(() => {
    navigate(`/professionals/${professionalId}`);
  }, [navigate, professionalId]);

  return (
    <PageWrapper>
      <Box display="flex" columnGap={12} height={300} width="100%">
        <ProfessionalAccountSummaryInfo
          profileInformation={professional?.profileInformation}
        />
        <ProfessionalAccountSummaryDescription
          accountInformation={professional?.accountInformation}
          profileInformation={professional?.profileInformation}
        />
      </Box>
      <Divider />
      <Section isLoading={isLoadingProject}>
        <Box pb={10} pt={10}>
          <BackButton onClick={onBackHandler} backLabel="Back To Profile" />
          <CurrentProjectHeader />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box flexGrow={1}>
            <Details />
          </Box>
          <CollectionNavigationActions
            label={"Project"}
            index={index}
            totalCount={projects?.length ?? 0}
            handleSelectNext={handleSelectNext}
            handleSelectPrevious={handleSelectPrevious}
          />
        </Box>
      </Section>
    </PageWrapper>
  );
};
