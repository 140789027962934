import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../app/container/PageWrapper";
import { useResponsiveness } from "../hooks/utils/useResponsiveness";
import { BrowseMainbar } from "./browse-mainbar";
import { BrowseContent } from "./BrowseContent";

export const Browse: React.FC = () => {
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const { isSmall, isMedium } = useResponsiveness();

  useEffect(() => {
    const pageNo = searchParams.get("page") ?? 1;
    setPage(Number(pageNo));
  }, [searchParams]);

  const onChangeCategoryHandler = useCallback(() => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <Box height="100%">
      <PageWrapper>
        <Box height={isMedium ? 360 : 220}>
          <Box py={10}>
            <Typography
              variant={"h1"}
              sx={{
                fontWeight: "bold",
              }}
            >
              Professionals and Teams
            </Typography>
            <BrowseMainbar onChangeCategory={onChangeCategoryHandler} />
          </Box>
        </Box>
        <Box flexGrow={1} pt={isSmall ? 20 : 15} height="calc(100% - 220px)">
          <BrowseContent page={page} setPage={setPage} />
        </Box>
      </PageWrapper>
    </Box>
  );
};
