import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../common/elements/BackButton";

type SavedMainbarProps = {};

export const SavedMainbar: React.FC<SavedMainbarProps> = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box pt={10}>
        <BackButton
          onClick={() => {
            navigate("/job-requests/search");
          }}
          backLabel="Back to Search"
        />
      </Box>
      <Box pl={1} py={10}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: "bold",
          }}
        >
          Saved Job Request(s)
        </Typography>
      </Box>
    </Box>
  );
};
