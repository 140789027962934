import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Field, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Professional } from "../../../../api-types/professionals/professional";
import { BLUE } from "../../../../themes/components/utils";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { LoadingTypography } from "../../../common/components";
import { ProfessionalSummary } from "../../../common/components/ProfessionalSummary";
import { CustomTextArea } from "../../../common/elements";
import { BackButton } from "../../../common/elements/BackButton";
import { useProfessional } from "../../../common/hooks/professionals/professional/useProfessional";
import {
  useReferProfessionalMutation,
  useSentCollaborationProposals,
} from "../../../common/hooks/projects/project/collaborations";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { useProjectsForCollaborating } from "../../../common/hooks/projects/useProjectsForCollaborating";
import { useProjectsQuery } from "../../../common/hooks/projects/useProjectsQuery";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useSearchProfessionals } from "../../../common/professionals-search/hooks";
import { ProfilePicture } from "../../../common/summary-profile";
import { useUser } from "../../../state/hooks";

const useStyles = makeStyles({
  autocomplete: {
    "& .MuiInputBase-root": {
      height: 70,
    },
    "& .MuiAutocomplete-popupIndicator": {
      marginRight: 0,
    },
    "& .MuiSvgIcon-root": {
      color: BLUE,
    },
  },
});

export const ProfessionalReferralInvitation: React.FC = () => {
  const navigate = useNavigate();
  const styles = useStyles();
  const { isExtraSmall, isSmall, isMedium } = useResponsiveness();

  const inputProjectWidth = useMemo(() => {
    if (isSmall) {
      return 330;
    }

    if (isMedium) {
      return 500;
    }

    return 800;
  }, [isSmall, isMedium]);

  const [searchParams] = useSearchParams();

  const [selectedProfessionalId, setSelectedProfessionalId] = useState("");
  const { data: projects, isLoading: isLoadingProjects } = useProjectsQuery();

  const projectId = searchParams.get("projectId") ?? "";

  const { professionalId } = useParams();
  const projectsForCollaborating = useProjectsForCollaborating(
    professionalId ?? selectedProfessionalId
  );

  const { data: professional } = useProfessional(professionalId);

  const { data: collaborationsSent } = useSentCollaborationProposals();
  const { data: project } = useProjectQuery(projectId);

  const { data: professionals, isLoading: isLoadingProfessionals } =
    useSearchProfessionals();

  const filteredProfessionals = useMemo(() => {
    const currentCollaboratorIds =
      project?.professionals.map(({ professional }) => professional._id) ?? [];

    const collaborationProfessionalIds =
      collaborationsSent?.map(({ professional }) => professional._id) ?? [];

    return professionals?.filter(
      ({ _id }) =>
        !currentCollaboratorIds.includes(_id) &&
        !collaborationProfessionalIds.includes(_id)
    );
  }, [collaborationsSent, professionals, project?.professionals]);

  const { mutate: onReferProfessional } = useReferProfessionalMutation();

  const { id: currentUserId } = useScopedDowngradedStateValue(useUser());

  const initialValues = {
    projectId,
    professionalId,

    introductoryMessage: "",
  };

  const onBackHandler = useCallback(() => {
    // eslint-disable-next-line no-undef
    window.history.back();
  }, []);

  const onCancelHandler = useCallback(() => {
    navigate("/browse/?type=professionals");
  }, [navigate]);

  const validationSchema = Yup.object({
    professionalId: Yup.string().required(),
    projectId: Yup.string().required(),
  });

  const onReferHandler = useCallback(
    (
      projectId: string,
      professionalId: string,
      introductoryMessage: string
    ) => {
      onReferProfessional({
        projectId,
        professionalId,
        introductoryMessage,
      });
    },
    [onReferProfessional]
  );

  const projectTitle = useMemo(() => {
    if (!projectId) return "";

    const match = projects?.find((project) => project._id === projectId);

    return match?.title ?? "";
  }, [projectId, projects]);

  return (
    <PageWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onReferHandler(
            values.projectId ?? projectId,
            values.professionalId,
            values.introductoryMessage
          );
        }}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <Box display="flex" flexDirection="column" height="100%" pt={10}>
              <Box>
                <Box pb={10}>
                  <Typography
                    variant="h1"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Invitation To Collaborate on a Project
                  </Typography>
                </Box>
                <Typography variant="subtitle2">
                  Bring together your complementing skills to collaborate on a
                  project and get more projects done.
                </Typography>
              </Box>

              <Box py={2.5}>
                <Divider />
              </Box>

              <BackButton onClick={onBackHandler} backLabel="Back" />

              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box pt={10}>
                  <Box display="flex" flexDirection="column" rowGap={5}>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        You are inviting
                      </Typography>
                      {!professionalId ? (
                        <Box width={inputProjectWidth} pt={5}>
                          <Autocomplete
                            popupIcon={<ExpandMoreIcon />}
                            className={styles.autocomplete}
                            options={(filteredProfessionals ?? []).filter(
                              (user) => user._id !== currentUserId
                            )}
                            loading={isLoadingProfessionals}
                            onChange={(e, value: Professional) => {
                              setFieldValue("professionalId", value?._id || "");
                              setSelectedProfessionalId(value?._id || "");
                            }}
                            getOptionLabel={(option: any) =>
                              `${option?.accountInformation?.firstName} ${option?.accountInformation?.lastName}`
                            }
                            renderOption={(props, option: any) => {
                              return (
                                <li {...props}>
                                  <Box
                                    height={64}
                                    display="flex"
                                    justifyContent="left"
                                    pl={2}
                                    alignItems="center"
                                    columnGap={5}
                                  >
                                    <ProfilePicture
                                      width={48}
                                      profileInformation={
                                        option?.profileInformation
                                      }
                                    />
                                    {option?.accountInformation?.firstName},{" "}
                                    {option?.accountInformation?.lastName}{" "}
                                    {option?.profileInformation?.position
                                      ? "-"
                                      : ""}{" "}
                                    {option?.profileInformation?.position}
                                  </Box>{" "}
                                </li>
                              );
                            }}
                            onOpen={handleBlur}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="professionalId"
                                fullWidth
                                placeholder={
                                  isLoadingProfessionals
                                    ? "Loading professionals"
                                    : "Search Professional’s Name"
                                }
                                variant="outlined"
                                value={values.professionalId}
                              />
                            )}
                          />
                        </Box>
                      ) : (
                        <ProfessionalSummary professional={professional} />
                      )}
                    </Box>
                    {projectTitle ? (
                      <Box width={inputProjectWidth}>
                        <Box pb={2.5}>
                          <Typography
                            variant="subtitle1"
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            Project
                          </Typography>
                        </Box>
                        <LoadingTypography
                          isLoading={isLoadingProjects}
                          typographyProps={{
                            color: "primary",
                            variant: "subtitle2",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            {projectTitle}
                          </span>
                          <input
                            name="projectId"
                            hidden
                            value={values.projectId}
                          />
                        </LoadingTypography>
                      </Box>
                    ) : (
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Select the project you want to collaborate on
                        </Typography>
                        <Box width={inputProjectWidth} pt={5}>
                          <Autocomplete
                            popupIcon={<ExpandMoreIcon />}
                            className={styles.autocomplete}
                            options={projectsForCollaborating}
                            onChange={(e, value) =>
                              setFieldValue("projectId", value?._id || "")
                            }
                            getOptionLabel={(option) => `${option?.title}`}
                            loading={isLoadingProjects}
                            onOpen={handleBlur}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="projectId"
                                fullWidth
                                placeholder={
                                  isLoadingProjects
                                    ? "Loading Projects"
                                    : "Search Project"
                                }
                                variant="outlined"
                                value={values.projectId}
                              />
                            )}
                            value={projectsForCollaborating?.find(
                              ({ _id }) => _id === values.projectId
                            )}
                          />
                        </Box>
                      </Box>
                    )}
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Write a custom invitation message
                      </Typography>
                      <Box pt={10} width={inputProjectWidth}>
                        <Field
                          value={values.introductoryMessage}
                          component={CustomTextArea}
                          placeholder="Invitation message"
                          name="introductoryMessage"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          minRows={5}
                          maxRows={5}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                justifyContent="flex-end"
                alignItems="flex-end"
                flexGrow={1}
                display="flex"
                columnGap={2.5}
                py={10}
              >
                <Button
                  size="medium"
                  variant="outlined"
                  onClick={onCancelHandler}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  variant="contained"
                  type="submit"
                  disabled={
                    (!values.projectId?.length && !projectId?.length) ||
                    !values.professionalId?.length
                  }
                >
                  Send
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </PageWrapper>
  );
};
