import { Box } from "@mui/material";
import { chain } from "lodash";
import React, { ReactNode, useCallback } from "react";
import { DropzoneProps, useDropzone } from "react-dropzone";
import { uploadFiles } from "../../../../api-services/upload-files/uploadFiles";
import { IMAGE_MAX_SIZE_MB } from "../../../../api-services/utils";
import { S3Data } from "../../../../api-types/s3";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { isImageSizeValid } from "../../../utils";
import { useToast } from "../../../utils/useToast";

export type DropAreaProps = {
  children?: ReactNode;
  encodedFiles: any[];
  setEncodedFiles: (files: any[]) => void;
  drop?: DropzoneProps;
  setCurrentIndex?: (index: number) => void;
  maxFiles?: number;
};

export const DropArea = React.forwardRef(
  ({
    children,
    drop,
    encodedFiles,
    setEncodedFiles,
    setCurrentIndex,
    maxFiles = 0,
  }: DropAreaProps) => {
    const { notifyError } = useToast();

    const onDrop = useCallback(
      async (acceptedFiles) => {
        if (maxFiles > 0 && encodedFiles.length >= maxFiles) {
          return;
        }

        const sliceEnd = maxFiles > 0 ? maxFiles : undefined;
        const previewFiles = chain(encodedFiles)
          .concat(
            acceptedFiles.map((file: any) => ({
              ...file,
              loading: true,
              key: file.path,
            }))
          )
          .slice(0, sliceEnd)
          .value();
        setEncodedFiles(previewFiles);

        let fileUrls: S3Data[] = [];
        try {
          const uploadSliceEnd =
            maxFiles > 0 ? maxFiles - encodedFiles.length : undefined;
          const [files, tooBigFiles] = acceptedFiles
            .slice(0, uploadSliceEnd)
            .reduce(
              ([files1, files2]: [any, any], elem: any) =>
                isImageSizeValid(elem.size)
                  ? [[...files1, elem], files2]
                  : [files1, [...files2, elem]],
              [[], []]
            );

          if (tooBigFiles.length) {
            notifyError(
              `${tooBigFiles
                .map((file: any) => file.path)
                .join(
                  ", "
                )} are too big. Please upload files smaller than ${IMAGE_MAX_SIZE_MB}mb`
            );
          }

          fileUrls = (await uploadFiles(files, "images")).map((file) => ({
            key: file.Key,
            bucket: file.Bucket,
            location: file.Location,
          }));
        } catch (err) {
          notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
        }

        setCurrentIndex?.(fileUrls?.length + encodedFiles.length - 1);
        setEncodedFiles([
          ...encodedFiles.filter((file) => !file.loading),
          ...fileUrls,
        ]);
      },
      [encodedFiles, maxFiles, notifyError, setCurrentIndex, setEncodedFiles]
    );

    const { getRootProps, getInputProps } = useDropzone({
      accept: "image/jpeg, image/png, image/gif",
      onDrop,
      disabled: encodedFiles.length >= maxFiles,
      ...drop,
    });

    return (
      <Box className="container">
        <Box
          {...getRootProps({ className: "dropzone" })}
          style={{ cursor: "pointer" }}
        >
          <input {...getInputProps()} data-cy="drop-area" />
          {children}
        </Box>
      </Box>
    );
  }
);
