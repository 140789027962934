import { Box, Skeleton } from "@mui/material";
import React from "react";

export const PastProjectsSkeleton: React.FC = () => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" pt={8} width="100%">
        <Box width="60%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="40%"
            height={30}
          />
          <Box mt={8}>
            <Skeleton variant="rectangular" animation="wave" height={40} />
          </Box>
          <Box display="flex" mt={12}>
            <Box width="20%">
              <Skeleton variant="rectangular" animation="wave" height={80} />
            </Box>
            <Box ml={8} width="20%">
              <Skeleton variant="rectangular" animation="wave" height={80} />
            </Box>
          </Box>
        </Box>

        <Box mt={4} width="30%" justifyContent="space-between">
          <Box mb={2}>
            <Skeleton variant="rectangular" animation="wave" height={45} />
          </Box>
          <Box mb={4}>
            <Skeleton variant="rectangular" animation="wave" height={35} />
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" mt={16}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="40%"
          height={35}
        />

        <Skeleton
          variant="rectangular"
          animation="wave"
          width="40%"
          height={25}
        />
      </Box>

      <Box mt={8} columnGap={2.5}>
        <Box mb={4}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="30%"
            height={30}
          />
        </Box>
        <Box pt={2.5} display="flex" justifyContent="space-between">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="30%"
            height={25}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="40%"
            height={25}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="27%"
            height={25}
          />
        </Box>
        <Box pt={2.5} display="flex" justifyContent="space-between">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="40%"
            height={25}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="30%"
            height={25}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="27%"
            height={25}
          />
        </Box>
      </Box>

      <Box pt={10} width="40%">
        <Box display="flex" columnGap={2.5} alignItems="center">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="17.5%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="17.5%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="17.5%"
            height={40}
          />

          <Box display="flex" columnGap={1} alignItems="center">
            <Skeleton
              variant="circular"
              animation="wave"
              width={16}
              height={16}
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width={16}
              height={16}
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width={16}
              height={16}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
