import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";

export const useResponsiveness = () => {
  const isExtraSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  ); //600 px

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm")); //600 px

  const isMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  ); //960 px

  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg")); //1300 px

  const isExtraLarge = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl")
  ); //1600 px

  return { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge };
};
