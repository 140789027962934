import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Fade,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/system";
import React, { useState } from "react";

const useStyles = makeStyles(({ palette }) => ({
  feeButton: {
    paddingLeft: 0,
    paddingRight: 0,
    "&:hover": {
      backgroundColor: "inherit",
    },
    "& .MuiButton-endIcon": {
      marginLeft: 0,
    },
  },
  feeTextField: {
    height: 28,
    width: 52,

    "& .MuiOutlinedInput-input": {
      fontSize: 16,
      paddingLeft: 8,
      paddingRight: 0,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: palette.secondary.dark,
    },
  },
}));

interface SelectOptionProps {
  label?: string;
  options?: string[];
  onSave: (option: any) => any;
  defaultSelected: string;
  dataCy?: string;
}

export const SelectOption: React.FC<SelectOptionProps> = ({
  label,
  onSave,
  options,
  defaultSelected,
  dataCy,
}) => {
  const styles = useStyles();
  // eslint-disable-next-line no-undef
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const popoverOpen = Boolean(anchorEl);

  const onClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex">
      <Button
        className={styles.feeButton}
        endIcon={popoverOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
        variant="text"
        disableRipple
        data-cy-btn={dataCy}
      >
        <Box display="flex" alignItems="center">
          <Typography variant={isMd ? "body2" : "subtitle2"}>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {label}:{" "}
            </span>
          </Typography>
          <Typography
            style={{
              color: "white",
            }}
            variant={isMd ? "body2" : "subtitle2"}
            px={2}
          >
            {defaultSelected}
          </Typography>
        </Box>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={popoverOpen}
        TransitionComponent={Fade}
        // getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={onClosePopover}
      >
        {options?.map((option) => (
          <MenuItem
            key={option}
            selected={defaultSelected === option}
            value={option}
            onClick={() => {
              onSave(option);
              onClosePopover();
            }}
            data-cy={`menu-item-${option.toLowerCase()}`}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
