import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { useSearchFilters } from "../state/hooks";

type CategoriesStackProps = {
  onChangeCategory?: () => void;
};

const serviceCategories = [
  { label: "All", value: "" },
  {
    label: "Design & Creative",
    value: "designing",
  },
  {
    label: "Marketing & Strategy",
    value: "marketing",
  },
  {
    label: "Web, Mobile & Software Development",
    value: "development",
  },
  {
    label: "Writing & Content Creation",
    value: "writing",
  },
];

export const CategoriesStack: React.FC<CategoriesStackProps> = ({
  onChangeCategory,
}) => {
  const setFilters = useSearchFilters().set;
  const filters = useScopedDowngradedStateValue(useSearchFilters());

  return (
    <Box alignItems="center" display="flex" columnGap={2.5} flexWrap="wrap">
      <Typography variant="body2" style={{ fontSize: 14 }}>
        Categories:
      </Typography>
      {serviceCategories.map(({ label, value }, index) => (
        <Button
          key={index}
          size="small"
          variant={filters.serviceCategory === value ? "contained" : "text"}
          onClick={() => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              serviceCategory: value,
            }));
            onChangeCategory?.();
          }}
          style={{
            fontSize: 14,
            paddingLeft: 12,
            paddingRight: 12,
            minWidth: 0,
            height: 24,
          }}
          data-cy-btn={value}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
};
