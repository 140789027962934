import { Box, Divider, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { File } from "../../../../api-types/job-requests/common";
import { Professional } from "../../../../api-types/professionals/professional";
import { Files } from "../../../common/components/files";
import { Messages } from "../../../common/components/messages";
import { ProfessionalSummary } from "../../../common/components/ProfessionalSummary";
import { useProfessionalsInteractions } from "../../../common/hooks/projects/project/collaborations";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../../../state/hooks";
import {
  useAddInteractionFileMutation,
  useAddInteractionMessageMutation,
} from "../hooks";

type ProfessionalToProfessionalCommunicationProp = {
  professional?: Professional;
};

export const ProfessionalToProfessionalCommunication: React.FC<
  ProfessionalToProfessionalCommunicationProp
> = ({ professional }) => {
  const { _id: professionalId } = professional ?? {};
  const { data, isLoading } = useProfessionalsInteractions();

  const { id } = useScopedDowngradedStateValue(useUser());

  const { mutate: addInteractionMessage, isLoading: isAddingMessage } =
    useAddInteractionMessageMutation();

  const { mutate: addInteractionFile, isLoading: isAddingFile } =
    useAddInteractionFileMutation();

  const onNewMessageHandler = useCallback(
    (newMessage: string) => {
      professionalId &&
        addInteractionMessage({
          messages: [
            {
              content: newMessage,
              from: id ?? "",
              to: professionalId,
            },
          ],
          professionalId,
        });
    },
    [addInteractionMessage, id, professionalId]
  );

  const onNewFileHandler = useCallback(
    (files: File[]) => {
      professionalId &&
        addInteractionFile({
          files,
          professionalId,
        });
    },
    [addInteractionFile, professionalId]
  );

  return (
    <Box>
      <Box mb={8}>
        <Box display="flex" alignItems="center" columnGap={12}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
            }}
          >
            You are Viewing Communication With
          </Typography>
          <Typography variant="body2">
            Select from the Professionals you are working with in the Files and
            Messages dropdown above
          </Typography>
        </Box>
        <Divider />
        <ProfessionalSummary professional={professional} width={60} />
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box width="50%" borderRight="1px solid white">
          <Files
            isLoading={isLoading || isAddingFile}
            files={data?.files ?? []}
            onNewFile={(newFile: any[]) => {
              onNewFileHandler(newFile);
            }}
          />
        </Box>
        <Box width="50%" borderLeft="1px solid white">
          <Messages
            isAddingMessage={isAddingMessage}
            isLoading={isLoading || isAddingMessage}
            messages={data?.messages ?? []}
            onNewMessage={(newMessage: string) => {
              onNewMessageHandler(newMessage);
            }}
          />
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};
