import { Box, useMediaQuery } from "@mui/material";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useMobileStyles,
} from "../../../../../../themes/style-hooks";
import { AccountTypeSwitch } from "../../../client/parallax/AccountTypeSwitch";
import { MainActions } from "../../../MainActions";

export const LandingStepEContent = () => {
  const mobileView = useMediaQuery("(max-width: 800px)");
  const { mobileContentWrapper, mobileDescriptionWrapper } = useMobileStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: COLOR.DARK_BROWN,
    color: COLOR.WHITE,
    hover: COLOR.DARK_BROWN,
  });
  const descriptionText = useDescriptionText({
    color: COLOR.DARK_BROWN,
  });

  return (
    <Box className={mobileContentWrapper}>
      <Box className={mobileDescriptionWrapper}>
        <Box textAlign="left">
          <Box className={descriptionText.root}>
            Professionals partner with one another to get more projects done for
            our clients, and collaborate to bring their work to another level.
          </Box>
        </Box>
        <Box textAlign="left" pt={12}>
          <Box className={descriptionText.root}>
            We encourage you to do that.
          </Box>
        </Box>
      </Box>
      <MainActions classNames={`${common} ${root}`} />
      {!mobileView && <AccountTypeSwitch defaultChecked="professional" />}
    </Box>
  );
};
