import { Box, FormControlLabel, RadioGroup, Typography } from "@mui/material";
import { Field, FormikValues } from "formik";
import React from "react";
import { Radio } from "../../../../common/components";
import { BackButton } from "../../../../common/elements/BackButton";
import { TextBox } from "../../utils/TextBox";

type VisibilityProps = {
  onBack?: () => void;
  values: FormikValues;
};

export const initialVisibilityValues = {
  visibility: "private",
};

export const Visibility: React.FC<VisibilityProps> = ({ onBack, values }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="column"
      pt={10}
    >
      <BackButton onClick={() => onBack?.()} />
      <Box display="flex" justifyContent="space-between" columnGap={5} pt={10}>
        <Box display="flex" flexDirection="column" rowGap={5}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
            }}
          >
            Rate and Visibility
          </Typography>
          <Typography variant="subtitle2">
            Select the professional service(s)
            <br />
            you’re looking for.
          </Typography>
        </Box>
        <Box width="58%" color="white">
          <TextBox
            text="Select the Visibility of Your Post"
            variant="subtitle2"

            // classes={boldStyles.root}
          />
          <Box pt={2.5}>
            <Typography variant="body1">
              You will be able to change the visibility of your job post at a
              later time.
            </Typography>
          </Box>
          <Box pt={10}>
            <RadioGroup>
              <Field
                as={FormControlLabel}
                value="public"
                name={"visibility"}
                control={<Radio checked={values.visibility === "public"} />}
                label={`Make the post visible to all matching professionals${"\n"}(You will also be able to invite professionals on your own)`}
              />
              <Field
                as={FormControlLabel}
                value="private"
                name="visibility"
                control={<Radio checked={values.visibility === "private"} />}
                label="I only want to invite specific professionals on my own"
              />
            </RadioGroup>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
