import { Box, Divider } from "@mui/material";
import React, { useCallback } from "react";
import { File } from "../../../../api-types/job-requests/common";
import { LIGHT_BLUE } from "../../../../themes/components/utils";
import { Files } from "../../../common/components/files";
import { Messages } from "../../../common/components/messages";
import { ProfessionalSummary } from "../../../common/components/ProfessionalSummary";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useAddProjectMessageMutation } from "../../../professional/projects/hooks";
import { useAddProjectFileMutation } from "../../../professional/projects/hooks/useAddProjectFileMutation";
import { useUser } from "../../../state/hooks";
import { useCurrentProfessionalIndex } from "../state/hooks";

export const Communication: React.FC = () => {
  const { id } = useScopedDowngradedStateValue(useUser());

  const { mutate: addProjectMessage, isLoading: isAddingMessage } =
    useAddProjectMessageMutation();

  const { mutate: addProjectFile, isLoading: isAddingFile } =
    useAddProjectFileMutation();

  const { isSmall } = useResponsiveness();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const currentProfessionalIndex = useScopedDowngradedStateValue(
    useCurrentProfessionalIndex()
  );

  const onNewMessageHandler = useCallback(
    (newMessage: string) => {
      addProjectMessage({
        professionalId:
          project?.professionals[currentProfessionalIndex].professional._id,
        messages: [
          {
            content: newMessage,
            from: id ?? "",
            to:
              project?.professionals[currentProfessionalIndex].professional
                ._id ?? "",
          },
        ],
      });
    },
    [addProjectMessage, currentProfessionalIndex, id, project?.professionals]
  );

  const onNewFileHandler = useCallback(
    (files: File[]) => {
      addProjectFile({
        professionalId:
          project?.professionals[currentProfessionalIndex].professional._id,
        files,
      });
    },
    [addProjectFile, currentProfessionalIndex, project?.professionals]
  );

  return (
    <Box>
      <Divider />
      <Box py={5}>
        <ProfessionalSummary
          professional={
            project?.professionals[currentProfessionalIndex].professional
          }
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        {!isSmall && (
          <Box width="50%">
            <Files
              isLoading={isLoadingProject}
              files={
                project?.professionals[currentProfessionalIndex].files ?? []
              }
              onNewFile={(file: File[]) => {
                onNewFileHandler(file);
              }}
            />
          </Box>
        )}
        <Box
          width={isSmall ? "100%" : "50%"}
          borderLeft={isSmall ? "none" : `1px solid ${LIGHT_BLUE}`}
        >
          <Messages
            isAddingMessage={isAddingMessage}
            isLoading={isAddingFile}
            messages={
              project?.professionals[currentProfessionalIndex].messages ?? []
            }
            onNewMessage={(newMessage: string) => {
              onNewMessageHandler(newMessage);
            }}
          />
        </Box>
      </Box>
      <Box pt={0.5} pb={10}>
        <Divider />
      </Box>
    </Box>
  );
};
