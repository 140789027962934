import makeStyles from "@mui/styles/makeStyles";
import { BLUE, COLOR, YELLOW } from "../components/utils";

export const useStepAStyles = makeStyles({
  container: {
    paddingTop: 100,
    paddingLeft: 0,
    paddingRight: 0,
    width: "82%",
    ["@media screen and (max-width: 1024px), screen and (max-height: 900px)"]: {
      paddingTop: 48,
    },
    ["@media screen and (max-width: 720px)"]: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      width: "100%",
    },
  },
  typewriter: {
    display: "inline",
    background: YELLOW,
    color: BLUE,
  },
  descriptionWrapper: {
    paddingTop: 48,
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      paddingTop: 24,
    },
    ["@media screen and (max-height: 400px)"]: {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  mediumItalic: {
    transform: "skew(-10deg)",
  },
  headlineText: {
    letterSpacing: 0,
    color: COLOR.WHITE_BLUE,
    fontSize: 145,
    fontWeight: 500,
    paddingTop: 72,
    textAlign: "center",
    marginLeft: "0.1em",
    ["@media screen and (max-width: 1750px)"]: {
      fontSize: "8.2vw",
    },
    ["@media screen and (max-width: 850px)"]: {
      fontSize: "8.6vw",
      display: "flex",
      alignItems: "center",
      flexGrow: "0.4",
      marginLeft: "0.2em",
    },
    ["@media screen and (max-width: 700px)"]: {
      fontSize: "9.8vw",
    },
    ["@media screen and (max-width: 550px)"]: {
      fontSize: "9.6vw",
    },
    ["@media screen and (max-width: 450px)"]: {
      fontSize: "9.4vw",
    },
    ["@media screen and (max-width: 360px)"]: {
      fontSize: "9.2vw",
    },
    ["@media screen and (max-width: 310px)"]: {
      fontSize: "9vw",
    },
    ["@media screen and (max-width: 280px)"]: {
      fontSize: "25px",
    },
    ["@media screen and (max-height: 500px)"]: {
      paddingTop: 30,
      fontSize: "36px",
    },
    ["@media screen and (max-height: 350px)"]: {
      paddingTop: 32,
      fontSize: "28px",
    },
  },
  clientHeadlineText: {
    letterSpacing: 0,
    color: COLOR.BLUE,
    fontSize: 145,
    fontWeight: 500,
    paddingTop: 72,
    textAlign: "center",
    marginLeft: "0.1em",
    ["@media screen and (max-width: 1750px)"]: {
      fontSize: "8.2vw",
    },
    ["@media screen and (max-width: 850px)"]: {
      fontSize: "8.6vw",
      display: "flex",
      alignItems: "center",
      flexGrow: "0.4",
      marginLeft: "0.2em",
    },
    ["@media screen and (max-width: 700px)"]: {
      fontSize: "9.8vw",
    },
    ["@media screen and (max-width: 550px)"]: {
      fontSize: "9.6vw",
    },
    ["@media screen and (max-width: 450px)"]: {
      fontSize: "9.4vw",
    },
    ["@media screen and (max-width: 360px)"]: {
      fontSize: "9.2vw",
    },
    ["@media screen and (max-width: 310px)"]: {
      fontSize: "9vw",
    },
    ["@media screen and (max-width: 280px)"]: {
      fontSize: "25px",
    },
    ["@media screen and (max-height: 500px)"]: {
      paddingTop: 30,
      fontSize: "36px",
    },
    ["@media screen and (max-height: 350px)"]: {
      paddingTop: 32,
      fontSize: "28px",
    },
  },
  descriptionText: {
    fontSize: 28,
    color: "white",
    ["@media screen and (max-width: 1200px)"]: {
      fontSize: 26,
    },
    ["@media screen and (max-width: 1100px), screen and (max-height: 950px)"]: {
      fontSize: 24,
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 700px)"]: {
      fontSize: 20,
    },
    ["@media screen and (max-width: 550px), screen and (max-height: 500px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 350px)"]: {
      fontSize: 12,
    },
  },
  clientDescriptionText: {
    fontSize: 28,
    color: COLOR.BLUE,
    ["@media screen and (max-width: 1200px)"]: {
      fontSize: 26,
    },
    ["@media screen and (max-width: 1100px), screen and (max-height: 950px)"]: {
      fontSize: 24,
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 700px)"]: {
      fontSize: 20,
    },
    ["@media screen and (max-width: 550px), screen and (max-height: 500px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 350px)"]: {
      fontSize: 12,
    },
  },
  mobileCardWrapper: {
    marginBottom: 24,
    paddingLeft: 64,
    paddingRight: 64,
    ["@media screen and (max-width: 1100px), screen and (max-height: 950px)"]: {
      paddingRight: 48,
      paddingLeft: 48,
    },
    ["@media screen and (max-width: 1000px)"]: {
      paddingRight: 24,
      paddingLeft: 24,
    },
    ["@media screen and (max-width: 850px)"]: {
      paddingRight: 12,
      paddingLeft: 12,
    },
    ["@media screen and (max-height: 450px)"]: {
      marginBottom: 12,
    },
  },
  cardWrapper: {
    marginBottom: 24,
    paddingLeft: 64,
    paddingRight: 64,
    ["@media screen and (max-width: 1100px), screen and (max-height: 950px)"]: {
      paddingLeft: 48,
      paddingRight: 48,
    },
    ["@media screen and (max-width: 1000px)"]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    ["@media screen and (max-width: 850px)"]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    ["@media screen and (max-height: 450px)"]: {
      marginBottom: 12,
    },
  },
  cardText: {
    color: "white",
    fontSize: 26,
    fontWeight: 550,
    // flexGrow: 1,
    // margin: 36,
    // marginBottom: "10%",
    ["@media screen and (max-width: 1440px)"]: {
      fontSize: 24,
    },
    ["@media screen and (max-width: 1340px)"]: {
      fontSize: 23,
    },
    ["@media screen and (max-width: 1240px)"]: {
      fontSize: 22,
    },
    ["@media screen and (max-width: 1140px)"]: {
      fontSize: 21,
    },
    ["@media screen and (max-width: 1040px)"]: {
      fontSize: 20,
    },
    ["@media screen and (max-width: 940px), screen and (max-height: 700px)"]: {
      fontSize: 18,
      textAlign: "left",
    },
    ["@media screen and (max-width: 800px), screen and (max-height: 500px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 625px)"]: {
      fontSize: 15,
    },
    ["@media screen and (max-width: 400px)"]: {
      fontSize: 12,
    },
    ["@media screen and (max-width: 300px)"]: {
      fontSize: 10,
    },
    ["@media screen and (max-height: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 350px)"]: {
      fontSize: 12,
    },
  },
  clientCardText: {
    color: "#7B65AA",
    fontSize: 26,
    fontWeight: 550,
    ["@media screen and (max-width: 1440px)"]: {
      fontSize: 24,
    },
    ["@media screen and (max-width: 1340px)"]: {
      fontSize: 23,
    },
    ["@media screen and (max-width: 1240px)"]: {
      fontSize: 22,
    },
    ["@media screen and (max-width: 1140px)"]: {
      fontSize: 21,
    },
    ["@media screen and (max-width: 1040px)"]: {
      fontSize: 20,
    },
    ["@media screen and (max-width: 940px), screen and (max-height: 700px)"]: {
      fontSize: 18,
      textAlign: "left",
    },
    ["@media screen and (max-width: 800px), screen and (max-height: 500px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 625px)"]: {
      fontSize: 15,
    },
    ["@media screen and (max-width: 400px)"]: {
      fontSize: 12,
    },
    ["@media screen and (max-width: 300px)"]: {
      fontSize: 10,
    },
    ["@media screen and (max-height: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 350px)"]: {
      fontSize: 12,
    },
  },
});
