import { Box, Typography } from "@mui/material";
import { Field, FormikErrors, FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { JOB_REQUEST_FREELANCE_CREATION_ROUTE_URL } from "../../../../../routes/client";
import { CustomCheckbox } from "../../../../common/elements/CustomCheckbox";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { useJobPackages } from "../hooks/useJobPackages";

type ServicesProps = {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
};

export const JobType: React.FC<ServicesProps> = ({
  handleBlur,
  handleChange,
  errors,
  values,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMedium } = useResponsiveness();

  const handleNext = () => {
    const step = Number(searchParams.get("step"));
    searchParams.set("step", String(step + 1));
    setSearchParams(searchParams);
  };

  const { jobPostsCredits } = useJobPackages();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection={isMedium ? "column" : "row"}
      pt={10}
    >
      <Box
        display="flex"
        flexDirection={isMedium ? "column" : "row"}
        columnGap={25}
      >
        <Box
          width={isMedium ? "100%" : "40%"}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
              }}
            >
              What Kind of Job
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
              }}
            >
              Are You Posting?
            </Typography>
          </Box>
          <Box mt={4} mb={4}>
            <Typography variant="subtitle2">Welcome!</Typography>
            <Typography variant="subtitle2">
              To get started, select the kind of job you are looking to post.
            </Typography>
          </Box>
        </Box>

        <Box display="flex" maxWidth={isMedium ? "100%" : 1200}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={isMedium ? "center" : "flex"}
            rowGap={isMedium ? 2.5 : 15}
          >
            <Box display="flex" flexDirection="column" rowGap={2.5}>
              <Field
                component={CustomCheckbox}
                width={isMedium ? "100%" : 675}
                onClick={() => {
                  // eslint-disable-next-line no-undef
                  window.location.replace(
                    JOB_REQUEST_FREELANCE_CREATION_ROUTE_URL
                  );
                }}
                label="A freelance project (free to post)"
                onChange={handleChange}
                onBlur={handleBlur}
                name="freelance"
              />
              <Box py={isMedium ? 3 : 6}>
                <Typography variant="subtitle2">
                  Or any one of these:
                </Typography>
              </Box>
              <Box>
                <Field
                  component={CustomCheckbox}
                  width={isMedium ? "100%" : 675}
                  height={isMedium ? 270 : 340}
                  multiple
                  labels={[
                    "A permanent, full-time job",
                    "A contract job that will not be completed on this platform",
                    "A job posted on behalf of another company",
                    "An in-person or hybrid job",
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.marketing}
                  onClick={handleNext}
                  name="permanent"
                />

                {jobPostsCredits > 0 && (
                  <Field
                    component={CustomCheckbox}
                    isSelected
                    width={isMedium ? "100%" : 675}
                    label={`You have ${jobPostsCredits} Job Post credit(s) - Post Now`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.writing}
                    onClick={handleNext}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
