import { Box, Typography, Divider } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { Message } from "../../../../api-types/job-requests/common";
import {
  MessagesProvider,
  MessagesInput,
} from "../../../common/components/messages";

type MessagesProps = {
  isLoading?: boolean;
  isAddingMessage?: boolean;
  messages: Message[];
  onNewMessage: (value: string) => void;
};

export const Messages: React.FC<MessagesProps> = ({
  isLoading,
  isAddingMessage,
  messages,
  onNewMessage,
}) => {
  return (
    <React.Fragment>
      <Box
        height={80}
        pl={4}
        pr={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle2">Messages</Typography>
      </Box>
      <Box py={2}>
        <Divider />
      </Box>
      <Formik
        initialValues={{
          message: "",
        }}
        onSubmit={(values, { resetForm }) => {
          onNewMessage(values.message);
          resetForm();
        }}
      >
        {({ handleChange, handleSubmit, handleBlur, errors, values }) => (
          <Box height={720} pl={4} pr={4}>
            <MessagesProvider
              messages={messages}
              boxProps={{ borderColor: "transparent", padding: "0px" }}
            />
            <form onSubmit={handleSubmit}>
              <MessagesInput
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                isLoading={isAddingMessage}
              />
            </form>
          </Box>
        )}
      </Formik>
    </React.Fragment>
  );
};
