import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";
import { CurrentProjectHeaderContent } from "./CurrentProjectHeaderContent";
import { CurrentProjectHeaderSummary } from "./CurrentProjectHeaderSummary";

export const CurrentProjectHeader: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { isLarge } = useResponsiveness();

  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={isLarge ? "column" : "row"}
        alignItems={isLarge ? "flex-start" : "center"}
        columnGap={10}
        py={10}
      >
        <CurrentProjectHeaderContent />
        {!isLarge && <Divider orientation="vertical" flexItem />}
        <CurrentProjectHeaderSummary />
      </Box>
      {searchParams.get("tab") === "replies" && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            mr={8}
            variant={"subtitle1"}
            sx={{
              fontWeight: "bold",
            }}
          >
            You Are Viewing Communication With
          </Typography>
          <Typography variant="body1">
            Select from the Professionals you are working with in the Files and
            Messages dropdown above
          </Typography>
        </Box>
      )}
    </Box>
  );
};
