import { Box, Button, MenuItem, Popover } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useProjectsForCollaborating } from "../../../hooks/projects/useProjectsForCollaborating";

type ProfessionalSelectProps = { professionalId: string };

export const ProfessionalSelect: React.FC<ProfessionalSelectProps> = ({
  professionalId,
}) => {
  const navigate = useNavigate();
  const projectsForCollaborating = useProjectsForCollaborating(professionalId);

  // eslint-disable-next-line no-undef
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  // eslint-disable-next-line no-undef
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(open ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCollaborateHandler = useCallback(
    (projectId: string, projectTitle: string) => () => {
      handleClose();
      navigate(`/professional-referral/${professionalId}`, {
        state: {
          projectId,
          projectTitle,
        },
      });
    },
    [navigate, professionalId]
  );

  const hasProjects = (projectsForCollaborating?.length ?? 0) > 0;

  return (
    <Box>
      <Button
        size="medium"
        disabled={!hasProjects}
        variant="contained"
        onClick={handleClick}
      >
        Collaborate
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: { minWidth: 160 },
          }}
        >
          {projectsForCollaborating.map((project) => (
            <MenuItem
              key={project._id}
              value={project._id}
              onClick={onCollaborateHandler(project._id, project.title)}
            >
              {project.title}
            </MenuItem>
          ))}
        </Popover>
      </Button>
    </Box>
  );
};
