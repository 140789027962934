import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogContent, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import { forwardRef, ReactNode } from "react";
import { SubscribeData } from "../../../../../api-services/subscribe/subscribe";
import { BLUE } from "../../../../../themes/components/utils";
import { useHandleCloseDialog } from "../../../hooks/utils";
import { useDialogStyles } from "./SubscribeDialog";

export type PostSubscribeDialogProps = {
  open: boolean;
  title?: string;
  subtitle?: string;
  isLoading?: boolean;
  subscribed?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  message?: ReactNode | string;
  onClose: () => void;
  onConfirm?: (values: SubscribeData) => void;
  classNames?: string;
};

const useStyles = makeStyles({
  button: {
    height: 64,
    width: 100,
    "& .MuiButton-label": {
      fontSize: 19,
      fontWeight: 550,
    },
    ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]: {
      height: 48,
      width: 75,
      "& .MuiButton-label": {
        fontSize: 16,
        fontWeight: 550,
      },
    },
    ["@media screen and (max-height: 300px)"]: {
      height: 30,
      width: 60,
      "& .MuiButton-label": {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
  title: {
    fontSize: 24,
    ["@media screen and (max-width: 1000px)"]: {
      fontSize: 18,
    },
  },
  subtitle: {
    fontSize: 21,
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: 19,
    },
    ["@media screen and (max-width: 860px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 720px)"]: {
      fontSize: 14,
    },
  },
});

export const PostSubscribeDialog = forwardRef<
  HTMLDialogElement,
  PostSubscribeDialogProps
>((props, ref) => {
  const styles = useDialogStyles();
  const buttonStyles = useStyles();
  const mobileView = useMediaQuery("(max-width: 600px)");

  const handleCloseDialog = useHandleCloseDialog(props.onClose);

  return (
    <Dialog
      // @ts-ignore
      ref={ref}
      open={props.open}
      className={`${styles.fullWidth} ${props.classNames}`}
      onClose={handleCloseDialog}
    >
      <Box onClick={props.onClose} className={styles.closeIcon}>
        <CloseIcon />
      </Box>
      <DialogContent>
        <Box
          pl={mobileView ? 0 : 10}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography
            color="secondary"
            className={styles.title}
            style={{
              fontWeight: "bold",
            }}
          >
            Thank you for joining our email list.
          </Typography>
          <Typography
            color="secondary"
            className={styles.subtitle}
            style={{
              fontWeight: 400,
            }}
          >
            Stay tuned for the product launch email invite - coming very soon.
            Looking forward to having you on board.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="grid" gridAutoFlow="column" columnGap={5}>
          <Button
            variant="outlined"
            className={buttonStyles.button}
            style={{
              color: BLUE,
              borderColor: BLUE,
            }}
            onClick={props.onClose}
            autoFocus
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});
