import { Box, Skeleton } from "@mui/material";
import React from "react";

export const MainbarSkeleton: React.FC = () => {
  return (
    <Box pt={2}>
      <Box pb={2}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="40%"
          height={20}
        />
      </Box>
      <Box pt={2} display="flex" columnGap={2.5} justifyContent="space-between">
        <Box display="flex" columnGap={9} width="65%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="78%"
            height={68}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="15%"
            height={68}
          />
        </Box>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={68}
        />
      </Box>{" "}
      <Box display="flex" columnGap={2.5} justifyContent="space-between">
        <Box display="flex" columnGap={3} width="80%" pt={10}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="10%"
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="15%"
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="15%"
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="10%"
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="20%"
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="15%"
            height={30}
          />
        </Box>
      </Box>
    </Box>
  );
};
