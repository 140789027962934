import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ListItemButton, Collapse, List, ListItemText } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BLUE } from "../../../themes/components/utils";

type NestedLinkProps = {
  label: string;
  nestedItems: { label: string; to: string }[];
};

export const NestedLink: React.FC<NestedLinkProps> = ({
  label,
  nestedItems,
}) => {
  const [open, setOpen] = useState(false);
  const linkStyle = {
    color: BLUE,
    textDecoration: "none",
  };
  const handleNestedClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItemButton
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        onClick={handleNestedClick}
      >
        <ListItemText
          style={{
            width: "100%",
            alignItems: "center",
            paddingLeft: "1rem",
          }}
          primary={label}
          disableTypography={true}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {nestedItems.map((item, index) => (
            <ListItemButton
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                paddingLeft: "3.2rem",
              }}
              key={index}
            >
              <Link to={item.to} style={linkStyle}>
                {item.label}
              </Link>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </div>
  );
};
