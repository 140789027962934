import { Box, Card } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { Professional } from "../../../../api-types/professionals/professional";
import { BLUE, LIGHT_BLUE, YELLOW } from "../../../../themes/components/utils";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";
import { ProfessionalFeaturedProject } from "./ProfessionalFeaturedProject";
import { ProfileSummaryDescription } from "./ProfileSummaryDescription";
import { ProfileSummaryInfo } from "./ProfileSummaryInfo";

type ProfessionalListItemProps = {
  professional: Professional;
  onSelect: () => void;
  isLoading?: boolean;
};

export const ProfessionalListItem: React.FC<ProfessionalListItemProps> = ({
  professional,
  onSelect,
  isLoading,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  // eslint-disable-next-line no-undef
  const { ref, width = 0 } = useResizeObserver<HTMLDivElement>();

  const { isSmall, isMedium } = useResponsiveness();

  const onMouseEnterHandler = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeaveHandler = useCallback(() => {
    setIsHovering(false);
  }, []);

  const hasImages = Boolean(
    professional?.profileInformation?.projects?.[0]?.images
  );

  const bgColor = useMemo(() => {
    if (!hasImages) return BLUE;

    if (isHovering) return YELLOW;

    return LIGHT_BLUE;
  }, [hasImages, isHovering]);

  return (
    <Card
      // @ts-ignore
      ref={ref}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      elevation={0}
      style={{ borderRadius: 0, backgroundColor: "transparent" }}
    >
      <Box>
        <Box
          bgcolor={BLUE}
          display="flex"
          justifyContent="space-between"
          width={width}
          height={400}
        >
          <Box width={width * 0.15} display="flex" alignItems="center">
            <ProfileSummaryInfo
              {...professional}
              isHovering={isHovering}
              isLoading={isLoading}
              onView={onSelect}
            />
          </Box>
          <Box width={width * (isSmall || isMedium ? 0.6 : 0.4)} py={3}>
            <ProfileSummaryDescription
              {...professional}
              onClick={onSelect}
              isHovering={isHovering}
            />
          </Box>
          {isSmall || isMedium ? (
            <></>
          ) : (
            <ProfessionalFeaturedProject
              width={width * 0.35}
              height={380}
              bgColor={bgColor}
              professional={professional}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
};
