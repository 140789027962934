import { Box, Divider } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PageWrapper } from "../../../../../../app/container/PageWrapper";
import { CollectionNavigationActions } from "../../../../../../common/elements/CollectionNavigationActions";
import { useNavigationHandlers } from "../../../../../../common/hooks/navigation";
import { useProfessional } from "../../../../../../common/hooks/professionals/professional/useProfessional";
import { usePastProjectsQuery } from "../../../../../../common/hooks/projects/usePastProjectsQuery";
import { Details } from "../../../../sections/portfolio/portfolio-project/project-details";
import {
  ProfessionalAccountSummaryDescription,
  ProfessionalAccountSummaryInfo,
} from "../../../account-summary";
import { PortfolioProjectHeader } from "./header";
import { useDefaultProjectPage } from "./hooks";

export const PortfolioProject: React.FC = () => {
  useDefaultProjectPage();
  const location = useLocation();
  const { projectId, professionalId } = useParams();

  const { data: pastProjects, isLoading: isLoadingProjects } =
    usePastProjectsQuery(professionalId);

  const { data: professional, isLoading: isLoadingProfessionals } =
    useProfessional(professionalId);

  const isLoading = isLoadingProjects || isLoadingProfessionals;

  const collectionConfig = {
    type: `professionals/${professionalId}/portfolio/projects`,
    items: pastProjects,
  };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(collectionConfig, isLoadingProjects, projectId);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <PageWrapper>
      <Box display="flex" columnGap={12} height={300} width="100%">
        <ProfessionalAccountSummaryInfo
          profileInformation={professional?.profileInformation}
        />
        <ProfessionalAccountSummaryDescription
          accountInformation={professional?.accountInformation}
          profileInformation={professional?.profileInformation}
          averageRating={professional?.averageRating}
          ratingsCount={professional?.ratingsCount}
          isLoading={isLoading}
        />
      </Box>
      <Divider />
      <Box>
        <Box pb={10}>
          <PortfolioProjectHeader />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box flexGrow={1}>
            <Details />
          </Box>
          <CollectionNavigationActions
            label={"Project"}
            index={index}
            totalCount={pastProjects?.length ?? 0}
            handleSelectNext={handleSelectNext}
            handleSelectPrevious={handleSelectPrevious}
          />
        </Box>
      </Box>
    </PageWrapper>
  );
};
