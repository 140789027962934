import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { usePointerStyles } from "../../../../../../themes/style-hooks";
import { LoadingTypography } from "../../../../../common/components";
import { useMetadataQuery } from "../../../../../common/hooks/metadata/useMetadataQuery";
import { useProjectQuery } from "../../../../../common/hooks/projects/project/useProjectQuery";
import { useResponsiveness } from "../../../../../common/hooks/utils/useResponsiveness";

export const UnpublishedPastProjectHeaderSummary: React.FC = () => {
  const pointerStyles = usePointerStyles();
  const { isLarge } = useResponsiveness();
  const { isLoading: isLoadingProject } = useProjectQuery();
  const { data: metadata } = useMetadataQuery();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      marginBottom={isLarge ? -10 : 0}
      marginTop={isLarge ? -16 : 0}
      py={10}
    >
      <Box marginBottom={isLarge ? 4 : 6}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
          }}
        >
          Published Past Projects
        </Typography>
      </Box>
      <Box>
        <LoadingTypography
          isLoading={isLoadingProject}
          typographyProps={{ variant: "subtitle2" }}
        >
          {metadata?.publishedPastProjectsCount ?? 0} Past Projects are
          published
        </LoadingTypography>
      </Box>
      <Box className={pointerStyles.root}>
        <Link variant="subtitle2" href={"/projects/history"} underline="none">
          View published past projects
        </Link>
      </Box>
    </Box>
  );
};
