import makeStyles from "@mui/styles/makeStyles";

type ButtonProps = {
  color?: string;
  background?: string;
  hover?: string;
};

export const useCommonMainButtonsStyles = makeStyles(() => ({
  root: {
    borderRadius: `10px !important`,
    backgroundColor: (props: ButtonProps) => `${props.background} !important`,
    color: (props: ButtonProps) => `${props.color} !important`,
    "&:hover": {
      backgroundColor: (props: ButtonProps) => `${props.hover} !important`,
    },
  },
  common: {
    height: "60px !important",
    width: "fit-content",
    minWidth: 80,
    ["@media screen and (max-width: 850px)"]: {
      height: "50px !important",
    },
    ["@media screen and (max-width: 550px), screen and (max-height: 650px)"]: {
      padding: "0px 12px !important",
      height: "40px !important",
    },
    ["@media screen and (max-width: 340px), screen and (max-height: 370px)"]: {
      padding: "0px 10px !important",
      height: "32px !important",
    },
    ["@media screen and (max-height: 290px)"]: {
      height: "30px !important",
    },
    "& .MuiButton-label": {
      fontSize: 18,
      fontWeight: 600,
      ["@media screen and (max-width: 1200px), screen and (max-height: 900px)"]:
        {
          fontSize: 17,
        },
      ["@media screen and (max-width: 850px)"]: {
        fontSize: 18,
      },
      ["@media screen and (max-width: 550px), screen and (max-height: 650px)"]:
        {
          fontSize: 16,
        },
      ["@media screen and (max-width: 400px), screen and (max-height: 320px)"]:
        {
          fontSize: 12,
        },
    },
  },
}));
