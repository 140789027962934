import { Box } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Project } from "../../../../../api-types/projects";
import { BLUE } from "../../../../../themes/components/utils";
import { Section } from "../../../../common/components";
import { useProjectsQuery } from "../../../../common/hooks/projects/useProjectsQuery";
import { PaginationFooter } from "../../../../common/professionals-search/PaginationFooter";
import { PortfolioSkeleton } from "../portfolio/PortfolioSkeleton";
import { ProjectItem } from "./project-item";

const defaultRowsPerPage = 2;
const itemsPerRow = 1;

export const CurrentAccountProjects: React.FC = () => {
  const { data: projects, isLoading } = useProjectsQuery();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(
    () =>
      projects
        ? Math.round(projects.length / (defaultRowsPerPage * itemsPerRow))
        : 0,
    [projects]
  );

  const currentPageFilteredItems = useMemo(() => {
    const itemsCount = defaultRowsPerPage * itemsPerRow;
    const endIndex = currentPage * itemsCount;

    return projects ? projects.slice(endIndex - itemsCount, endIndex) : [];
  }, [currentPage, projects]);

  const handleChangePage = useCallback((event: Object, page: number) => {
    setCurrentPage(page);
  }, []);

  const errorMessage = useMemo(() => {
    return !isLoading && projects?.length
      ? ""
      : "On-going projects will be shown here.";
  }, [projects?.length, isLoading]);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Section
        isLoading={isLoading}
        errorMessage={errorMessage}
        sectionProps={{
          height: "100%",
        }}
        loadingSkeleton={
          <Box display="flex" flexDirection="column" rowGap={6} pb={10}>
            <PortfolioSkeleton />
            <PortfolioSkeleton />
          </Box>
        }
      >
        <Box py={5} height="100%" flexGrow={1}>
          <Box display="flex" flexDirection="column">
            {currentPageFilteredItems.map((project: Project) => {
              return (
                <Box key={project._id} width="100%">
                  <Box
                    key={project._id}
                    bgcolor={BLUE}
                    py={4}
                    onClick={() => {
                      navigate(`/projects/${project._id}`);
                    }}
                  >
                    <ProjectItem project={project} />
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box py={10}>
            <PaginationFooter
              rowsPerPage={defaultRowsPerPage}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handleChangePage}
              totalCount={projects?.length}
              objectNamePlural="projects"
              objectNameSingular="project"
            />
          </Box>
        </Box>
      </Section>
    </Box>
  );
};
