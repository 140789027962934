import { Box } from "@mui/material";
import { FormikValues } from "formik";
import React, { useRef } from "react";
import { PageWrapper } from "../../app/container/PageWrapper";
import { Section } from "../components";
import { useSignInMutation } from "../hooks/sign-in";
import { SignInForm } from "./SignInForm";
import { useAutoLogin } from "./useAutoLogin";

export const SignIn: React.FC = () => {
  const formRef = useRef<FormikValues>();
  const { mutate: signIn, isLoading } = useSignInMutation();

  const token = useAutoLogin();

  if (isLoading || token) return <Section isLoading />;

  return (
    <PageWrapper>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Box width={400}>
          <SignInForm formRef={formRef} onSignIn={signIn} />
        </Box>
      </Box>
    </PageWrapper>
  );
};
