import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Box, IconButton, Typography, useTheme, Skeleton } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { LIGHT_BLUE } from "../../../themes/components/utils";
import { OptionsDropdown } from "../components";
import { Option } from "../components/HeaderDropdown";
import { useResponsiveness } from "../hooks/utils/useResponsiveness";
import { SkeletonCollection } from "../professionals-search/browse-mainbar/SkeletonCollection";

type CardProps = {
  width?: number | string;
  height?: number;
  select?: boolean;
  onSelect?: (index: number) => void;
  selectedIndex?: number;
  options?: Option[];
  label: string;
  onClick?: () => void;
  isSelected?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  variant?: "body1" | "subtitle1" | "subtitle2";
};

export const Card: React.FC<CardProps> = ({
  width,
  height,
  label,
  onSelect,
  onClick,
  isSelected,
  disabled = false,
  isLoading,
  options = [],
  select,
  selectedIndex = 0,
  variant = "body1",
}) => {
  const theme = useTheme();
  const { isMedium, isSmall } = useResponsiveness();
  // eslint-disable-next-line no-undef
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onOptionsDropdownClick = useCallback(
    // eslint-disable-next-line no-undef
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const onDropdownClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onSelectHandler = useCallback(
    (index: number) => {
      onSelect?.(index);
      onDropdownClose();
    },
    [onSelect, onDropdownClose]
  );

  const ref = useRef(null);
  const hasSelect = Boolean((options?.length ?? 0) > 1 && select);

  return (
    <Box
      // @ts-ignore
      ref={ref}
      style={
        disabled
          ? {
              pointerEvents: "none",
              opacity: 0.4,
            }
          : {}
      }
      width={width ?? "fit-content"}
      height={height ?? 100}
    >
      <SkeletonCollection
        isLoading={isLoading}
        skeleton={
          <Skeleton
            variant="rectangular"
            width={width ?? "fit-content"}
            height={height ?? 100}
            animation="wave"
          />
        }
      >
        <Box
          height={height ?? 100}
          width={width ?? "fit-content"}
          sx={{
            display: "flex",
            color: isSelected ? theme.palette.primary.main : LIGHT_BLUE,
            border: `2px solid ${
              isSelected ? theme.palette.primary.main : LIGHT_BLUE
            }`,
            flexDirection: "column",
            paddingTop: isSelected ? "14px" : "0px",
            borderTop: isSelected ? "2px solid" : "2px solid",
            borderTopColor: isSelected
              ? theme.palette.primary.main + " !important"
              : LIGHT_BLUE,
            borderBottom: isSelected ? "16px solid" : "",
            borderBottomColor: isSelected ? theme.palette.primary.main : "",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              color: theme.palette.primary.main,
              cursor: "pointer",
              border: `2px solid ${theme.palette.primary.main}`,
              paddingTop: "14px",
              borderTop: "2px solid",
              borderTopColor: theme.palette.primary.main,
              borderBottom: "16px solid",
              borderBottomColor: theme.palette.primary.main,
            },
          }}
          onClick={onClick}
          data-cy={label.toLowerCase()}
        >
          <Box
            px={6}
            display="flex"
            alignItems="center"
            justifyContent={select ? "space-between" : "center"}
          >
            <Typography
              variant={variant}
              color="inherit"
              noWrap
              style={{
                fontWeight: 600,
                fontSize: isSmall ? "14px" : "inherit",
              }}
            >
              {label}
            </Typography>
            {hasSelect && (
              <React.Fragment>
                <IconButton
                  edge="end"
                  onClick={onOptionsDropdownClick}
                  size="large"
                >
                  <ExpandMoreRoundedIcon fontSize="large" />
                </IconButton>
                <OptionsDropdown
                  anchorEl={anchorEl}
                  onClose={onDropdownClose}
                  selectedOptionId={options[selectedIndex]?.id}
                  options={options}
                  onOptionSelect={onSelectHandler}
                />
              </React.Fragment>
            )}
          </Box>
        </Box>
      </SkeletonCollection>
    </Box>
  );
};
