import { Box, Collapse } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { CreateBidRequest } from "../../../../../../api-types/job-requests/bids";
import { JobRequest } from "../../../../../../api-types/job-requests/job-request";
import { Section } from "../../../../../common/components";
import { EditingForm } from "../../../../bids/communication/EditingForm";
import { useBidCreateMutation } from "../../../../bids/communication/hooks";

type ShowBidProps = {
  isExpanded: boolean;
  jobRequest: JobRequest;
  onCancel?: () => void;
  onPlaceBid?: () => void;
  topY?: number;
};

const bidInitialValues = {
  introductoryMessage: "",
  hourlyProjectFunding: { hourlyRate: 0 },
  fixedProjectFunding: { totalPrice: 0 },
};

export const ShowBid: React.FC<ShowBidProps> = ({
  isExpanded,
  jobRequest,
  onCancel,
  onPlaceBid,
  topY,
}) => {
  const { mutate: createBid, isLoading } = useBidCreateMutation();

  const onHandlePlaceBid = useCallback(
    (values: CreateBidRequest) => {
      createBid(
        {
          ...values,
          jobRequest: jobRequest._id,
          messages: [],
        },
        {
          onSuccess() {
            onPlaceBid?.();
          },
        }
      );
    },
    [createBid, jobRequest._id, onPlaceBid]
  );

  useEffect(() => {
    if (!isExpanded) return;

    // eslint-disable-next-line no-undef
    window.scroll({ top: topY, behavior: "smooth" });
  }, [isExpanded, topY]);

  return (
    <Collapse in={isExpanded}>
      <Box width="100%" minHeight={150} pt={4}>
        <Section isLoading={isLoading}>
          <EditingForm
            onHandlePlaceBid={onHandlePlaceBid}
            onCancel={onCancel}
            initialValues={bidInitialValues}
            budgetType={jobRequest.budget.type}
            isLoading={isLoading}
          />
        </Section>
      </Box>
    </Collapse>
  );
};
