import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../app/container/PageWrapper";
import { Section } from "../../common/components";
import { CollectionNavigationActions } from "../../common/elements/CollectionNavigationActions";
import {
  Collection,
  useNavigationHandlers,
} from "../../common/hooks/navigation";
import { useProjectQuery } from "../../common/hooks/projects/project/useProjectQuery";
import { useProjectsQuery } from "../../common/hooks/projects/useProjectsQuery";
import { PortfolioSkeleton } from "../../professional/account/sections/portfolio/PortfolioSkeleton";
import { BidDetailsSkeleton } from "../../professional/bids/BidDetailsSkeleton";
import { Communication } from "./communication/";
import { Details } from "./details";
import { CurrentProjectHeader } from "./header";
import { useDefaultProjectPage } from "./hooks";
import { CurrentProjectDetailsSkeleton } from "./pagesSkeletons/currentProjectDetailsSkeleton";
import { CurrentProjectMessagesSkeleton } from "./pagesSkeletons/currentProjectMessagesSkeleton";
import { CurrentProjectPaymentsSkeleton } from "./pagesSkeletons/currentProjectPaymentsSkeleton";
import { Payments } from "./payments";

export const ProjectDetails: React.FC = () => {
  useDefaultProjectPage();

  const [searchParams] = useSearchParams();

  const { projectId } = useParams();

  const { data: projects, isLoading: isLoadingProjects } = useProjectsQuery();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const collectionConfig = { type: Collection.PROJECTS, items: projects };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(collectionConfig, isLoadingProjects, projectId);

  const currentTab = searchParams.get("tab");

  const isContentLoading = isLoadingProjects || isLoadingProject;

  const errorMessage = useMemo(() => {
    return !isContentLoading && !project
      ? "Your on-going projects will be shown here. Post a new job request to get started."
      : "";
  }, [isContentLoading, project]);

  const content = useMemo(() => {
    if (currentTab === "payments") return <Payments />;

    if (currentTab === "replies") return <Communication />;

    return <Details />;
  }, [currentTab]);

  const loadingSkeleton = (() => {
    if (currentTab === "payments") {
      return <CurrentProjectPaymentsSkeleton />;
    } else if (currentTab === "replies") {
      return <CurrentProjectMessagesSkeleton />;
    } else {
      return <CurrentProjectDetailsSkeleton />;
    }
  })();

  const isNavigationActionsHidden =
    isLoadingProjects || currentTab !== "details";

  return (
    <PageWrapper>
      <Section
        isLoading={isContentLoading}
        errorMessage={errorMessage}
        loadingSkeleton={loadingSkeleton}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box display="flex" flexDirection="column" height="100%">
            <CurrentProjectHeader />
            <Box flexGrow={1}>{content}</Box>
          </Box>
          <CollectionNavigationActions
            label={"Project"}
            index={index}
            totalCount={projects?.length ?? 0}
            handleSelectNext={handleSelectNext}
            handleSelectPrevious={handleSelectPrevious}
            hidden={isNavigationActionsHidden}
          />
        </Box>
      </Section>
    </PageWrapper>
  );
};
