import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Checkbox,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFormikContext } from "formik";
import { ChangeEvent, FC, useCallback, useLayoutEffect, useRef } from "react";
import { YELLOW } from "../../../../themes/components/utils";
import { useTransparentSelectStyles } from "../../../../themes/style-hooks";
import { HourlyRateMilestone } from "../../../client/job-requests/create-job-request-wizard/validation-schema";
import { TableCellWithInput } from "../../../client/job-requests/utils";
import { stringToTitleCase } from "../../../utils";

type HourlyRateEditableTableRowProps = {
  estimatedCostPerWeek: {
    min: number;
    max: number;
  };
  hasBorder?: boolean;
  hoursLabel: string;
  hourlyRateLabel: string;
  milestone: HourlyRateMilestone;
  onSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  rowIndex: number;
  selected?: boolean;
  remove: any;
};

const useStyles = makeStyles({
  root: {
    height: "auto",
  },
  select: {
    "&.MuiSelect-select": {
      paddingRight: 34,
    },
  },
});

export const HourlyRateEditableTableRow: FC<
  HourlyRateEditableTableRowProps
> = ({
  estimatedCostPerWeek,
  hasBorder,
  hoursLabel,
  hourlyRateLabel,
  milestone,
  onSelect,
  rowIndex,
  selected,
  remove,
}) => {
  const { description, dueDate, status } = milestone;
  const styles = useStyles();
  const transparentSelectStyles = useTransparentSelectStyles();
  const rowRef = useRef<HTMLTableRowElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { setFieldValue } = useFormikContext();

  const onDueDateTypeChange = useCallback(
    (
      event: SelectChangeEvent<{
        name?: string;
        value: unknown;
      }>
    ) => {
      setFieldValue(`milestones.${rowIndex}.dueDate.type`, event.target.value);
    },
    [rowIndex, setFieldValue]
  );

  useLayoutEffect(() => {
    if (rowRef.current) {
      rowRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }

    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <TableRow
      ref={rowRef}
      style={{
        border: hasBorder ? `1px solid ${YELLOW}` : "none",
        height: 64,
      }}
    >
      <TableCell>
        <Checkbox
          checked={selected}
          onChange={onSelect}
          style={{ paddingTop: 0, paddingBottom: 0 }}
        />
      </TableCell>
      <TableCellWithInput
        ref={inputRef}
        name={`milestones.${rowIndex}.description`}
        value={description}
      />
      <TableCellWithInput
        cellWidth="20%"
        betweenLabel="and"
        isMinMaxInput
        inputWidth={80}
        labelBefore="Between"
        maxNameSuffix="max"
        maxValue={dueDate?.max ?? 0}
        minNameSuffix="min"
        minValue={dueDate?.min ?? 0}
        name={`milestones.${rowIndex}.dueDate`}
        type="number"
      >
        <Select
          className={transparentSelectStyles.root}
          classes={{
            // root: styles.root,
            select: styles.select,
          }}
          disableUnderline
          IconComponent={KeyboardArrowDownIcon}
          defaultValue={{ value: dueDate?.type ?? "weeks" }}
          name={`milestones.${rowIndex}.dueDate.type`}
          onChange={onDueDateTypeChange}
          style={{
            fontSize: 16,
            height: "auto",
          }}
        >
          <MenuItem value="weeks">{`week(s)`}</MenuItem>
          <MenuItem value="days">{`day(s)`}</MenuItem>
          <MenuItem value="hours">{`hour(s)`}</MenuItem>
        </Select>
      </TableCellWithInput>

      <TableCell>
        <Typography component="span" variant="body2">
          {hoursLabel}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography component="span" variant="body2">
          {hourlyRateLabel}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography component="span" variant="body2">
          {`$${(dueDate?.min ?? 0) * estimatedCostPerWeek.min} - $${
            (dueDate?.max ?? 0) * estimatedCostPerWeek.max
          }`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography component="span" variant="body2">
          {stringToTitleCase(status ?? "")}
        </Typography>
      </TableCell>
      <TableCell>
        <ListItemIcon
          style={{
            minWidth: "auto",
            marginRight: 8,
            cursor: "pointer",
          }}
          onClick={() => remove(rowIndex)}
        >
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
      </TableCell>
    </TableRow>
  );
};
