import { Box, useMediaQuery } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { MilestoneInvoice } from "../../../../api-types/projects/milestone-invoices";
import { formatInvoiceId } from "../../../utils";
import { DownloadableDataGrid } from "../../components";
import { formatNominativeMonth } from "../../components/LoadingTypography";
import { useMilestonesInvoices } from "../../hooks/projects/project/milestone-invoices/useMilestonesInvoices";

export const DepositInvoices: React.FC = () => {
  const smallWidth = useMediaQuery("(max-width: 1000px)", { noSsr: true });

  const generateColumnDef = (isSmallScreen: boolean): GridColDef[] => [
    {
      field: "createdAt",
      headerName: "Date",
      width: isSmallScreen ? 120 : 250,
      sortable: false,
    },
    {
      field: "description",
      headerName: "Description",
      width: isSmallScreen ? 150 : 250,
      sortable: false,
    },
    {
      field: "client",
      headerName: "Client",
      width: isSmallScreen ? 100 : 250,
      sortable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: isSmallScreen ? 80 : 300,
      sortable: false,
    },
    {
      field: "id",
      headerName: "Invoice ID",
      width: isSmallScreen ? 250 : 300,
      sortable: false,
    },
  ];

  const { data: milestoneInvoices, isLoading } = useMilestonesInvoices();
  const columns = generateColumnDef(smallWidth);

  const rows = useMemo(() => {
    return (
      milestoneInvoices
        ?.map((milestoneInvoice: MilestoneInvoice) => ({
          amount: `$${milestoneInvoice.amount}`,
          client: milestoneInvoice.client.email,
          createdAt: format(
            new Date(milestoneInvoice.createdAt),
            formatNominativeMonth
          ),
          description: milestoneInvoice.description,
          id: milestoneInvoice._id,
          invoice: formatInvoiceId(milestoneInvoice),
          type: milestoneInvoice?.type?.toUpperCase(),
        }))
        .filter((milestoneInvoice) => milestoneInvoice.type === "FUND") ?? []
    );
  }, [milestoneInvoices]);

  return (
    <Box columnGap={6}>
      <Box>
        <DownloadableDataGrid
          isLoading={isLoading}
          columns={columns}
          data={rows}
          title="Deposit Invoices"
        />
      </Box>
    </Box>
  );
};
