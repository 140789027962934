import { Box, Typography } from "@mui/material";
import React from "react";

export const FundingDetailsTopContainer = () => {
  return (
    <Box display="flex" flexDirection="column" gap={10}>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
        }}
      >
        Funding Your Project
      </Typography>
      <Typography variant="body2">
        By default, all fixed price projects have one milestone.
      </Typography>
      <Typography variant="body2">
        In order to begin a fixed price project, at least the first project
        milestone has to be funded.
      </Typography>
      <Typography variant="body2">
        If multiple milestones are set up, at least the upcoming milestone has
        to be funded for work to continue.
      </Typography>
      <Typography variant="body2">
        Once a Professional is hired, you will be prompted to add the amount you
        specify here into an escrow account, to be released to the Professional
        on completion of the milestone.
      </Typography>
      <Typography variant="body2">
        All payments are enabled by Stripe. There is a 3.9% + $0.30 processing
        fee for each transaction.
      </Typography>
    </Box>
  );
};
