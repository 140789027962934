import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Typography,
  TableCell,
  TableRow,
} from "@mui/material";
import FileSaver from "file-saver";
import { startCase } from "lodash";
import { Fragment, useCallback, useMemo, useState } from "react";
import { getAWSFiles } from "../../../api-services/upload-files/uploadFiles";
import { Status } from "../../../api-types/job-applications/JobApplication";
import { JobPostSummary } from "../../../api-types/job-posts";
import { YELLOW } from "../../../themes/components/utils";
import { useJobApplicationQuery } from "../../common/hooks/job-applications";
import { getZipFiles } from "../../utils";
import { ExpiredJobPostActions } from "./ExpiredJobPostActions";
import { JobPostActions } from "./JobPostActions";
import { JobPostApplicantDetails } from "./JobPostApplicantDetails";
import { useStyles } from "./JobPostsDashboard";
import { Select } from "./Select";

type JobPostTableRowProps = {
  jobPost: JobPostSummary;
};

const VALID_DAYS = 90;

export function JobPostTableRow({ jobPost }: JobPostTableRowProps) {
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  const daysUntilExpiry = useMemo(() => {
    const today = new Date();
    const createdAt = new Date(jobPost.createdAt);

    return (
      VALID_DAYS -
      Math.floor((today.getTime() - createdAt.getTime()) / (1000 * 3600 * 24))
    );
  }, [jobPost.createdAt]);

  const isJobPostExpired = daysUntilExpiry <= 0 || jobPost.isDraft;

  const { data: jobPostApplications } = useJobApplicationQuery(jobPost._id, {
    enabled: jobPost.numberOfApplicants > 0,
  });

  const [filter, setFilter] = useState<{ field: string; option: string }>();

  const filteredJobPostApplications = useMemo(() => {
    const nonRejectedJobPostApplications = jobPostApplications?.filter(
      (application) => application.status !== Status.REJECTED
    );

    if (filter?.field === "createdAt") {
      if (filter?.option === "Most Recent to Oldest") {
        return nonRejectedJobPostApplications?.sort((a, b) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);

          return bDate.getTime() - aDate.getTime();
        });
      }

      if (filter?.option === "Oldest to Most Recent") {
        return nonRejectedJobPostApplications?.sort((a, b) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);

          return aDate.getTime() - bDate.getTime();
        });
      }
    }

    if (filter?.field === "status") {
      if (filter?.option === "Shortlisted") {
        return nonRejectedJobPostApplications?.filter(
          (application) => application.status === Status.SHORTLISTED
        );
      }
    }

    return nonRejectedJobPostApplications;
  }, [filter?.field, filter?.option, jobPostApplications]);

  const onDownloadResumes = useCallback(async () => {
    const keys = jobPost.resumes.map(
      (url) => url.match(/resumes\/.*$/)?.[0] ?? ""
    );
    const files = await getAWSFiles(keys);

    const mappedFiles = files.map(({ key, buffer }) => ({
      key: key.replace(`resumes/${jobPost._id}/`, ""),
      buffer,
    }));

    const zippedFiles = await getZipFiles(mappedFiles);

    FileSaver.saveAs(
      zippedFiles,
      `${jobPost.jobTitle}_${new Date(Date.now()).toISOString()}.zip`
    );
  }, [jobPost._id, jobPost.jobTitle, jobPost.resumes]);

  return (
    <Fragment>
      <TableRow data-cy="job-post-table-row">
        <TableCell className={styles.border}>
          {jobPost.numberOfApplicants > 0 && (
            <IconButton
              size="small"
              sx={{
                color: YELLOW,
              }}
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell className={styles.border}>
          <Typography variant="body2">{jobPost.jobTitle}</Typography>
        </TableCell>
        <TableCell className={styles.border}>
          <Typography variant="body2">{jobPost.numberOfApplicants}</Typography>
        </TableCell>
        <TableCell className={styles.border}>
          <Typography variant="body2">
            {startCase(jobPost.methodOfApplication?.toLowerCase())}
          </Typography>
        </TableCell>
        <TableCell className={styles.border}>
          {!jobPost.resumes.length ? (
            <Typography variant="body2">N/A</Typography>
          ) : (
            <Button
              onClick={onDownloadResumes}
              size="medium"
              variant="contained"
            >
              Download Resumes
            </Button>
          )}
        </TableCell>
        <TableCell className={styles.border} style={{ maxWidth: 110 }}>
          <Typography
            color={isJobPostExpired ? "error" : undefined}
            variant="body2"
          >
            {isJobPostExpired
              ? "Job Post Expired"
              : `${daysUntilExpiry} day(s)`}
          </Typography>
        </TableCell>
        <TableCell className={styles.border}>
          {isJobPostExpired ? (
            <ExpiredJobPostActions jobPostId={jobPost._id} />
          ) : (
            <JobPostActions jobPostId={jobPost._id} />
          )}
        </TableCell>
      </TableRow>

      {open && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 24, paddingTop: 0, border: "none" }}
            colSpan={7}
          >
            <Collapse in={open} unmountOnExit>
              <Box display="flex" columnGap={4} py={5}>
                <Select
                  defaultSelected="Most Recent to Oldest"
                  label="View"
                  options={["Most Recent to Oldest", "Oldest to Most Recent"]}
                  onSave={(option) => setFilter({ field: "createdAt", option })}
                />
                <Select
                  defaultSelected="All"
                  label="Status"
                  options={["All", "Shortlisted"]}
                  onSave={(option) => setFilter({ field: "status", option })}
                />
              </Box>

              {filteredJobPostApplications?.map((application) => (
                <JobPostApplicantDetails
                  key={application._id}
                  application={application}
                />
              ))}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
}
