import { Typography, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ReactNode } from "react";
import { YELLOW } from "../../../themes/components/utils";

export const formatShortDate = "yyyy/MM/dd";
export const formatAltShortDate = "d/M/yy";
export const formatNominativeMonth = "MMM d, yyyy";

export const formatMessageDate = "EEE, LLL d, p";

type LoadingTypographyProps = {
  typographyProps?: any;
  isLoading?: boolean;
  width?: number | string;
  onClick?: any;
  endIcon?: ReactNode;
  lineHeight?: number;
  style?: any;
};

const useStyles = makeStyles(({ palette }) => ({
  root: {
    textDecoration: "none",
    color: YELLOW,
  },
  divider: {
    backgroundColor: palette.secondary.main,
  },
}));

export const LoadingTypography: React.FC<LoadingTypographyProps> = ({
  typographyProps,
  onClick,
  isLoading = false,
  width,
  lineHeight = 1,
  style,
  children,
}) => {
  const styles = useStyles();

  return (
    <Typography
      component="div"
      {...typographyProps}
      onClick={onClick}
      style={{
        ...style,
        lineHeight,
      }}
    >
      {isLoading ? (
        <Skeleton
          animation="wave"
          width={width || "100%"}
          variant="text"
          classes={{
            wave: styles.root,
          }}
        />
      ) : (
        children
      )}
    </Typography>
  );
};
