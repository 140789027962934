import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { Fragment, useMemo } from "react";
import ShowMoreText from "react-show-more-text";
import { YELLOW } from "../../../../../../themes/components/utils";
import { CustomTooltip } from "../../../../components";

type DescriptionProps = { description?: string; label?: string };

const useStyles = makeStyles(() => ({
  root: {
    textDecoration: "none",
    color: YELLOW,
  },
}));

export const Description: React.FC<DescriptionProps> = ({
  description,
  label = "Description",
}) => {
  const anchorStyles = useStyles();

  const tooltipTitle = useMemo(() => {
    return (
      <Fragment>
        <Typography color="secondary" variant="body1">
          {description}
        </Typography>
      </Fragment>
    );
  }, [description]);

  return (
    <Box flexWrap="wrap" mt={3}>
      {label && (
        <Box pb={2}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
            }}
          >
            {label}
          </Typography>
        </Box>
      )}

      <Typography
        sx={{
          color: "white",
        }}
      >
        <ShowMoreText
          lines={3}
          more={
            <CustomTooltip arrow placement="right" title={tooltipTitle}>
              <Typography component="span" variant="body2" color="primary">
                More
              </Typography>
            </CustomTooltip>
          }
          anchorClass={anchorStyles.root}
          expanded={false}
          // @ts-ignore: A bug in the library. The prop exists, but the interface is not updated.
          expandByClick={false}
        >
          {description}
        </ShowMoreText>
      </Typography>
    </Box>
  );
};
