import { Box } from "@mui/material";
import axios from "axios";
import React from "react";
import { BASE_URL } from "../../../../api-services/utils";
import { PageWrapper } from "../../../app/container/PageWrapper";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../utils/useToast";
import { WithdrawFundsDialog } from "../../components/modals/withdraw-funds-dialog";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { AccountBalance } from "./AccountBalance";
import { useWithdrawFundsModal } from "./state/hooks";

export const AccountBalancePage: React.FC = () => {
  const { notifySuccess, notifyError } = useToast();

  const withdrawFundsDialog = useScopedDowngradedStateValue(
    useWithdrawFundsModal()
  );
  const setWithdrawFundsDialog = useWithdrawFundsModal().set;

  const handleWithdrawAmount = async (amount: number) => {
    const response = await axios.post(`${BASE_URL}/stripe/withdraw`, {
      amount,
    });
    if (response.status == 200 || response.status == 201) {
      notifySuccess(feedbackMessage("", FEEDBACK_TYPE.MONEY_WITHDRAW));
    } else {
      notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
    }

    setWithdrawFundsDialog({ open: false });
  };

  return (
    <Box height="100%">
      <PageWrapper>
        <AccountBalance />
        <WithdrawFundsDialog
          open={withdrawFundsDialog.open}
          onClose={() => setWithdrawFundsDialog({ open: false })}
          onConfirm={handleWithdrawAmount}
        />
      </PageWrapper>
    </Box>
  );
};
