import { Box, Skeleton } from "@mui/material";
import React from "react";

export const UnpublishedSkeleton: React.FC = () => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" pt={8} width="100%">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="40%"
          height={30}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="30%"
          height={30}
        />
      </Box>

      <Box display="flex" justifyContent="space-between" pt={8} width="100%">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="60%"
          height={30}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="30%"
          height={30}
        />
      </Box>

      <Box mt={16}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={100}
        />
      </Box>
      <Box mt={8}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="40%"
          height={25}
        />
      </Box>
      <Box mt={4}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="30%"
          height={15}
        />
      </Box>

      <Box mt={2}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={15}
        />
      </Box>

      <Box display="flex" justifyContent="space-between" mt={8}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="30%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="15%"
          height={40}
        />
      </Box>

      <Box mt={8}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={60}
        />
      </Box>

      <Box pt={2.5} display="flex" justifyContent="space-between">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="30%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="40%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="27%"
          height={25}
        />
      </Box>

      <Box width="40%">
        <Box pt={10} display="flex" columnGap={2.5} alignItems="center">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="17.5%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="17.5%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="17.5%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="17.5%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="17.5%"
            height={40}
          />
          <Box display="flex" columnGap={1} alignItems="center">
            <Skeleton
              variant="circular"
              animation="wave"
              width={16}
              height={16}
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width={16}
              height={16}
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width={16}
              height={16}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
