import { Box, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { JobRequest } from "../../../../../api-types/job-requests/job-request";
import { YELLOW } from "../../../../../themes/components/utils";
import { usePointerStyles } from "../../../../../themes/style-hooks";
import { TextOverflow, ShowImages } from "../../../../common/components";
import { formatShortDate } from "../../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { Description } from "../../../../common/professionals-search/projects/project/sections/Description";
import { ShowInquiry, ShowBid, AvailabilityCostBids } from "./content";
import { JobRequestItemActions } from "./JobRequestItemActions";

type JobRequestItemProps = {
  index: number;
  inquiriesExpandedState: any;
  onUpdateInquiriesState: any;
  bidsExpandedState: any;
  onUpdateBidsState: any;
  jobRequest: JobRequest;

  onCreateBidOrInquiry?: () => void;
};

const useStyles = makeStyles({
  root: {
    fontSize: 16,
    whiteSpace: "nowrap",
  },
});

export const JobRequestItem: React.FC<JobRequestItemProps> = ({
  jobRequest,
  onCreateBidOrInquiry,
  inquiriesExpandedState,
  onUpdateInquiriesState,
  bidsExpandedState,
  onUpdateBidsState,
  index,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const pointerStyles = usePointerStyles();

  const { isSmall, isMedium } = useResponsiveness();

  const jobHeaderDetailsStyles = useStyles();

  const { description, title, createdAt, createdBy, images } = jobRequest;

  const onMouseEnterHandler = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeaveHandler = useCallback(() => {
    setIsHovering(false);
  }, []);

  const skills = useMemo(() => {
    if (jobRequest?.skills) {
      return jobRequest?.skills.map((skill) => skill.name);
    }
    return [];
  }, [jobRequest]);

  const hasImages = Boolean(images?.length ?? 0 > 0);

  const ref = useRef(null);

  const isCurrentBidOpen = bidsExpandedState[index];

  const isCurrentInquiryOpen = inquiriesExpandedState[index];

  return (
    <Box
      // @ts-ignore
      ref={ref}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      className={pointerStyles.root}
      data-cy="job-request-item"
    >
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        columnGap={10}
      >
        <TextOverflow
          width="30%"
          style={{
            fontSize: 24,
            color: isHovering ? YELLOW : "white",
          }}
          // className={boldStyles.root}
        >
          {title}
        </TextOverflow>
        {!isMedium && createdAt && (
          <Typography className={jobHeaderDetailsStyles.root}>
            Posted {format(new Date(createdAt), formatShortDate)}
          </Typography>
        )}
        {!isSmall && createdBy && (
          <Box display="flex" alignItems="center" columnGap={2} width="15%">
            <Typography
              display="inline"
              className={jobHeaderDetailsStyles.root}
            >
              Posted by
            </Typography>
            <TextOverflow width="60%" className={jobHeaderDetailsStyles.root}>
              {`${createdBy?.accountInformation?.firstName} ${createdBy?.accountInformation?.lastName}`}
            </TextOverflow>
          </Box>
        )}
        <JobRequestItemActions
          isCurrentBidOpen={isCurrentBidOpen}
          isCurrentInquiryOpen={isCurrentInquiryOpen}
          jobRequestId={jobRequest._id}
          onToggleInquiry={() => {
            onUpdateInquiriesState(index, !inquiriesExpandedState[index]);
          }}
          onToggleBid={() => {
            onUpdateBidsState(index, !bidsExpandedState[index]);
          }}
        />
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>
      <Description description={description} />
      <Box py={2.5}>
        <AvailabilityCostBids
          budget={jobRequest?.budget}
          fixedProjectFunding={jobRequest?.fixedProjectFunding}
          jobRequestId={jobRequest._id}
        />
      </Box>
      {skills.length > 0 && (
        <Box py={2.5}>
          <SkillsOutput skills={skills} isHovering={isHovering} />
        </Box>
      )}
      {hasImages && (
        <Box py={2.5}>
          <ShowImages selectedFiles={images} />{" "}
        </Box>
      )}

      <ShowInquiry
        isExpanded={inquiriesExpandedState[index]}
        jobRequest={jobRequest}
        onPlaceInquiry={onCreateBidOrInquiry}
        scrollIntoView={inquiriesExpandedState[index]}
        topY={(ref?.current as any)?.offsetTop ?? 0}
      />
      <ShowBid
        isExpanded={bidsExpandedState[index]}
        jobRequest={jobRequest}
        onPlaceBid={onCreateBidOrInquiry}
        onCancel={() => {
          onUpdateBidsState(index, !bidsExpandedState[index]);
        }}
        topY={(ref?.current as any)?.offsetTop ?? 0}
      />
    </Box>
  );
};
