import { useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BROWSE_DEFAULT_URL } from "../../../routes/utils";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { useMetadataQuery } from "../../common/hooks/metadata/useMetadataQuery";
import { useResponsiveness } from "../../common/hooks/utils/useResponsiveness";
import { NavbarMenuItem } from "../../common/navbar/NavbarMenuItem";
import { More } from "./More";

const useStyles = makeStyles({
  menuItem: {
    paddingLeft: 8,
    backgroundColor: BLUE,
    color: `${YELLOW} !important`,
  },
});

export const ProfessionalNavbar: React.FC = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  const { isLarge, isExtraLarge } = useResponsiveness();

  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();

  const onNavbarItemClickHandler = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate]
  );

  return (
    <Box display={"flex"} columnGap={1} marginLeft={1}>
      <NavbarMenuItem
        label="Search Jobs & Projects"
        onClick={() => onNavbarItemClickHandler("/job-requests/search")}
        dataCyBtn="search-job-requests"
      />
      <NavbarMenuItem
        label="Browse Professionals"
        onClick={() => onNavbarItemClickHandler(BROWSE_DEFAULT_URL)}
        dataCyBtn="professionals-and-teams"
      />
      <NavbarMenuItem
        label="Applications"
        isLoading={isLoadingMetadata}
        onClick={() => onNavbarItemClickHandler("/job-applications/")}
        labelCount={metadata?.jobApplicationsCount ?? 0}
        dataCyBtn="jobs-applied"
      />
      <NavbarMenuItem
        label="Current Projects"
        labelCount={metadata?.projectsCount ?? 0}
        isLoading={isLoadingMetadata}
        onClick={() => onNavbarItemClickHandler("/projects/")}
        dataCyBtn="current-projects"
      />
      {!isLarge && !isExtraLarge && (
        <NavbarMenuItem
          label="Bids"
          labelCount={metadata?.accountBidsCount ?? 0}
          isLoading={isLoadingMetadata}
          onClick={() => onNavbarItemClickHandler("/bids/")}
          dataCyBtn="bids"
        />
      )}
      <More
        classes={styles.menuItem}
        label="In Process"
        isLoading={isLoadingMetadata}
        labelCount={
          (metadata?.clientInvitationsCount ?? 0) +
          (metadata?.accountInquiriesCount ?? 0) +
          (metadata?.professionalReferralsCount ?? 0) +
          (!isLarge && isExtraLarge ? metadata?.accountBidsCount ?? 0 : 0)
        }
      />
    </Box>
  );
};
