import { Box, Skeleton } from "@mui/material";
import React from "react";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";

export const ProjectsSkeleton: React.FC = () => {
  const { isSmall, isMedium } = useResponsiveness();

  return (
    <Box
      pt={5}
      columnGap={3}
      rowGap={3}
      display="flex"
      flexDirection={isSmall || isMedium ? "column" : "row"}
    >
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={isSmall || isMedium ? "100%" : "50%"}
        height={400}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={isSmall || isMedium ? "100%" : "50%"}
        height={400}
      />
    </Box>
  );
};
