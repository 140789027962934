import { Components } from "@mui/material";
import { COLOR } from "./utils";

export const MuiTableCell: Components["MuiTableCell"] = {
  styleOverrides: {
    body: {
      border: "none",
      color: COLOR.WHITE,
    },
    head: {
      border: "none",
      color: COLOR.WHITE,
      fontSize: 16,
      fontWeight: 700,
    },
    footer: {
      border: "none",
      color: COLOR.WHITE,
    },
  },
};
//
// export const MuiTableCell: MuiComponentThemeDefinition<
//   TableCellClassKey,
//   TableCellProps
// > = {
//   props: {},
//   override: () => ({
//     body: {
//       border: "none",
//       color: COLOR.WHITE,
//     },
//     head: {
//       border: "none",
//       color: COLOR.WHITE,
//       fontSize: 16,
//       fontWeight: 700,
//     },
//     footer: {
//       border: "none",
//       color: COLOR.WHITE,
//     },
//   }),
// };
