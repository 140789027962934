import { format, subDays } from "date-fns";
import { useCallback, useMemo } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { searchJobPosts } from "../../../../api-services/job-posts";
import { JobPost } from "../../../../api-types/job-posts";
import { JobPostsSearchFilters } from "../../../../api-types/job-requests/common";
import { formatShortDate } from "../../../common/components/LoadingTypography";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { formatDate } from "../../job-requests/hooks/useSearchJobRequests";
import { useJobPostSearchFilters } from "../../job-requests/state/hooks";

export const SEARCH_JOB_POSTS_QUERY_KEY = "search-job-posts";

const searchKey = (filters: any) => {
  return `${SEARCH_JOB_POSTS_QUERY_KEY}-${JSON.stringify(filters)}`;
};

export const defaultJobPostSearchFilters: JobPostsSearchFilters = {
  search: "",
  date: "All",
  budget: {
    min: 0,
    max: 0,
  },
};

export const useSearchJobPosts = (
  useDefaultFilters?: boolean,
  options?: UseQueryOptions<JobPost[]>
) => {
  const queryClient = useQueryClient();

  const filters = useScopedDowngradedStateValue(useJobPostSearchFilters());

  const formattedDate = formatDate(filters.date);

  const queryResult = useQuery<JobPost[], unknown, JobPost[]>(
    searchKey(
      useDefaultFilters
        ? defaultJobPostSearchFilters
        : {
            ...filters,
            date: formattedDate
              ? format(formattedDate, formatShortDate)
              : undefined,
          }
    ),
    async () => {
      const { data } = await searchJobPosts(
        useDefaultFilters
          ? defaultJobPostSearchFilters
          : {
              ...filters,
              date: formattedDate
                ? format(formattedDate, formatShortDate)
                : undefined,
            }
      );

      return data;
    },
    {
      ...options,
    }
  );

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries(
      searchKey(
        useDefaultFilters
          ? defaultJobPostSearchFilters
          : {
              ...filters,
              date: formattedDate
                ? format(formattedDate, formatShortDate)
                : undefined,
            }
      )
    );
  }, [filters, formattedDate, queryClient, useDefaultFilters]);

  return { ...queryResult, invalidate };
};
