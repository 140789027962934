import { Formik } from "formik";
import { noop } from "lodash";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { AccountType } from "../../../../api-types/common";
import { useSearchJobRequests } from "../../../professional/job-requests/hooks";
import { useUser } from "../../../state/hooks";
import { BrowseMainbarForm } from "./BrowseMainbarForm";
import { MainbarSkeleton } from "./MainbarSkeleton";
import { SkeletonCollection } from "./SkeletonCollection";

const defaultOption = {
  id: "none",
  title: "Search skills or apply skill set from Job Request",
  skills: [],
  index: 0,
};

const defaultInitialValues = {
  skills: [],
  match: "match",
  fee: "fee",
};

type MainbarProps = {
  isMock?: boolean;
  onChangeCategory?: () => void;
};

export const BrowseMainbar: React.FC<MainbarProps> = ({
  isMock = false,
  onChangeCategory,
}) => {
  const [searchParams] = useSearchParams();

  const user = useUser().value;

  const { data: jobRequests, isLoading: isLoading } = useSearchJobRequests(
    false,
    { enabled: user.accountType === AccountType.PROFESSIONAL }
  );

  const filteredJobRequests = useMemo(() => {
    if (user.accountType === "client") {
      return jobRequests?.filter(({ createdBy }) => createdBy?._id === user.id);
    }

    return [];
  }, [jobRequests, user.accountType, user.id]);

  const jobRequestsOptions = useMemo(() => {
    if (isLoading || !filteredJobRequests?.length) {
      return [{ ...defaultOption, title: "Search skills" }];
    }

    const jobRequestsSummaries = filteredJobRequests.map(
      (jobRequest, index) => ({
        id: jobRequest._id,
        title: jobRequest.title,
        skills: jobRequest.skills.map((skill) => skill.name),
        index: 1 + index,
      })
    );

    return [defaultOption, ...jobRequestsSummaries];
  }, [isLoading, filteredJobRequests]);

  const skills = useMemo(() => {
    return (
      jobRequestsOptions.find(
        (option) => option.id === searchParams.get("jobRequestId")
      )?.skills ?? []
    );
  }, [jobRequestsOptions, searchParams]);

  const initialValues = useMemo(() => {
    if (isLoading || !jobRequestsOptions) return defaultInitialValues;

    return {
      ...defaultInitialValues,
      skills: skills,
    };
  }, [isLoading, jobRequestsOptions, skills]);

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={noop}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <SkeletonCollection
            isLoading={isLoading && !isMock}
            skeleton={<MainbarSkeleton />}
          >
            <BrowseMainbarForm
              jobRequestsOptions={jobRequestsOptions}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              setFieldValue={setFieldValue}
              onChangeCategory={onChangeCategory}
            />
          </SkeletonCollection>
        </form>
      )}
    </Formik>
  );
};
