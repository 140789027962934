import makeStyles from "@mui/styles/makeStyles";
import { BLUE, YELLOW } from "../../../../themes/components/utils";

export const useSearchJobPostFiltersStyles = makeStyles(() => ({
  checkbox: {
    "& .MuiSvgIcon-root": {
      fill: BLUE,
      height: 40,
      width: 40,
    },
  },
  input: {
    color: BLUE,
    border: `2px solid ${BLUE} !important`,
  },
  inputFocus: {
    border: `2px solid #FFF !important`,
  },
  inputLabel: {
    color: `${BLUE} !important`,
    backgroundColor: `${YELLOW} !important`,
    "&:focus": {
      backgroundColor: "transparent !important",
    },
  },
  label: {
    fontWeight: 600,
  },
  radio: {
    "& .MuiSvgIcon-root": {
      fill: BLUE,
      height: 40,
      width: 40,
    },
  },
  dropdownIcon: {
    transform: "scale(0.8)",
    marginRight: "10px",
    marginLeft: "11px",
  },
  boxContainerReset: {
    marginLeft: "16px",
    borderRadius: 2,
    borderColor: "white",
    fontSize: 24,
    width: 56,
  },
  boxContainer: {
    alignItems: "center",
    border: `2px solid${BLUE}`,
    height: 43,
  },
  mainBoxContainer: {
    marginLeft: "16px",
  },
  selectLabel: {
    color: BLUE,
  },
  disabledLabel: {
    opacity: 0.6,
  },
}));
