import { Box, Skeleton } from "@mui/material";
import React from "react";

export const PaymentReportsSkeleton: React.FC = () => {
  return (
    <Box>
      <Box display="flex" pt={24} width="100%" justifyContent="space-between">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={30}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="10%"
          height={30}
        />
      </Box>

      <Box pt={10} width="100%">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={40}
        />
      </Box>

      <Box display="flex" pt={4} width="100%" columnGap={6}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
      </Box>
      <Box display="flex" pt={4} width="100%" columnGap={6}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
      </Box>
      <Box display="flex" pt={4} width="100%" columnGap={6}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
      </Box>
      <Box display="flex" pt={4} width="100%" columnGap={6}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
      </Box>
      <Box display="flex" pt={4} width="100%" columnGap={6}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
      </Box>
      <Box display="flex" pt={4} width="100%" columnGap={6}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={40}
        />
      </Box>

      <Box
        mt={4}
        pr={4}
        width="100%"
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        columnGap={4}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="10%"
          height={25}
        />
        <Box display="flex" columnGap={4} alignItems="center">
          <Skeleton
            variant="circular"
            animation="wave"
            width={24}
            height={24}
          />
          <Skeleton
            variant="circular"
            animation="wave"
            width={24}
            height={24}
          />
        </Box>
      </Box>
    </Box>
  );
};
