import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo } from "react";
import { LIGHT_BLUE } from "../../../themes/components/utils";
import { PageWrapper } from "../../app/container/PageWrapper";
import { LoadingTypography } from "../../common/components";
import { useJobApplicationsQuery } from "../../common/hooks/job-applications";
import { useResponsiveness } from "../../common/hooks/utils/useResponsiveness";
import { SkeletonCollection } from "../../common/professionals-search/browse-mainbar/SkeletonCollection";
import { JobPostItem } from "../job-requests/search-job-requests-list/job-post-item";
import { JobRequestsSkeleton } from "../job-requests/search-job-requests-list/JobRequestsSkeleton";

export const JobPostApplications = () => {
  const { data: jobApplications, isLoading } = useJobApplicationsQuery();
  const { isSmall, isMedium } = useResponsiveness();

  const filteredJobApplications = useMemo(
    () =>
      jobApplications?.filter(
        (jobApplication) => jobApplication.jobPost !== null
      ) ?? [],
    [jobApplications]
  );

  return (
    <PageWrapper>
      <Box
        pt={10}
        mb={10}
        pb={5}
        style={{ borderBottom: isSmall ? "none" : `1px solid ${LIGHT_BLUE}` }}
      >
        <Box width="50%">
          <LoadingTypography
            isLoading={isLoading}
            style={{ fontWeight: 650 }}
            typographyProps={{
              variant: "h2",
            }}
          >
            You Applied For {filteredJobApplications.length} Job(s)
          </LoadingTypography>
        </Box>
        <Box width="75%" pt={2.5} pl={1}>
          <LoadingTypography
            isLoading={isLoading}
            typographyProps={{
              variant: "subtitle2",
            }}
          >
            Jobs the client has deleted are automatically removed from your
            list.
          </LoadingTypography>
        </Box>
      </Box>
      <SkeletonCollection
        isLoading={isLoading}
        skeleton={
          <Box pt={2}>
            <JobRequestsSkeleton />
            <JobRequestsSkeleton />
            <JobRequestsSkeleton />
          </Box>
        }
      >
        {filteredJobApplications.map((jobApplication) => (
          <Box key={jobApplication._id} py={10}>
            <JobPostItem
              jobPost={jobApplication.jobPost}
              jobApplication={jobApplication}
            />
            <Box py={2.5}>
              <Divider />
            </Box>
          </Box>
        ))}
      </SkeletonCollection>
    </PageWrapper>
  );
};
