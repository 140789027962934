import { Box, Link } from "@mui/material";
import React from "react";
import { LoadingTypography } from "../../../common/components";
import { useMetadataQuery } from "../../../common/hooks/metadata/useMetadataQuery";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";

export const InquiryHeaderSummary = () => {
  const { isMedium, isSmall } = useResponsiveness();
  const { data: metadata, isLoading } = useMetadataQuery();

  const { professionalReferralsCount = 0, clientInvitationsCount = 0 } =
    metadata ?? {};

  return (
    <Box
      maxWidth={300}
      flexGrow={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      py={10}
    >
      <Box
        justifyContent={isSmall ? "center" : "flex-start"}
        alignItems="center"
      >
        <LoadingTypography
          isLoading={isLoading}
          lineHeight={2}
          typographyProps={{
            variant: "subtitle1",
            // className: boldStyles.root,
          }}
        >
          Client Invitations
        </LoadingTypography>
        <Box display="flex" flexDirection="column" rowGap={1.5}>
          <LoadingTypography
            isLoading={isLoading}
            typographyProps={{ variant: "subtitle2" }}
          >
            You were invited to consider {clientInvitationsCount} project(s)
          </LoadingTypography>
          {clientInvitationsCount > 0 && (
            <LoadingTypography
              isLoading={isLoading}
              typographyProps={{
                variant: "subtitle2",
              }}
            >
              <Link
                href="/invitations/"
                underline="none"
                sx={{
                  maxWidth: isMedium ? "100%" : 160,
                  marginTop: isMedium ? "8px" : "4",
                  display: "block",
                }}
              >
                View Client Invitations Page
              </Link>
            </LoadingTypography>
          )}
        </Box>
      </Box>
      <Box>
        <LoadingTypography
          isLoading={isLoading}
          lineHeight={2}
          typographyProps={{
            // className: boldStyles.root,
            variant: "subtitle1",
          }}
        >
          Job Referrals From Pros
        </LoadingTypography>
        <Box display="flex" flexDirection="column" rowGap={1.5}>
          <LoadingTypography
            isLoading={isLoading}
            typographyProps={{
              variant: "subtitle2",
            }}
          >
            You were referred to {metadata?.professionalReferralsCount ?? "0"}{" "}
            project(s)
          </LoadingTypography>
          {professionalReferralsCount > 0 && (
            <LoadingTypography
              isLoading={isLoading}
              typographyProps={{
                variant: "subtitle2",
              }}
            >
              <Link href="/professional-referrals/">View Referrals Page</Link>
            </LoadingTypography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
