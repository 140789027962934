import { Box, Link } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { LoadingTypography } from "../../../../common/components";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import { useMetadataQuery } from "../../../../common/hooks/metadata/useMetadataQuery";

export const formatShortDate = "MM/dd";

export const JobRequestHeaderSummary: React.FC = () => {
  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();
  const projectsCount = metadata?.projectsCount ?? 0;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height={250}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="inherit"
        py={2}
      >
        <LoadingTypography
          isLoading={isLoadingMetadata}
          width="100%"
          typographyProps={{
            variant: "subtitle1",
            // className: boldStyles.root,
          }}
        >
          Status of Your {projectsCount} On-going Project(s)
        </LoadingTypography>
        {metadata?.onGoingProjectsTimestamps
          ?.slice(0, 4)
          .map((createdAt: string, index) => (
            <LoadingTypography
              typographyProps={{
                variant: "body1",
              }}
              key={index}
            >
              Project started on{" "}
              {format(new Date(createdAt), formatNominativeMonth)}
            </LoadingTypography>
          ))}
        <LoadingTypography
          isLoading={isLoadingMetadata}
          typographyProps={{
            variant: "subtitle2",
          }}
        >
          {projectsCount > 0 && (
            <Link href="/projects/">View on-going project(s)</Link>
          )}
        </LoadingTypography>
      </Box>
    </Box>
  );
};
