import { Box } from "@mui/material";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import {
  FixedPriceFundingDetails,
  JobRequestFixedPriceMilestoneTable,
  MilestonesDetails,
} from "../../utils";
import { useUpdateFixedProjectFunding } from "../hooks";

export const FixedPriceFundingContent = () => {
  useUpdateFixedProjectFunding();
  const { isSmall } = useResponsiveness();

  return (
    <Box
      display={isSmall ? "column" : "flex"}
      justifyContent="space-between"
      columnGap={5}
      flexWrap="wrap"
    >
      <MilestonesDetails />

      <Box width={isSmall ? "100%" : "60%"} mt={isSmall ? 6 : 0}>
        <Box display="flex" flexDirection="column" flexWrap="wrap" gap={10}>
          <FixedPriceFundingDetails />

          <JobRequestFixedPriceMilestoneTable />
        </Box>
      </Box>
    </Box>
  );
};
