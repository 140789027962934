import { Box, Typography } from "@mui/material";
import { Field, FormikErrors, FormikValues } from "formik";
import React, { ChangeEvent, useMemo } from "react";
import { CustomCheckbox } from "../../../../common/elements/CustomCheckbox";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";

type ServicesProps = {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
};

export const initialServicesValues = {
  services: {
    designing: false,
    marketing: false,
    development: false,
    writing: false,
  },
  project: "new",
};

export const Services: React.FC<ServicesProps> = ({
  handleBlur,
  handleChange,
  errors,
  values,
}) => {
  const { isSmall, isMedium } = useResponsiveness();
  const width = useMemo(() => {
    if (isSmall) {
      return 340;
    }
    if (isMedium) {
      return 400;
    }
    return 500;
  }, [isSmall, isMedium]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      pt={10}
    >
      <Box
        display="flex"
        flexDirection={isMedium ? "column" : "row"}
        columnGap={isMedium ? 20 : 83}
        alignItems="flex-start"
      >
        <Box
          width={isMedium ? "100%" : "30%"}
          display="flex"
          flexDirection="column"
          rowGap={5}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
            }}
          >
            Services Sought
          </Typography>
          <Box flexDirection="column">
            <Typography variant="subtitle2" mb={4}>
              Select the kind of professional you're looking for.
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            rowGap={15}
          >
            <Box
              alignItems={isSmall ? "flex-start" : "flex-end"}
              display="flex"
              flexDirection="column"
              rowGap={2.5}
            >
              <Field
                component={CustomCheckbox}
                width={width}
                label="Design & Creative"
                name="services.designing"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.designing}
                value={values.services.designing}
              />
              <Field
                component={CustomCheckbox}
                width={width}
                label="Marketing & Strategy"
                name="services.marketing"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.marketing}
                value={values.services.marketing}
              />
              <Field
                component={CustomCheckbox}
                width={width}
                label="Web, Mobile & Software Development"
                name="services.development"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.development}
                value={values.services.development}
              />
              <Field
                component={CustomCheckbox}
                width={width}
                label="Writing & Content Creation"
                name="services.writing"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.writing}
                value={values.services.writing}
              />
            </Box>

            {/*TODO: don't remove commented code, will be added back in the future*/}
            {/*<Box>*/}
            {/*  <Box pb={5}>*/}
            {/*    <Typography variant="subtitle2" className={boldStyles.root}>*/}
            {/*      Do you want to create a new design, website*/}
            {/*      <br />*/}
            {/*      or app, or change an existing one?*/}
            {/*    </Typography>*/}
            {/*  </Box>*/}

            {/*  <RadioGroup>*/}
            {/*    <Field*/}
            {/*      as={FormControlLabel}*/}
            {/*      name="project"*/}
            {/*      value="new"*/}
            {/*      control={*/}
            {/*        <Radio*/}
            {/*          className={radioStyles.root}*/}
            {/*          checked={values.project === "new"}*/}
            {/*        />*/}
            {/*      }*/}
            {/*      label="Build something new"*/}
            {/*    />*/}
            {/*    <Field*/}
            {/*      as={FormControlLabel}*/}
            {/*      name="project"*/}
            {/*      value="existing"*/}
            {/*      control={*/}
            {/*        <Radio*/}
            {/*          className={radioStyles.root}*/}
            {/*          checked={values.project === "existing"}*/}
            {/*        />*/}
            {/*      }*/}
            {/*      label="Change something existing"*/}
            {/*    />*/}
            {/*  </RadioGroup>*/}
            {/*</Box>*/}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
