import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { LoadingTypography } from "../../../common/components";
import { formatNominativeMonth } from "../../../common/components/LoadingTypography";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";

export const ProjectTitle: React.FC = () => {
  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const { isSmall } = useResponsiveness();

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      columnGap={10}
    >
      <LoadingTypography
        width={400}
        isLoading={isLoadingProject}
        typographyProps={{
          variant: "subtitle1",
        }}
      >
        {project?.title}
      </LoadingTypography>
      {!isSmall && project?.createdAt && (
        <Typography variant="body2">
          Started {format(new Date(project?.createdAt), formatNominativeMonth)}
        </Typography>
      )}
      {!isSmall && project?.client && (
        <Box display="flex" columnGap={2.5} width="15%">
          <Typography variant="body2">
            Posted by
            {`${project?.client?.accountInformation?.firstName} ${project?.client?.accountInformation?.lastName}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
