import { Box } from "@mui/material";
import React from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useMobileStyles,
} from "../../../../../../themes/style-hooks";
import { MainActions } from "../../../MainActions";

export const LandingStepCContent = () => {
  const { mobileContentWrapper, mobileDescriptionWrapper } = useMobileStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: COLOR.YELLOW,
    color: COLOR.BROWN,
    hover: COLOR.YELLOW,
  });
  const descriptionText = useDescriptionText({
    color: COLOR.WHITE_BROWN,
  });

  return (
    <Box className={mobileContentWrapper}>
      <Box className={mobileDescriptionWrapper}>
        <Box textAlign="left" color={COLOR.WHITE_PINK}>
          <Box className={descriptionText.root}>
            All bids are free for professionals, even before signing up.
          </Box>
          <Box className={descriptionText.root}>
            Bid on any project! As a professional, you donʼt need to earn
            credits on the platform to become eligible to bid on projects.
          </Box>
        </Box>
        <Box pt={8} textAlign="left">
          <Box className={descriptionText.root}>
            Sign up for a monthly subscription of $25 per month only when you
            start working on a project.
          </Box>
        </Box>
      </Box>
      <MainActions classNames={`${common} ${root}`} />
    </Box>
  );
};
