import { Components, MenuClassKey, MenuProps } from "@mui/material";
import { MuiComponentThemeDefinition } from "../utils";
import { LIGHT_BLUE } from "./utils";

export const MuiMenu: Components["MuiMenu"] = {
  styleOverrides: {
    list: {
      backgroundColor: LIGHT_BLUE,
      padding: 0,
    },
  },
};
//
// export const MuiMenu: MuiComponentThemeDefinition<MenuClassKey, MenuProps> = {
//   props: {},
//   override: () => ({
//     list: {
//       backgroundColor: LIGHT_BLUE,
//       padding: 0,
//     },
//   }),
// };
