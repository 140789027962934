import { FundingDashboard } from "../../modules/client/funding";
import { EditJobPost, JobPostsDashboard } from "../../modules/client/job-posts";
import { JobsPackagesDashboard } from "../../modules/client/job-posts/jobs-packages";
import { FreelanceJobRequestWizard } from "../../modules/client/job-requests/create-job-request-wizard";
import {
  JobPostWizard,
  NewJobPost,
} from "../../modules/client/job-requests/create-job-request-wizard/job-post";
import { CurrentJobRequest } from "../../modules/client/job-requests/current-job-request";
import { DraftJobRequests } from "../../modules/client/job-requests/drafts";
import { PaymentDashboard } from "../../modules/client/payment";
import { ProjectDetails } from "../../modules/client/projects";
import { PastProjectDetails } from "../../modules/client/projects/past-projects";
import { PaymentReports } from "../../modules/common/account/payment-reports";
import { InviteProfessional } from "../../modules/common/professionals-search/professionals/InviteProfessional";
import { PrivateRoute } from "../PrivateRoute";

export const JOB_REQUEST_CREATION_ROUTE_URL = "/job-request/new";
export const JOB_REQUEST_FREELANCE_CREATION_ROUTE_URL =
  "/job-request/freelance/new";

export const clientRoutes = [
  <PrivateRoute
    path="/invite/:professionalId"
    element={<InviteProfessional />}
  />,
  <PrivateRoute path="/payment/" element={<PaymentDashboard />} />,
  <PrivateRoute path="/jobs-packages/" element={<JobsPackagesDashboard />} />,
  <PrivateRoute path="/funding/:jobRequestId" element={<FundingDashboard />} />,
  <PrivateRoute path="/projects/:projectId" element={<ProjectDetails />} />,
  <PrivateRoute path="/projects/" element={<ProjectDetails />} />,
  <PrivateRoute
    path="/projects/history/:projectId"
    element={<PastProjectDetails />}
  />,
  <PrivateRoute path="/projects/history" element={<PastProjectDetails />} />,
  <PrivateRoute
    path={JOB_REQUEST_CREATION_ROUTE_URL}
    element={<JobPostWizard />}
  />,
  <PrivateRoute
    path={"/job-requests/drafts/:jobRequestId"}
    element={<FreelanceJobRequestWizard />}
  />,
  <PrivateRoute
    path={JOB_REQUEST_FREELANCE_CREATION_ROUTE_URL}
    element={<FreelanceJobRequestWizard />}
  />,
  <PrivateRoute path="/job-requests/drafts/" element={<DraftJobRequests />} />,
  <PrivateRoute
    path="/job-requests/:jobRequestId"
    element={<CurrentJobRequest />}
  />,
  <PrivateRoute path="/job-requests/" element={<CurrentJobRequest />} />,
  <PrivateRoute path="/payment-reports/" element={<PaymentReports />} />,
  <PrivateRoute path="/job-posts/" element={<JobPostsDashboard />} />,
  <PrivateRoute path="/job-post/new" element={<NewJobPost />} />,
  <PrivateRoute path="/job-posts/:jobPostId" element={<EditJobPost />} />,
  // @todo bring back companies later
  // <PrivateRoute path="/companies" element={<TeamManagementDashboard />} />,
  // <PrivateRoute path="/companies/create" element={<CreateCompany />} />,
  // <PrivateRoute path="/companies/invite" element={<CompanyInvite />} />,
  // <PrivateRoute path="/companies/my-companies" element={<MyCompanies />} />,
  // <PrivateRoute path="/companies/my-invites" element={<MyCompanyInvites />} />,
];
