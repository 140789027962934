import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, Input, InputAdornment } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FieldProps } from "formik";
import React, {
  useState,
  useEffect,
  useMemo,
  ChangeEvent,
  ReactNode,
} from "react";
import { BLUE, COLOR, RED, YELLOW } from "../../../themes/components/utils";
import { CustomTooltip } from "../components";

export const useStyles = makeStyles({
  container: {
    position: "relative",
    zIndex: 1,
    display: "inline-block",
    width: "100%",
    "-webkit-transition": "background-color 0.3s, border-color 0.3s",
    transition: "background-color 0.3s, border-color 0.3s",
    height: "68px",
    backgroundColor: "transparent",
  },
  input: {
    width: "100%",
    "-webkit-transition": "background-color 0.3s, border-color 0.3s",
    transition: "background-color 0.3s, border-color 0.3s",
    position: "relative",
    display: "block",
    padding: "1em",
    fontFamily: "Jost !important",
    fontSize: "16px",
    borderRadius: 0,
    color: COLOR.WHITE,
    fontWeight: 400,
    "-webkit-appearance": "none" /* for box shadows to show on iOS */,
    height: "100%",
    backgroundColor: "transparent",
    border: `2px solid ${COLOR.WHITE}`,
    outline: "none",
    "& :-webkit-autofill": {
      "-webkit-box-shadow": `0 0 0 50px ${COLOR.BLUE} inset !important`,
      transition: "background-color 600000s 0s, color 600000s 0s",
    },
  },
  inputFocus: {
    border: `2px solid ${YELLOW}`,
  },
  inputError: {
    border: `2px solid ${RED}`,
  },
  label: {
    textAlign: "left",
    position: "absolute",
    bottom: "102%",
    pointerEvents: "none",
    overflow: "hidden",
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: BLUE,
    left: 6,
    width: "fit-content",
    "-webkit-transform": "translate3d(0, 3em, 0)",
    transform: "translate3d(0, 3em, 0)",
    "-webkit-transition": "-webkit-transform 0.3s, background 0.5s",
    transition: "transform 0.3s, background 0.5s",
    display: "inline-block",
    padding: "0 1.6em",
    color: "rgba(255,255,255,0.6)",
    "-webkit-font-smoothing": "antialiased",
    "-moz-osx-font-smoothing": "grayscale",
    "-webkit-touch-callout": "none",
    "-webkit-user-select": "none",
    "-khtml-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
    letterSpacing: "1px",
    textTransform: "capitalize",
  },
  labelFocus: {
    "-webkit-transform": "translate3d(0, -30%, 0)",
    transform: "translate3d(0, -30%, 0)",
    color: YELLOW,
    "-webkit-transition": "-webkit-transform 0.3s, background 0.1s",
    transition: "transform 0.3s, background 0.1s",
    background: "transparent",
    opacity: 1,
    fontWeight: 700,
  },
  endAdornment: {
    color: "inherit",
    position: "absolute",
    right: 15,
    top: 34,
    columnGap: 8,
  },
});

type CustomInputProps = Partial<FieldProps> & {
  value: string;
  placeholder: ReactNode;
  type?: string;
  handleChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => void;
  handleFocus?: any;
  error?: string;
  onKeyDown?: any;
  customStyles?: {
    input?: string;
    inputFocus?: string;
    label?: string;
  };
  disabled?: boolean;
};

export const CustomInput: React.FC<CustomInputProps> = ({
  field,
  form,
  value,
  placeholder,
  type = "text",
  handleChange,
  error,
  handleFocus,
  onKeyDown,
  customStyles,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const styles = useStyles();
  const hasValue = Boolean(value);
  const [focus, setFocus] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const fieldName = field?.name ?? "";

  useEffect(() => {
    if (hasValue && !focus) {
      setFocus(true);
    }
  }, [focus, hasValue, value]);

  const isPassword = type === "password";

  const inputType = useMemo(() => {
    if (isPassword) {
      return showPassword ? "text" : "password";
    }

    return type;
  }, [isPassword, showPassword, type]);

  const isFilledOrFocused = value || focus;

  return (
    <div
      className={styles.container}
      onFocus={() => setFocus(true)}
      onBlur={() => !hasValue && setFocus(false)}
    >
      <Input
        disabled={disabled}
        aria-autocomplete="none"
        disableUnderline
        autoComplete="off"
        onKeyDown={onKeyDown}
        id={`id-${fieldName}`}
        type={inputType}
        className={`${styles.input} ${
          isFilledOrFocused
            ? `${styles.inputFocus} ${customStyles?.inputFocus}`
            : ""
        } ${error ? styles.inputError : ""} ${customStyles?.input}`}
        onChange={(event) => {
          form?.setFieldValue(fieldName, event.target.value);
          handleChange?.(event, event.target.value);
          // handleFocus?.(fieldName, event.target.value);
        }}
        endAdornment={
          <InputAdornment position="start" className={styles.endAdornment}>
            {isPassword && (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                color="primary"
                disabled={!value}
                size="large"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            )}

            {error && (
              <CustomTooltip title={error} type="error">
                <IconButton edge="end" size="large">
                  <ErrorRoundedIcon
                    color="error"
                    className="error"
                    data-cy-error={fieldName}
                    style={{ cursor: "pointer" }}
                  />
                </IconButton>
              </CustomTooltip>
            )}
          </InputAdornment>
        }
        value={value}
        data-cy-input
      />
      <label
        className={`${styles.label} ${
          isFilledOrFocused ? styles.labelFocus : ""
        } ${customStyles?.label}`}
        htmlFor={`id-${fieldName}`}
      >
        <span>{placeholder}</span>
      </label>
    </div>
  );
};
