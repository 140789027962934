import { Box, Typography } from "@mui/material";
import { FormikValues } from "formik";
import React, { useMemo } from "react";
import { Skill, SkillV2 } from "../../../../../api-types/job-requests/common";
import { SkillsInput } from "../../../../common/components/SkillsInput";
import { BackButton } from "../../../../common/elements/BackButton";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";

type SkillsProps = {
  onBack?: () => void;
  handleChange: (newSkills: SkillV2[]) => void;
  values: FormikValues;
};

export const Skills: React.FC<SkillsProps> = ({
  onBack,
  handleChange,
  values,
}) => {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } =
    useResponsiveness();
  const SkillInputWidth = useMemo(() => {
    if (isExtraSmall) {
      return 280;
    }
    if (isSmall) {
      return 300;
    }
    if (isMedium) {
      return 400;
    }
    if (isLarge) {
      return 500;
    }
    if (isExtraLarge) {
      return 600;
    }
    return 830;
  }, [isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge]);

  return (
    <Box pt={10} width="100%">
      <BackButton onClick={onBack} backLabel="Back" />
      <Box
        display="flex"
        flexDirection={isMedium ? "column" : "row"}
        columnGap={15}
        pt={8}
      >
        <Box width={isMedium ? "100%" : "30%"}>
          <Typography
            variant="h1"
            sx={{
              fontWeight: "bold",
            }}
          >
            Select Skills You're Proficient In
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" width="80%">
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <SkillsInput
              width={SkillInputWidth}
              initialSkills={values.skills.map((skill: Skill) => skill.name)}
              handleChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
