import { Box } from "@mui/material";
import { Field, FormikErrors, FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { CustomInput } from "../../elements";
import { useRequiredFieldLabel } from "../../hooks/utils";

type SignUpInfoFormProps = {
  handleChange: (e: ChangeEvent) => void;
  // eslint-disable-next-file no-undef
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
};

export const SignUpInfoForm: React.FC<SignUpInfoFormProps> = ({
  handleBlur,
  handleChange,
  values,
  errors,
}) => {
  const requiredFieldLabel = useRequiredFieldLabel();

  return (
    <Box display="flex" flexDirection="column" rowGap={12}>
      <Field
        component={CustomInput}
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        error={errors.firstName}
        variant="outlined"
        placeholder={requiredFieldLabel("First name")}
        name="firstName"
        id="firstName"
      />
      <Field
        component={CustomInput}
        value={values.lastName}
        error={errors.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        variant="outlined"
        placeholder={requiredFieldLabel("Last name")}
        name="lastName"
        id="lastName"
      />
      <Field
        component={CustomInput}
        fullWidth
        variant="outlined"
        placeholder={requiredFieldLabel("Email address")}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        name="email"
        id="email"
      />
      <Field
        component={CustomInput}
        fullWidth
        variant="outlined"
        placeholder={requiredFieldLabel("Password")}
        name="password"
        id="password"
        type="password"
        error={errors.password}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
      />
      <Field
        component={CustomInput}
        fullWidth
        variant="outlined"
        placeholder={requiredFieldLabel("Confirm password")}
        name="confirmPassword"
        id="confirmPassword"
        type="password"
        error={errors.confirmPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.confirmPassword}
      />
    </Box>
  );
};
