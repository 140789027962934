import makeStyles from "@mui/styles/makeStyles";

export const useDescriptionText = makeStyles({
  root: {
    fontSize: 28,
    color: (props: { color: string }) => props.color,
    ["@media screen and (max-width: 1200px), screen and (max-height: 900px)"]: {
      fontSize: 22,
    },
    ["@media screen and (max-width: 1024px), screen and (max-height: 650px)"]: {
      paddingRight: 0,
    },
    ["@media screen and (max-width: 850px)"]: {
      fontSize: 24,
    },
    ["@media screen and (max-width: 550px), screen and (max-height: 650px)"]: {
      fontSize: 20,
    },
    ["@media screen and (max-width: 340px), screen and (max-height: 430px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-height: 320px)"]: {
      fontSize: 14,
    },
  },
});
