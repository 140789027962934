import { Box, Typography } from "@mui/material";
import { FormikValues } from "formik";
import React from "react";
import { FileWithPath } from "react-dropzone";
import { BackButton } from "../../../../common/elements/BackButton";
import { ImageComponent } from "../../utils";

type ImagesProps = {
  onBack?: () => void;
  handleFocus?: (field: string, value: string) => void;
  handleChangeFileUpload?: (files: FileWithPath[]) => void;
  values: FormikValues;
};

export const Images: React.FC<ImagesProps> = ({
  onBack,
  handleChangeFileUpload,
  handleFocus,
  values,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="column"
      pt={10}
    >
      <BackButton onClick={() => onBack?.()} />
      <Box columnGap={5} pt={10}>
        <Box display="flex" flexDirection="column" rowGap={5}>
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
              }}
            >
              Job Request Images
            </Typography>
          </Typography>
          <Typography variant="subtitle2">
            Upload up to 5 images with size lower than 2mb that help to describe
            what you are looking to get done, whether they are sources of
            inspiration or starting points for your project
          </Typography>
        </Box>
        <ImageComponent
          images={values.images}
          handleChangeFileUpload={handleChangeFileUpload}
          handleFocus={handleFocus}
          maxFiles={5}
        />
      </Box>
    </Box>
  );
};
