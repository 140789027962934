import { Box, Skeleton } from "@mui/material";
import React from "react";

export const DraftsJobRequestSkeleton: React.FC = () => {
  return (
    <Box>
      <Box display="flex" width="100%">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="30%"
          height={45}
        />
      </Box>

      <Box
        mt={12}
        width="100%"
        columnGap={24}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={35}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={35}
        />
      </Box>

      <Box width="35%" pt={2}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
      </Box>

      <Box mt={4} mb={16} width="50%" columnGap={6} display="flex">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
      </Box>

      {/*----------------------------*/}

      <Box
        mt={26}
        width="100%"
        columnGap={24}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={35}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={35}
        />
      </Box>

      <Box width="35%" pt={2}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
      </Box>

      <Box mt={4} mb={16} width="50%" columnGap={6} display="flex">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
      </Box>

      <Box
        mt={26}
        width="100%"
        columnGap={24}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={35}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={35}
        />
      </Box>

      <Box width="35%" pt={2}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
      </Box>

      <Box mt={4} mb={16} width="50%" columnGap={6} display="flex">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
      </Box>

      <Box
        mt={26}
        width="100%"
        columnGap={24}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={35}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={35}
        />
      </Box>

      <Box width="35%" pt={2}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
      </Box>

      <Box mt={4} mb={16} width="50%" columnGap={6} display="flex">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={20}
        />
      </Box>
    </Box>
  );
};
