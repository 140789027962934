import { Box, Divider } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Project } from "../../../../../api-types/projects";
import { Section } from "../../../../common/components";
import { usePastProjectsQuery } from "../../../../common/hooks/projects/usePastProjectsQuery";
import { useToggleFeaturedProject } from "../../../projects/past-projects/unpublished-projects/hooks/useToggleFeaturedProject";
import { PortfolioRow } from "./PortfolioRow";
import { PortfolioSkeleton } from "./PortfolioSkeleton";

export const Portfolio: React.FC = () => {
  const { data: projects, isLoading: isLoadingProjects } =
    usePastProjectsQuery();

  const errorMessage = useMemo(() => {
    return !isLoadingProjects && projects?.length
      ? ""
      : "Portfolio projects list is empty. Upload them by clicking on Upload Portfolio Projects button.";
  }, [projects?.length, isLoadingProjects]);

  const projectsWithImages = useMemo(() => {
    return projects?.filter((project) => {
      return (project?.images?.length ?? 0) > 0;
    }, []);
  }, [projects]);

  const hasFeaturedProject = Boolean(
    projectsWithImages?.find((project) => project.isFeatured)
  );

  const { mutate: toggleFeaturedProject } = useToggleFeaturedProject();

  useEffect(() => {
    if (!hasFeaturedProject && (projectsWithImages?.length ?? 0) > 0) {
      toggleFeaturedProject(projectsWithImages![0]._id, {
        onSuccess: () => {},
      });
    }
  }, [hasFeaturedProject, projectsWithImages, toggleFeaturedProject]);

  const isFeaturedDisabled =
    hasFeaturedProject && (projectsWithImages?.length ?? 0) === 1;

  return (
    <Section
      isLoading={isLoadingProjects}
      errorMessage={errorMessage}
      loadingSkeleton={
        <Box display="flex" flexDirection="column" rowGap={6} pb={10}>
          <PortfolioSkeleton />
          <PortfolioSkeleton />
        </Box>
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        rowGap={6}
      >
        <Box width="100%">
          {projects?.map((project: Project) => (
            <Box key={project._id} width="100%">
              <PortfolioRow
                project={project}
                isFeaturedDisabled={isFeaturedDisabled}
              />
              <Box py={4}>
                <Divider />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Section>
  );
};
