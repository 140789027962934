import { Box } from "@mui/system";
import React from "react";
import { PageWrapper } from "../../app/container/PageWrapper";
import { InfoBox } from "../../common/components/InfoBox";
import { useMetadataQuery } from "../../common/hooks/metadata/useMetadataQuery";
import { SearchMainbar } from "./search-form";
import { MergedJobs } from "./search-job-requests-list";

export const SearchJobsPage: React.FC = () => {
  const { data: metadata } = useMetadataQuery();

  return (
    <PageWrapper>
      {metadata?.restricted && (
        <Box py={2}>
          <InfoBox
            messages={
              " This account is restricted. Placing bids will require having position, and description. Please finish your profile to remove all restrictions."
            }
          />
        </Box>
      )}
      <Box height="100%" display="flex" flexDirection="column">
        <SearchMainbar />

        <Box flexGrow={1} pt={1}>
          <MergedJobs />
        </Box>
      </Box>
    </PageWrapper>
  );
};
