import { Box, Divider, List, Typography, useTheme } from "@mui/material";
import { filter } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../state/hooks";
import { Section } from "../../components";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";
import { useSearchProfessionals } from "../hooks";
import { PaginationFooter } from "../PaginationFooter";
import { ProfessionalListItem } from "./ProfessionalListItem";
import { ProfessionalsSkeleton } from "./ProfessionalsSkeleton";

const defaultRowsPerPage = 5;
const itemsPerRow = 1;

type ProfessionalsPage = {
  page: number;
  setPage: (page: number) => void;
};

export const Professionals: React.FC<ProfessionalsPage> = ({
  page,
  setPage,
}) => {
  const { data: professionals, isLoading: isLoadingProfessionals } =
    useSearchProfessionals();
  const { palette } = useTheme();

  const { isSmall, isMedium } = useResponsiveness();

  const navigate = useNavigate();

  const currentUser = useUser();

  const filteredProfessionals = useMemo(
    () => filter(professionals, ({ _id }) => _id !== currentUser.id.value),
    [currentUser.id, professionals]
  );

  const totalPages = useMemo(
    () =>
      filteredProfessionals
        ? Math.ceil(
            filteredProfessionals.length / (defaultRowsPerPage * itemsPerRow)
          ) + 1
        : 1,
    [filteredProfessionals]
  );

  const currentPageFilteredItems = useMemo(() => {
    const itemsCount = defaultRowsPerPage * itemsPerRow;
    const endIndex = page * itemsCount;

    return filteredProfessionals
      ? filteredProfessionals.slice(endIndex - itemsCount, endIndex)
      : [];
  }, [page, filteredProfessionals]);

  const handleChangePage = useCallback(
    (event: Object, page: number) => {
      setPage(page);

      // eslint-disable-next-line no-undef
      window.scrollTo({ top: 0 });
    },
    [setPage]
  );

  return (
    <React.Fragment>
      <Section
        isLoading={isLoadingProfessionals}
        loadingSkeleton={
          <Box
            display="flex"
            flexDirection="column"
            rowGap={9}
            pt={isSmall || isMedium ? 20 : 0}
          >
            <ProfessionalsSkeleton />
            <ProfessionalsSkeleton />
            <ProfessionalsSkeleton />
          </Box>
        }
      >
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {!isLoadingProfessionals && page === totalPages ? (
            <Box
              bgcolor={palette.primary.main}
              flexGrow={1}
              maxHeight={300}
              mt={30}
              mx={-15}
              px={15}
              py={8}
            >
              <Typography
                color="secondary"
                variant="subtitle1"
                style={{ fontWeight: 700 }}
              >
                Professionals and Teams Notifications
              </Typography>
              <Typography
                color="secondary"
                variant="body1"
                style={{ fontWeight: 500 }}
              >
                The site is in an early access stage. For the time being we will
                be automatically notifying you of matches for your job request.
              </Typography>
            </Box>
          ) : (
            <List disablePadding={true}>
              {currentPageFilteredItems?.map((professional, index) => (
                <Box key={index} pt={6}>
                  <ProfessionalListItem
                    professional={professional}
                    onSelect={() => {
                      navigate(`/professionals/${professional._id}`);
                    }}
                    isLoading={isLoadingProfessionals}
                  />
                  <Box pt={6}>
                    <Divider />
                  </Box>
                </Box>
              ))}
            </List>
          )}
          <PaginationFooter
            noCount
            boxProps={{ pt: isMedium ? 0 : 10, pb: 20 }}
            rowsPerPage={defaultRowsPerPage}
            totalPages={totalPages}
            currentPage={page}
            onPageChange={handleChangePage}
            totalCount={filteredProfessionals?.length ?? 0}
            objectNamePlural="professionals"
            objectNameSingular="professional"
          />
        </Box>
      </Section>
    </React.Fragment>
  );
};
