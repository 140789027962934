import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useMetadataQuery } from "../../../../common/hooks/metadata/useMetadataQuery";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";

export const ProfessionalReferralHeaderSummary: React.FC = () => {
  const { data: metadata } = useMetadataQuery();
  const { isMedium } = useResponsiveness();
  const { clientInvitationsCount = 0, accountInquiriesCount = 0 } =
    metadata ?? {};

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={0.5}
      width={isMedium ? "100%" : "35%"}
      marginTop={isMedium ? 8 : 0}
    >
      <Box display="flex" flexDirection="column">
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
          }}
        >
          Client Invitations
        </Typography>
        <Typography variant="subtitle2">
          You were invited to consider {clientInvitationsCount} projects
        </Typography>
        {clientInvitationsCount > 0 && (
          <Typography variant="subtitle2">
            <Link href="/invitations/">View Client Invitations Page</Link>
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
          }}
        >
          Job Request Inquiries
        </Typography>
        <Typography variant="subtitle2">
          You placed {accountInquiriesCount} job request inquiries
        </Typography>
        {accountInquiriesCount > 0 && (
          <Typography variant="subtitle2">
            <Link href="/inquiries/">View Job Inquiries Page</Link>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
