import { Box, Button, Divider, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useMemo } from "react";
import { AccountInformation as AccountInformationFormType } from "../../../../api-types/account-information";
import { initialAccountInformation } from "../../../../api-types/billing-information";
import { ConfirmationDialog } from "../../components";
import { useAccountInformation } from "../../hooks/account-information/useAccountInformation";
import { useDeleteAccountMutation } from "../../hooks/account-information/useDeleteAccountMutation";
import { useUpdateAccountInformationMutation } from "../../hooks/account-information/useUpdateAccountInformationMutation";
import { useMetadataQuery } from "../../hooks/metadata/useMetadataQuery";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { useConfirmAccountDeletion } from "../state/hooks";
import { AccountInformationForm } from "./AccountInformationForm";

type AccountInformationProps = {
  label?: string;
  hasLabel?: boolean;
};

export const AccountInformationStep: React.FC<AccountInformationProps> = () => {
  const { mutate: updateAccountInformationMutation, isLoading: isUpdating } =
    useUpdateAccountInformationMutation();

  const { data: accountInformation, isLoading } = useAccountInformation();

  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();

  const accountInformationValues = useMemo((): AccountInformationFormType => {
    if (isLoading || !accountInformation) return initialAccountInformation;

    return accountInformation;
  }, [isLoading, accountInformation]);

  const { mutate: deleteAccount } = useDeleteAccountMutation();

  const { isSmall } = useResponsiveness();

  const handleClose = () => {
    setConfirmAccountDeletion({ open: false });
  };

  const confirmAccountDeletion = useScopedDowngradedStateValue(
    useConfirmAccountDeletion()
  );

  const handleConfirm = () => {
    deleteAccount(undefined);
    setConfirmAccountDeletion({ open: false });
  };

  const setConfirmAccountDeletion = useConfirmAccountDeletion().set;

  const isBalanceEmpty = (metadata?.balance?.total ?? 0) === 0;

  const { modalTitle, modalText } = useMemo(() => {
    if ((metadata?.projectsCount ?? 0) > 0) {
      return {
        modalTitle: "Current Projects Are Still Active",
        modalText: `Current projects are still on-going. Complete or cancel all current projects before deleting your account. 
                  \nWhen canceling a project, money already deposited in a Stripe escrow account towards a current milestone for a current project will be automatically deposited to the 
                professional(s) working on the project. Money commited to milestones other than the current one in an escrow account will be automatically returned to your account 
when canceling a project.`,
      };
    }

    return {
      modalTitle: "Are you sure you want to delete your account?",
      modalText: `By deleting your account, you are deleting all your profile information as well as any projects you posted.
                  Any reviews, ratings and record of projects completed on the platform will be lost. We won’t be able to retrieve it at a later time, should you decide to reinstate your profile.
                  \nIf you’d like, consider canceling your subscription instead. By canceling your subscription, you can keep all your projects and profile information just as they are. However, you will no longer appear in searches or be notified of jobs that match your skills.
                  \nOnce you restart your canceled subscription, all these features will be reinstated, and your subscription will be activated once your bid is accepted and you start working on a project.`,
    };
  }, [metadata?.projectsCount]);

  return (
    <>
      <Box height="inherit">
        <Box height="100%" flexGrow={1}>
          <Formik
            initialValues={accountInformationValues}
            enableReinitialize
            onSubmit={(values) => {
              updateAccountInformationMutation(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              errors,
              values,
              isValid,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: "1",
                }}
              >
                <Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Account Information
                    </Typography>
                    {isSmall ? null : (
                      <Box display="flex" columnGap={2.5}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setConfirmAccountDeletion({ open: true });
                          }}
                        >
                          Delete Account
                        </Button>
                        <Button
                          variant="contained"
                          disabled={true}
                          size="small"
                        >
                          Reset Password
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Box pt={2}>
                    <Divider />
                  </Box>
                  {isSmall ? (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      columnGap={2.5}
                      pt={2}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setConfirmAccountDeletion({ open: true });
                        }}
                      >
                        Delete Account
                      </Button>
                      <Button variant="contained" disabled={true} size="small">
                        Reset Password
                      </Button>
                    </Box>
                  ) : null}
                </Box>
                <Box pt={15}>
                  <AccountInformationForm
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values}
                    errors={errors}
                  />
                </Box>
                <Box
                  py={10}
                  flexGrow={1}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  columnGap={2.5}
                >
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={() =>
                      window.location.replace("browse?type=professionals")
                    }
                  >
                    Cancel
                  </Button>
                  <Button size="medium" variant="contained" type="submit">
                    Save Changes
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
      <ConfirmationDialog
        open={Boolean(confirmAccountDeletion.open)}
        onClose={handleClose}
        onConfirm={
          (metadata?.projectsCount ?? 0) > 0 ? undefined : handleConfirm
        }
        confirmLabel={"Delete My Account"}
        title={modalTitle}
        customHeader={!isBalanceEmpty}
        hasMoreLabel={!isBalanceEmpty}
        message={modalText}
      />
    </>
  );
};
