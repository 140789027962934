import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  DialogContent,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from "@mui/styles/makeStyles";
import { Field, Formik, FormikValues } from "formik";
import React, { forwardRef, ReactNode, useCallback } from "react";
import { BLUE, YELLOW } from "../../../../../themes/components/utils";
import { useHandleCloseDialog } from "../../../hooks/utils";
import { useDialogStyles } from "../subscribe-dialog/SubscribeDialog";

export type CancelProjectDialogProps = {
  open: boolean;
  title?: string;
  confirmLabel?: string;
  message?: ReactNode | string;
  onClose: () => void;
  onConfirm: (values: FormikValues) => void;
};

const useStyles = makeStyles({
  button: {
    height: 64,
    width: "fit-content",
    "& .MuiButton-label": {
      fontSize: 19,
      fontWeight: 550,
    },
    ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]: {
      height: 48,
      width: 75,
      "& .MuiButton-label": {
        fontSize: 16,
        fontWeight: 550,
      },
    },
    ["@media screen and (max-height: 300px)"]: {
      height: 30,
      width: 60,
      "& .MuiButton-label": {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
  title: {
    fontSize: 24,
    ["@media screen and (max-width: 1000px)"]: {
      fontSize: 18,
    },
  },
  subtitle: {
    fontSize: 21,
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: 19,
    },
    ["@media screen and (max-width: 860px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 720px)"]: {
      fontSize: 14,
    },
  },
  textArea: {
    background: "none",
    border: "1px solid",
    borderColor: BLUE,
    color: BLUE,
    fontFamily: "inherit",
    fontSize: 16,
    padding: 8,
    outline: "none",
    width: "100%",
    resize: "none",
  },
});

export const CancelProjectDialog = forwardRef<
  HTMLDialogElement,
  CancelProjectDialogProps
>((props, ref) => {
  const {
    title,
    message,
    onClose,
    onConfirm,
    confirmLabel = "Confirm Withdrawal",
    open,
  } = props;
  const styles = useDialogStyles(Boolean(message));
  const buttonStyles = useStyles();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleDialogClose = useHandleCloseDialog(onClose);

  return (
    <Dialog
      // @ts-ignore
      ref={ref}
      open={open}
      className={`${styles.fullWidth} `}
      onClose={handleDialogClose}
    >
      <Formik
        initialValues={{ closingDetails: "" }}
        onSubmit={(values) => onConfirm(values)}
      >
        {({ handleBlur, handleChange, handleSubmit, submitForm, values }) => (
          <form onSubmit={handleSubmit}>
            <Box onClick={handleClose} className={styles.closeIcon}>
              <CloseIcon />
            </Box>
            {title && (
              <DialogTitle>
                <Typography
                  color="secondary"
                  style={{
                    fontSize: 27,
                    fontWeight: 550,
                  }}
                >
                  {title}
                </Typography>
              </DialogTitle>
            )}
            {message && (
              <DialogContent>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  style={{ fontWeight: "normal" }}
                >
                  {message}
                </Typography>

                <Field
                  as={TextareaAutosize}
                  placeholder="Enter text here"
                  name="closingDetails"
                  value={values.closingDetails}
                  className={buttonStyles.textArea}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  minRows={3}
                  maxRows={3}
                />
              </DialogContent>
            )}
            <DialogActions>
              <Box display="flex" justifyContent="flex-end" columnGap={2.5}>
                <Button
                  variant="outlined"
                  style={{
                    color: BLUE,
                    borderColor: BLUE,
                  }}
                  onClick={onClose}
                  className={buttonStyles.button}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: BLUE,
                    color: YELLOW,
                  }}
                  variant="contained"
                  onClick={submitForm}
                  autoFocus
                  className={buttonStyles.button}
                >
                  {confirmLabel}
                </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
});
