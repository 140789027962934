import { Box, Button, Divider, Typography } from "@mui/material";
import { format } from "date-fns";
import { Formik } from "formik";
import { upperFirst } from "lodash";
import React, { useCallback, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { BidData } from "../../../../../api-types/job-requests/bids";
import { YELLOW } from "../../../../../themes/components/utils";
import {
  PlacedBidDetails,
  ConfirmationDialog,
} from "../../../../common/components";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import {
  MessagesProvider,
  MessagesInput,
} from "../../../../common/components/messages";
import { ProfessionalSummary } from "../../../../common/components/ProfessionalSummary";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useBidConfirmationModal } from "../../state/hooks";
import { useOnAcceptBidHandler } from "../hooks/useOnAcceptBidHandler";

type BidProps = {
  isLoading?: boolean;
  bid: BidData;
  onNewMessage: (value: string) => void;
};

export const Bid: React.FC<BidProps> = ({ bid, onNewMessage, isLoading }) => {
  const [reply, setReply] = useState(false);
  const { messages, createdAt, createdBy } = bid;

  const navigate = useNavigate();

  const bidConfirmationModal = useScopedDowngradedStateValue(
    useBidConfirmationModal()
  );
  const setBidConfirmationModal = useBidConfirmationModal().set;

  const onAcceptBidHandler = useOnAcceptBidHandler();

  const onTriggerAcceptBidHandler = useCallback(
    (bidId: string) => {
      if (bid?.jobRequest?.budget?.type === "fixed") {
        navigate({
          pathname: `/funding/${bid.jobRequest._id}`,
          search: `?${createSearchParams({
            bidId,
          })}`,
        });
      } else {
        onAcceptBidHandler(bidId);
      }
    },
    [
      bid.jobRequest._id,
      bid.jobRequest?.budget?.type,
      navigate,
      onAcceptBidHandler,
    ]
  );

  const handleClose = useCallback(() => {
    setBidConfirmationModal({ accept: false });
  }, [setBidConfirmationModal]);

  const onConfirmHandler = useCallback(() => {
    if (bidConfirmationModal.bidId) {
      onTriggerAcceptBidHandler(bidConfirmationModal.bidId);
      setBidConfirmationModal({ accept: false });
    }
  }, [
    bidConfirmationModal.bidId,
    onTriggerAcceptBidHandler,
    setBidConfirmationModal,
  ]);

  const isOfferSent = bid.status === "accepted";

  return (
    <Formik
      initialValues={{
        message: "",
      }}
      onSubmit={(values, { resetForm }) => {
        onNewMessage(values.message);
        resetForm();
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        values,
        isValid,
      }) => (
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ProfessionalSummary professional={createdBy} />
            <Typography variant="body1">
              Placed on {format(new Date(createdAt), formatNominativeMonth)}
            </Typography>
            {isOfferSent && (
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    color: YELLOW,
                    fontWeight: "bold",
                  }}
                >
                  Contract Offer:
                  <span
                    style={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {upperFirst(bid.offer.toLowerCase())}
                  </span>
                </Typography>
              </Box>
            )}
          </Box>
          <form onSubmit={handleSubmit}>
            {(messages.length > 0 || reply) && (
              <MessagesProvider
                messages={messages}
                boxProps={{
                  borderColor: "transparent",
                  padding: "0px",
                }}
                maxScrollHeight={400}
              >
                {reply && (
                  <MessagesInput
                    isLoading={isLoading}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                  />
                )}
              </MessagesProvider>
            )}
          </form>
          <Box pt={5}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 600,
              }}
            >
              Introductory Message:
            </Typography>
            <Box pt={2.5}>
              <Typography variant="body2">{bid.introductoryMessage}</Typography>
            </Box>
          </Box>
          <Box py={5}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 600,
              }}
            >
              Proposed Bid:
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            pb={1}
            mt={-5}
            alignItems="center"
          >
            <PlacedBidDetails bid={bid} />
            <Box display="flex" columnGap={3}>
              {reply ? (
                <Button
                  size="small"
                  onClick={() => {
                    setReply(false);
                  }}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  size="small"
                  onClick={() => {
                    setReply(true);
                  }}
                >
                  Send message
                </Button>
              )}
              {bid.status === "pending" && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setBidConfirmationModal({ accept: true, bidId: bid._id });
                  }}
                >
                  Accept bid
                </Button>
              )}
            </Box>
          </Box>
          <Box py={2.5}>
            <Divider />
          </Box>
          <ConfirmationDialog
            open={Boolean(bidConfirmationModal.accept)}
            onClose={handleClose}
            onConfirm={onConfirmHandler}
            confirmLabel={"Yes! Let's Get Started"}
            title="Are you ready to accept this bid?"
            message="The project request will no longer be listed and open to bids."
          />
        </Box>
      )}
    </Formik>
  );
};
