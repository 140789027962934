import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { YELLOW } from "../../../../themes/components/utils";

import { HourlyRateMilestone } from "../../../client/job-requests/create-job-request-wizard/validation-schema";
import { stringToTitleCase } from "../../../utils";

type HourlyRateDiffTableRowProps = {
  actions: ReactNode;
  actualWork?: {
    dueDate: number;
    costPerHour: number;
    hoursPerWeek: number;
  };
  estimatedCostPerWeek: {
    min: number;
    max: number;
  };
  hoursLabel: string;
  hourlyRateLabel: string;
  milestone?: HourlyRateMilestone;
};

export const HourlyRateDiffTableRow: FC<HourlyRateDiffTableRowProps> = ({
  actions,
  actualWork,
  estimatedCostPerWeek,
  hoursLabel,
  hourlyRateLabel,
  milestone,
}) => {
  const hasActualWork =
    actualWork?.dueDate || actualWork?.costPerHour || actualWork?.hoursPerWeek;

  const actualCost =
    (actualWork?.dueDate ?? 0) *
    (actualWork?.hoursPerWeek ?? 0) *
    (actualWork?.costPerHour ?? 0);

  return (
    <TableRow
      style={{
        backgroundColor: YELLOW,
        height: 64,
      }}
    >
      <TableCell />
      <TableCell>
        <Typography color="secondary" component="span" variant="body2">
          {hasActualWork ? "Actual work" : milestone?.description}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="secondary" component="span" variant="body2">
          {hasActualWork
            ? `${actualWork?.dueDate} week(s)`
            : `Between ${milestone?.dueDate.min} and ${milestone?.dueDate.max} ${milestone?.dueDate.type}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="secondary" component="span" variant="body2">
          {hasActualWork ? `${actualWork?.hoursPerWeek}hrs/wk` : hoursLabel}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="secondary" component="span" variant="body2">
          {hasActualWork ? `x $${actualWork?.costPerHour}/hr` : hourlyRateLabel}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="secondary" component="span" variant="body2">
          {hasActualWork
            ? `$${actualCost}`
            : `$${
                (milestone?.dueDate?.min ?? 0) * estimatedCostPerWeek.min
              } - $${
                (milestone?.dueDate?.max ?? 0) * estimatedCostPerWeek.max
              }`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="secondary" component="span" variant="body2">
          {hasActualWork ? "" : stringToTitleCase(milestone?.status ?? "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="flex-end" columnGap={2}>
          {actions}
        </Box>
      </TableCell>
    </TableRow>
  );
};
