import { Components } from "@mui/material";

export const MuiInput: Components["MuiInput"] = {
  styleOverrides: {
    root: {
      height: 68,
      borderRadius: 1,
    },
    sizeSmall: {
      height: 48,
    },
  },
};
