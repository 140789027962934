/* eslint-disable no-undef */
import React from "react";
import { JOB_REQUEST_CREATION_ROUTE_URL } from "../../../../routes/client";
import { ACCOUNT_EDIT_ROUTE_URL } from "../../../../routes/professional";
import { SUBSCRIBE_ROUTE } from "../../../app/container/App";
import { useUser } from "../../../state/hooks";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { Topbar as LandingTopbar } from "../../landing-page/Topbar";
import { MockNavbar } from "../../navbar/MockNavbar";
import { Navbar } from "../../navbar/Navbar";

export const Topbar: React.FC = () => {
  const { accountType } = useScopedDowngradedStateValue(useUser());

  const isLandingRoute = () =>
    location.pathname === "/" ||
    location.pathname.includes(SUBSCRIBE_ROUTE) ||
    location.pathname.includes("sign-in");

  const isWizard = () =>
    location.pathname.includes(JOB_REQUEST_CREATION_ROUTE_URL) ||
    location.pathname.includes(ACCOUNT_EDIT_ROUTE_URL) ||
    location.search.includes("wizard");

  const isOurBlog = () => location.pathname.includes("blog");

  if (isOurBlog()) return <LandingTopbar height={80} />;

  if (isLandingRoute() || isWizard()) return <MockNavbar inverse />;

  return <Navbar accountType={accountType} />;
};
