import { TableCell, TableHead, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LIGHT_BLUE } from "../../../themes/components/utils";
import { useResponsiveness } from "../../common/hooks/utils/useResponsiveness";

const useStyles = makeStyles(() => ({
  border: {
    borderTop: `0.5px solid ${LIGHT_BLUE}`,
  },
}));
export const JobPostsTableHead = () => {
  const styles = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={styles.border} style={{ minWidth: 190 }} />
        <TableCell
          className={styles.border}
          sx={{
            minWidth: 190,
          }}
        >
          Job Title
        </TableCell>
        <TableCell
          className={styles.border}
          sx={{
            maxWidth: 100,
          }}
        >
          Number of Applicants
        </TableCell>
        <TableCell
          className={styles.border}
          sx={{
            maxWidth: 120,
          }}
        >
          Method of Application
        </TableCell>
        <TableCell
          className={styles.border}
          sx={{
            minWidth: 70,
          }}
        >
          Resumes
        </TableCell>
        <TableCell
          className={styles.border}
          sx={{
            minWidth: 70,
          }}
        >
          Days Remaining (from 90)
        </TableCell>
        <TableCell align="right" className={styles.border}>
          Job Post Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
