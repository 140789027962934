import { Box, Divider, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useMemo } from "react";
import {
  TextOverflow,
  ShowImages,
  LoadingTypography,
  Section,
} from "../../../../common/components";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { useProjectQuery } from "../../../../common/hooks/projects/project/useProjectQuery";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { Description } from "../../../../common/professionals-search/projects/project/sections/Description";

export const Details: React.FC = () => {
  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const { isSmall } = useResponsiveness();

  const skills = useMemo(() => {
    if (!project?.skills || isLoadingProject) {
      return [];
    }

    return project?.skills.map((skill) => skill.name);
  }, [isLoadingProject, project?.skills]);

  const hasImages = Boolean(project?.jobRequest.images?.length);

  return (
    <Section
      isLoading={isLoadingProject}
      // loadingSkeleton={<BidDetailsSkeleton />}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <LoadingTypography
          width={300}
          isLoading={isLoadingProject}
          typographyProps={{
            variant: "subtitle1",
            // className: boldStyles.root,
          }}
        >
          {project?.jobRequest?.title}
        </LoadingTypography>
        <Box display="flex" columnGap={10}>
          {project?.createdAt && (
            <Typography>
              {`Started ${format(
                new Date(project?.createdAt),
                formatNominativeMonth
              )}`}
            </Typography>
          )}
          {!isSmall && project?.client && (
            <Box display="flex" columnGap={2.5}>
              <Typography display="inline">Posted by</Typography>
              <TextOverflow width="40%">
                {`${project?.client?.accountInformation?.firstName} ${project?.client?.accountInformation?.lastName}`}
              </TextOverflow>
            </Box>
          )}
        </Box>
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>
      <Description description={project?.description} />
      {Boolean(skills.length) && (
        <Box pt={2.5}>
          <SkillsOutput skills={skills} />
        </Box>
      )}
      {hasImages && (
        <Box pt={2.5}>
          <ShowImages selectedFiles={project?.jobRequest.images} />{" "}
        </Box>
      )}
      <Box py={2.5}>
        <Divider />
      </Box>
    </Section>
  );
};
