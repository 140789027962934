import makeStyles from "@mui/styles/makeStyles";
import { BLUE, COLOR, YELLOW } from "../components/utils";

export const useMobileStyles = makeStyles({
  mobileContentWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    // alignItems: "center",
  },
  mobileHeadlineWrapper: {
    textAlign: "left",
    width: "fit-content",
    height: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // ["@media screen and (orientation: landscape)"]: {
    //   height: "auto",
    // paddingTop: 30,
    // },
  },
  mobileDescriptionWrapper: {
    width: "fit-content",
    justifyContent: "center",
    alignSelf: "center",
    // ["@media screen and (orientation: landscape)"]: {
    //   width: "90%",
    //   alignSelf: "center",
    // },
  },
});
