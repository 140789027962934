import makeStyles from "@mui/styles/makeStyles";
import { BLUE, COLOR, YELLOW } from "../components/utils";

export const useYellowInputStyles = makeStyles(() => ({
  root: {
    height: 68,
    border: `2px solid ${YELLOW} !important`,
    color: `${YELLOW} !important`,
    backgroundColor: `${BLUE} !important`,
    "& .MuiSvgIcon-root": {
      color: `${YELLOW} !important`,
      marginRight: 15,
    },
    ["@media screen and (max-width: 600px)"]: {
      height: 44,
    },
  },
}));
