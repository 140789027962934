import { Box, Button, Divider } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BROWSE_DEFAULT_URL } from "../../../../routes/utils";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { ProfileTabs } from "../../../common/account/profile";
import { useCollaboratorsHistory } from "../../../common/hooks/projects/project/collaborations/useCollaboratorsHistory";
import { usePastProjectsQuery } from "../../../common/hooks/projects/usePastProjectsQuery";
import { useProjectsQuery } from "../../../common/hooks/projects/useProjectsQuery";
import { ProfessionalAccountSummary } from "./account-summary/ProfessionalAccountSummary";
import { Portfolio } from "./sections";
import { CollaboratorsHistory } from "./sections/collaborators";
import { CurrentAccountProjects } from "./sections/on-going-projects";

export const ProfessionalPublicAccount: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { professionalId } = useParams();
  const {
    data: pastProjects,
    isLoading: isLoadingPastProjects,
    isError: isErrorLoadingPastProjects,
  } = usePastProjectsQuery(professionalId);

  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorLoadingProjects,
  } = useProjectsQuery(professionalId);

  const {
    data: collaboratorsHistory,
    isLoading: isLoadingCollaborators,
    isError: isErrorLoadingCollaborators,
  } = useCollaboratorsHistory(professionalId);

  const content = useMemo(() => {
    if (searchParams.get("tab") === "collaborators")
      return <CollaboratorsHistory />;

    if (searchParams.get("tab") === "current")
      return <CurrentAccountProjects />;

    return <Portfolio />;
  }, [searchParams]);

  const isCurrentProjectsTabDisabled =
    !projects?.length || isLoadingProjects || isErrorLoadingProjects;

  const isCollaboratorsTabDisabled =
    !collaboratorsHistory?.length ||
    isLoadingCollaborators ||
    isErrorLoadingCollaborators;

  const isPortfolioProjectsTabDisabled =
    !pastProjects?.length ||
    isLoadingPastProjects ||
    isErrorLoadingPastProjects;

  const isLoading =
    isLoadingProjects ||
    isLoadingPastProjects ||
    isLoadingCollaborators ||
    true;

  const areAllDisabled =
    isCollaboratorsTabDisabled &&
    isCurrentProjectsTabDisabled &&
    isPortfolioProjectsTabDisabled;

  return (
    <PageWrapper>
      <Box display="flex" flexDirection="column" height="100%">
        <ProfessionalAccountSummary />
        <Box py={4}>
          <Divider />
        </Box>
        <Box pb={4}>
          <ProfileTabs
            disableCurrentProjectsTab={isCurrentProjectsTabDisabled}
            disableCollaboratorsTab={isCollaboratorsTabDisabled}
            disablePortfolioProjectsTab={isPortfolioProjectsTabDisabled}
          />
        </Box>
        {areAllDisabled && !isLoading ? (
          <Box
            display="flex"
            alignItems="center"
            pt={25}
            justifyContent="center"
          >
            <Button
              onClick={() => {
                navigate(BROWSE_DEFAULT_URL);
              }}
            >
              See More Pros and Teams
            </Button>
          </Box>
        ) : (
          <Box px={1} flexGrow={1}>
            {content}
          </Box>
        )}
      </Box>
    </PageWrapper>
  );
};
