import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { FC, Fragment, ReactNode, useState } from "react";

type InfoBoxProps = {
  alertTitle?: string;
  hideNumbers?: boolean;
  messages: string | string[] | ReactNode;
  containerProps?: any;
};

export const InfoBox: FC<InfoBoxProps> = ({
  messages,
  hideNumbers,
  alertTitle,
  containerProps,
}) => {
  const { palette } = useTheme();

  const [showMessage, setShowMessage] = useState(true);

  return Boolean(messages) && showMessage ? (
    <Box
      {...containerProps}
      alignItems="stretch"
      display="flex"
      minHeight={68}
      width="100%"
    >
      <Box
        alignItems="center"
        border={1}
        borderColor={palette.primary.main}
        color={palette.primary.main}
        bgcolor={palette.secondary.main}
        display="flex"
        fontSize={33}
        justifyContent="center"
        width={68}
      >
        <ErrorOutlineOutlinedIcon color="inherit" fontSize="inherit" />
      </Box>
      <Box
        bgcolor={palette.primary.main}
        color={palette.secondary.main}
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="center"
        pl={2.5}
        py={2.5}
      >
        <Box display="flex" columnGap={1}>
          <Typography
            align="justify"
            color="inherit"
            variant="body1"
            style={{
              fontWeight: 700,
            }}
          >
            {alertTitle}
          </Typography>
        </Box>
        {Array.isArray(messages) ? (
          messages?.map((message, index) => (
            <Box display="flex" columnGap={1} key={index}>
              {!hideNumbers && (
                <Typography color="inherit" variant="body2">
                  {index + 1}.
                </Typography>
              )}
              <Typography align="justify" color="inherit" variant="body2">
                {message}
              </Typography>
            </Box>
          ))
        ) : (
          <Fragment>
            {typeof messages === "string" ? (
              <Typography color="inherit" variant="body2">
                {messages}
              </Typography>
            ) : (
              messages
            )}
          </Fragment>
        )}
      </Box>
      <Box
        alignItems="center"
        bgcolor={palette.primary.main}
        color={palette.secondary.main}
        display="flex"
        px={2.5}
      >
        <IconButton
          color="inherit"
          onClick={() => setShowMessage(false)}
          style={{
            fontSize: 32,
          }}
          size="large"
        >
          <CloseOutlinedIcon color="inherit" fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  ) : null;
};
