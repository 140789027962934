import { Box } from "@mui/material";
import React from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useHeadlineText,
  useMediumItalic,
  useStepBStyles,
} from "../../../../../../themes/style-hooks";
import { ClientMainActions } from "../../../ClientMainActions";

export const LandingStepB = () => {
  const styles = useStepBStyles();
  const mediumItalic = useMediumItalic();
  const { transparent, skewedText } = useHeadlineText({
    primary: "#B1D9D9",
    secondary: "#7B65AA",
    background: "#7B65AA",
  });
  const { root, common } = useCommonMainButtonsStyles({
    background: "#7B65AA",
    color: COLOR.WHITE_BLUE,
    hover: "#7B65AA",
  });

  const descriptionText = useDescriptionText({
    color: "#7B65AA",
  });

  return (
    <React.Fragment>
      <Box textAlign="left" width="100%">
        <Box
          className={mediumItalic.root}
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <Box className={`${transparent} ${styles.headline} `}>
            <span>&nbsp;Post&nbsp;</span>
          </Box>
          <Box className={`${skewedText} ${styles.headline} `}>
            <span>&nbsp;jobs&nbsp;</span>
          </Box>
          <Box className={`${transparent} ${styles.headline} `}>
            <span>&nbsp;and&nbsp;</span>
          </Box>
          <Box className={`${skewedText} ${styles.headline}`}>
            <span>&nbsp;freelance&nbsp;</span>
          </Box>
        </Box>
        <br />
        <Box
          className={mediumItalic.root}
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <Box className={`${skewedText} ${styles.headline}`}>
            <span>&nbsp;projects.&nbsp;</span>
          </Box>
          <Box className={`${transparent} ${styles.headline}`}>
            <span>&nbsp;All&nbsp;</span>
          </Box>
          <Box className={`${skewedText} ${styles.headline}`}>
            <span>&nbsp;in one place.&nbsp;</span>
          </Box>
        </Box>
      </Box>

      <Box width="100%" pl={8}>
        <span>&nbsp;</span>
        <Box textAlign="left" color={COLOR.GREEN}>
          <Box className={descriptionText.root}>
            Whether it’s a project you need freelancing help with, or you’re
            searching for a professional to fill an open job, find professionals
            you’re looking for and that fit your specific needs.{" "}
          </Box>
        </Box>
      </Box>
      <ClientMainActions classNames={`${common} ${root}`} />
      {/*<AccountTypeSwitch defaultChecked="client" />*/}
    </React.Fragment>
  );
};
