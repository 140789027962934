import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback } from "react";
import { useCommonMainButtonsStyles } from "../../../themes/style-hooks";
import { useIsMobileView } from "./hooks";

type ClientMainActionsProps = {
  classNames?: string;
};

const useMainActionsLayout = makeStyles({
  root: {
    width: "100%",
    height: 64,
    display: "grid",
    gridTemplateColumns: "auto auto",
    justifyContent: "center",
    justifyItems: "center",
    gridColumnGap: 182,
    ["@media screen and (max-width: 1200px), screen and (max-height: 900px)"]: {
      gridColumnGap: 140,
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      height: "auto",
      gridColumnGap: 60,
    },
    ["@media screen and (max-width: 570px) and (orientation: portrait)"]: {
      flexDirection: "column",
      gridTemplateColumns: "none",
      gridRowGap: 12,
    },
  },
});

export const ClientMainActions: React.FC<ClientMainActionsProps> = ({
  classNames,
}) => {
  const mainActionsLayout = useMainActionsLayout();
  const commonMainButtonsStyles = useCommonMainButtonsStyles({});
  const isMobileView = useIsMobileView();
  // const [open, setOpen] = useState(false);

  const onPostJob = useCallback(() => {
    // if (!isMobileView) {
    window.history.pushState(undefined, "/");
    window.location.replace("/job-request/new?step=2");
    // return;
    // }
    // setOpen(true);
  }, []);

  const onPostFreelanceProject = useCallback(() => {
    // if (!isMobileView) {
    window.history.pushState(undefined, "/");
    window.location.replace("/job-request/freelance/new");
    // return;
    // }
    // setOpen(true);
  }, []);

  return (
    <Box
      className={`${mainActionsLayout.root} ${commonMainButtonsStyles.common}`}
    >
      {/* <Button
        variant="contained"
        disableRipple
        className={classNames}
        onClick={() => {
          navigate("/browse/subscribe/?type=professionals");
        }}
      >
        Browse Professionals
      </Button>
      <Button
        variant="contained"
        disableRipple
        className={classNames}
        onClick={() => {
          navigate("/jobs/subscribe");
        }}
      >
        View Listed Projects
      </Button> */}
      <Button
        style={{
          width: isMobileView ? 220 : 240,
        }}
        variant="contained"
        disableRipple
        className={classNames}
        onClick={onPostJob}
      >
        Post A Job
      </Button>
      <Button
        style={{
          width: isMobileView ? 220 : 240,
        }}
        variant="contained"
        disableRipple
        className={classNames}
        onClick={onPostFreelanceProject}
      >
        Post A Freelance Project
      </Button>
      {/*<MobileNotSupportedDialog open={open} onClose={() => setOpen(false)} />*/}
    </Box>
  );
};
