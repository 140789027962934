import { Box, Button } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { ConfirmationDialog, Section } from "../../../common/components";
import { CollectionNavigationActions } from "../../../common/elements/CollectionNavigationActions";
import { useJobRequestQuery } from "../../../common/hooks/job-requests/job-request/useJobRequestQuery";
import {
  Collection,
  useNavigationHandlers,
} from "../../../common/hooks/navigation";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useJobRequestsOptions } from "../../../professional/bids/hooks/useJobRequestsOptions";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../utils/useToast";
import { useBidConfirmationModal } from "../state/hooks";
import { Bids } from "./bids/Bids";
import { BidsJobrequestSkeleton } from "./bids/BidsJobrequestSkeleton";
import { Details } from "./details";
import { DetailsJobRequestSkeleton } from "./details/DetailsJobRequestSkeleton";
import { JobRequestHeader } from "./header";
import {
  useDefaultJobRequestPage,
  useOnConfirmWithdrawalHandler,
} from "./hooks";
import { Inquires } from "./inquires";
import { InquiresJobRequestSkeleton } from "./inquires/InquiresJobRequestSkeleton";
import { Invited } from "./Invited";
import { InvitedJobRequestSkeleton } from "./Invited/InvitedJobRequestSkeleton";

export const CurrentJobRequest: React.FC = () => {
  useDefaultJobRequestPage();
  const { notifySuccess } = useToast();

  const confirmationModalState = useScopedDowngradedStateValue(
    useBidConfirmationModal()
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { jobRequestId } = useParams();

  const { isSmall } = useResponsiveness();

  const { data: jobRequest, isLoading: isLoadingJobRequest } =
    useJobRequestQuery();

  const setConfirmationModalState = useBidConfirmationModal().set;

  const [editing, setIsEditing] = useState(false);

  const { data: jobRequests, isLoading: isLoadingJobRequests } =
    useJobRequestsOptions();

  const isLoading = isLoadingJobRequest || isLoadingJobRequests;

  const collectionConfig = {
    type: Collection.JOB_REQUESTS,
    items: jobRequests,
  };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(collectionConfig, isLoadingJobRequests, jobRequestId);

  const onWithdrawalHandler = useOnConfirmWithdrawalHandler(() => {
    if (index <= 1) {
      handleSelectPrevious();
      setConfirmationModalState({ withdraw: false });
      notifySuccess(feedbackMessage("job request", FEEDBACK_TYPE.WITHDRAW));
      return;
    }

    if (index <= jobRequests.length) {
      handleSelectNext();
      setConfirmationModalState({ withdraw: false });
      notifySuccess(feedbackMessage("job request", FEEDBACK_TYPE.WITHDRAW));
      return;
    }
  });

  const handleClose = useCallback(() => {
    setConfirmationModalState({ withdraw: false, accept: false });
  }, [setConfirmationModalState]);

  const onMatchProfessionalHandler = useCallback(() => {
    navigate({
      pathname: `/browse`,
      search: `?${createSearchParams({
        jobRequestId: jobRequestId,
      })}`,
    });
  }, [jobRequestId, navigate]);

  const errorMessage = useMemo(() => {
    return !isLoading && !jobRequest
      ? "Active job requests you post will be shown here."
      : "";
  }, [isLoading, jobRequest]);

  const isNavigationActionsHidden =
    editing || isLoadingJobRequest || searchParams.get("tab") !== "details";

  const content = useMemo(() => {
    if (searchParams.get("tab") === "bids") return <Bids />;

    if (searchParams.get("tab") === "inquiries") return <Inquires />;

    if (searchParams.get("tab") === "invitations") return <Invited />;

    return <Details editing={editing} setIsEditing={setIsEditing} />;
  }, [editing, searchParams]);

  const loadingSkeleton = (() => {
    const tab = searchParams.get("tab");

    switch (tab) {
      case "bids":
        return <BidsJobrequestSkeleton />;
      case "inquiries":
        return <InquiresJobRequestSkeleton />;
      case "invitations":
        return <InvitedJobRequestSkeleton />;
      default:
        return <DetailsJobRequestSkeleton />;
    }
  })();

  return (
    <PageWrapper>
      <Box height="100%">
        <Section
          isLoading={isLoading}
          errorMessage={errorMessage}
          loadingSkeleton={loadingSkeleton}
        >
          <Box height="100%" display="flex" flexDirection="column">
            <JobRequestHeader />
            <Box flexGrow={1}>{content}</Box>
            <CollectionNavigationActions
              label="Job Request"
              index={index}
              totalCount={jobRequests?.length ?? 0}
              handleSelectNext={handleSelectNext}
              handleSelectPrevious={handleSelectPrevious}
              hidden={isNavigationActionsHidden}
            >
              {!isSmall && (
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onMatchProfessionalHandler}
                  disabled
                >
                  Find Matching Professionals
                </Button>
              )}
            </CollectionNavigationActions>
          </Box>
          <ConfirmationDialog
            open={Boolean(confirmationModalState.withdraw)}
            onClose={handleClose}
            onConfirm={onWithdrawalHandler}
            title="Are you sure you want to withdraw this Job Request?"
            message="This Job Request will be permanently erased."
          />
        </Section>
      </Box>
    </PageWrapper>
  );
};
