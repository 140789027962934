import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  LoadingTypography,
  HeaderDropdown,
} from "../../../../../common/components";
import {
  Collection,
  useNavigationHandlers,
} from "../../../../../common/hooks/navigation";
import { useProjectQuery } from "../../../../../common/hooks/projects/project/useProjectQuery";
import { useResponsiveness } from "../../../../../common/hooks/utils/useResponsiveness";
import { useUnpublishedPastProjectsOptions } from "../../../../bids/hooks/useUnpublishedPastProjectOptions";

export const UnpublishedPastProjectHeaderContent: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isMedium } = useResponsiveness();
  const { data: project, isLoading: isLoadingProject } = useProjectQuery();
  const { data: projects, isLoading: isLoadingUnpublishedProjects } =
    useUnpublishedPastProjectsOptions();

  const isLoading = isLoadingProject || isLoadingUnpublishedProjects;

  const collectionConfig = {
    type: Collection.UNPUBLISHED_PROJECTS,
    items: projects,
  };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingUnpublishedProjects,
    projectId
  );

  const handleSelectCurrentPastProject = useCallback(
    (index: number) => {
      if (!projects) return;

      navigate(`/projects/unpublished/${projects[index]._id}`);
    },
    [navigate, projects]
  );

  return (
    <Box width={isMedium ? "100%" : "60%"} py={10}>
      <LoadingTypography
        typographyProps={{
          variant: "subtitle2",
        }}
      >
        Unpublished Past Project ({index + 1} of {projects?.length})
      </LoadingTypography>
      <Box py={10}>
        <HeaderDropdown
          isLoading={isLoading}
          selectedOptionId={projectId}
          options={projects}
          onSelect={handleSelectCurrentPastProject}
          placeholder={project?.jobRequest?.title}
        />
      </Box>
    </Box>
  );
};
