import { Typography } from "@mui/material";
import React from "react";
import { YELLOW } from "../../../themes/components/utils";
import { useResponsiveness } from "../hooks/utils/useResponsiveness";

type StepProps = {
  label: string;
  isSelected?: boolean;
  color?: string;
};

export const Step: React.FC<StepProps> = ({
  label,
  isSelected,
  color = YELLOW,
}) => {
  const { isSmall } = useResponsiveness();

  return (
    <Typography
      variant={isSmall ? "body1" : "subtitle2"}
      style={{
        maxWidth: isSmall ? 50 : 200,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        opacity: isSelected ? 1 : 0.5,
        color: isSelected ? color : "gray",
        fontWeight: 600,
      }}
    >
      {label}
    </Typography>
  );
};
