import { Box } from "@mui/material";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useMobileStyles,
} from "../../../../../../themes/style-hooks";
import { ClientMainActions } from "../../../ClientMainActions";

export const LandingStepBContent = () => {
  const { mobileContentWrapper, mobileDescriptionWrapper } = useMobileStyles();

  const { root, common } = useCommonMainButtonsStyles({
    background: "#7B65AA",
    color: COLOR.WHITE_BLUE,
    hover: "#7B65AA",
  });

  const descriptionText = useDescriptionText({
    color: "#7B65AA",
  });

  return (
    <Box className={mobileContentWrapper}>
      <Box className={mobileDescriptionWrapper}>
        <Box textAlign="left">
          <Box className={descriptionText.root}>
            Whether it’s a project you need freelancing help with, or you’re
            searching for a professional to fill an open job, find professionals
            you’re looking for and that fit your specific needs.{" "}
          </Box>
        </Box>
      </Box>
      <ClientMainActions classNames={`${common} ${root}`} />
    </Box>
  );
};
