import { Box, Divider, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { usePointerStyles } from "../../../../../../../themes/style-hooks";
import { formatNominativeMonth } from "../../../../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../../../../common/components/SkillsOutput";
import { Collaborator } from "../../../../../../common/hooks/projects/project/collaborations/useCollaboratorsHistory";
import { ProfilePicture } from "../../../../../../common/summary-profile";

type CollaboratorItemProps = {
  collaborator: Collaborator;
};

export const CollaboratorItem: React.FC<CollaboratorItemProps> = ({
  collaborator,
}) => {
  const pointerStyles = usePointerStyles();
  const [isHovering, setIsHovering] = useState(false);

  const { professional } = collaborator;

  const skills = useMemo(() => {
    if (professional?.profileInformation?.skills) {
      return professional?.profileInformation?.skills.map(
        (skill) => skill.name
      );
    }
    return [];
  }, [professional?.profileInformation?.skills]);

  const onMouseEnterHandler = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeaveHandler = useCallback(() => {
    setIsHovering(false);
  }, []);

  return (
    <Box
      height="100%"
      className={pointerStyles.root}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <Box display="flex" height={300}>
        <Box display="flex" alignItems="center" columnGap={12} width={760}>
          <Box textAlign="center">
            <ProfilePicture
              width={196}
              profileInformation={professional.profileInformation}
            />
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 600,
              }}
            >
              {professional.accountInformation?.firstName}{" "}
              {professional.accountInformation?.lastName}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">
              {professional.profileInformation?.position ?? ""}
            </Typography>
            <SkillsOutput skills={skills} isHovering={isHovering} />
          </Box>
        </Box>
        <Box display="flex">
          <Box pr={4} height="100%">
            <Divider orientation="vertical" />
          </Box>
          {!!collaborator?.openProjects?.length && (
            <Box>
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: 600,
                }}
              >
                Collaborating on {collaborator?.openProjects?.length ?? 0}{" "}
                projects
              </Typography>
              {collaborator?.openProjects.map((project) => (
                <Box key={project._id} pt={2}>
                  <Typography
                    variant="subtitle2"
                    noWrap
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {project?.title}
                  </Typography>
                  <Typography variant="subtitle2">
                    Timeframe:{" "}
                    {format(
                      new Date(project?.createdAt),
                      formatNominativeMonth
                    )}{" "}
                    -{" "}
                    {format(
                      new Date(project?.updatedAt),
                      formatNominativeMonth
                    )}
                  </Typography>
                  <Divider />
                </Box>
              ))}
            </Box>
          )}
          {!!collaborator?.closedProjects?.length && (
            <Box>
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: 600,
                }}
              >
                Collaborated on {collaborator?.closedProjects?.length ?? 0}{" "}
                projects
              </Typography>
              {collaborator?.closedProjects.map((project) => (
                <Box key={project._id} pt={2}>
                  <Typography
                    variant="subtitle2"
                    noWrap
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {project?.title}
                  </Typography>
                  <Typography variant="subtitle2">
                    Timeframe:{" "}
                    {format(
                      new Date(project?.createdAt),
                      formatNominativeMonth
                    )}{" "}
                    -{" "}
                    {format(
                      new Date(project?.updatedAt),
                      formatNominativeMonth
                    )}
                  </Typography>
                  <Divider />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
