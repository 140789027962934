import { Box, Typography } from "@mui/material";
import { FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { BackButton } from "../../../../common/elements/BackButton";
import { TimeframeRadioGroup } from "../../utils";

type TimeframeCostProps = {
  onBack?: () => void;
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  handleFocus: (field: string, value: string) => void;
  values: FormikValues;
};

export const initialTimeframeValues = {
  timeFrame: {
    projectTimeFrame: {
      type: undefined,
      weeks: 0,
    },
    timeDedicated: {
      type: undefined,
      hoursPerWeek: 0,
    },
    fee: {
      type: undefined,
      perHour: 0,
      fixedPrice: 0,
    },
  },
};

export const TimeframeCost: React.FC<TimeframeCostProps> = ({
  onBack,
  handleBlur,
  handleChange,
  handleFocus,
  values,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="column"
      pt={6}
    >
      <BackButton onClick={() => onBack?.()} />
      <Box display="flex" justifyContent="space-between" columnGap={3} pt={6}>
        <Box maxWidth="30%">
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
            }}
          >
            Project Timeframe and Schedule
          </Typography>
        </Box>
        <Box width="60%">
          <TimeframeRadioGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            values={values}
          />
        </Box>
      </Box>
    </Box>
  );
};
