import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { Section } from "../../../common/components";
import { InfoBox } from "../../../common/components/InfoBox";
import { CollectionNavigationActions } from "../../../common/elements/CollectionNavigationActions";
import {
  Collection,
  useNavigationHandlers,
} from "../../../common/hooks/navigation";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { usePastProjectsQuery } from "../../../common/hooks/projects/usePastProjectsQuery";
import { Details } from "./details";
import { PastProjectHeader } from "./header";
import { useDefaultPastProjectPage } from "./hooks";
import { PastProjectsSkeleton } from "./PastProjectsSkeleton";
import { Payments } from "./payments";
import { PaymentsSkeleton } from "./PaymentsSkeleton";

export const PastProjectDetails: React.FC = () => {
  useDefaultPastProjectPage();

  const { projectId } = useParams();

  const { data: pastProjects, isLoading: isLoadingPastProjects } =
    usePastProjectsQuery();

  const [searchParams] = useSearchParams();
  const { data: project, isLoading: isLoadingProject } = useProjectQuery();
  const isLoading = isLoadingPastProjects || isLoadingProject;

  const collectionConfig = {
    type: Collection.PAST_PROJECTS,
    items: pastProjects,
  };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(collectionConfig, isLoadingPastProjects, projectId);

  const tab = searchParams.get("tab");

  const errorMessage = useMemo(() => {
    return !isLoading && !project
      ? "All your completed or canceled projects will be shown here."
      : "";
  }, [isLoading, project]);

  const loadingSkeleton = (() => {
    if ("payments" === tab) return <PaymentsSkeleton />;
    return <PastProjectsSkeleton />;
  })();

  const content = useMemo(() => {
    if ("payments" === tab) return <Payments />;
    return <Details />;
  }, [tab]);

  const closingDetails = useMemo(() => {
    return project?.closingDetails
      ? [
          "The professional wrote the following regarding the cancelation:",
          project.closingDetails,
        ]
      : [];
  }, [project?.closingDetails]);

  const isNavigationHidden = tab !== "details";

  return (
    <PageWrapper>
      <Section
        isLoading={isLoading}
        errorMessage={errorMessage}
        loadingSkeleton={loadingSkeleton}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <PastProjectHeader />
          {project?.closingRequester === "professional" && (
            <Box pb={3}>
              <InfoBox
                hideNumbers
                alertTitle="The professional has canceled the project."
                messages={[
                  "The funds for the milestone they were working on are being restored back to your account.",
                  "There are no ratings or reviews associated with a canceled project.",
                  ...closingDetails,
                ]}
              />
            </Box>
          )}
          <Box flexGrow={1}>{content}</Box>
          <CollectionNavigationActions
            label={"Past Project"}
            index={index}
            totalCount={pastProjects?.length ?? 0}
            handleSelectNext={handleSelectNext}
            handleSelectPrevious={handleSelectPrevious}
            hidden={isNavigationHidden}
          />
        </Box>
      </Section>
    </PageWrapper>
  );
};
