import { useCallback, useMemo } from "react";
import { useMutation, UseMutationOptions } from "react-query";
import { startFreeTrial } from "../../../../../api-services/account-information/startFreeTrial";
import { JobPost } from "../../../../../api-types/job-posts";
import { useJobPackage } from "../../../../client/job-requests/create-job-request-wizard/state/hooks";
import { useScopedDowngradedStateValue } from "../../utils/useScopedDowngradedStateValue";

export const useStartFreeTrialMutation = (
  options?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const jobPackage = useScopedDowngradedStateValue(useJobPackage());
  const { mutate: startFreeTrialMutation, isLoading } =
    useStartFreeTrialMutationCore();

  const freeTrialJobPosts = useMemo(() => {
    if (jobPackage.package === "3-jobs") {
      return 3;
    }

    if (jobPackage.package === "5-jobs") {
      return 5;
    }

    if (jobPackage.package === "10-jobs") {
      return 10;
    }

    return 1;
  }, [jobPackage.package]);

  const startFreeTrial = useCallback(
    (jobPost: JobPost) => {
      startFreeTrialMutation({ freeTrialJobPosts, jobPost }, options);
    },
    [freeTrialJobPosts, options, startFreeTrialMutation]
  );

  return { startFreeTrial, isLoading };
};

export const useStartFreeTrialMutationCore = (
  options?: UseMutationOptions<unknown, unknown, unknown>
) => {
  return useMutation(startFreeTrial, {
    ...options,
  });
};
