import { FormikValues } from "formik";
import React, { ChangeEvent, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";
import { JobType } from "../freelance-job-request";
import { useJobPackages } from "../hooks/useJobPackages";
import { JobPackage } from "./JobPackage";
import { JobPostBillingDetails } from "./JobPostBillingDetails";
import { JobPostSetUp } from "./JobPostSetUp";

type CurrentJobPostStepProps = {
  handleChange: (e: ChangeEvent) => void;
  handleUpdateSkills: (newSkills: string[]) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleBlur: (e: FocusEvent) => void;
  onSubmit: () => void;
  values: FormikValues;
  errors: any;
  onNext?: () => void;
  isValid?: boolean;
  setValues: (fields: SetStateAction<any>) => void;
};

export const CurrentJobPostStep: React.FC<CurrentJobPostStepProps> = (
  props
) => {
  const [searchParams] = useSearchParams();
  const { jobPostsCredits } = useJobPackages();
  const hasCredits = jobPostsCredits > 0;
  const currentStep = Number(searchParams.get("step"));

  if (hasCredits && currentStep <= 2) {
    if (currentStep === 1) {
      return <JobType {...props} />;
    }

    if (currentStep === 2) {
      return <JobPostSetUp {...props} />;
    }

    return null;
  }

  if (currentStep === 1) {
    return <JobType {...props} />;
  }

  if (currentStep === 2) {
    return <JobPackage onNext={props.onNext} />;
  }

  if (currentStep === 3) {
    return <JobPostSetUp {...props} />;
  }

  if (currentStep === 4) {
    return <JobPostBillingDetails {...props} />;
  }

  return null;
};
