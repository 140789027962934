import { Box } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import { BackButton } from "../../../../common/elements/BackButton";
import {
  FixedProjectFundingType,
  HourlyRateFundingType,
} from "../validation-schema";
import { FixedPriceFundingContent } from "./FixedPriceFundingContent";
import { JobRequestFormValues } from "./FreelanceJobRequestWizard";
import { HourlyRateFundingContent } from "./HourlyRateFundingContent";

type ProjectFundingStepProps = {
  onBack?: () => void;
};

export const initialProjectFundingValues: {
  fixedProjectFunding: FixedProjectFundingType;
  hourlyRateProjectFunding: HourlyRateFundingType;
} = {
  fixedProjectFunding: {
    totalPrice: 0,
    dueDate: {
      days: 0,
      weeks: 0,
      hours: 0,
    },
    milestones: [
      {
        description: "Your first milestone",
        chargeId: null,
        amount: 0,
        dueDate: {
          type: "weeks",
          count: 0,
        },
      },
    ],
  },
  hourlyRateProjectFunding: {
    timeframe: {
      min: 0,
      max: 0,
    },
    milestones: [
      {
        description: "Your first milestone",
        dueDate: {
          type: "weeks",
          min: 0,
          max: 0,
        },
      },
    ],
  },
};

export const ProjectFundingStep: FC<ProjectFundingStepProps> = ({ onBack }) => {
  const { values } = useFormikContext<JobRequestFormValues>();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="column"
      pt={10}
    >
      <BackButton onClick={() => onBack?.()} />
      <Box pt={5}>
        {values.budget.type === "fixed" ? (
          <FixedPriceFundingContent />
        ) : (
          <HourlyRateFundingContent />
        )}
      </Box>
    </Box>
  );
};
