import { Box, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";
import React, { Fragment } from "react";
import { BudgetType } from "../../../../../client/job-requests/create-job-request-wizard/freelance-job-request/BudgetStep";
import { FixedProjectFundingType } from "../../../../../client/job-requests/create-job-request-wizard/validation-schema";
import { LoadingTypography } from "../../../../../common/components";
import { useJobRequestBids } from "../../../../../common/hooks/job-requests/job-request/bids/useJobRequestBids";

type AvailabilityCostBidsProps = {
  budget: BudgetType | undefined;
  fixedProjectFunding: FixedProjectFundingType | undefined;
  jobRequestId?: string;
};

export const AvailabilityCostBids: React.FC<AvailabilityCostBidsProps> = ({
  budget,
  fixedProjectFunding,
  jobRequestId,
}) => {
  const { data: jobRequestBids, isLoading } = useJobRequestBids(jobRequestId);

  const getBudgetTimeframeType = () => {
    const type = budget?.timeFrame?.estimate?.type ?? "weeks";

    return `${type.substring(0, type.length - 1)}(s)`;
  };

  const getTimeframeInterval = () => {
    if (budget?.timeFrame.estimate.min === budget?.timeFrame.estimate.max)
      return `${budget?.timeFrame.estimate.min}`;

    return `${budget?.timeFrame.estimate.min} - ${budget?.timeFrame.estimate.max}`;
  };

  return (
    <Box display="flex" columnGap={12.5} flexWrap="wrap">
      {budget?.type === "fixed" && fixedProjectFunding && (
        <Fragment>
          <Box display="flex" columnGap={2} alignItems="baseline">
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              Fixed Price Budget
            </Typography>
            <Typography variant="body2">
              ${fixedProjectFunding.totalPrice}
            </Typography>
          </Box>
          <Box display="flex" columnGap={2} alignItems="baseline">
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              Timeframe
            </Typography>
            {fixedProjectFunding.dueDate.weeks > 0 && (
              <Typography variant="body2">
                {`${fixedProjectFunding.dueDate.weeks} week(s)`}
              </Typography>
            )}
            {fixedProjectFunding.dueDate.weeks > 0 &&
              fixedProjectFunding.dueDate.days > 0 && (
                <Typography variant="body2">and</Typography>
              )}
            {fixedProjectFunding.dueDate.days > 0 && (
              <Typography variant="body2">
                {`${fixedProjectFunding.dueDate.days} day(s)`}
              </Typography>
            )}
          </Box>
          <Box display="flex" columnGap={2} alignItems="baseline">
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              Milestone(s)
            </Typography>
            <Typography variant="body2">
              {fixedProjectFunding.milestones.length}
            </Typography>
          </Box>
          <Box display="flex" columnGap={2} alignItems="baseline">
            <Typography
              variant="body2"
              component="span"
              style={{
                fontWeight: 600,
              }}
            >
              Bids Placed
            </Typography>
            <LoadingTypography
              isLoading={isLoading}
              lineHeight={1.5}
              width={15}
              typographyProps={{
                component: "span",
                variant: "body2",
              }}
            >
              {jobRequestBids?.length ?? 0}
            </LoadingTypography>
          </Box>
        </Fragment>
      )}
      {budget?.type === "hourly" && fixedProjectFunding && (
        <Fragment>
          <Box display="flex" columnGap={2} alignItems="baseline">
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              Hours/Week
            </Typography>
            {budget.hours.type === "fixed" ? (
              <Typography variant="body2">
                {budget.hours.fixed}hrs/wk
              </Typography>
            ) : (
              <Typography variant="body2">
                {budget.hours.estimate.min}hr(s)/wk -{" "}
                {budget.hours.estimate.max}hr(s)/wk
              </Typography>
            )}
          </Box>
          <Box display="flex" columnGap={2} alignItems="baseline">
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              Fee/Hour
            </Typography>
            {budget.hourlyRate.type === "fixed" ? (
              <Typography variant="body2">
                ${budget.hourlyRate.fixed}/hr
              </Typography>
            ) : (
              <Typography variant="body2">
                ${budget.hourlyRate.estimate.min}/hr - $
                {budget.hourlyRate.estimate.max}/hr
              </Typography>
            )}
          </Box>
          <Box display="flex" columnGap={2} alignItems="baseline">
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              Timeframe
            </Typography>
            {budget?.timeFrame.type === "fixed" ? (
              <Typography variant="body2">
                {budget.timeFrame.fixed} week(s)
              </Typography>
            ) : (
              <Typography variant="body2">
                {getTimeframeInterval()} {getBudgetTimeframeType()}
              </Typography>
            )}
          </Box>
          <Box display="flex" columnGap={2} alignItems="baseline">
            <Typography
              variant="body2"
              component="span"
              style={{
                fontWeight: 600,
              }}
            >
              Bids Placed
            </Typography>
            <LoadingTypography
              isLoading={isLoading}
              lineHeight={1.5}
              width={15}
              typographyProps={{
                component: "span",
                variant: "body2",
              }}
            >
              {jobRequestBids?.length ?? 0}
            </LoadingTypography>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};
