import { FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { SignUpConfirmation } from "../common";
import { SignUpProfessionalInfo } from "./SignUpProfessionalInfo";
import { SignUpProfessionalOptionalInfo } from "./SignUpProfessionalOptionalInfo";
import { SignUpProfessionalOptionalSkills } from "./SignUpProfessionalOptionalSkills";
import { SignUpProfessionalServices } from "./SignUpProfessionalServices";

type CurrentStepProps = {
  handleChange: (e: ChangeEvent) => void;
  // eslint-disable-next-file no-undef
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: any;
  onBack?: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

export const CurrentProfessionalStep: React.FC<CurrentStepProps> = (props) => {
  const [searchParams] = useSearchParams();

  const currentStep = Number(searchParams.get("step"));

  if (currentStep === 2) {
    return <SignUpProfessionalServices {...props} />;
  }

  if (Number(searchParams.get("step")) === 3) {
    return <SignUpProfessionalInfo {...props} />;
  }

  if (Number(searchParams.get("step")) === 4) {
    return <SignUpProfessionalOptionalInfo {...props} />;
  }

  if (Number(searchParams.get("step")) === 5) {
    return <SignUpProfessionalOptionalSkills {...props} />;
  }

  if (Number(searchParams.get("step")) === 6) {
    return <SignUpConfirmation {...props} />;
  }

  return null;
};
