import { Box } from "@mui/material";
import React, { useCallback } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  LoadingTypography,
  HeaderDropdown,
} from "../../../../common/components";
import { Card } from "../../../../common/elements/Card";
import {
  Collection,
  useNavigationHandlers,
} from "../../../../common/hooks/navigation";
import { useProjectQuery } from "../../../../common/hooks/projects/project/useProjectQuery";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { usePastProjectsOptions } from "../../../../professional/bids/hooks/usePastProjectOptions";

export const PastProjectHeaderContent: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const { data: pastProjects, isLoading: isLoadingPastProjects } =
    usePastProjectsOptions();

  const collectionConfig = {
    type: Collection.PAST_PROJECTS,
    items: pastProjects,
  };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingPastProjects,
    projectId
  );

  const isLoading = isLoadingProject || isLoadingPastProjects;

  const handleSelectCurrentPastProject = useCallback(
    (index: number) => {
      if (!pastProjects) return;

      navigate({
        pathname: `/projects/history/${pastProjects[index]._id}`,
        search: `?${createSearchParams({ tab: "details" })}`,
      });
    },
    [navigate, pastProjects]
  );

  const onTabNavigationHandler = useCallback(
    (tab: string) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const { isSmall } = useResponsiveness();

  return (
    <Box width={isSmall ? "100%" : "60%"} py={10}>
      <LoadingTypography
        typographyProps={{
          variant: "subtitle2",
        }}
      >
        Past Project ({index + 1} of {pastProjects?.length})
      </LoadingTypography>
      <Box py={10}>
        <HeaderDropdown
          isLoading={isLoading}
          selectedOptionId={projectId}
          options={pastProjects}
          onSelect={handleSelectCurrentPastProject}
          placeholder={project?.jobRequest?.title}
        />
      </Box>
      <Box display="flex" flexDirection={isSmall ? "column" : "row"} gap={5}>
        <Card
          width={isSmall ? "100%" : 110}
          label="Details"
          isSelected={searchParams.get("tab") === "details"}
          onClick={() => onTabNavigationHandler("details")}
        />
        <Card
          width={isSmall ? "100%" : 180}
          label="Payments"
          isSelected={searchParams.get("tab") === "payments"}
          onClick={() => onTabNavigationHandler("payments")}
        />
      </Box>
    </Box>
  );
};
