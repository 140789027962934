import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC, useCallback } from "react";
import { stringToTitleCase } from "../../../../utils";
import {
  DueDate,
  Milestone,
} from "../../../job-requests/create-job-request-wizard/validation-schema";

type FixedPriceMilestonesTableProps = {
  milestones: Milestone[];
};

export const FixedPriceMilestonesTable: FC<FixedPriceMilestonesTableProps> = ({
  milestones,
}) => {
  const dueDateType = useCallback((dueDate: DueDate) => {
    return dueDate.type.substring(0, dueDate.type.length - 1);
  }, []);

  return (
    <Table>
      <TableHead>
        <TableCell variant="head">Milestone Description</TableCell>
        <TableCell variant="head">Milestone Amount</TableCell>
        <TableCell variant="head">Due Date</TableCell>
        <TableCell variant="head">Funded</TableCell>
        <TableCell variant="head">Status</TableCell>
      </TableHead>

      <TableBody>
        {milestones.map(
          ({ amount, description, dueDate, isFunded, status }, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography component="span">{description}</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">${amount}</Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {`${dueDate.count} ${dueDateType(dueDate)}(s) from start of ${
                    index === 0 ? "project" : "milestone"
                  }`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {isFunded ? "Yes" : "No"}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span">
                  {stringToTitleCase(status ?? "")}
                </Typography>
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};
