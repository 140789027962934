import makeStyles from "@mui/styles/makeStyles";

export const useStepCStyles = makeStyles({
  headline: {
    fontSize: 112,
    fontWeight: 500,
    ["@media screen and (max-width: 2050px)"]: {
      fontSize: "5.5vw",
    },
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: "7vw",
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      fontSize: "10vw",
    },
    ["@media screen and (max-aspect-ratio: 7/4)"]: {
      ["@media screen and (max-width: 850px)"]: {
        fontSize: "10vw",
      },
      ["@media screen and (max-width: 650px)"]: {
        fontSize: "9.7vw",
      },
      ["@media screen and (max-width: 500px)"]: {
        fontSize: "9.5vw",
      },
      ["@media screen and (max-width: 400px)"]: {
        fontSize: "9.3vw",
      },
      ["@media screen and (max-width: 350px)"]: {
        fontSize: "9.1vw",
      },
      ["@media screen and (max-width: 280px)"]: {
        fontSize: "25px",
      },
    },
    ["@media screen and (min-aspect-ratio: 7/4)"]: {
      ["@media screen and (max-height: 650px)"]: {
        fontSize: "11.5vh",
      },
      ["@media screen and (max-height: 500px)"]: {
        fontSize: "13.6vh",
      },
      ["@media screen and (max-height: 300px)"]: {
        fontSize: "12.6vh",
      },
      ["@media screen and (max-height: 280px)"]: {
        fontSize: "35px",
      },
    },
  },
});
