import { Components } from "@mui/material";

export const MuiFormHelperText: Components["MuiFormHelperText"] = {
  styleOverrides: {
    root: {
      display: "none",
    },
  },
};
//
// export const MuiFormHelperText: MuiComponentThemeDefinition<
//   FormHelperTextClassKey,
//   FormHelperTextProps
// > = {
//   props: {},
//   override: () => ({
//     root: {
//       display: "none",
//     },
//   }),
// };
