import { Box, Typography } from "@mui/material";

export const MilestonesDetailsLeftContainer = () => {
  return (
    <Box display="flex" flexDirection="column" rowGap={6} maxWidth="30%">
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: "bold",
        }}
      >
        Working with Milestones
      </Typography>

      <Typography variant="body2">
        When a milestone is completed, it is submitted for your review. You can
        request changes or close the completed milestone.
      </Typography>
      <Typography variant="body2">
        If you take no action on a submitted milestone, the money assigned to it
        will be automatically released to the Professional, 5 business days
        after it is submitted.
      </Typography>
      <Typography variant="body2">
        Once work begins on a milestone, it is no longer editable. All other
        future milestones are always editable.
      </Typography>
    </Box>
  );
};
