import { Box, Button, CircularProgress } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { BASE_URL } from "../../../api-services/utils";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import {
  METADATA_QUERY_KEY,
  useMetadataQuery,
} from "../../common/hooks/metadata/useMetadataQuery";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../utils/useToast";
import { useAddJobPackage } from "./hooks/useAddJobPackage";

type PaymentFormProps = {
  amount: number;
  selectedPackageCount: number;
  onSuccess?: () => void;
  buttonLabel?: string;
};

const cardStyle = {
  style: {
    base: {
      paddingTop: 10,
      paddingBottom: 10,
      height: "40px !important",
      iconColor: "#fff",
      color: "#fff",
      fontWeight: 400,
      fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",

      "::placeholder": {
        color: "#BFAEF6",
      },
      ":-webkit-autofill": {
        color: "#fce883",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
  },
};

export const JobPostPaymentForm: React.FC<PaymentFormProps> = ({
  amount,
  selectedPackageCount,
  buttonLabel,
  onSuccess,
}) => {
  const { notifyError } = useToast();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const { data: metadata } = useMetadataQuery();

  const { mutate: addJobPackages } = useAddJobPackage({
    onSuccess: () => {
      queryClient.invalidateQueries(METADATA_QUERY_KEY);
      onSuccess?.();
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    },
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(CardNumberElement);

    if (cardNumberElement) {
      // use stripe.createToken to get a unique token for the card
      const { error, token } = await stripe.createToken(cardNumberElement);

      if (!error && token) {
        axios
          .post(`${BASE_URL}/stripe/pay`, {
            token: token.id,
            currency: "USD",
            price: Math.round(amount * 100),
            email: metadata?.email ?? "",
          })
          .then(() => {
            addJobPackages({ jobPostsCredits: selectedPackageCount });
          })
          .catch(() => {
            notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          });
      } else {
        notifyError(error?.message ?? "");
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    }
  };

  return (
    <Box width="100%">
      <form id="payment-form">
        <Box
          style={{
            padding: 20,
            border: "2px solid white",
          }}
          data-cy="card-number"
        >
          <CardNumberElement options={cardStyle} />
        </Box>
        <Box display="flex" width="100%" justifyContent="space-between" pt={5}>
          <Box
            width="48.5%"
            style={{
              padding: 20,
              border: "2px solid white",
            }}
            data-cy="card-expiry"
          >
            <CardExpiryElement options={cardStyle} />
          </Box>
          <Box
            width="48%"
            style={{
              padding: 20,
              border: "2px solid white",
            }}
            data-cy="card-cvc"
          >
            <CardCvcElement options={cardStyle} />
          </Box>
        </Box>
        <Box pt={5}>
          <Button
            disabled={isLoading}
            data-cy-btn="pay"
            style={{
              cursor: "pointer",
              width: "100%",
              height: 45,
              backgroundColor: BLUE,
              border: `2px solid ${YELLOW}`,
              color: YELLOW,
            }}
            endIcon={isLoading ? <CircularProgress size={16} /> : null}
            onClick={handleSubmit}
          >
            Pay ${amount} {buttonLabel}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
