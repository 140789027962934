/* eslint-disable no-unused-vars */
import { Box, Typography, useTheme } from "@mui/material";
import { addDays, addWeeks, format } from "date-fns";
import { FormikValues } from "formik";
import { isEqual, pick } from "lodash";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ClosedProjectData,
  Project,
} from "../../../../api-types/projects/project";
import {
  HourlyRateMilestone,
  Milestone,
  MilestoneStatus,
} from "../../../client/job-requests/create-job-request-wizard/validation-schema";
import { useCloseProjectRequest } from "../../../client/projects/hooks/useCloseProjectRequest";
import { ConfirmationDialog, CustomTooltip } from "../../../common/components";
import { InfoBox } from "../../../common/components/InfoBox";
import { formatNominativeMonth } from "../../../common/components/LoadingTypography";
import {
  FIXED_PRICE_DIFF_PROPS,
  HOURLY_RATE_DIFF_PROPS,
} from "../../../common/components/milestones/constants";
import { CancelProjectDialog } from "../../../common/components/modals/cancel-project-dialog";
import {
  useMilestoneAcceptChanges,
  useMilestoneDenyChanges,
  useMilestoneSubmitWork,
} from "../../../common/hooks/projects/milestones";
import {
  useInvalidateProjectQuery,
  useProjectQuery,
} from "../../../common/hooks/projects/project/useProjectQuery";
import { useUpdateProject } from "../../../common/hooks/projects/project/useUpdateProjectMutation";
import { FixedPriceMilestonesTable } from "./FixedPriceMilestonesTable";
import { FixedPriceMoreInfo } from "./FixedPriceMoreInfo";
import {
  ActualWork,
  HourlyRateMilestonesTable,
} from "./HourlyRateMilestonesTable";
import { HourlyRateMoreInfo } from "./HourlyRateMoreInfo";

export enum AlertMilestoneMessage {
  WORK_SUBMITTED = "Work has been submitted for the current milestone. The client may request changes or release payment. If he takes no action on a submitted milestone, the money assigned to it will be automatically released to the you, 5 business days after it is submitted.",
  FUND_UPCOMING = "The upcoming milestone has to be funded for work on it to begin.",
  MILESTONE_CHANGED = "Changes to one or more existing milestones have been proposed.",
  MILESTONE_ADDED = "One or more new milestones are proposed.",
  MILESTONE_DELETED = "One or more milestones have been deleted.",
  DUE_DATE_UPCOMING = "The current milestone has to be submitted, the due date is ",
}

export const MilestonesPaymentAndFunding = () => {
  const { spacing } = useTheme();
  const [isEditingMilestones, setIsEditingMilestones] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updatedMilestones, setUpdatedMilestones] = useState<Milestone[]>();
  const [projectClosing, setProjectClosing] = useState(false);

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();
  const { mutate: milestoneSubmitWorkMutation, isLoading: isSubmittingWork } =
    useMilestoneSubmitWork();
  const { mutate: updateProjectMutation, isLoading: isUpdatingProject } =
    useUpdateProject();
  const invalidateProjectQuery = useInvalidateProjectQuery();
  const { mutate: milestoneAcceptChanges, isLoading: isAcceptingChanges } =
    useMilestoneAcceptChanges();
  const { mutate: milestoneDenyChanges, isLoading: isDenyingChanges } =
    useMilestoneDenyChanges();

  const isRequesterClient = project?.pendingChanges?.requester === "client";

  const budgetType = project?.jobRequest.budget.type;

  const milestones = useMemo(() => {
    const milestones =
      budgetType === "fixed"
        ? project?.jobRequest.fixedProjectFunding.milestones
        : project?.jobRequest.hourlyRateProjectFunding.milestones;
    return milestones ?? [];
  }, [
    budgetType,
    project?.jobRequest.fixedProjectFunding.milestones,
    project?.jobRequest.hourlyRateProjectFunding.milestones,
  ]);

  const existingMilestonesIds = useMemo(() => {
    return milestones.map(({ _id }) => _id ?? "");
  }, [milestones]);

  const areEqual = useCallback(
    (milestones: any[], pendingMilestones?: any[]) => {
      if (milestones.length != (pendingMilestones?.length ?? 0)) return false;

      const diffProps =
        project?.jobRequest.budget.type === "fixed"
          ? FIXED_PRICE_DIFF_PROPS
          : HOURLY_RATE_DIFF_PROPS;

      for (let i = 0; i < milestones.length; i += 1) {
        if (
          !isEqual(
            pick(milestones[i], diffProps),
            pick(pendingMilestones?.[i], diffProps)
          )
        )
          return false;
      }

      return true;
    },
    [project?.jobRequest.budget.type]
  );

  const hasPendingChanges = useMemo(() => {
    return (
      Boolean(project?.pendingChanges?.milestones) &&
      !areEqual(milestones, project?.pendingChanges?.milestones)
    );
  }, [areEqual, milestones, project?.pendingChanges?.milestones]);

  const milestonesExtended = useMemo(() => {
    const newMilestones =
      project?.pendingChanges?.milestones
        ?.map((m, index) => {
          if (!m._id) {
            return { ...m, _id: index.toString() };
          }

          return m;
        })
        ?.filter(
          ({ _id }) => !_id || !existingMilestonesIds.includes(_id ?? "")
        ) ?? [];

    return [...milestones, ...newMilestones];
  }, [existingMilestonesIds, milestones, project?.pendingChanges?.milestones]);

  const updateProject = useCallback(
    (milestones: Milestone[] | HourlyRateMilestone[]) => {
      if (!project) {
        return;
      }

      const updatedProject: Partial<Project> = {
        // ...project,
        pendingChanges: {
          milestones,
          requester: "professional",
          status: "pending",
        },
      };

      updateProjectMutation(updatedProject, {
        onSuccess: ({ _id }) => {
          invalidateProjectQuery(_id);
        },
      });
    },
    [invalidateProjectQuery, project, updateProjectMutation]
  );

  const onEditMilestonesHandler = useCallback(() => {
    setIsEditingMilestones(true);
  }, []);

  const onFinishEditingMilestonesHandler = useCallback(
    (values: FormikValues) => {
      setUpdatedMilestones(values.milestones);
      setOpenDialog(true);
    },
    []
  );

  const { projectId } = useParams();
  const navigate = useNavigate();
  const onCloseDialogHandler = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const { mutate: onCloseProjectMutation } = useCloseProjectRequest();

  const onCancelProjectHandler = useCallback(
    ({ closingDetails }: ClosedProjectData) => {
      onCloseProjectMutation({ closingDetails });
      setProjectClosing(false);
      navigate(`/projects/history/${projectId}/?tab=details`);
    },
    [navigate, onCloseProjectMutation, projectId]
  );

  const onConfirmChangesHandler = useCallback(() => {
    setIsEditingMilestones(false);
    updateProject(updatedMilestones ?? []);
    setOpenDialog(false);
  }, [updateProject, updatedMilestones]);

  const onMilestoneSubmitWorkHandler = useCallback(
    (milestoneId?: string) => {
      if (!milestoneId) return;

      milestoneSubmitWorkMutation(
        { milestoneId },
        {
          onSuccess: () => {
            if (!project?._id) return;

            invalidateProjectQuery(project._id);
          },
        }
      );
    },
    [invalidateProjectQuery, milestoneSubmitWorkMutation, project?._id]
  );

  const onHourlyMilestoneSubmitWorkHandler = useCallback(
    (milestoneId: string, actualWork: ActualWork) => {
      milestoneSubmitWorkMutation(
        { milestoneId, actualWork },
        {
          onSuccess: () => {
            if (!project?._id) return;

            invalidateProjectQuery(project._id);
          },
        }
      );
    },
    [invalidateProjectQuery, milestoneSubmitWorkMutation, project?._id]
  );

  const cancelProjectTitle = useMemo(() => {
    return project?.jobRequest?.fixedProjectFunding?.milestones?.length ?? 0 > 0
      ? "Are You Sure You Want To Cancel This Fixed Price Project?"
      : "Are You Sure You Want To Cancel This Project? ";
  }, [project]);

  const cancelProjectMessage = useMemo(() => {
    return (project?.jobRequest?.fixedProjectFunding?.milestones?.length ?? 0) >
      0 ? (
      <Box py={2}>
        <Typography color="secondary" variant="body2">
          There are no ratings or reviews associated with a canceled project. A
          notice of cancellation will be indicated in the “Timeframe” section of
          the project once published.
        </Typography>
        <Typography
          color="secondary"
          variant="body2"
          style={{ marginTop: spacing(8), fontWeight: 500 }}
        >
          Leave a message for the client regarding the cancelation.
        </Typography>
      </Box>
    ) : (
      <Box py={2}>
        <Typography color="secondary" variant="body2">
          There are no ratings or reviews associated with a canceled project. A
          notice of cancellation will be indicated in the “Timeframe” section of
          the project once published.{" "}
        </Typography>
        <Typography
          color="secondary"
          variant="body2"
          style={{ marginTop: spacing(8), fontWeight: 500 }}
        >
          Leave a message for the client regarding the cancellation.
        </Typography>
      </Box>
    );
  }, [project?.jobRequest?.fixedProjectFunding?.milestones?.length, spacing]);

  const { alertsMap, alertsMessages } = useMemo(() => {
    let alertsMessages = [];
    let alertsMap: { alertIndex: number; milestoneId: string }[] = [];
    let lastIndex = 0;

    const milestones =
      project?.jobRequest?.fixedProjectFunding?.milestones ?? [];

    const inProgressIndex = milestones.findIndex(
      (milestone) => milestone.status === MilestoneStatus.IN_PROGRESS
    );

    if (inProgressIndex >= 0) {
      let expirationDate = project?.createdAt
        ? new Date(project.createdAt)
        : new Date();

      for (let i = 0; i <= inProgressIndex; i += 1) {
        if (milestones[i].dueDate.type === "weeks") {
          expirationDate = addWeeks(
            expirationDate,
            milestones[i].dueDate.count
          );
        }

        if (milestones[i].dueDate.type === "days") {
          expirationDate = addDays(expirationDate, milestones[i].dueDate.count);
        }
      }

      const timeDiff = expirationDate.getTime() - new Date().getTime();

      const daysDiff = timeDiff / (1000 * 3600 * 24);

      if (daysDiff <= 1) {
        lastIndex++;
        alertsMessages.push(
          `${AlertMilestoneMessage.DUE_DATE_UPCOMING} ${format(
            expirationDate,
            formatNominativeMonth
          )}`
        );
        alertsMap = [
          ...alertsMap,
          {
            alertIndex: lastIndex,
            milestoneId: milestones[inProgressIndex]._id ?? "",
          },
        ];
      }
    }

    const isSubmittedIndex = milestones.findIndex(
      (milestone) => milestone.status == "in review"
    );

    if (isSubmittedIndex >= 0) {
      lastIndex++;
      alertsMessages.push(AlertMilestoneMessage.WORK_SUBMITTED);
      alertsMap = [
        ...alertsMap,
        {
          alertIndex: lastIndex,
          milestoneId: milestones[isSubmittedIndex]._id ?? "",
        },
      ];
    }

    const fundedMilestones = milestones.filter(
      ({ status, isFunded }) =>
        (status === MilestoneStatus.PENDING ||
          status === MilestoneStatus.IN_PROGRESS) &&
        isFunded
    );

    if (
      isSubmittedIndex < 0 &&
      !fundedMilestones.length &&
      milestones[milestones.length - 1]?.status !== "completed" &&
      project?.jobRequest.budget.type === "fixed"
    ) {
      lastIndex++;
      alertsMessages.push(AlertMilestoneMessage.FUND_UPCOMING);

      const firstUnfundedMilestone = milestones.find(
        ({ status, isFunded }) =>
          status === MilestoneStatus.PENDING && !isFunded
      );

      alertsMap = [
        ...alertsMap,
        {
          alertIndex: lastIndex,
          milestoneId: firstUnfundedMilestone?._id ?? "",
        },
      ];
    }

    if (project?.pendingChanges?.milestones) {
      const pendingChangesIds = project?.pendingChanges?.milestones.map(
        ({ _id }) => _id
      );
      const newMilestoneIds = pendingChangesIds.filter(
        (id) => !existingMilestonesIds.includes(id ?? "")
      );

      const deletedMilestoneIds = existingMilestonesIds.filter(
        (id) => !pendingChangesIds.includes(id ?? "")
      );

      const changedMilestones = milestones.filter((milestone) => {
        const suggestedChanges = project?.pendingChanges?.milestones.find(
          (m) => m._id === milestone._id
        );

        if (suggestedChanges) {
          return !isEqual(
            pick(milestone, FIXED_PRICE_DIFF_PROPS),
            pick(suggestedChanges, FIXED_PRICE_DIFF_PROPS)
          );
        }

        return false;
      });

      if (changedMilestones.length > 0) {
        lastIndex++;
        alertsMessages.push(AlertMilestoneMessage.MILESTONE_CHANGED);

        alertsMap = [
          ...alertsMap,
          ...changedMilestones.map(({ _id }) => ({
            alertIndex: lastIndex,
            milestoneId: _id ?? "",
          })),
        ];
      }

      if (newMilestoneIds.length > 0) {
        lastIndex++;
        alertsMessages.push(AlertMilestoneMessage.MILESTONE_ADDED);

        alertsMap = [
          ...alertsMap,
          ...newMilestoneIds.map((id) => ({
            alertIndex: lastIndex,
            milestoneId: id ?? "",
          })),
        ];
      }

      if (deletedMilestoneIds.length > 0) {
        lastIndex++;
        alertsMessages.push(AlertMilestoneMessage.MILESTONE_DELETED);

        alertsMap = [
          ...alertsMap,
          ...deletedMilestoneIds.map((id) => ({
            alertIndex: lastIndex,
            milestoneId: id ?? "",
          })),
        ];
      }
    }

    return { alertsMap, alertsMessages };
  }, [
    existingMilestonesIds,
    project?.createdAt,
    project?.jobRequest.budget.type,
    project?.jobRequest?.fixedProjectFunding?.milestones,
    project?.pendingChanges?.milestones,
  ]);

  const onMilestoneAcceptChangesHandler = useCallback(
    (milestoneId?: string, isNew?: boolean) => {
      if (!milestoneId) return;

      milestoneAcceptChanges(
        { milestoneId, isNew },
        {
          onSuccess: () => {
            if (!project?._id) return;

            invalidateProjectQuery(project._id);
          },
        }
      );
    },
    [invalidateProjectQuery, milestoneAcceptChanges, project?._id]
  );

  const onMilestoneDenyChangesHandler = useCallback(
    (milestoneId?: string, isNew?: boolean) => {
      if (!milestoneId) return;

      milestoneDenyChanges(
        { milestoneId, isNew },
        {
          onSuccess: () => {
            if (!project?._id) return;

            invalidateProjectQuery(project._id);
          },
        }
      );
    },
    [invalidateProjectQuery, milestoneDenyChanges, project?._id]
  );

  return (
    <Fragment>
      {alertsMessages.length > 0 && (
        <InfoBox messages={alertsMessages} containerProps={{ my: 10 }} />
      )}

      <Box pt={9} display="flex" alignItems="baseline" columnGap={2}>
        <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
          Milestone
        </Typography>
        <Box component="span" color="#fff" display="flex">
          (
          <CustomTooltip
            arrow
            // interactive
            title={
              budgetType === "fixed" ? (
                <FixedPriceMoreInfo />
              ) : (
                <HourlyRateMoreInfo />
              )
            }
          >
            <Typography mt={1} variant="body2" color="primary">
              More info
            </Typography>
          </CustomTooltip>
          )
        </Box>
      </Box>

      {budgetType === "fixed" ? (
        <FixedPriceMilestonesTable
          alertsMap={alertsMap}
          canAcceptPendingChanges={isRequesterClient}
          canEditMilestones={!hasPendingChanges || !isRequesterClient}
          existingMilestonesIds={existingMilestonesIds}
          isEditingMilestones={isEditingMilestones}
          isLoading={isLoadingProject || isUpdatingProject}
          isSubmittingWork={isSubmittingWork}
          milestones={milestonesExtended as Milestone[]}
          // closingRequest={project?.closingRequest}
          onAcceptChanges={onMilestoneAcceptChangesHandler}
          onDenyChanges={onMilestoneDenyChangesHandler}
          onFinishEdit={onFinishEditingMilestonesHandler}
          onStartEdit={onEditMilestonesHandler}
          onSubmitMilestone={onMilestoneSubmitWorkHandler}
          pendingChanges={
            (project?.pendingChanges?.milestones as Milestone[]) ?? []
          }
          setIsClosing={() => setProjectClosing(true)}
          setIsEditingMilestones={setIsEditingMilestones}
        />
      ) : (
        <HourlyRateMilestonesTable
          canAcceptPendingChanges={isRequesterClient}
          canEditMilestones={!hasPendingChanges || !isRequesterClient}
          existingMilestonesIds={existingMilestonesIds}
          isEditingMilestones={isEditingMilestones}
          isLoading={isLoadingProject || isUpdatingProject}
          milestones={milestonesExtended as HourlyRateMilestone[]}
          // closingRequest={project?.closingRequest}
          setIsClosing={() => setProjectClosing(true)}
          onAcceptChanges={onMilestoneAcceptChangesHandler}
          onDenyChanges={onMilestoneDenyChangesHandler}
          onFinishEdit={onFinishEditingMilestonesHandler}
          onStartEdit={onEditMilestonesHandler}
          pendingChanges={
            (project?.pendingChanges?.milestones as HourlyRateMilestone[]) ?? []
          }
          setIsEditingMilestones={setIsEditingMilestones}
          onSubmitMilestone={onHourlyMilestoneSubmitWorkHandler}
        />
      )}

      <ConfirmationDialog
        confirmLabel="Confirm"
        message={
          <Box pt={2}>
            <Typography color="secondary" variant="body2">
              The changes you made to the milestone(s) will be reviewed by the
              Client. They can suggest further changes too, or approve.
            </Typography>
            <Typography color="secondary" variant="body2">
              Both parties have to agree on changes to a milestone before work
              can begin on it.
            </Typography>
            <Typography
              color="secondary"
              variant="body2"
              style={{ marginTop: spacing(8) }}
            >
              Would you like to add a comment regarding the proposed change?
              Comments added below will be found in the “Files and Messages”
              section of the current project.
            </Typography>
          </Box>
        }
        onConfirm={onConfirmChangesHandler}
        onClose={onCloseDialogHandler}
        open={openDialog}
        title="Changes to Milestone(s) for a Current Project"
      />
      <CancelProjectDialog
        title={cancelProjectTitle}
        message={cancelProjectMessage}
        open={projectClosing}
        onClose={() => {
          setProjectClosing(false);
        }}
        onConfirm={onCancelProjectHandler}
        confirmLabel="Confirm"
      />
    </Fragment>
  );
};
