import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { fundJobRequestMilestones } from "../../../../../api-services/job-requests/job-request/fundJobRequestMilestones";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../../utils/useToast";
import { jobRequestKey } from "./useJobRequestQuery";

export const useJobRequestFundMilestonesMutation = (
  mutationOptions?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const { notifyError } = useToast();

  const jobRequestId = searchParams.get("jobRequestId") ?? "";

  return useMutation(
    async (body: {
      chargeId: string;
      milestones: string[];
      isHourly?: boolean;
      professionalId?: string;
    }) => {
      const { data } = await fundJobRequestMilestones(jobRequestId, body);

      return data;
    },
    {
      ...mutationOptions,
      onError() {
        notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
      },
      onSettled() {
        queryClient.invalidateQueries(jobRequestKey(jobRequestId));
        window.location.reload();
      },
    }
  );
};
