import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PageWrapper } from "../../app/container/PageWrapper";
import { JobPreview } from "../../client/job-requests/create-job-request-wizard/job-post";
import { Section } from "../../common/components";
import { useJobPostQuery } from "../../common/hooks/job-posts";
import { useCanApplyToJobPost } from "./hooks";

export const JobPreviewPage = () => {
  const navigate = useNavigate();
  const {
    data: jobPost,
    isLoading,
    isError,
  } = useJobPostQuery(undefined, {
    onSettled: () => {
      // @ts-ignore
      window.prerenderReady = true;
    },
  });

  const canApplyToJobPost = useCanApplyToJobPost();

  const errorMessage = !jobPost && isError ? "Job post not found" : "";

  return (
    <PageWrapper>
      <Box height="100%">
        <Section isLoading={isLoading} errorMessage={errorMessage}>
          {jobPost && !isError && (
            <JobPreview
              isProfessionalView
              values={jobPost}
              onClose={() => navigate(-1)}
              disableApplication={!canApplyToJobPost}
            />
          )}
        </Section>
      </Box>
    </PageWrapper>
  );
};
