import { GridColDef } from "@mui/x-data-grid";
import { Professional } from "../../professionals/professional";
import { Project } from "../project";

export type MilestoneInvoice = {
  _id: number;
  amount: number;
  description: string;
  professional: Professional;
  client: any;
  type: string;
  project: Project;
  createdAt: Date;
};

export const milestoneInvoicesColumns: GridColDef[] = [
  {
    field: "createdAt",
    headerName: "Date",
    width: 250,
    sortable: false,
  },
  {
    field: "description",
    headerName: "Description",
    width: 250,
    sortable: false,
  },
  {
    field: "professional",
    headerName: "Professional",
    width: 250,
    sortable: false,
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 250,
    sortable: false,
  },
  {
    field: "id",
    headerName: "Invoice ID",
    width: 250,
    sortable: false,
    // hide: true,
  },

  { field: "invoice", headerName: "Invoice ID", width: 250, sortable: false },
];
