import makeStyles from "@mui/styles/makeStyles";
import { LIGHT_BLUE } from "../components/utils";

export const useMessagesInputStyles = makeStyles({
  root: {
    width: "100% !important",
    height: `48px !important`,
    background: "none !important",
    border: "1px solid !important",
    borderRadius: "10px !important",
    borderWidth: "2px !important",
    borderColor: `${LIGHT_BLUE} !important`,
    color: "white !important",
    padding: "8px 9px 8px 6px !important",
    fontSize: "18px",
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 600px)"]: {
      fontSize: 14,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "white !important",
      opacity: "1 !important",
      fontSize: "18px",
      ["@media screen and (max-width: 1024px)"]: {
        fontSize: 16,
      },
      ["@media screen and (max-width: 600px)"]: {
        fontSize: 14,
      },
    },
  },
});
