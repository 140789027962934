import { Box } from "@mui/material";
import React, { useCallback } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { HeaderDropdown, LoadingTypography } from "../../../common/components";
import { Card } from "../../../common/elements/Card";
import {
  Collection,
  useNavigationHandlers,
} from "../../../common/hooks/navigation";
import { useNavigateOnCondition } from "../../../common/hooks/utils";
import { useResponsiveness } from "../../../common/hooks/utils/useResponsiveness";
import { NEW_ID } from "../../inquiries/hooks/useDefaultInquiryPage";
import { useAccountBid } from "../hooks/useAccountBid";
import { useAccountBids } from "../hooks/useAccountBids";
import { useBidsOptions } from "../hooks/useBidsOptions";

export const BidHeader: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { bidId } = useParams();

  const { data: accountBids, isLoading: isLoadingAccountBids } =
    useAccountBids();

  const { data: accountBid, isLoading: isLoadingAccountBid } = useAccountBid();

  const collectionConfig = { type: Collection.BIDS, items: accountBids };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingAccountBids,
    bidId
  );

  const bidsOptions = useBidsOptions();

  const handleSelectBid = useCallback(
    (index: number) => {
      if (isLoadingAccountBids || !accountBids) return;

      navigate({
        pathname: `/bids/${accountBids[index]._id}`,
        search: `?${createSearchParams({
          tab: "details",
        })}`,
      });
    },

    [isLoadingAccountBids, accountBids, navigate]
  );

  const noData = !isLoadingAccountBids && !isLoadingAccountBid && !accountBid;
  const isLoading = isLoadingAccountBid || isLoadingAccountBids;
  const { isSmall } = useResponsiveness();

  useNavigateOnCondition("/bids/", noData);

  const onTabNavigationHandler = (tab: string) => {
    searchParams.set("tab", tab);
    setSearchParams(searchParams);
  };

  return (
    <Box width="60%" pt={10} pb={10}>
      <Box>
        {bidId !== NEW_ID && (
          <LoadingTypography
            isLoading={isLoading}
            typographyProps={{
              width: "50%",
            }}
          >
            Bid ({1 + index} of {accountBids?.length})
          </LoadingTypography>
        )}
      </Box>
      <Box py={10}>
        <HeaderDropdown
          variant={"h1"}
          isLoading={isLoading}
          selectedOptionId={bidId}
          options={bidsOptions}
          onSelect={handleSelectBid}
          placeholder={accountBid?.jobRequest?.title}
        />
      </Box>
      <Box display="flex" columnGap={5}>
        <Card
          width={106}
          label="Details"
          isSelected={searchParams.get("tab") === "details"}
          onClick={() => onTabNavigationHandler("details")}
          isLoading={isLoading}
        />
        <Card
          width={208}
          label="Bids and Messages"
          isSelected={searchParams.get("tab") === "replies"}
          onClick={() => onTabNavigationHandler("replies")}
          isLoading={isLoadingAccountBids}
        />
      </Box>
    </Box>
  );
};
