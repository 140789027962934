import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { SchemaOf, object, string } from "yup";
import { useCreateJobApplication } from "../../professional/job-posts/hooks";
import { UploadResume } from "../../professional/job-posts/UploadResume";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../utils/useToast";

// const useStyles = makeStyles({
//   textArea: {
//     background: "none",
//     border: "1px solid",
//     borderColor: BLUE,
//     color: BLUE,
//     fontFamily: "inherit",
//     fontSize: 16,
//     padding: 8,
//     outline: "none",
//     width: "100%",
//     resize: "none",
//   },
// });

export type ApplyByFormDialogFormData = {
  message?: string;
  resume: string;
};

type ApplyByFormDialogValidationSchema = SchemaOf<ApplyByFormDialogFormData>;

const applyByFormDialogValidationSchema: ApplyByFormDialogValidationSchema =
  object({
    message: string().optional(),
    resume: string().required().min(1),
  });

type ApplyByFormDialogProps = {
  open: boolean;
  onClose: () => void;
  jobPostId: string; // Pass the job post ID to the modal
};

export const ApplyByFormDialog = ({
  open,
  onClose,
  jobPostId,
}: ApplyByFormDialogProps) => {
  //const styles = useStyles();
  const navigate = useNavigate();

  const { notifyError } = useToast();

  const { mutate: createJobApplication } = useCreateJobApplication();

  const onSubmitHandler = useCallback(
    ({ resume }: ApplyByFormDialogFormData) => {
      if (!jobPostId) return;

      createJobApplication(
        {
          jobPost: jobPostId,
          resume,
        },
        {
          onSuccess: () => {
            navigate("/job-requests/search");
          },
          onError: () => {
            notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
          },
        }
      );
    },
    [createJobApplication, jobPostId, navigate, notifyError]
  );

  return (
    <Dialog
      open={open}
      PaperProps={{ style: { height: "auto", overflow: "unset" } }}
    >
      <Formik
        initialValues={{ message: "", resume: "" }}
        isInitialValid={false}
        onSubmit={onSubmitHandler}
        validateOnChange
        validationSchema={applyByFormDialogValidationSchema}
      >
        {({ handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <Typography
                color="secondary"
                variant="subtitle1"
                style={{ fontWeight: 700 }}
              >
                Send Your Resume to the Client
              </Typography>
              <Typography color="secondary" variant="body2">
                Upload your resume to the client.
              </Typography>
            </DialogTitle>
            <DialogContent style={{ overflowY: "unset" }}>
              {/*<Field
                as={TextareaAutosize}
                placeholder="Enter message here (optional)"
                name="message"
                value={values.message}
                className={styles.textArea}
                onChange={handleChange}
                onBlur={handleBlur}
                minRows={3}
                maxRows={3}
              />*/}
              <UploadResume jobPostId={jobPostId} />
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                disabled={!isValid}
                type="submit"
                variant="contained"
                data-cy-btn="confirm"
              >
                Confirm
              </Button>
              <Button color="secondary" onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
