import { Box, Divider } from "@mui/material";
import React from "react";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { JobRequestHeaderContent } from "./JobRequestHeaderContent";
import { JobRequestHeaderSummary } from "./JobRequestHeaderSummary";

export const JobRequestHeader: React.FC = () => {
  const { isSmall, isMedium, isLarge } = useResponsiveness();

  return (
    <Box
      justifyContent="space-between"
      display={"flex"}
      alignItems="center"
      columnGap={isMedium ? 12 : 10}
    >
      <Box width={isSmall ? "100%" : "65%"} py={10}>
        <JobRequestHeaderContent />
      </Box>
      {!isLarge && <Divider orientation="vertical" flexItem />}
      {!isLarge && (
        <Box width="25%" py={10}>
          <JobRequestHeaderSummary />
        </Box>
      )}
    </Box>
  );
};
