import { Box, Typography } from "@mui/material";
import React from "react";
import { Project } from "../../../../../api-types/projects";
import {
  BLUE,
  LIGHT_BLUE,
  YELLOW,
} from "../../../../../themes/components/utils";
import { ImagePreview } from "../../../../client/job-requests/utils";
import { CustomTooltip } from "../../../../common/components";

type PortfolioProjectProps = {
  project?: Project;
  height?: number;
  width?: number;
  hover?: boolean;
};

export const PortfolioProject: React.FC<PortfolioProjectProps> = ({
  project,
  height = 320,
  width = 650,
  hover,
}) => {
  return (
    <CustomTooltip
      title={
        <Typography variant="subtitle2" color="inherit">
          {project?.title ?? ""}
        </Typography>
      }
    >
      <Box
        height="inherit"
        p={2}
        bgcolor={hover ? YELLOW : LIGHT_BLUE}
        color={BLUE}
      >
        <ImagePreview
          height={height}
          width={width}
          title={project?.title}
          isLocalImages={false}
          selectedFiles={project?.images ?? []}
          arrowColor="secondary"
        />
      </Box>
    </CustomTooltip>
  );
};
