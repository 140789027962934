import { Box } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useCurrentProfessionalIndex } from "../../../../client/projects/state/hooks";
import {
  LoadingTypography,
  HeaderDropdown,
} from "../../../../common/components";
import { BackButton } from "../../../../common/elements/BackButton";
import { Card } from "../../../../common/elements/Card";
import {
  Collection,
  useNavigationHandlers,
} from "../../../../common/hooks/navigation";
import { useProjectQuery } from "../../../../common/hooks/projects/project/useProjectQuery";
import { useProjectsQuery } from "../../../../common/hooks/projects/useProjectsQuery";
import { useNavigateOnCondition } from "../../../../common/hooks/utils";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../../../../state/hooks";
import { useProjectsOptions } from "../../../bids/hooks/useProjectOptions";

type ProjectHeaderContentProps = {
  variant?: "project" | "collaboration";
};

export const ProjectHeaderContent: React.FC<ProjectHeaderContentProps> = ({
  variant = "project",
}) => {
  const navigate = useNavigate();
  const { id: currentUserId } = useScopedDowngradedStateValue(useUser());
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isSmall } = useResponsiveness();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const { data: projects, isLoading: isLoadingProjects } = useProjectsQuery();

  const isLoading = isLoadingProject || isLoadingProjects;

  const collectionConfig = { type: Collection.PROJECTS, items: projects };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingProjects,
    projectId
  );

  const projectsOptions = useProjectsOptions();

  const noData = !isLoading && !project;

  const currentProjectIndex = isLoading
    ? ""
    : `(${index + 1} of ${projects?.length})`;

  const currentProfessionalIndex = useScopedDowngradedStateValue(
    useCurrentProfessionalIndex()
  );

  const setCurrentProfessionalIndex = useCurrentProfessionalIndex().set;

  useNavigateOnCondition("/projects/", noData);

  const handleSelectCurrentProject = useCallback(
    (index: number) => {
      if (!projects) return;

      navigate({
        pathname: `/projects/${projects[index]._id}`,
        search: `?${createSearchParams({
          tab: "details",
        })}`,
      });
    },
    [navigate, projects]
  );

  const onTabNavigationHandler = useCallback(
    (tab: string) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleOnBackButtonClick = useCallback(() => {
    navigate("/projects/");
  }, [navigate]);

  const options = useMemo(() => {
    return (
      project?.professionals
        .filter(({ professional }) => professional._id !== currentUserId)
        .map(({ professional }, index) => ({
          id: professional._id,
          title: professional.email ?? "",
          index,
        })) ?? []
    );
  }, [currentUserId, project?.professionals]);

  return (
    <Box width="60%" flex={2} py={10}>
      <Box>
        <LoadingTypography
          typographyProps={{
            variant: "subtitle2",
          }}
        >
          {variant === "project"
            ? `Current Project ${currentProjectIndex}`
            : "Collaborators on current project"}
        </LoadingTypography>
      </Box>
      <Box py={10}>
        <HeaderDropdown
          isLoading={isLoading}
          selectedOptionId={projectId}
          options={projectsOptions}
          onSelect={handleSelectCurrentProject}
          placeholder={project?.jobRequest?.title}
        />
      </Box>
      <Box display="flex" columnGap={5}>
        <Card
          label="Details"
          isSelected={
            searchParams.get("tab") === "details" || !searchParams.get("tab")
          }
          onClick={() => onTabNavigationHandler("details")}
          variant="subtitle2"
        />
        {variant === "project" ? (
          <Card
            label="Files and Messages"
            isSelected={searchParams.get("tab") === "replies"}
            onClick={() => onTabNavigationHandler("replies")}
          />
        ) : (
          <Card
            select
            label={`Files and Messages(${
              options[currentProfessionalIndex]?.title ?? "-"
            })`}
            options={options}
            selectedIndex={currentProfessionalIndex}
            onSelect={(newIndex) => setCurrentProfessionalIndex(newIndex)}
            isSelected={searchParams.get("tab") === "replies"}
            onClick={() => {
              onTabNavigationHandler("replies");
            }}
          />
        )}

        {!isSmall && variant === "project" && (
          <Card
            label="Payments"
            isSelected={searchParams.get("tab") === "payments"}
            onClick={() => onTabNavigationHandler("payments")}
          />
        )}
      </Box>

      {variant === "collaboration" && (
        <Box marginTop="60px">
          <BackButton
            onClick={handleOnBackButtonClick}
            backLabel="Back to [On-going Project]"
          />
        </Box>
      )}
    </Box>
  );
};
