import makeStyles from "@mui/styles/makeStyles";
import { BLUE, COLOR, YELLOW } from "../components/utils";

export const useTransparentSelectStyles = makeStyles(() => ({
  root: {
    height: 68,
    color: `white !important`,
    border: 0,
    outline: 0,
    backgroundColor: `${BLUE} !important`,
    "& .MuiSvgIcon-root": {
      color: `${YELLOW} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "& :focus": { backgroundColor: BLUE },
  },
}));
