import { Box, Skeleton } from "@mui/material";
import React from "react";

export const CurrentProjectMessagesSkeleton: React.FC = () => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" pt={8} width="100%">
        <Box width="50%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="40%"
            height={30}
          />
          <Box mt={8}>
            <Skeleton variant="rectangular" animation="wave" height={40} />
          </Box>
          <Box display="flex" mt={12}>
            <Box width="20%">
              <Skeleton variant="rectangular" animation="wave" height={80} />
            </Box>
            <Box ml={8} width="40%">
              <Skeleton variant="rectangular" animation="wave" height={80} />
            </Box>
            <Box ml={8} width="20%">
              <Skeleton variant="rectangular" animation="wave" height={80} />
            </Box>
          </Box>
        </Box>

        <Box mt={8} width="37%" justifyContent="space-between">
          <Box mb={4}>
            <Skeleton variant="rectangular" animation="wave" height={40} />
          </Box>
          <Box mb={2}>
            <Skeleton variant="rectangular" animation="wave" height={30} />
          </Box>
          <Box mb={2}>
            <Skeleton variant="rectangular" animation="wave" height={30} />
          </Box>
          <Box mb={2}>
            <Skeleton variant="rectangular" animation="wave" height={30} />
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        pt={16}
        width="100%"
        alignItems="center"
      >
        <Box width="40%">
          <Skeleton variant="rectangular" animation="wave" height={40} />
        </Box>
        <Box width="40%">
          <Skeleton variant="rectangular" animation="wave" height={30} />
        </Box>
      </Box>

      <Box mt={8} display="flex" alignItems="center">
        <Skeleton variant="circular" animation="wave" width={90} height={90} />
        <Box width="40%" ml={4}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="35%"
            height={30}
          />
          <Box pt={2}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="20%"
              height={15}
            />
          </Box>
        </Box>
      </Box>

      <Box mt={10} width="100%" display="flex" columnGap={90}>
        <Box
          ml={2}
          width="25%"
          columnGap={2.5}
          display="flex"
          justifyContent="space-between"
        >
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="50%"
            height={35}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="90%"
            height={35}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="90%"
            height={35}
          />
        </Box>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="15%"
          height={35}
        />
      </Box>

      <Box pt={12} display="flex" justifyContent="flex-end">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="15%"
          height={35}
        />
      </Box>

      <Box pt={12} display="flex" justifyContent="flex-end">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="15%"
          height={35}
        />
      </Box>
    </Box>
  );
};
