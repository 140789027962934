import { Components } from "@mui/material";
import { BLUE } from "./utils";

export const MuiPaginationItem: Components["MuiPaginationItem"] = {
  styleOverrides: {
    icon: {
      color: BLUE,
    },
  },
};
//
// export const MuiPaginationItem: MuiComponentThemeDefinition<
//   PaginationItemClassKey,
//   PaginationItemProps
// > = {
//   props: {},
//   override: () => ({
//     icon: {
//       color: BLUE,
//     },
//   }),
// };
