import { Components } from "@mui/material";
import { BLUE, YELLOW, LIGHT_BLUE } from "./utils";

export const MuiListItem: Components["MuiListItem"] = {
  styleOverrides: {
    root: {
      height: 50,
      justifyContent: "center",
      fontSize: 18,
    },
    button: {
      "&:hover": {
        color: `${YELLOW} !important`,
        backgroundColor: `${BLUE} !important`,
      },
    },
    divider: {
      borderBottomColor: LIGHT_BLUE,
    },
  },
};
//
// export const MuiListItem: MuiComponentThemeDefinition<
//   ListItemClassKey,
//   ListItemProps
// > = {
//   props: {},
//   override: () => ({
//     root: {
//       height: 50,
//       justifyContent: "center",
//       fontSize: 18,
//     },
//     button: {
//       "&:hover": {
//         color: `${YELLOW} !important`,
//         backgroundColor: `${BLUE} !important`,
//       },
//     },
//   }),
// };
