import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Box, Button, Input, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Field, Formik, FormikErrors, FormikValues } from "formik";
import React, { useCallback, useMemo } from "react";
import * as Yup from "yup";
import { CreateBidRequest } from "../../../../api-types/job-requests/bids";
import { YELLOW, COLOR, RED } from "../../../../themes/components/utils";
import { CustomTooltip } from "../../../common/components";
import { CustomTextArea } from "../../../common/elements";
import { useRequiredFieldLabel } from "../../../common/hooks/utils";

type EditingFormProps = {
  setIsEditing?: (value: boolean) => void;
  onHandlePlaceBid: (values: CreateBidRequest) => void;
  initialValues: any;
  onCancel?: () => void;
  budgetType?: string;
  isLoading?: boolean;
};

const useStyles = makeStyles({
  radio: {
    "& .MuiSvgIcon-root": {
      width: 48,
      height: 48,
      fill: YELLOW,
    },
  },
  label: {
    "& .MuiTypography-body1": {
      fontSize: "16px !important",
      lineHeight: 1,
    },
  },
  textArea: {
    padding: 4,
    fontSize: 21,
    color: "white",
    backgroundColor: "transparent",
    height: "160px",
    width: "100%",
    resize: "none",
  },
  input: {
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: COLOR.WHITE,
    borderRadius: 2,
    marginRight: 12,
    color: COLOR.WHITE,
    fontSize: 16,
    width: 54,
    height: 32,

    "& input": {
      padding: "0px 12px",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  button: {
    fontSize: 12,
    width: "fit-content",
    height: 40,
  },
  inputError: {
    border: `2px solid ${RED}`,
  },
});

const fixBidSchema = Yup.object({
  fixedProjectFunding: Yup.object({
    totalPrice: Yup.number()
      .min(1, "Fixed price for complete project must be greater than zero")
      .required("Fixed price for complete project is required"),
  }).test((o) => (o.totalPrice ?? 0) > 0),
});

type FixBidSchema = Yup.InferType<typeof fixBidSchema>;

const isFixBidSchema = (
  errors: FormikErrors<FormikValues>
): errors is FormikErrors<FixBidSchema> => Boolean(errors.fixedProjectFunding);

const hourlyBidSchema = Yup.object({
  hourlyProjectFunding: Yup.object({
    hourlyRate: Yup.number()
      .min(1, "Hourly rate must be greater than zero")
      .required("Hourly rate is required"),
  }).test((o) => (o.hourlyRate ?? 0) > 0),
});

type HourlyBidSchema = Yup.InferType<typeof hourlyBidSchema>;

const isHourlyBidSchema = (
  errors: FormikErrors<FormikValues>
): errors is FormikErrors<HourlyBidSchema> =>
  Boolean(errors.hourlyProjectFunding);

export const EditingForm: React.FC<EditingFormProps> = ({
  setIsEditing,
  onHandlePlaceBid,
  initialValues,
  onCancel,
  budgetType,
  isLoading,
}) => {
  const styles = useStyles();
  const requiredFieldLabel = useRequiredFieldLabel();

  const onCancelHandler = useCallback(() => {
    setIsEditing?.(false);
    onCancel?.();
  }, [onCancel, setIsEditing]);

  const validationSchema = useMemo(() => {
    return budgetType === "hourly" ? hourlyBidSchema : fixBidSchema;
  }, [budgetType]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onHandlePlaceBid(values);

        setIsEditing?.(false);
      }}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      {({
        isValid,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box color="white" pt={6}>
            {initialValues?.introductoryMessage !== "null" && (
              <Box pb={2.5}>
                <Field
                  component={CustomTextArea}
                  className={styles.textArea}
                  name="introductoryMessage"
                  placeholder="Introductory Message"
                  value={values.introductoryMessage}
                  error={errors.introductoryMessage}
                />
              </Box>
            )}
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Proposed Bid:
              </Typography>
            </Box>
            {budgetType === "fixed" && (
              <Box display="flex" columnGap={12.5}>
                <Box display="flex" columnGap={2.5} py={2} alignItems="center">
                  <Typography variant="body2" style={{ fontWeight: 700 }}>
                    {requiredFieldLabel("Fixed Price for Complete Project")}
                  </Typography>
                  <Box display="flex" alignItems="center" columnGap={1}>
                    <Typography variant="body2">$</Typography>
                    <Field
                      as={Input}
                      disableUnderline
                      className={`${styles.input} ${
                        isFixBidSchema(errors) &&
                        errors.fixedProjectFunding?.totalPrice
                          ? styles.inputError
                          : ""
                      }`}
                      name="fixedProjectFunding.totalPrice"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values?.fixedProjectFunding?.totalPrice ?? 0}
                      style={{ width: 80 }}
                      endAdornment={
                        isFixBidSchema(errors) &&
                        errors.fixedProjectFunding?.totalPrice && (
                          <CustomTooltip
                            type="error"
                            title={
                              (isFixBidSchema(errors) &&
                                errors.fixedProjectFunding?.totalPrice) ??
                              ""
                            }
                          >
                            <ErrorRoundedIcon
                              color="error"
                              fontSize="small"
                              data-cy-error="fixedProjectFunding.totalPrice"
                              style={{ cursor: "initial" }}
                            />
                          </CustomTooltip>
                        )
                      }
                    />
                  </Box>
                </Box>
                {/*<Box display="flex" columnGap={8} py={2}>*/}
                {/*  <Typography variant="body2" style={{ fontWeight: 700 }}>*/}
                {/*    Due Date */}
                {/*  </Typography>*/}
                {/*  <Box display="flex" alignItems="center" columnGap={4}>*/}
                {/*    <Field*/}
                {/*      as={Input}*/}
                {/*      className={styles.input}*/}
                {/*      name="fixedProjectFunding.dueDate.weeks"*/}
                {/*      onBlur={handleBlur}*/}
                {/*      onChange={handleChange}*/}
                {/*      type="number"*/}
                {/*      value={values?.fixedProjectFunding?.dueDate?.weeks ?? 0}*/}
                {/*    />*/}
                {/*    <Typography variant="body2">week(s)</Typography>*/}
                {/*  </Box>*/}
                {/*</Box>*/}
                {/*<Box*/}
                {/*  display="flex"*/}
                {/*  columnGap={8}*/}
                {/*  py={2}*/}
                {/*  alignItems="center"*/}
                {/*>*/}
                {/*  <Typography variant="body2" style={{ fontWeight: 700 }}>*/}
                {/*    Milestone(s)*/}
                {/*  </Typography>*/}
                {/*  <Field*/}
                {/*    as={Input}*/}
                {/*    className={styles.input}*/}
                {/*    name="fixedProjectFunding.milestones"*/}
                {/*    onBlur={handleBlur}*/}
                {/*    onChange={handleChange}*/}
                {/*    type="number"*/}
                {/*    value={values?.fixedProjectFunding?.milestones ?? 0}*/}
                {/*  />*/}
                {/*</Box>*/}
              </Box>
            )}
            {budgetType === "hourly" && (
              <Box pt={2.5}>
                {/*<RadioGroupContainer*/}
                {/*  subTitle="Estimate the hours you’d like dedicated to this project per week"*/}
                {/*  title="Hours Dedicated to This Project"*/}
                {/*>*/}
                {/*  <FormControlLabel*/}
                {/*    control={*/}
                {/*      <RadioWithInput*/}
                {/*        onChange={handleChange}*/}
                {/*        onBlur={handleBlur}*/}
                {/*        colorRadio*/}
                {/*        name="hourlyProjectFunding.hours"*/}
                {/*        minValue={*/}
                {/*          values?.hourlyProjectFunding?.hours?.estimate?.min*/}
                {/*        }*/}
                {/*        maxValue={*/}
                {/*          values?.hourlyProjectFunding?.hours?.estimate?.max*/}
                {/*        }*/}
                {/*        checked={*/}
                {/*          values?.hourlyProjectFunding?.hours?.type ===*/}
                {/*          "estimate"*/}
                {/*        }*/}
                {/*        radioValue="estimate"*/}
                {/*        isMinMaxInput*/}
                {/*        leftLabel="Between"*/}
                {/*        betweenInputsLabel="and"*/}
                {/*      />*/}
                {/*    }*/}
                {/*    label="hours per week"*/}
                {/*  />*/}
                {/*  <FormControlLabel*/}
                {/*    control={*/}
                {/*      <RadioWithInput*/}
                {/*        onChange={handleChange}*/}
                {/*        onBlur={handleBlur}*/}
                {/*        leftLabel="Exactly"*/}
                {/*        colorRadio*/}
                {/*        width="small"*/}
                {/*        name="hourlyProjectFunding.hours"*/}
                {/*        value={values?.hourlyProjectFunding?.hours?.fixed}*/}
                {/*        checked={*/}
                {/*          values?.hourlyProjectFunding?.hours?.type === "fixed"*/}
                {/*        }*/}
                {/*        radioValue="fixed"*/}
                {/*      />*/}
                {/*    }*/}
                {/*    label="hours per week"*/}
                {/*  />*/}
                {/*</RadioGroupContainer>*/}
                <Box
                  display="flex"
                  // flexDirection="column"
                  columnGap={2.5}
                  pb={2.5}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                    variant="body2"
                  >
                    {requiredFieldLabel("Hourly Rate")}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" style={{ paddingRight: 6 }}>
                      $
                    </Typography>
                    <Field
                      as={Input}
                      disableUnderline
                      className={`${styles.input} ${
                        isHourlyBidSchema(errors) &&
                        errors.hourlyProjectFunding?.hourlyRate
                          ? styles.inputError
                          : ""
                      }`}
                      value={values?.hourlyProjectFunding?.hourlyRate ?? 0}
                      name="hourlyProjectFunding.hourlyRate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      style={{
                        width: 80,
                      }}
                      endAdornment={
                        isHourlyBidSchema(errors) &&
                        errors.hourlyProjectFunding?.hourlyRate && (
                          <CustomTooltip
                            title={
                              (isHourlyBidSchema(errors) &&
                                errors.hourlyProjectFunding?.hourlyRate) ??
                              ""
                            }
                            type="error"
                          >
                            <ErrorRoundedIcon
                              color="error"
                              fontSize="small"
                              data-cy-error="hourlyProjectFunding.hourlyRate"
                              style={{ cursor: "initial" }}
                            />
                          </CustomTooltip>
                        )
                      }
                    />
                    <Typography variant="body1">/hr</Typography>
                  </Box>
                </Box>
                {/*<RadioGroupContainer*/}
                {/*  subTitle="Estimate how long the you think the project will last"*/}
                {/*  title="Estimate Timeframe"*/}
                {/*>*/}
                {/*  <FormControlLabel*/}
                {/*    control={*/}
                {/*      <RadioWithInput*/}
                {/*        onChange={handleChange}*/}
                {/*        onBlur={handleBlur}*/}
                {/*        colorRadio*/}
                {/*        width="small"*/}
                {/*        name={`hourlyProjectFunding.timeFrame`}*/}
                {/*        minValue={*/}
                {/*          values?.hourlyProjectFunding?.timeFrame?.estimate?.min*/}
                {/*        }*/}
                {/*        maxValue={*/}
                {/*          values?.hourlyProjectFunding?.timeFrame?.estimate?.max*/}
                {/*        }*/}
                {/*        checked={*/}
                {/*          values?.hourlyProjectFunding?.timeFrame?.type ===*/}
                {/*          "estimate"*/}
                {/*        }*/}
                {/*        radioValue="estimate"*/}
                {/*        isMinMaxInput*/}
                {/*        leftLabel="Between"*/}
                {/*        betweenInputsLabel="and"*/}
                {/*      />*/}
                {/*    }*/}
                {/*    label="weeks"*/}
                {/*  />*/}
                {/*  <FormControlLabel*/}
                {/*    control={*/}
                {/*      <RadioWithInput*/}
                {/*        onChange={handleChange}*/}
                {/*        onBlur={handleBlur}*/}
                {/*        leftLabel="Exactly"*/}
                {/*        colorRadio*/}
                {/*        width="small"*/}
                {/*        name={`hourlyProjectFunding.timeframe`}*/}
                {/*        value={values?.hourlyProjectFunding?.timeFrame?.fixed}*/}
                {/*        checked={*/}
                {/*          values?.hourlyProjectFunding?.timeFrame?.type ===*/}
                {/*          "fixed"*/}
                {/*        }*/}
                {/*        radioValue="fixed"*/}
                {/*      />*/}
                {/*    }*/}
                {/*    label="weeks"*/}
                {/*  />*/}
                {/*</RadioGroupContainer>*/}
              </Box>
            )}
            <Box display="flex" columnGap={3} py={2.5}>
              <Button size="small" onClick={onCancelHandler}>
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                type="submit"
                disabled={!dirty || !isValid || isLoading}
              >
                Submit Bid
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};
