import makeStyles from "@mui/styles/makeStyles";
import { BLUE } from "../components/utils";

export const useBlueTextColorStyles = makeStyles(() => ({
  root: {
    height: 68,
    color: "#0F1E4A",
    "& .MuiSvgIcon-root": {
      color: `${BLUE} !important`,
      marginRight: 15,
    },
  },
}));
