import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { COLOR } from "../../../../themes/components/utils";
import { CustomInput } from "../../../common/elements";
import { SearchJobFiltersFormData } from "../schemas/searchJobFiltersSchema";
import { useSearchJobPostFiltersStyles } from "./useSearchJobPostFiltersStyles";

const jobSponsorship = [
  {
    label: "Sponsorship offered",
    value: "sponsorship",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const JobSponsorshipFilter = () => {
  const { handleBlur, handleChange, values } =
    useFormikContext<SearchJobFiltersFormData>();
  const styles = useSearchJobPostFiltersStyles();

  const isSponsorshipDisabled =
    values.jobSponsorship?.length === 0 ||
    (values.jobSponsorship?.length === 1 &&
      values.jobSponsorship?.includes("sponsorship"));

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        className={`${styles.label} ${values.showAll && styles.disabledLabel}`}
        color="secondary"
        variant="subtitle1"
      >
        Status Requirement
      </Typography>
      {jobSponsorship.map((item) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={values.jobSponsorship.includes(item.value)}
              className={styles.checkbox}
              data-cy={item.value}
              disabled={values.showAll}
              name="jobSponsorship"
              onChange={handleChange}
              style={{
                color: COLOR.BLUE,
              }}
            />
          }
          key={item.value}
          label={
            <Typography color="secondary" variant="subtitle2">
              {item.label}
            </Typography>
          }
          value={item.value}
        />
      ))}
      {!isSponsorshipDisabled && !values.showAll && (
        <Box pt={5} minWidth={300}>
          <Field
            size="medium"
            component={CustomInput}
            placeholder={"Other Status Requirement"}
            name="jobSponsorshipOther"
            value={values.jobSponsorshipOther}
            onChange={handleChange}
            onBlur={handleBlur}
            customStyles={{
              input: styles.input,
              label: styles.inputLabel,
              inputFocus: styles.inputFocus,
            }}
          />
        </Box>
      )}
    </Box>
  );
};
