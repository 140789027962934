import { DialogProps } from "@mui/material";
import { useCallback } from "react";

export function useHandleCloseDialog(onClose?: () => void) {
  return useCallback<Required<DialogProps>["onClose"]>(
    (_, reason) => {
      if (reason === "backdropClick") return;

      onClose?.();
    },
    [onClose]
  );
}
