import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

type NavigationActionsProps = {
  onSuccess?: () => void;
  containerProps?: any;
};

const useStyles = makeStyles({
  nextButton: {
    fontWeight: "normal",
    height: 60,
    width: 140,
  },
  saveDraftButton: {
    fontWeight: "normal",
    height: 60,
    width: 140,
  },
});

export const NavigationActions: React.FC<NavigationActionsProps> = ({
  onSuccess,
  containerProps,
}) => {
  const styles = useStyles();

  return (
    <Box
      alignSelf="flex-end"
      display="flex"
      flexDirection="column"
      {...containerProps}
    >
      <Button
        variant="outlined"
        color="primary"
        onClick={onSuccess}
        className={styles.nextButton}
        size="small"
      >
        Next Step
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={onSuccess}
        className={styles.saveDraftButton}
        size="small"
      >
        Save Draft
      </Button>
    </Box>
  );
};
