import valid from "card-validator";
import * as Yup from "yup";
import { AccountInformation } from "../account-information";

export type BillingInformation = {
  cardNumber?: string;
  expirationDate?: string;
  cvcCode?: string;
  isSameAsAccountInformation?: boolean;
  billingAddress?: AccountInformation;
};

export const initialAccountInformation = {
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  country: "",
  city: "",
  state: "",
  zipCode: "",
  phoneNumber: "",
  email: "",
};

export const initialBillingInformation: BillingInformation = {
  cardNumber: "",
  expirationDate: "",
  cvcCode: "",
  isSameAsAccountInformation: false,
  billingAddress: initialAccountInformation,
};

export const billingInformationValidationSchema = Yup.object({
  cardNumber: Yup.string()
    .test(
      "test-number",
      "Credit Card number is invalid",
      (value) => valid.number(value).isValid
    )
    .required(),
  expirationDate: Yup.string()
    .test(
      "test-expiration-date",
      "Card expiration date is invalid",
      (value) => valid.expirationDate(value).isValid
    )
    .required(),
  cvcCode: Yup.string()
    .test(
      "test-cvv",
      "Card CVV code is invalid",
      (value) => valid.cvv(value).isValid
    )
    .required(),
});
