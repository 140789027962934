/* eslint-disable no-undef */
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Gleap from "gleap";
import { FC, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Slide, ToastContainer } from "react-toastify";
import WebFont from "webfontloader";
import { RouterIndex } from "../../../routes";
import { BLUE, COLOR } from "../../../themes/components/utils";
import { useCheckAccessExpiry } from "../../common/hooks/sign-in";
import { ThemeContext } from "../contexts";
import "react-toastify/dist/ReactToastify.css";

export const SUBSCRIBE_ROUTE = "/subscribe";

WebFont.load({
  custom: {
    families: ["Jost"],
  },
});

const useStyle = makeStyles(() => ({
  toast: {
    "& .Toastify__toast-theme--dark": {
      backgroundColor: COLOR.WHITE_BLUE,
      color: BLUE,
    },
    "& .Toastify__close-button": {
      color: BLUE,
    },
  },
}));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      retry: 3,
      retryOnMount: true,
      staleTime: 1000 * 60 * 2,
    },
  },
});

export const App: FC = () => {
  useCheckAccessExpiry();

  const styles = useStyle();

  useEffect(() => {
    if (!sessionStorage.getItem("platform")) {
      if (process.env.REACT_APP_ENV === "dev") {
        sessionStorage.setItem("platform", "dev");
        return;
      }

      if (process.env.REACT_APP_ENV === "prod") {
        sessionStorage.setItem("platform", "prod");
        return;
      }
      sessionStorage.setItem("platform", "local");
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/client") {
      Gleap.showFeedbackButton(false);
    } else {
      Gleap.showFeedbackButton(true);
    }
  });

  return (
    <ThemeContext>
      <QueryClientProvider client={queryClient}>
        {/*<Metadata />*/}
        <ToastContainer
          className={styles.toast}
          position="bottom-center"
          autoClose={2500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          transition={Slide}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Box display="flex" flexDirection="column" height="100%" flexGrow={1}>
          <RouterIndex />
        </Box>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeContext>
  );
};
