import makeStyles from "@mui/styles/makeStyles";
import { BLUE, COLOR, RED, YELLOW } from "../components/utils";

export const useRedButtonStyles = makeStyles({
  delete: {
    borderColor: RED,
    "& .MuiButton-label": {
      color: RED,
    },
    "&:hover": {
      borderColor: RED,
      color: RED,
    },
    "&.Mui-disabled": {
      borderColor: RED,
    },
    "&.Mui-disabled > .MuiButton-label": {
      color: RED,
    },
  },
});
