import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { COLOR } from "../../../../themes/components/utils";
import { CustomInput } from "../../../common/elements";
import { SearchJobFiltersFormData } from "../schemas";
import { useSearchJobPostFiltersStyles } from "./useSearchJobPostFiltersStyles";

const jobLocation = [
  {
    label: "Remote Job",
    value: "remote",
  },
  {
    label: "On site only",
    value: "on-site",
  },
  {
    label: "Hybrid required",
    value: "hybrid",
  },
];

export const JobLocationFilter = () => {
  const { handleBlur, handleChange, values } =
    useFormikContext<SearchJobFiltersFormData>();
  const styles = useSearchJobPostFiltersStyles();

  const isLocationDisabled =
    values.jobLocation?.length === 0 ||
    (values.jobLocation?.length === 1 && values.jobLocation.includes("remote"));

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        className={`${styles.label} ${values.showAll && styles.disabledLabel}`}
        color="secondary"
        variant="subtitle1"
      >
        Job Location
      </Typography>
      {jobLocation.map((item) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={values.jobLocation?.includes(item.value)}
              className={styles.checkbox}
              data-cy={item.value}
              disabled={values.showAll}
              name="jobLocation"
              onChange={handleChange}
              style={{
                color: COLOR.BLUE,
              }}
            />
          }
          key={item.value}
          label={
            <Typography color="secondary" variant="subtitle2">
              {item.label}
            </Typography>
          }
          value={item.value}
        />
      ))}
      {!isLocationDisabled && !values.showAll && (
        <Box pt={5} width={180}>
          <Field
            size="medium"
            component={CustomInput}
            placeholder={"Location"}
            name="location"
            value={values.location}
            onChange={handleChange}
            onBlur={handleBlur}
            customStyles={{
              input: styles.input,
              label: styles.inputLabel,
              inputFocus: styles.inputFocus,
            }}
          />
        </Box>
      )}
    </Box>
  );
};
