import { Components } from "@mui/material";

export const MuiDialogActions: Components["MuiDialogActions"] = {
  styleOverrides: {
    root: {
      justifyContent: "center",
      paddingBottom: 50,
    },
  },
};
