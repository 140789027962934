import { Box, Skeleton } from "@mui/material";
import React from "react";

export const AccountBalanceSkeleton: React.FC = () => {
  return (
    <Box
      display="flex"
      columnGap={6}
      rowGap={8}
      pt={10}
      justifyContent="flex-end"
      flexDirection="column"
    >
      <Box display="flex" flexDirection="column" rowGap={3}>
        <Box pb={10}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="25%"
            height={40}
          />
        </Box>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={25}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" columnGap={3} width="100%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="30%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="30%"
            height={40}
          />
        </Box>
        <Box display="flex" columnGap={3}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={140}
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={140}
            height={40}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" pt={10}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="25%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={140}
          height={40}
        />
      </Box>
      <Box display="flex" flexDirection="column" width="100%" rowGap={3}>
        <Box display="flex" columnGap={3} width="100%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="3%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
        </Box>
        <Box display="flex" columnGap={3} width="100%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="3%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
        </Box>
        <Box display="flex" columnGap={3} width="100%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="3%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" pt={10}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="25%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={140}
          height={40}
        />
      </Box>
      <Box display="flex" flexDirection="column" width="100%" rowGap={3}>
        <Box display="flex" columnGap={3} width="100%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="3%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
        </Box>
        <Box display="flex" columnGap={3} width="100%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="3%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
        </Box>
        <Box display="flex" columnGap={3} width="100%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="3%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="19%"
            height={40}
          />
        </Box>
      </Box>
    </Box>
  );
};
