import { Box, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useMemo, useState } from "react";
import { SubscribeDialog } from "../../../components/modals/subscribe-dialog";
import { useSubscribe } from "../../../hooks/subscribe";
import {
  DESKTOP_NAVBAR_HEIGHT,
  MOBILE_NAVBAR_HEIGHT,
  SMALL_MOBILE_NAVBAR_HEIGHT,
} from "../../constants";
import { Topbar } from "../../Topbar";
import { SearchMainbar } from "./search-form";

export const useDialogStyles = makeStyles({
  dialog: {
    marginTop: DESKTOP_NAVBAR_HEIGHT,
    ["@media screen and (max-width: 1000px), screen and (max-height: 1000px)"]:
      {
        marginTop: MOBILE_NAVBAR_HEIGHT,
      },
    ["@media screen and (max-height: 400px)"]: {
      marginTop: SMALL_MOBILE_NAVBAR_HEIGHT,
    },
  },
});

export const JobRequestsPageSubscribe: React.FC = () => {
  const mobileWidth = useMediaQuery("(max-width: 1024px)");
  const hideMainbar = useMediaQuery("(max-height: 950px)");
  const shrinkLogo = useMediaQuery("(max-height: 750px)", { noSsr: true });
  const mobileHeight = useMediaQuery("(max-height: 650px)");
  const smallMobileHeight = useMediaQuery("(max-height: 400px)", {
    noSsr: true,
  });
  const mobileView = mobileWidth || mobileHeight;
  const styles = useDialogStyles();

  const [isSubscribed, setIsSubscribed] = useState(false);

  const { mutate: subscribe, isLoading } = useSubscribe({
    onSuccess: () => {
      setIsSubscribed(true);
    },
  });

  const onCloseModalHandler = useCallback(() => {
    // eslint-disable-next-line no-undef
    location.replace("/");
  }, []);

  const height = useMemo(() => {
    if (smallMobileHeight) return SMALL_MOBILE_NAVBAR_HEIGHT;

    if (shrinkLogo) return MOBILE_NAVBAR_HEIGHT;

    return DESKTOP_NAVBAR_HEIGHT;
  }, [shrinkLogo, smallMobileHeight]);

  return (
    <Box height="100%">
      <Topbar inverse={!mobileView} height={height} />
      <Box pt={20}>{hideMainbar || mobileView ? null : <SearchMainbar />}</Box>
      <SubscribeDialog
        classNames={styles.dialog}
        subscribed={isSubscribed}
        open
        isLoading={isLoading}
        onClose={onCloseModalHandler}
        title="Be part of The Talented community! Join us as an early adopter."
        subtitle="We’re launching very soon. Stay tuned for the product launch email
            invite. Looking forward to having you on board."
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        onConfirm={(values) => {
          subscribe(values);
        }}
      />
    </Box>
  );
};
