import { Components } from "@mui/material";
import { YELLOW } from "./utils";

export const MuiTableContainer: Components["MuiTableContainer"] = {
  styleOverrides: {
    root: {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "none",
        border: `2px solid ${YELLOW}`,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "none",
        border: `2px solid ${YELLOW}`,
      },
      "&::-webkit-scrollbar-track-piece": {
        backgroundColor: "none",
      },
      "&::-webkit-scrollbar": {
        width: 5,
        height: 5,
      },
    },
  },
};
