import { Components, iconButtonClasses } from "@mui/material";

export const MuiIconButton: Components["MuiIconButton"] = {
  styleOverrides: {
    root: {
      padding: 6,
      [`&.${iconButtonClasses["disabled"]}`]: {
        color: "inherit",
        opacity: 0.6,
        pointerEvents: "none",
      },
    },
  },
};
//
// export const MuiIconButton: MuiComponentThemeDefinition<
//   IconButtonClassKey,
//   IconButtonProps
// > = {
//   props: {},
//   override: () => ({
//     root: { padding: 6 },
//     disabled: {
//       color: "inherit !important",
//       opacity: 0.3,
//       pointerEvents: "none",
//     },
//   }),
// };
