import { Box, Button } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";

type NavigationActionsProps = {
  label: string;
  index: number;
  totalCount: number;
  hidden?: boolean;
  children?: React.ReactNode;
  handleSelectNext: any;
  handleSelectPrevious: any;
};

export const CollectionNavigationActions: React.FC<NavigationActionsProps> = ({
  label,
  index,
  totalCount,
  hidden,
  handleSelectNext,
  handleSelectPrevious,
  children,
}) => {
  const [searchParams] = useSearchParams();

  const hasNavigationActions = totalCount > 1;

  if (searchParams.get("isEditing") === "1") return null;

  if (hidden) return <Box py={10} />;

  return (
    <Box
      display="flex"
      justifyContent={children ? "space-between" : "flex-end"}
      alignItems="flex-end"
      justifySelf="flex-end"
      py={10}
    >
      {children}
      {hasNavigationActions && (
        <Box display="flex" columnGap={2.5}>
          <Button
            size="medium"
            disabled={index === 0}
            variant="contained"
            onClick={handleSelectPrevious}
          >
            Previous {label}
          </Button>
          <Button
            size="medium"
            disabled={index + 1 === totalCount}
            variant="contained"
            onClick={handleSelectNext}
          >
            Next {label}
          </Button>
        </Box>
      )}
    </Box>
  );
};
