import { Drawer, Box, ListItemButton } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { AccountType } from "../../../api-types/common";
import { BROWSE_DEFAULT_URL } from "../../../routes/utils";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { useMetadataQuery } from "../hooks/metadata/useMetadataQuery";
import { TheTalentedLogo } from "../logo";
import { NestedLink } from "./NestedLinkDrawer";

const drawerProfessionalLinks = [
  { label: "Search Jobs & Projects", url: "/job-requests/search" },
  { label: "Browse Professionals", url: BROWSE_DEFAULT_URL },
  { label: "Applications", url: "/job-applications/" },
  { label: "Current Projects", url: "/projects/" },
  { label: "Bids", url: "/bids/" },
];

const drawerClientLinks = [
  { label: "Jobs Posted", url: "/job-posts" },
  { label: "Freelancing Requests", url: "/job-requests" },
  { label: "Current Projects", url: "/projects" },
  { label: "New Job or Project", url: "/job-request/new" },
  { label: "Browse Professionals", url: BROWSE_DEFAULT_URL },
];

type MobileDrawerProps = {
  labelCount?: number;
  isOpen: boolean;
  onClose: () => void;
  accountType?: AccountType | null;
};

export const MobileDrawer: React.FC<MobileDrawerProps> = ({
  isOpen,
  onClose,
  accountType,
}) => {
  const drawerLinks = useMemo(() => {
    return accountType === AccountType.PROFESSIONAL
      ? drawerProfessionalLinks
      : drawerClientLinks;
  }, [accountType]);

  const { data: metadata } = useMetadataQuery();

  const entityCount = useCallback(
    (index) => {
      switch (accountType) {
        case AccountType.PROFESSIONAL:
          switch (index) {
            case 2:
              return <b>{`(${metadata?.jobApplicationsCount ?? 0})`}</b>;
            case 3:
              return <b>{`(${metadata?.projectsCount ?? 0})`}</b>;
            case 4:
              return <b>{`(${metadata?.accountBidsCount ?? 0})`}</b>;
            default:
              return null;
          }
        case AccountType.CLIENT:
          switch (index) {
            case 0:
              return <b>{`(${metadata?.jobPostsCount ?? 0})`}</b>;
            case 1:
              return <b>{`(${metadata?.jobRequestsCount ?? 0})`}</b>;
            case 2:
              return <b>{`(${metadata?.projectsCount ?? 0})`}</b>;
            default:
              return null;
          }
        default:
          return null;
      }
    },
    [accountType, metadata]
  );

  const memoizedLinkElements = useMemo(() => {
    return drawerLinks.map((link, index) => (
      <Box key={index}>
        <ListItemButton
          sx={{
            display: "flex",
            height: "3.1rem",
            width: "100%",
            alignItems: "center",
            paddingLeft: "2rem",
          }}
          href={link.url}
        >
          {link.label}&nbsp;{entityCount(index)}
        </ListItemButton>
      </Box>
    ));
  }, [drawerLinks, entityCount]);

  const nestedLinkElement =
    accountType === AccountType.PROFESSIONAL ? (
      <NestedLink
        label="In Process"
        nestedItems={[
          {
            label: `Job Request Inquiries (${
              metadata?.accountInquiriesCount ?? 0
            })`,
            to: "/inquiries/",
          },
          {
            label: `Referrals From Pros (${
              metadata?.professionalReferralsCount ?? 0
            })`,
            to: "/professional-referrals/",
          },
          {
            label: `Client Invitations (${
              metadata?.clientInvitationsCount ?? 0
            })`,
            to: "/invitations/",
          },
        ]}
      />
    ) : null;

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: YELLOW,
          color: BLUE,
          width: 300,
          overflow: "hidden",
        },
      }}
    >
      <Box
        style={{
          marginBottom: "2rem",
          height: "80px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TheTalentedLogo inverse={true} />
      </Box>
      {memoizedLinkElements}
      {nestedLinkElement}
    </Drawer>
  );
};
