import { Components } from "@mui/material";

export const MuiRadio: Components["MuiRadio"] = {
  styleOverrides: {
    root: {
      transform: "scale(.8) !important",
      "&.Mui-checked svg:last-child": {
        transform: "scale(1.3) !important",
      },
    },
  },
};
//
// export const MuiRadio: MuiComponentThemeDefinition<RadioClassKey, RadioProps> =
//   {
//     props: {},
//     override: () => ({
//       root: {
//         transform: "scale(.8) !important",
//         "&.Mui-checked svg:last-child": {
//           transform: "scale(1.3) !important",
//         },
//       },
//     }),
//   };
