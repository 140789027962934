import { Box, Typography } from "@mui/material";
import { useFixedFundingBudgetSummary } from "../../../client/projects/hooks";

export const FixedPriceMilestonesSummary = () => {
  const fixedFundingBudgetSummary = useFixedFundingBudgetSummary();

  return (
    <Box display="flex" columnGap={16}>
      <Box>
        <Typography
          component="span"
          variant="body2"
          style={{ fontWeight: 700 }}
        >
          Total Budget
        </Typography>{" "}
        <Typography component="span" variant="body2">
          ${fixedFundingBudgetSummary?.total}
        </Typography>
      </Box>
      <Box>
        <Typography
          component="span"
          variant="body2"
          style={{ fontWeight: 700 }}
        >
          Milestone(s) Paid
        </Typography>{" "}
        <Typography component="span" variant="body2">
          ${fixedFundingBudgetSummary?.paid}
        </Typography>
      </Box>
      <Box>
        <Typography
          component="span"
          variant="body2"
          style={{ fontWeight: 700 }}
        >
          In Escrow
        </Typography>{" "}
        <Typography component="span" variant="body2">
          ${fixedFundingBudgetSummary?.escrow}
        </Typography>
      </Box>
      <Box>
        <Typography
          component="span"
          variant="body2"
          style={{ fontWeight: 700 }}
        >
          Remaining
        </Typography>{" "}
        <Typography component="span" variant="body2">
          ${fixedFundingBudgetSummary?.remaining}
        </Typography>
      </Box>
    </Box>
  );
};
