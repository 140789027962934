import { Box, Skeleton } from "@mui/material";
import React from "react";

export const AccountInformationSkeleton: React.FC = () => {
  return (
    <Box
      display="flex"
      columnGap={6}
      rowGap={9}
      justifyContent="flex-end"
      flexDirection="column"
    >
      <Box display="flex" justifyContent="space-between">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="60%"
          height={40}
        />
        <Box display="flex" columnGap={3}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={140}
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={140}
            height={40}
          />
        </Box>
      </Box>
      <Box
        pt={10}
        display="flex"
        alignSelf="flex-end"
        justifySelf="flex-end"
        columnGap={6}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={400}
          height={68}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={400}
          height={68}
        />
      </Box>
      <Box
        display="flex"
        alignSelf="flex-end"
        justifySelf="flex-end"
        columnGap={6}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={400}
          height={68}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={400}
          height={68}
        />
      </Box>
      <Box
        display="flex"
        alignSelf="flex-end"
        justifySelf="flex-end"
        columnGap={6}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={400}
          height={68}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={400}
          height={68}
        />
      </Box>
      <Box
        display="flex"
        alignSelf="flex-end"
        justifySelf="flex-end"
        columnGap={6}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={400}
          height={68}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={400}
          height={68}
        />
      </Box>
      <Box
        display="flex"
        alignSelf="flex-end"
        justifySelf="flex-end"
        columnGap={6}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={400}
          height={68}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={400}
          height={68}
        />
      </Box>
    </Box>
  );
};
