import makeStyles from "@mui/styles/makeStyles";

export const useStepDStyles = makeStyles({
  headline: {
    fontSize: 100,
    fontWeight: 500,
    ["@media screen and (max-width: 2050px)"]: {
      fontSize: "4.5vw",
    },
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: "5vw",
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      fontSize: "7vw",
    },
    ["@media screen and (max-aspect-ratio: 5/4)"]: {
      ["@media screen and (max-width: 850px)"]: {
        fontSize: "9vw",
      },
      ["@media screen and (max-width: 700px)"]: {
        fontSize: "9vw",
      },
      ["@media screen and (max-width: 550px)"]: {
        fontSize: "9vw",
      },
      ["@media screen and (max-width: 450px)"]: {
        fontSize: "9vw",
      },
      ["@media screen and (max-width: 350px)"]: {
        fontSize: "9vw",
      },
      ["@media screen and (max-width: 280px)"]: {
        fontSize: "28px",
      },
    },
    ["@media screen and (min-aspect-ratio: 5/4)"]: {
      ["@media screen and (max-height: 650px)"]: {
        fontSize: "11.7vh",
      },
      ["@media screen and (max-height: 500px)"]: {
        fontSize: "11.3vh",
      },
      ["@media screen and (max-height: 400px)"]: {
        fontSize: "10.3vh",
      },
      ["@media screen and (max-height: 280px)"]: {
        fontSize: "29px",
      },
    },
  },
});
