import { Box, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useMemo } from "react";
import { useSubscribeModal } from "../../state/hooks";
import { useScopedDowngradedStateValue } from "../hooks/utils/useScopedDowngradedStateValue";
import { useIsMobileView } from "./hooks";
import { FadingPage } from "./professional/steps/FadingPage";

export const useStyles = makeStyles({
  mobile: {
    marginTop: 74,
    height: "calc(100% - 80px)",
    ["@media screen and (max-height: 800px)"]: {
      marginTop: 58,
      height: "calc(100% - 64px)",
    },
    ["@media screen and (max-height: 400px)"]: {
      marginTop: 50,
      height: "calc(100% - 56px)",
    },
  },
});

type CurrentPageContentProps = {
  desktopPages: any[];
  mobilePages: any[];
  currentPage: number;
  previousPage: number;
  children?: React.ReactNode;
};

export const CurrentPageContent: React.FC<CurrentPageContentProps> = ({
  desktopPages,
  mobilePages,
  currentPage,
  previousPage,
  children,
}) => {
  // const mobileWidth = useMediaQuery("(max-width: 850px)", { noSsr: true });
  // const mobileHeight = useMediaQuery("(max-height: 750px)", { noSsr: true });
  // const mobileView = mobileWidth || mobileHeight;
  const isMobileView = useIsMobileView();
  const subscribeModalState = useScopedDowngradedStateValue(
    useSubscribeModal()
  );
  const landscape = useMediaQuery("(orientation: landscape)");
  const tabletSize = useMediaQuery("(max-width: 1024px)");
  const styles = useStyles();

  const pagesContent = useMemo(
    () => (isMobileView ? mobilePages : desktopPages),
    [desktopPages, mobilePages, isMobileView]
  );

  const showChildren = currentPage > 0;

  if (isMobileView) {
    return (
      <FadingPage previousPage={previousPage} currentPage={currentPage}>
        <>
          <Box
            className={styles.mobile}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="column"
            height={landscape ? "90%" : "100%"}
            width={showChildren ? "95%" : "100%"}
            maxWidth={1440}
            margin="0 auto"
          >
            {pagesContent[currentPage]}
          </Box>
          {showChildren && children}
        </>
      </FadingPage>
    );
  }

  return (
    <FadingPage previousPage={previousPage} currentPage={currentPage}>
      <>
        <Box
          className={styles.mobile}
          style={{
            visibility: subscribeModalState.open ? "hidden" : "visible",
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          rowGap={"2.5%"}
          flexDirection="column"
          width={tabletSize ? "85%" : "70%"}
          px={0}
          maxWidth={1440}
          margin="0 auto"
        >
          {pagesContent[currentPage]}
        </Box>
        {showChildren && children}
      </>
    </FadingPage>
  );
};
