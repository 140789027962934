import { Box, Button, Divider, Typography } from "@mui/material";
import axios from "axios";
import React, { useMemo } from "react";
import { BASE_URL } from "../../../../../api-services/utils";
import { useRedButtonStyles } from "../../../../../themes/style-hooks";
import { ConfirmationDialog } from "../../../components";
import { useCancelSubscriptionMutation } from "../../../hooks/account-information/useCancelSubscriptionMutation";
import { useMetadataQuery } from "../../../hooks/metadata/useMetadataQuery";
import { useScopedDowngradedStateValue } from "../../../hooks/utils/useScopedDowngradedStateValue";
import {
  useConfirmCancelSubscription,
  useSubscriptionModal,
} from "../../state/hooks";

type SubscriptionInformationProps = {
  onSuccess: () => void;
  label?: string;
};

export const SubscriptionInformationStep: React.FC<
  SubscriptionInformationProps
> = () => {
  const styles = useRedButtonStyles();

  const { data: metadata } = useMetadataQuery();

  const { mutate: cancelSubscription, isLoading: isCancelling } =
    useCancelSubscriptionMutation();

  const confirmCancelSubscription = useScopedDowngradedStateValue(
    useConfirmCancelSubscription()
  );

  const setConfirmCancelSubscription = useConfirmCancelSubscription().set;

  const setSubscriptionModal = useSubscriptionModal().set;
  const subscriptionModal = useScopedDowngradedStateValue(
    useSubscriptionModal()
  );

  const handleConfirm = () => {
    cancelSubscription(undefined);
    setConfirmCancelSubscription({ open: false });
  };

  const handleClose = () => {
    setConfirmCancelSubscription({ open: false });
  };

  const handleCloseSubscriptionModal = () => {
    setSubscriptionModal({ open: false });
  };

  const hasPendingOffers = metadata?.pendingProjectOffersCount;

  const handleSubscribe = async () => {
    if (hasPendingOffers) {
      const response = await axios.post(`${BASE_URL}/stripe/subscribe_pro`);

      if (response?.data?.url) {
        // eslint-disable-next-line no-undef
        window.location = response.data.url;
      }
    } else {
      setSubscriptionModal({ open: true });
    }
  };

  const { modalTitle, modalText } = useMemo(() => {
    if ((metadata?.projectsCount ?? 0) > 0) {
      return {
        modalTitle: "Current Projects Are Still Active",
        modalText: `Current projects are still on-going. Complete or cancel all current projects before canceling your subscription. \nIf you have any money deposited in your Stripe account, you’ll also have to withdraw your money to your bank account before canceling your subscription.`,
      };
    }

    return {
      modalTitle: "Are you sure you want to cancel your subscription?",
      modalText: `If you cancel your subscription, we will keep all your projects and profile information just as they are. However, you will no longer appear in searches or be notified of jobs that match your skills.
              \nOnce you restart your subscription, all these features will be reinstated. Your subscription will be activated once your bid is accepted and you start working on a project.`,
    };
  }, [metadata?.projectsCount]);

  return (
    <>
      <Box display="flex" pt={0} height="100%" flexDirection="column">
        {metadata?.theTalentedPro ? (
          <Typography
            variant="subtitle1"
            color="primary"
            style={{
              fontWeight: 600,
            }}
          >
            You are currently subscribed. Your subscription is set to renew on a
            monthly basis for $25/month.
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 600,
            }}
          >
            Your Subscription Starts Only When You Start Working On A Project
          </Typography>
        )}
        {!metadata?.theTalentedPro && (
          <Box display="flex" columnGap={6} pt={5}>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{
                fontWeight: 600,
              }}
            >
              Subscribe For $25/month
            </Typography>
            <Button onClick={handleSubscribe} variant="contained" size="small">
              Subscribe
            </Button>
          </Box>
        )}
        <Box pt={5}>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 600,
            }}
          >
            Subscription Information
          </Typography>
          <Box pt={2}>
            <Divider />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" rowGap={5} pt={2.5}>
          <Typography variant="body1">
            All bids are free for professionals, even before signing up. Bid on
            any project! As a pro, you don’t need to build or purchase “credits”
            to become eligible to bid on projects.
          </Typography>
          <Typography variant="body1">
            Keep all the money you earn. There is no commission.
          </Typography>
          <Box>
            <Typography variant="body1">
              There is no payment tier model. You have access to all features
              from the moment you sign up.
            </Typography>
            <Typography variant="body1">
              There is no free work. Professionals only work on projects they
              win.
            </Typography>
          </Box>
          {!metadata?.theTalentedPro ? (
            <Box>
              <Typography
                variant="body1"
                style={{
                  fontWeight: 600,
                }}
              >
                Sign up for $25/month. The subscription starts only when you
                start working on a project.
              </Typography>{" "}
              <Typography
                variant="body1"
                style={{
                  fontWeight: 600,
                }}
              >
                It is renewed on a monthly basis.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="body1"
                color="primary"
                style={{
                  fontWeight: 600,
                }}
              >
                The subscription starts only when you start working on a
                project, and is renewed on a monthly basis.
              </Typography>
            </Box>
          )}
        </Box>
        <Box pt={2.5}>
          <Divider />
        </Box>
        <Box pt={5}>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 600,
            }}
          >
            Cancelling Subscription
          </Typography>
          <Box pt={2.5}>
            <Divider />
          </Box>
          <Box pt={5} pb={10}>
            <Typography variant="body1">
              You may cancel your subscription at any time. We will save your
              profile information, including all your portfolio projects as well
              as projects you completed on the platform. However, you will have
              to start your subscription again in order to be able to bid on any
              new job requests.{" "}
            </Typography>
          </Box>
          {metadata?.theTalentedPro && (
            <Box pb={10}>
              <Button
                onClick={() => {
                  setConfirmCancelSubscription({ open: true });
                }}
                variant="outlined"
                size="medium"
                disabled={isCancelling}
                className={`${styles.delete}`}
              >
                Cancel Subscription
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <ConfirmationDialog
        open={Boolean(confirmCancelSubscription.open)}
        onClose={handleClose}
        onConfirm={
          (metadata?.projectsCount ?? 0) > 0 ? undefined : handleConfirm
        }
        confirmLabel={"Cancel My Subscription"}
        title={modalTitle}
        message={modalText}
      />{" "}
      <ConfirmationDialog
        open={subscriptionModal.open}
        onClose={handleCloseSubscriptionModal}
        cancelLabel="Close"
        title="Your Subscription Starts Only When You Start Working On A Project"
        message="Start your subscription only once a client accepts a bid you place and you’re ready to start working on a project."
      />
    </>
  );
};
