import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { TimeFrame } from "../../../../../api-types/job-requests/job-request";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";

type PortfolioProjectAvailabilityProps = { timeFrame?: TimeFrame };

export const PortfolioProjectAvailability: React.FC<
  PortfolioProjectAvailabilityProps
> = ({ timeFrame }) => {
  const availabilityMessage = useMemo(() => {
    if (
      timeFrame?.projectTimeFrame.weeks &&
      timeFrame?.timeDedicated.hoursPerWeek
    ) {
      return `${timeFrame.timeDedicated.hoursPerWeek} hours/week for ${timeFrame.projectTimeFrame.weeks} weeks`;
    }

    if (timeFrame?.projectTimeFrame.weeks) {
      return `${timeFrame.projectTimeFrame.weeks} weeks`;
    }

    if (timeFrame?.timeDedicated.hoursPerWeek) {
      return `${timeFrame.timeDedicated.hoursPerWeek} hours/week`;
    }

    return "N/A";
  }, [
    timeFrame?.projectTimeFrame.weeks,
    timeFrame?.timeDedicated.hoursPerWeek,
  ]);

  const feeMsg = useMemo(() => {
    if (timeFrame?.fee.perHour) {
      return `$${timeFrame.fee.perHour}/hr`;
    }

    if (timeFrame?.fee.fixedPrice) {
      return `$${timeFrame.fee.fixedPrice} (fixed price)`;
    }

    return "N/A";
  }, [timeFrame?.fee.fixedPrice, timeFrame?.fee.perHour]);

  const totalCostMsg = useMemo(() => {
    if (timeFrame?.fee.fixedPrice) {
      return `$${timeFrame.fee.fixedPrice}`;
    }

    if (timeFrame?.fee.perHour) {
      if (timeFrame?.projectTimeFrame.weeks) {
        if (timeFrame?.timeDedicated.hoursPerWeek) {
          return `$${
            timeFrame.timeDedicated.hoursPerWeek *
            timeFrame.projectTimeFrame.weeks *
            timeFrame.fee.perHour
          }`;
        }
        return "N/A";
      }
      return "N/A";
    }
    return "N/A";
  }, [
    timeFrame?.fee.fixedPrice,
    timeFrame?.fee.perHour,
    timeFrame?.projectTimeFrame.weeks,
    timeFrame?.timeDedicated.hoursPerWeek,
  ]);

  const { isMedium } = useResponsiveness();

  return (
    <Box display="flex" justifyContent="space-between" columnGap={3}>
      <Box>
        <Typography component="div" variant="body2">
          <Typography
            variant="body2"
            style={{
              display: "inline",
              fontWeight: 600,
            }}
          >
            Timeframe:
          </Typography>{" "}
          {format(new Date(), formatNominativeMonth)} -{" "}
          {format(new Date(), formatNominativeMonth)}
        </Typography>
        <Typography component="div" variant="body2">
          <Typography
            variant="body2"
            style={{
              display: "inline",
              fontWeight: 600,
            }}
          >
            Availability:{" "}
          </Typography>
          {availabilityMessage}
        </Typography>
      </Box>
      <Box>
        {!isMedium && (
          <>
            <Typography component="div" variant="body2">
              <Typography
                variant="body2"
                style={{
                  display: "inline",
                  fontWeight: 600,
                }}
              >
                Fee:
              </Typography>{" "}
              {feeMsg}
            </Typography>
            <Typography component="div" variant="body2">
              <Typography
                variant="body2"
                style={{
                  display: "inline",
                  fontWeight: 600,
                }}
              >
                Total cost:{" "}
              </Typography>
              {totalCostMsg}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
