import { Box } from "@mui/material";
import {
  HourlyRateFundingDetails,
  JobRequestHourlyRateMilestoneTable,
} from "../../utils";
import { useUpdateBudgetTimeFrame } from "../hooks";

export const HourlyRateFundingContent = () => {
  useUpdateBudgetTimeFrame();

  return (
    <Box display="flex" flexDirection="column" columnGap={5}>
      <HourlyRateFundingDetails />

      <JobRequestHourlyRateMilestoneTable />
    </Box>
  );
};
