import { Box, Button, Chip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useMemo } from "react";
import { JobPost } from "../../../../../api-types/job-posts";
import { YELLOW } from "../../../../../themes/components/utils";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";

type JobPostItemChipsProps = {
  jobPost: JobPost;
};

const jobStyle = {
  "& .MuiChip-label": {
    padding: 10,
  },
  borderRadius: 0,
  fontWeight: 500,
  fontSize: 16,
  padding: 16,
  height: "50px",
};

export const useJobPreviewStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    whiteSpace: "nowrap",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  jobLevel: {
    ...jobStyle,
    backgroundColor: "#FFFFFF",
  },
  fullTime: {
    ...jobStyle,
    backgroundColor: "#FFA842",
  },
  contract: {
    ...jobStyle,
    backgroundColor: "#00EBE9",
  },
  temp: {
    ...jobStyle,
    backgroundColor: "#DAE838",
  },
  partTime: {
    ...jobStyle,
    backgroundColor: "#A7F5C8",
  },
  onSite: {
    ...jobStyle,
    backgroundColor: "#CAE3F4",
  },
  remote: {
    ...jobStyle,
    backgroundColor: "#2BFD8C",
  },
  sponsorship: {
    ...jobStyle,
    backgroundColor: "#FFF2B1",
  },
  salary: {
    "& .MuiChip-label": {
      padding: 10,
    },
    padding: 18,
    borderRadius: 0,
    border: `1px solid ${YELLOW}`,
    backgroundColor: "transparent",
    color: YELLOW,
    fontWeight: 500,
    fontSize: 14,
    height: "60px",
    width: "auto",
  },
  salaryLabel: {
    whiteSpace: "normal",
    height: 50,
    pointerEvents: "none",
    cursor: "none",
    borderColor: "white",
    color: "white",
    maxWidth: "100%",
    marginLeft: 12,
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
      border: "none",
    },
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      flexDirection: "row",
      alignItems: "flex-start",
      flexWrap: "wrap",
      gap: theme.spacing(1),
    },
    experienceLabel: {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

export const JobPostItemChips: React.FC<JobPostItemChipsProps> = ({
  jobPost,
}) => {
  const styles = useJobPreviewStyles();

  const { isSmall } = useResponsiveness();

  const {
    jobType,
    jobLevel,
    jobLocation,
    daysRequiredInOffice,
    other,
    location,
    yearsOfExperience,
    statusRequirement,
  } = jobPost;

  const jobTypeClassName = (jobType: string) => {
    if (jobType === "full-time") {
      return styles.fullTime;
    }

    if (jobType === "contract, 40hrs/wk") {
      return styles.contract;
    }

    if (jobType === "temp-to-perm") {
      return styles.temp;
    }

    return styles.partTime;
  };

  const jobLocationClassName = (jobLocation: string) => {
    if (jobLocation === "remote") {
      return styles.remote;
    }

    return styles.onSite;
  };

  const capitalizeFirstLetter = (string: string) => {
    if ((string?.length ?? 0) === 0) return "";

    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  const jobLevelLabel = capitalizeFirstLetter(jobLevel);
  const jobTypeLabel = capitalizeFirstLetter(jobType);
  const jobLocationLabel = capitalizeFirstLetter(jobLocation ?? location);

  const statusRequirementLabel = useMemo(() => {
    if (statusRequirement === "other") {
      return capitalizeFirstLetter(other) || "Other";
    }

    if (statusRequirement === "usCitizen") {
      return "USC or GC";
    }

    if (statusRequirement === "noSponsorship") {
      return "No Sponsorship";
    }

    if (statusRequirement === "sponsorship") {
      return "Visa Sponsorship";
    }

    return "";
  }, [other, statusRequirement]);
  const experienceInYearsLabel = useMemo(() => {
    if (jobLevel === "intern") return "";

    if (!yearsOfExperience) return "";

    if (yearsOfExperience.type === "estimate") {
      if (
        yearsOfExperience.estimate?.min === 0 &&
        yearsOfExperience.estimate?.max === 0
      ) {
        return "";
      }
      return `${yearsOfExperience.estimate?.min} - ${yearsOfExperience.estimate?.max} yrs of exp`;
    } else {
      if (yearsOfExperience.fixed === 0) {
        return "";
      }

      return `${yearsOfExperience.fixed}+ yrs of exp`;
    }
  }, [jobLevel, yearsOfExperience]);

  const daysInOfficeLabel = useMemo(() => {
    let timeframe = "wk";

    if (!daysRequiredInOffice) return "";

    if (daysRequiredInOffice.timeframe === "month") {
      timeframe = "mo";
    }

    if (daysRequiredInOffice.timeframe === "quarter") {
      timeframe = "qtr";
    }

    if (daysRequiredInOffice.type === "estimate") {
      if (
        daysRequiredInOffice.estimate?.min === 0 &&
        daysRequiredInOffice.estimate?.max === 0
      ) {
        return "";
      }
      return `${daysRequiredInOffice.estimate?.min} - ${daysRequiredInOffice.estimate?.max} d/${timeframe}`;
    } else {
      if (daysRequiredInOffice.fixed === 0) {
        return "";
      }

      return `${daysRequiredInOffice.fixed} d/${timeframe}`;
    }
  }, [daysRequiredInOffice]);

  const salaryPerYearLabel = useMemo(() => {
    let currency = "$";

    if (!jobPost?.salaryPerYear?.currency) return "";

    if (jobPost.salaryPerYear.currency === "EUR") {
      currency = "€";
    }

    if (jobPost.salaryPerYear.currency === "RON") {
      currency = "RON";
    }

    if (jobPost.salaryPerYear.type === "estimate") {
      if (
        jobPost.salaryPerYear.estimate.min === 0 &&
        jobPost.salaryPerYear.estimate.max === 0
      ) {
        return "";
      }
      return `${currency}${jobPost.salaryPerYear.estimate.min}/yr - ${currency}${jobPost.salaryPerYear.estimate.max}/yr`;
    } else {
      if (!jobPost.salaryPerYear.fixed) {
        return "";
      }

      return `${currency}${jobPost.salaryPerYear.fixed}/yr`;
    }
  }, [
    jobPost?.salaryPerYear?.currency,
    jobPost.salaryPerYear?.estimate?.max,
    jobPost.salaryPerYear?.estimate?.min,
    jobPost.salaryPerYear?.fixed,
    jobPost.salaryPerYear?.type,
  ]);

  const ratePerHourLabel = useMemo(() => {
    if (jobPost.ratePerHour?.type === "estimate") {
      if (
        jobPost.ratePerHour?.estimate.min === 0 &&
        jobPost.ratePerHour?.estimate.max === 0
      ) {
        return "";
      }
      return `$${jobPost.ratePerHour?.estimate.min}/hr - $${jobPost.ratePerHour?.estimate.max}/hr`;
    } else {
      if (!jobPost.ratePerHour?.fixed) {
        return "";
      }

      return `$${jobPost.ratePerHour?.fixed}/hr`;
    }
  }, [
    jobPost.ratePerHour?.estimate?.max,
    jobPost.ratePerHour?.estimate?.min,
    jobPost.ratePerHour?.fixed,
    jobPost.ratePerHour?.type,
  ]);

  const jobPreviewClasses = useJobPreviewStyles();

  return (
    <Box display="flex" justifyContent="space-between">
      <Box
        display="flex"
        alignItems={isSmall ? "flex-start" : "center"}
        flexDirection={isSmall ? "column" : "row"}
        columnGap={4}
        flexWrap="wrap"
        gap={2}
      >
        {jobLevelLabel && (
          <Box display="flex" alignItems="center" columnGap={4.5}>
            <Chip label={jobLevelLabel} className={styles.jobLevel} />
            {Boolean(experienceInYearsLabel?.length) && (
              <Typography className={styles.experienceLabel}>
                {experienceInYearsLabel}
              </Typography>
            )}
          </Box>
        )}
        {jobTypeLabel && (
          <Chip label={jobTypeLabel} className={jobTypeClassName(jobType)} />
        )}
        {jobType === "temp-to-perm" && (
          <Typography>{jobPost.monthsBeforeTransfer} mos to perm</Typography>
        )}
        {jobType === "part-time" && (
          <Typography>{jobPost.hoursPerWeek} hrs/week</Typography>
        )}
        <Chip
          label={jobLocationLabel.length ? jobLocationLabel : "Remote"}
          className={jobLocationClassName(jobLocation)}
        />
        {jobLocation === "on-site" && <Typography>{location}</Typography>}
        {jobLocation === "hybrid" && (
          <Typography>{`${daysInOfficeLabel}, ${location}`}</Typography>
        )}
        {statusRequirementLabel && (
          <Chip label={statusRequirementLabel} className={styles.sponsorship} />
        )}
      </Box>
      {/* eslint-disable-next-line no-nested-ternary */}
      {jobType === "full-time" && salaryPerYearLabel?.length ? (
        <Box>
          <Button
            size="large"
            variant="outlined"
            disableTouchRipple
            className={jobPreviewClasses.salaryLabel}
          >
            {salaryPerYearLabel}
          </Button>
        </Box>
      ) : ratePerHourLabel?.length ? (
        <Box py={2.5}>
          <Button
            size="large"
            variant="outlined"
            disableTouchRipple
            style={{
              pointerEvents: "none",
              cursor: "none",
              borderColor: "white",
              color: "white",
            }}
          >
            {ratePerHourLabel}
          </Button>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
