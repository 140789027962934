import { Box, Link, Typography } from "@mui/material";
import React from "react";

type SavedJobRequestHighlightsProps = {};

export const SavedJobRequestHighlights: React.FC<
  SavedJobRequestHighlightsProps
> = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      pt={10}
      rowGap={3}
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
      width="100%"
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
        }}
        noWrap
      >
        Saved Job Requests
      </Typography>
      <Typography noWrap>You have saved {0} Job Request(s)</Typography>
      <Typography noWrap>
        <Link href="/job-requests/saved">Go to Saved Job Request(s) Page</Link>
      </Typography>
    </Box>
  );
};
