import { Box, Typography } from "@mui/material";
import { Fragment, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../app/container/PageWrapper";
import { Section } from "../../common/components";
import { CollectionNavigationActions } from "../../common/elements/CollectionNavigationActions";
import {
  Collection,
  useNavigationHandlers,
} from "../../common/hooks/navigation";
import { useAccountInquiriesOptions } from "../bids/hooks/useAccountInquiriesOptions";
import { Communication } from "./communication";
import { Details } from "./details";
import { useDefaultInquiryPage } from "./hooks";
import { useAccountInquiryQuery } from "./hooks/useAccountInquiry";
import { InquiryHeader } from "./inquiry-header";

export const InquiryDetails = () => {
  useDefaultInquiryPage();

  const [searchParams] = useSearchParams();
  const { inquiryId } = useParams();

  const { data: accountInquiry, isLoading: isLoadingAccountInquiry } =
    useAccountInquiryQuery();

  const { data: accountInquiries, isLoading: isLoadingAccountInquiries } =
    useAccountInquiriesOptions();

  const collectionConfig = {
    type: Collection.INQUIRIES,
    items: accountInquiries,
  };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(
      collectionConfig,
      isLoadingAccountInquiries,
      inquiryId
    );

  const isLoading = isLoadingAccountInquiry || isLoadingAccountInquiries;

  const errorMessage = useMemo(() => {
    return !isLoading && !accountInquiry ? (
      <Fragment>
        <Typography variant="body1">
          An inquiry is a question or a comment on a job request, made either by
          you or by the client. All inquiries will be shown here.
        </Typography>
        <Typography variant="body1">
          If you decide to place a bid on a job request you first only inquired
          about, that job request will be moved to the bids section.
        </Typography>
      </Fragment>
    ) : (
      ""
    );
  }, [accountInquiry, isLoading]);

  const content = useMemo(
    () =>
      searchParams.get("tab") === "replies" ? <Communication /> : <Details />,
    [searchParams]
  );

  const isNavigationHidden =
    searchParams.get("tab") !== "details" || isLoadingAccountInquiries;

  if (!inquiryId && !isLoading) {
    return (
      <PageWrapper>
        <Section errorMessage={errorMessage} />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Box display="flex" height="inherit" flexDirection="column">
        <InquiryHeader />
        <Box flexGrow={1}>{content}</Box>
        <CollectionNavigationActions
          label={"Inquiry"}
          index={index}
          totalCount={accountInquiries?.length ?? 0}
          handleSelectNext={handleSelectNext}
          handleSelectPrevious={handleSelectPrevious}
          hidden={isNavigationHidden}
        />
      </Box>
    </PageWrapper>
  );
};
