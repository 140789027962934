import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FieldArray, Formik, FormikValues } from "formik";
import { first, isEqual, keys, last, pick, uniqueId } from "lodash";
import { ChangeEvent, FC, Fragment, useCallback, useState } from "react";
import { object } from "yup";
import { BLUE } from "../../../../themes/components/utils";
import { useRedButtonStyles } from "../../../../themes/style-hooks";
import {
  Milestone,
  MilestonesSchemaDefinition,
  MilestoneStatus,
} from "../../../client/job-requests/create-job-request-wizard/validation-schema";
import { useFixedFundingBudgetSummary } from "../../../client/projects/hooks";
import { FIXED_PRICE_DIFF_PROPS } from "../../../common/components/milestones/constants";
import { FixedPriceDiffTableRow } from "../../../common/components/milestones/FixedPriceDiffTableRow";
import { FixedPriceEditableTableRow } from "../../../common/components/milestones/FixedPriceEditableTableRow";
import { FixedPriceTableRow } from "../../../common/components/milestones/FixedPriceTableRow";
import { MilestoneChangesActions } from "../../../common/components/milestones/MilestoneChangesActions";
import { getPendingChanges } from "../../../common/components/milestones/utils";
import { NumberChip } from "../../../common/components/NumberChip";
import { useRequiredFieldLabel } from "../../../common/hooks/utils";
import { TABLE_HEIGHT } from "./constants";

type FixedPriceMilestonesTableProps = {
  alertsMap?: { alertIndex: number; milestoneId: string }[];
  canAcceptPendingChanges?: boolean;
  canEditMilestones?: boolean;
  closingRequest?: boolean;
  existingMilestonesIds: string[];
  isEditingMilestones: boolean;
  isLoading?: boolean;
  isSubmittingWork?: boolean;
  milestones: Milestone[];
  onAcceptChanges?: (milestoneId?: string, isNew?: boolean) => void;
  onDenyChanges?: (milestoneId?: string, isNew?: boolean) => void;
  onFinishEdit: (values: FormikValues) => void;
  onStartEdit: () => void;
  onSubmitMilestone?: (milestoneId?: string) => void;
  pendingChanges?: Milestone[];
  setIsClosing: () => void;
  setIsEditingMilestones: (isEditing: boolean) => void;
};

export const FixedPriceMilestonesTable: FC<FixedPriceMilestonesTableProps> = ({
  alertsMap,
  canAcceptPendingChanges,
  canEditMilestones,
  closingRequest,
  existingMilestonesIds,
  isEditingMilestones,
  isLoading,
  isSubmittingWork,
  milestones,
  onAcceptChanges,
  onDenyChanges,
  onFinishEdit,
  onStartEdit,
  onSubmitMilestone,
  pendingChanges,
  setIsEditingMilestones,
  setIsClosing,
}) => {
  const fixedFundingBudgetSummary = useFixedFundingBudgetSummary();
  const requiredFieldLabel = useRequiredFieldLabel();

  const [selectedMilestones, setSelectedMilestones] = useState<Milestone[]>([]);

  const handleSelectMilestone = useCallback(
    // eslint-disable-next-line no-undef
    (event: ChangeEvent<HTMLInputElement>, milestone: any) => {
      if (event.target.checked) {
        setSelectedMilestones([...selectedMilestones, milestone]);
      }

      if (!event.target.checked) {
        const updatedMilestones = [...selectedMilestones];

        const index = updatedMilestones.findIndex(
          (m) => m._id === milestone._id
        );

        updatedMilestones.splice(index, 1);
        setSelectedMilestones(updatedMilestones);
      }
    },
    [selectedMilestones]
  );

  const isMilestoneSelected = useCallback(
    (milestone: Milestone) => {
      return Boolean(
        selectedMilestones.find(({ _id }) => _id === milestone._id)
      );
    },
    [selectedMilestones]
  );

  return (
    <Fragment>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pb={4}
        pt={8}
      >
        <Box display="flex" columnGap={16}>
          <Box>
            <Typography
              component="span"
              variant="body2"
              style={{ fontWeight: 700 }}
            >
              Total Budget
            </Typography>{" "}
            <Typography component="span" variant="body2">
              ${fixedFundingBudgetSummary?.total}
            </Typography>
          </Box>
          <Box>
            <Typography
              component="span"
              variant="body2"
              style={{ fontWeight: 700 }}
            >
              Milestone(s) Paid
            </Typography>{" "}
            <Typography component="span" variant="body2">
              ${fixedFundingBudgetSummary?.paid}
            </Typography>
          </Box>
          <Box>
            <Typography
              component="span"
              variant="body2"
              style={{ fontWeight: 700 }}
            >
              In Escrow
            </Typography>{" "}
            <Typography component="span" variant="body2">
              ${fixedFundingBudgetSummary?.escrow}
            </Typography>
          </Box>
          <Box>
            <Typography
              component="span"
              variant="body2"
              style={{ fontWeight: 700 }}
            >
              Remaining
            </Typography>{" "}
            <Typography component="span" variant="body2">
              ${fixedFundingBudgetSummary?.remaining}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" columnGap={2}>
          <Button
            disabled={isEditingMilestones || closingRequest}
            onClick={setIsClosing}
            size="small"
          >
            Cancel Project
          </Button>
          <Button
            disabled={isEditingMilestones || !canEditMilestones}
            onClick={onStartEdit}
            size="small"
          >
            Edit Milestones
          </Button>
        </Box>
      </Box>

      <Divider />

      <Box pt={5} minHeight={240}>
        {isLoading ? (
          <Box
            alignItems="center"
            display="flex"
            height="100%"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Formik
            initialValues={{
              milestones,
            }}
            onSubmit={(values) => {
              const newChanges = getPendingChanges(
                values.milestones,
                milestones,
                pendingChanges
              );

              onFinishEdit({ milestones: newChanges });
            }}
            enableReinitialize
            validationSchema={object({
              milestones: MilestonesSchemaDefinition,
            })}
            validateOnChange
            validateOnMount
          >
            {({ errors, handleReset, submitForm, values }) => {
              return (
                <form>
                  <FieldArray name="milestones">
                    {({ push, remove }) => (
                      <Fragment>
                        <TableContainer
                          style={{
                            maxHeight: TABLE_HEIGHT,
                            marginLeft: -16,
                            width: "calc(100% + 16px)",
                          }}
                        >
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  <Checkbox
                                    disabled
                                    style={{
                                      color: BLUE,
                                    }}
                                  />
                                </TableCell>
                                <TableCell variant="head" width="25%">
                                  {requiredFieldLabel("Milestone Description")}
                                </TableCell>
                                <TableCell variant="head" width="20%">
                                  {requiredFieldLabel("Milestone Amount")}
                                </TableCell>
                                <TableCell variant="head" width="25%">
                                  {requiredFieldLabel("Due Date")}
                                </TableCell>
                                <TableCell variant="head" width="10%">
                                  Funded
                                </TableCell>
                                <TableCell variant="head" width="10%">
                                  Status
                                </TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.milestones.map((milestone, index) => {
                                const { _id, status, isFunded } = milestone;

                                const suggestedChanges = pendingChanges?.find(
                                  (m) => _id && m._id === _id
                                );

                                const hasSuggestedChanges =
                                  suggestedChanges &&
                                  !isEqual(
                                    pick(milestone, FIXED_PRICE_DIFF_PROPS),
                                    pick(
                                      suggestedChanges,
                                      FIXED_PRICE_DIFF_PROPS
                                    )
                                  );

                                const isNewMilestone =
                                  !_id || !existingMilestonesIds.includes(_id);

                                const isDeletedMilestone =
                                  (pendingChanges?.length ?? 0) > 0 &&
                                  milestone &&
                                  !suggestedChanges;

                                const editableMilestone =
                                  !status ||
                                  (status === MilestoneStatus.PENDING &&
                                    !isFunded);

                                const alerts = alertsMap?.filter(
                                  (m) => m.milestoneId === _id
                                );

                                const mainAlert = first(alerts);
                                const diffAlert = last(alerts);

                                return (
                                  <Fragment key={index}>
                                    {isEditingMilestones &&
                                    editableMilestone ? (
                                      <FixedPriceEditableTableRow
                                        hasBorder={hasSuggestedChanges}
                                        milestone={milestone}
                                        onSelect={(event) =>
                                          handleSelectMilestone(
                                            event,
                                            milestone
                                          )
                                        }
                                        remove={remove}
                                        rowIndex={index}
                                        selected={isMilestoneSelected(
                                          milestone
                                        )}
                                      />
                                    ) : (
                                      <FixedPriceTableRow
                                        actions={
                                          <Fragment>
                                            {(isDeletedMilestone ||
                                              isNewMilestone) &&
                                              canAcceptPendingChanges && (
                                                <MilestoneChangesActions
                                                  onAcceptChanges={() =>
                                                    onAcceptChanges?.(
                                                      _id,
                                                      isNewMilestone &&
                                                        !isDeletedMilestone
                                                    )
                                                  }
                                                  onDenyChanges={() =>
                                                    onDenyChanges?.(
                                                      _id,
                                                      isNewMilestone &&
                                                        !isDeletedMilestone
                                                    )
                                                  }
                                                />
                                              )}
                                            {status ===
                                              MilestoneStatus.IN_PROGRESS && (
                                              <Fragment>
                                                <Button
                                                  disabled={
                                                    isEditingMilestones ||
                                                    isSubmittingWork
                                                  }
                                                  onClick={() =>
                                                    onSubmitMilestone?.(_id)
                                                  }
                                                  endIcon={
                                                    isSubmittingWork ? (
                                                      <CircularProgress
                                                        size={16}
                                                      />
                                                    ) : null
                                                  }
                                                  size="small"
                                                >
                                                  Submit Work
                                                </Button>
                                              </Fragment>
                                            )}
                                          </Fragment>
                                        }
                                        alertChip={
                                          mainAlert?.alertIndex && (
                                            <NumberChip
                                              color={
                                                isNewMilestone ||
                                                isDeletedMilestone
                                                  ? "secondary"
                                                  : "primary"
                                              }
                                              label={mainAlert.alertIndex}
                                            />
                                          )
                                        }
                                        firstRow={index === 0}
                                        hasBorder={hasSuggestedChanges}
                                        milestone={milestone}
                                        showAsDiff={
                                          isDeletedMilestone || isNewMilestone
                                        }
                                      />
                                    )}
                                    {hasSuggestedChanges && (
                                      <FixedPriceDiffTableRow
                                        actions={
                                          canAcceptPendingChanges &&
                                          !isEditingMilestones && (
                                            <MilestoneChangesActions
                                              onAcceptChanges={() =>
                                                onAcceptChanges?.(_id, false)
                                              }
                                              onDenyChanges={() =>
                                                onDenyChanges?.(_id, false)
                                              }
                                            />
                                          )
                                        }
                                        alertChip={
                                          diffAlert?.alertIndex && (
                                            <NumberChip
                                              color="secondary"
                                              label={diffAlert.alertIndex}
                                            />
                                          )
                                        }
                                        firstRow={index === 0}
                                        milestone={suggestedChanges}
                                      />
                                    )}
                                  </Fragment>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {isEditingMilestones ? (
                          <Fragment>
                            <Box py={4}>
                              <Divider />
                            </Box>
                            <Box display="flex" columnGap={25}>
                              <Box display="flex" columnGap={4}>
                                <Button
                                  onClick={() => {
                                    handleReset();
                                    setIsEditingMilestones(false);
                                  }}
                                  size="small"
                                  variant="outlined"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  disabled={keys(errors).length > 0}
                                  onClick={submitForm}
                                  size="small"
                                  variant="contained"
                                >
                                  Save Changes
                                </Button>
                              </Box>
                              <Box display="flex" columnGap={4}>
                                <Button
                                  onClick={() =>
                                    push({
                                      _id: uniqueId(),
                                      amount: 0,
                                      description: "",
                                      dueDate: {
                                        type: "weeks",
                                        weeks: 0,
                                        days: 0,
                                      },
                                      chargeId: null,
                                      isFunded: false,
                                      status: MilestoneStatus.PENDING,
                                    })
                                  }
                                  size="small"
                                  variant="contained"
                                >
                                  Add Milestone
                                </Button>
                              </Box>
                            </Box>
                          </Fragment>
                        ) : (
                          <Box height={69} />
                        )}
                      </Fragment>
                    )}
                  </FieldArray>
                </form>
              );
            }}
          </Formik>
        )}
      </Box>
    </Fragment>
  );
};
