import { Box } from "@mui/material";
import {
  useHeadlineText,
  useMediumItalic,
  useMobileStyles,
  useStepBStyles,
} from "../../../../../../themes/style-hooks";

export const LandingStepBHeadline = () => {
  const styles = useStepBStyles();
  const mediumItalic = useMediumItalic();
  const { mobileHeadlineWrapper } = useMobileStyles();
  const { skewedText, transparent } = useHeadlineText({
    primary: "#B1D9D9",
    secondary: "#7B65AA",
    background: "#7B65AA",
  });

  return (
    <Box className={mobileHeadlineWrapper}>
      <Box
        className={mediumItalic.root}
        style={{
          whiteSpace: "nowrap",
        }}
      >
        <Box className={`${transparent} ${styles.headline}`}>
          <span>Post&nbsp;</span>
        </Box>
        <Box className={`${skewedText} ${styles.headline} `}>
          <span>&nbsp;jobs&nbsp;</span>
        </Box>
        <Box className={`${transparent} ${styles.headline} `}>
          <span>&nbsp;and&nbsp;</span>
        </Box>
        <br />
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;freelance&nbsp;</span>
        </Box>
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;projects.&nbsp;</span>
        </Box>
        <br />
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;All in one place.&nbsp;</span>
        </Box>
      </Box>
    </Box>
  );
};
