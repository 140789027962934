import { Box, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import { BLUE } from "../../../themes/components/utils";
import { formatNominativeMonth } from "../components/LoadingTypography";
import { useIsMobileView } from "../landing-page/hooks";

const useStyle = makeStyles(() => ({
  root: {
    backgroundColor: BLUE,
    opacity: 1,
  },
}));

export const Reconversion = () => {
  const isMobile = useIsMobileView();
  const style = useStyle();

  return (
    <Box>
      <Box pt={isMobile ? 7.5 : 15}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            variant={isMobile ? "subtitle1" : "h2"}
            style={{
              width: "70%",
              fontWeight: 600,
            }}
            color="secondary"
          >
            Embracing the Future: A Guide to Switching Careers to the IT
            Industry{" "}
          </Typography>
          <Typography color="secondary" variant="body2">
            {format(new Date("2023-05-24"), formatNominativeMonth)}
          </Typography>
        </Box>
        <Box py={2.5}>
          <Divider className={style.root} />
        </Box>
        <Box display="flex" flexDirection="column" rowGap={6}>
          <Typography variant="body1" color="secondary">
            In a rapidly evolving world, many professionals are considering
            breaking into the thriving field of information technology (IT).
            With its limitless opportunities, high-demand job market, and
            potential for growth, IT offers a promising career path for
            individuals from diverse backgrounds. In this blog article, we will
            explore the key steps and strategies to successfully transition into
            the IT industry, regardless of your current field or level of
            experience.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Assess Your Skills and Interests{" "}
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            Begin by assessing your existing skills, experience, and interests
            to determine the best fit within the IT industry. Identify
            transferable skills from your current profession that align with IT
            roles, such as problem-solving, analytical thinking, or
            communication. Research various IT domains, such as software
            development, cyber security, data analysis, or system
            administration, and evaluate which areas resonate with your passion
            and long-term goals.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Build an Impressive Portfolio
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            Creating an outstanding portfolio is essential for showcasing your
            skills and attracting potential clients. Develop a professional
            website or use online platforms like TheTalented.co, GitHub or
            Behance to display your previous work, projects, and achievements.
            Include a variety of examples that highlight your expertise and
            demonstrate your ability to solve real-world problems. Clients often
            rely on portfolios to gauge your capabilities, so ensure yours is
            polished and up to date.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Acquire Relevant Knowledge and Certifications
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            Depending on your chosen IT specialization, acquiring the necessary
            knowledge and certifications is crucial. Consider enrolling in
            accredited online courses, boot camps, or degree programs that
            provide comprehensive training and hands-on experience. Platforms
            like Coursera, Udemy, or Codecademy offer a wide range of IT-related
            courses. Additionally, pursue industry-recognized certifications
            like CompTIA, Cisco, or Microsoft to validate your skills and
            enhance your marketability.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Network and Collaborate{" "}
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            Building a strong professional network is invaluable in the
            freelancing world. Attend industry events, tech conferences, and
            meetups to connect with potential clients and fellow freelancers.
            Engage in online communities, forums, and social media platforms
            related to your niche. Participate in discussions, provide valuable
            insights, and offer assistance to establish yourself as an
            authoritative figure. Collaborating with other freelancers on
            projects can also lead to referrals and extended client networks. At
            TheTalented.co, professionals collaborate on projects and refer one
            another.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Gain Practical Experience
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            Building practical experience is vital for entering the field of IT.
            Seek opportunities to apply your newly acquired knowledge through
            internships, apprenticeships, or freelance projects. Volunteer for
            non-profit organizations or offer assistance to local businesses to
            gain real-world exposure. Creating your own projects or contributing
            to open-source initiatives can also demonstrate your abilities and
            help build a portfolio.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Network within the IT Community{" "}
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            Networking is essential in the IT industry to establish connections,
            gain insights, and explore job opportunities. Attend industry
            events, meetups, and conferences to meet professionals in the field.
            Engage in online communities, forums, and social media platforms
            where IT experts and enthusiasts share knowledge and experiences.
            Build genuine relationships by offering assistance, sharing your
            expertise, and seeking mentorship from experienced individuals who
            can guide you through your career switch journey.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Tailor Your Resume and Cover Letter
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            When applying for IT roles, it's crucial to customize your resume
            and cover letter to highlight your transferable skills, relevant
            experience, and technical knowledge. Emphasize your ability to
            adapt, learn quickly, and solve problems effectively. Showcase any
            IT-related projects or certifications you have obtained. If you lack
            direct IT experience, focus on your transferable skills and
            highlight how they can be applied in the IT field.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Continuous Learning and Adaptation
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            The IT industry is constantly evolving, and as an IT professional,
            you must commit to lifelong learning. Stay up to date with the
            latest industry trends, emerging technologies, and best practices.
            Engage in continuous professional development through online
            courses, webinars, and workshops. Develop a growth mindset that
            embraces change and adapts to new challenges, ensuring your skills
            remain relevant in an ever-changing IT landscape.
          </Typography>
          <Typography variant="body1" color="secondary">
            The switch to working in IT industry is an exciting journey that
            opens doors to numerous possibilities. By assessing your skills,
            acquiring relevant knowledge, gaining practical experience,
            networking, and emphasizing adaptability, you can successfully
            transition into the world of IT. Embrace the future, embrace the
            opportunities, and embark on a fulfilling career in the dynamic
            realm of information technology.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
