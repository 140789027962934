import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Icon, Typography } from "@mui/material";
import React from "react";

type ErrorWrapperProps = {
  errorMessage?: string;
};

export const ErrorWrapper: React.FC<ErrorWrapperProps> = ({ errorMessage }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      columnGap={2}
    >
      <ErrorOutlineIcon color="primary" />
      <Typography variant="body1" color="primary">
        {errorMessage}
      </Typography>
    </Box>
  );
};
