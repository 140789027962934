import { Box, Button } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

type StepActionsProps = {
  submitStep: number;
  onSubmit?: () => void;
  containerProps?: any;
  isDisabled?: boolean;
  setFieldValue?: any;
  isLoading?: boolean;
};

export const StepActions: React.FC<StepActionsProps> = ({
  onSubmit,
  submitStep,
  isDisabled = false,
  containerProps,
  isLoading,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentStep = Number(searchParams.get("step"));

  const onNextHandler = useCallback(() => {
    const nextStep = Number(searchParams.get("step")) + 1;
    searchParams.set("step", String(nextStep));
    setSearchParams(searchParams, { state: { from: "the-talented" } });
  }, [searchParams, setSearchParams]);

  const button = useMemo(() => {
    if (currentStep === submitStep) {
      return (
        <Button
          size="medium"
          variant="contained"
          disabled={isDisabled}
          onClick={onSubmit}
          data-cy-btn="submit"
        >
          Submit
        </Button>
      );
    }

    return (
      <Button
        size="medium"
        variant="contained"
        type="button"
        onClick={onNextHandler}
        disabled={isDisabled}
        data-cy-btn="next-step"
      >
        Next Step
      </Button>
    );
  }, [currentStep, isDisabled, onNextHandler, onSubmit, submitStep]);

  if (currentStep > submitStep) return null;

  if (currentStep === 4)
    return (
      <Box
        flexGrow={1}
        justifyContent="flex-end"
        alignItems="flex-end"
        display="flex"
        columnGap={5}
        py={10}
        {...containerProps}
      >
        <Button
          size="medium"
          variant="contained"
          type="button"
          onClick={onNextHandler}
          disabled={isLoading}
          data-cy-btn="next-step"
        >
          Next Step
        </Button>
      </Box>
    );

  if (currentStep === 5)
    return (
      <Box
        flexGrow={1}
        justifyContent="flex-end"
        alignItems="flex-end"
        display="flex"
        columnGap={5}
        py={10}
        {...containerProps}
      >
        <Button
          size="medium"
          variant="contained"
          type="button"
          onClick={onSubmit}
          disabled={isLoading}
          data-cy-btn="submit"
        >
          Complete Sign Up
        </Button>
      </Box>
    );

  return (
    <Box
      flexGrow={1}
      justifyContent="flex-end"
      alignItems="flex-end"
      display="flex"
      flexDirection="column"
      py={10}
      {...containerProps}
    >
      {button}
    </Box>
  );
};
