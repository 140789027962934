import { Components } from "@mui/material";
import { BLUE } from "./utils";

export const MuiTextField: Components["MuiTextField"] = {
  styleOverrides: {
    root: {
      width: "100%",
      // height: "100%",
      borderRadius: 1,
      "& ::placeholder": {
        color: BLUE,
      },
    },
  },
};
