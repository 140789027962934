import { Box } from "@mui/material";
import React from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useHeadlineText,
  useMediumItalic,
  useStepCStyles,
} from "../../../../../../themes/style-hooks";
import { ClientMainActions } from "../../../ClientMainActions";

export const LandingStepC = () => {
  const styles = useStepCStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: COLOR.BLUE,
    color: COLOR.YELLOW,
    hover: COLOR.BLUE,
  });
  const mediumItalic = useMediumItalic();
  const { transparent, skewedText } = useHeadlineText({
    primary: COLOR.ORANGE,
    secondary: COLOR.BLUE,
    background: COLOR.BLUE,
  });
  const descriptionText = useDescriptionText({
    color: COLOR.BLUE,
  });

  return (
    <React.Fragment>
      <Box textAlign="left" width="100%">
        <Box
          className={mediumItalic.root}
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <Box className={`${transparent} ${styles.headline} `}>
            <span>&nbsp;Freelance projects&nbsp;</span>
          </Box>
          <Box className={`${styles.headline} ${transparent}`}>
            <span>are&nbsp;</span>
          </Box>
        </Box>
        <br />
        <Box className={mediumItalic.root}>
          <Box className={`${skewedText} ${styles.headline}`}>
            <span>&nbsp;free to post.&nbsp;</span>
          </Box>
        </Box>
      </Box>
      <Box width="100%" pl={8}>
        <span>&nbsp;</span>
        <Box textAlign="left" color={COLOR.WHITE_PINK}>
          <Box className={descriptionText.root}>
            It’s free to post your freelance project requests. Build your app,
            your .com, or your brand.{" "}
          </Box>
        </Box>
      </Box>
      <ClientMainActions classNames={`${common} ${root}`} />
      {/*<AccountTypeSwitch defaultChecked="client" />*/}
    </React.Fragment>
  );
};
