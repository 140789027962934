import { createTheme } from "@mui/material/styles";
import * as components from "./components";
import { BLUE, YELLOW } from "./components/utils";

const theme = createTheme({
  components: {
    ...components,
  },
  spacing: 4,
  typography: {
    fontFamily: "Jost",
    body1: {
      fontSize: 18,
      ["@media screen and (max-width: 1020px)"]: {
        fontSize: 16,
      },
      ["@media screen and (max-width: 600px)"]: {
        fontSize: 14,
      },
    },
    body2: {
      fontSize: 14,
      ["@media screen and (max-width: 1020px)"]: {
        fontSize: 12,
      },
    },
    subtitle1: {
      fontSize: 24,
      ["@media screen and (max-width: 1020px)"]: {
        fontSize: 22,
      },
      ["@media screen and (max-width: 600px)"]: {
        fontSize: 20,
      },
    },
    subtitle2: {
      fontSize: 21,
      fontWeight: 400,
      ["@media screen and (max-width: 1020px)"]: {
        fontSize: 18,
      },
      ["@media screen and (max-width: 600px)"]: {
        fontSize: 16,
      },
    },
    h1: {
      fontSize: 36,
      fontWeight: 600,
      ["@media screen and (max-width: 1020px)"]: {
        fontSize: 32,
      },
      ["@media screen and (max-width: 600px)"]: {
        fontSize: 28,
      },
    },
    h2: {
      fontSize: 34,
      ["@media screen and (max-width: 1020px)"]: {
        fontSize: 30,
      },
      ["@media screen and (max-width: 600px)"]: {
        fontSize: 26,
      },
    },
    h3: {
      fontSize: 31,
      ["@media screen and (max-width: 1020px)"]: {
        fontSize: 27,
      },
      ["@media screen and (max-width: 600px)"]: {
        fontSize: 23,
      },
    },
    h4: {
      fontSize: 27,
      ["@media screen and (max-width: 1020px)"]: {
        fontSize: 23,
      },
      ["@media screen and (max-width: 600px)"]: {
        fontSize: 19,
      },
    },
  },
  palette: {
    background: {
      default: BLUE,
    },
    primary: {
      main: YELLOW,
      contrastText: BLUE,
    },
    secondary: {
      main: BLUE,
      contrastText: YELLOW,
    },
  },
  breakpoints: {
    values: {
      xs: 400,
      sm: 600,
      md: 1020,
      lg: 1300,
      xl: 1450,
    },
  },
});

export default theme;
