import { FormikErrors, FormikValues } from "formik";
import { ChangeEvent, FC } from "react";
import { ProfileInformation } from "../../../professional/account/edit-professional-account/steps";

export const initialProfessionalOptionalInfoValues = {
  position: "",
  profileImage: "",
  description: "",
  skills: [],
};

type SignUpProfessionalOptionalInfoProps = {
  onBack?: () => void;
  values: FormikValues;
  handleChange: (e: ChangeEvent) => void;
  // eslint-disable-next-file no-undef
  handleBlur: (e: FocusEvent) => void;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

export const SignUpProfessionalOptionalInfo: FC<
  SignUpProfessionalOptionalInfoProps
> = ({ errors, handleBlur, handleChange, onBack, setFieldValue, values }) => {
  return (
    <ProfileInformation
      errors={errors}
      handleBlur={handleBlur}
      handleChange={handleChange}
      onBack={onBack}
      setFieldValue={setFieldValue}
      values={values}
    />
  );
};
