import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Professional } from "../../../api-types/professionals/professional";
import { ProfilePicture } from "../summary-profile";
import { LoadingTypography } from "./LoadingTypography";

type ProfessionalSummaryProps = {
  professional?: Professional;
  width?: number;
  isLoading?: boolean;
};

export const ProfessionalSummary: React.FC<ProfessionalSummaryProps> = ({
  professional,
  width = 96,
  isLoading,
}) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" columnGap={6}>
      <Box display="flex" alignItems="center">
        <ProfilePicture
          width={width}
          onClick={() => {
            navigate(`/professionals/${professional?._id}`);
          }}
          isLoading={isLoading}
          profileInformation={professional?.profileInformation}
        />
        <Box px={4}>
          <LoadingTypography
            width={200}
            typographyProps={{
              variant: "subtitle2",
            }}
            style={{
              fontWeight: 700,
            }}
            isLoading={isLoading}
          >
            {professional?.accountInformation?.firstName}{" "}
            {professional?.accountInformation?.lastName}
          </LoadingTypography>
          <LoadingTypography
            width={200}
            isLoading={isLoading}
            typographyProps={{
              variant: "body2",
            }}
            style={{ fontWeight: 500 }}
          >
            {professional?.profileInformation?.position}
          </LoadingTypography>
        </Box>
      </Box>
    </Box>
  );
};
