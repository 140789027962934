import { Components } from "@mui/material";

export const MuiPopover: Components["MuiPopover"] = {
  styleOverrides: {
    paper: {
      top: "7,5vh",
    },
  },
};

// export const MuiPopover: MuiComponentThemeDefinition<
//   PopoverClassKey,
//   PopoverProps
// > = {
//   props: {},
//   override: () => ({
//     paper: {
//       top: "7,5vh",
//     },
//   }),
// };
