import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePointerStyles } from "../../../../themes/style-hooks";

export const Collaboration: React.FC = () => {
  const pointerStyles = usePointerStyles();
  const navigate = useNavigate();
  const { projectId } = useParams();

  return (
    <Box display="flex" flexDirection="column" rowGap={5}>
      <Typography
        variant={"subtitle1"}
        sx={{
          fontWeight: "bold",
        }}
      >
        Do you have a professional you’d like to recommend and collaborate with?
      </Typography>
      <Box
        onClick={() => {
          navigate(`/professional-referral/?projectId=${projectId}`);
        }}
        className={pointerStyles.root}
      >
        <Typography variant={"subtitle2"} color="primary">
          Yes, I want to refer a professional
        </Typography>
      </Box>
    </Box>
  );
};
