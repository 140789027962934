import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuthed, useUser } from "../../../state/hooks";
import { defaultFilters } from "../../professionals-search/state";
import { useSearchFilters } from "../../professionals-search/state/hooks";

export const clearAccessToken = (key: string) => {
  // eslint-disable-next-line no-undef
  window.localStorage.removeItem(key);
};

export const useLogout = () => {
  const setIsAuthed = useIsAuthed().set;
  const setUser = useUser().set;
  const navigate = useNavigate();
  const setFilters = useSearchFilters().set;

  return useCallback(() => {
    // eslint-disable-next-line no-undef
    window.sessionStorage.setItem("navigateTo", "");
    window.sessionStorage.setItem("showMessage", "true");
    setFilters(defaultFilters);
    clearAccessToken("access_token");
    setIsAuthed(false);
    setUser({
      name: "",
      id: null,
      accountType: null,
    });

    navigate("/sign-in", { replace: true });
  }, [navigate, setFilters, setIsAuthed, setUser]);
};
