import { Box, CircularProgress, Typography } from "@mui/material";
import React, { ReactElement } from "react";

type SectionProps = {
  loadingSkeleton?: any;
  isLoading?: boolean;
  errorMessage?: string | ReactElement;
  sectionProps?: any;
  color?: "primary" | "secondary" | "inherit";
  size?: "small" | "medium";
};

export const Section: React.FC<SectionProps> = ({
  isLoading = false,
  loadingSkeleton,
  errorMessage,
  sectionProps,
  color = "primary",
  size = "medium",
  children,
}) => {
  if (isLoading && loadingSkeleton) {
    return loadingSkeleton;
  }

  if (isLoading)
    return (
      <Box
        display="flex"
        height="100%"
        flexGrow={1}
        width="100%"
        alignItems="center"
        justifyContent="center"
        {...sectionProps}
      >
        <CircularProgress
          color={color}
          size={size === "small" ? "1.5rem" : "2.5rem"}
        />
      </Box>
    );

  if (errorMessage)
    return (
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        py={20}
        {...sectionProps}
      >
        <Typography component="div" variant="body1">
          {errorMessage}
        </Typography>
      </Box>
    );

  return (
    <Box height="100%" {...sectionProps}>
      {children}
    </Box>
  );
};
