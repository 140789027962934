import { Components } from "@mui/material";

export const MuiButtonBase: Components["MuiButtonBase"] = {
  styleOverrides: {
    root: {
      fontWeight: "normal",
      width: "fit-content",
      "&.Mui-disabled": {
        color: "white",
        opacity: 0.5,
      },
    },
  },
};
//
// export const MuiButtonBase: MuiComponentThemeDefinition<
//   ButtonBaseClassKey,
//   ButtonBaseProps
// > = {
//   props: {
//     color: "primary",
//   },
//   override: () => ({
//     root: {
//       fontWeight: "normal",
//       width: "fit-content",
//     },
//     disabled: {
//       opacity: 0.5,
//     },
//   }),
// };
