import {
  Box,
  Button,
  Divider,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { noop } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BLUE, YELLOW } from "../../../../../themes/components/utils";
import { ImageComponent } from "../../../../client/job-requests/utils";
import { SkillsInput } from "../../../../common/components/SkillsInput";
import { EditAccountProgressBar } from "./EditAccountProgressBar";

type ProjectProps = {};

const useStyles = makeStyles({
  editButton: {
    fontWeight: "normal",
    fontSize: 24,
    width: 80,
    height: 48,
    border: "1px solid",
    borderColor: YELLOW,
  },
  withdrawButton: {
    fontWeight: "normal",
    fontSize: 24,
    width: 260,
    height: 60,
  },
  nameTextArea: {
    width: 400,
    height: "80px !important",
    fontSize: 24,
    paddingTop: 24,
    paddingLeft: 12,
    border: "2px solid",
    borderColor: YELLOW,
    outline: "none",
    fontFamily: "inherit",
    color: "white",
  },
  titleTextArea: {
    width: 800,
    height: 80,
    fontSize: 24,
    padding: 12,
    border: "2px solid",
    borderColor: YELLOW,
    outline: "none",
    fontFamily: "inherit",
    color: "white",
  },
  descriptionTextArea: {
    width: 800,
    height: "200px !important",
    fontSize: 21,
    padding: 12,
    border: "2px solid",
    borderColor: YELLOW,
    outline: "none",
    fontFamily: "inherit",
    color: "white",
  },
  projectsList: {
    color: "white",
    backgroundColor: BLUE,
    "&:hover": {
      cursor: "pointer",
      color: BLUE,
      backgroundColor: YELLOW,
    },
  },
});

export const Project: React.FC<ProjectProps> = () => {
  const theme = useTheme();
  const styles = useStyles();
  const navigate = useNavigate();

  return (
    <Box
      bgcolor={theme.palette.background.paper}
      width="100%"
      height="100%"
      px={15}
    >
      <EditAccountProgressBar currentStep={3} />
      <Box pt={10}>
        <Box pt={5}>
          <Typography
            variant="h1"
            sx={{
              fontWeight: "bold",
            }}
          >
            Project Edit
          </Typography>
          <br />
          <Divider />
          <br />
          <Box pb={10}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: "bold",
              }}
            >
              Project Title Two
            </Typography>
          </Box>
          <TextareaAutosize
            className={styles.nameTextArea}
            minRows={1}
            maxRows={2}
          />
        </Box>

        <ImageComponent />

        <Box pl={130} pb={10}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
            }}
          >
            Skillsets Used in This Project
          </Typography>
          <br />
          <Typography variant="subtitle1">
            Select up to 10 skillsets used in this project.
          </Typography>
          <SkillsInput handleChange={noop} />
          <Box display="flex" flexDirection="column" rowGap={3}>
            <TextareaAutosize
              placeholder="Description"
              defaultValue=""
              className={styles.descriptionTextArea}
              minRows={7}
              maxRows={7}
            />
            <TextareaAutosize
              placeholder="Title"
              defaultValue=""
              className={styles.titleTextArea}
              minRows={1}
              maxRows={2}
            />
          </Box>
        </Box>
        <Box
          alignItems="flex-end"
          justifyContent="flex-end"
          display="flex"
          flexDirection="column"
          rowGap={6}
          pb={10}
        >
          <Button
            variant="outlined"
            color="primary"
            className={styles.withdrawButton}
            size="small"
            onClick={() => {
              navigate(-1);
            }}
          >
            Update this project
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
