import { Box, Divider, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { ShowImages, Section, TextOverflow } from "../../../common/components";
import { formatNominativeMonth } from "../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../common/components/SkillsOutput";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { Description } from "../../../common/professionals-search/projects/project/sections/Description";
import { useCurrentJobRequest } from "../../../state/hooks";
import { AvailabilityCostBids } from "../../job-requests/search-job-requests-list/job-request-item/content";
import { BidDetailsSkeleton } from "../BidDetailsSkeleton";
import { useAccountBid } from "../hooks/useAccountBid";

export const Details: React.FC = () => {
  const currentJobRequest = useScopedDowngradedStateValue(
    useCurrentJobRequest()
  );

  const { data: accountBid, isLoading: isLoadingAccountBid } = useAccountBid();
  const { isLoading: isLoadingAccountBids } = useAccountBid();

  const isLoading = isLoadingAccountBids || isLoadingAccountBid;

  const skills = accountBid?.jobRequest?.skills.map((skill) => skill.name);

  const hasImages = Boolean((accountBid?.jobRequest?.images?.length ?? 0) > 0);

  return (
    <Section isLoading={isLoading} loadingSkeleton={<BidDetailsSkeleton />}>
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        columnGap={10}
      >
        <TextOverflow
          width="30%"
          style={{
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          {accountBid?.jobRequest?.title ?? currentJobRequest?.title}
        </TextOverflow>
        <Box display="flex" columnGap={10}>
          {accountBid?.jobRequest?.createdAt && (
            <Typography variant="body2">
              {`Posted ${format(
                new Date(accountBid?.jobRequest?.createdAt),
                formatNominativeMonth
              )}`}
            </Typography>
          )}
          {accountBid?.createdAt && (
            <Typography variant="body2">
              {`Placed bid ${format(
                new Date(accountBid?.createdAt),
                formatNominativeMonth
              )}`}
            </Typography>
          )}
        </Box>
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>
      {accountBid?.jobRequest && (
        <React.Fragment>
          <Description
            description={accountBid?.jobRequest?.description}
            label=""
          />
          <Box pt={2.5}>
            <AvailabilityCostBids
              jobRequestId={accountBid.jobRequest._id}
              budget={accountBid?.jobRequest?.budget}
              fixedProjectFunding={accountBid.jobRequest.fixedProjectFunding}
            />
          </Box>
        </React.Fragment>
      )}
      {Boolean((skills?.length ?? 0) > 0) && (
        <Box pt={2.5}>
          <SkillsOutput skills={skills} />
        </Box>
      )}
      {hasImages && (
        <Box pt={2.5}>
          <ShowImages selectedFiles={accountBid?.jobRequest?.images ?? []} />
        </Box>
      )}
    </Section>
  );
};
