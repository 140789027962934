import { Box, Divider, Typography } from "@mui/material";
import { BLUE } from "../../../../themes/components/utils";

export const HourlyRateMoreInfo = () => {
  return (
    <Box display="flex" flexDirection="column" rowGap={3.5}>
      <Typography
        color="secondary"
        variant="subtitle2"
        style={{ fontWeight: 700 }}
      >
        Hourly Rate Projects
      </Typography>
      <Divider style={{ borderColor: BLUE }} />
      <Typography color="secondary" variant="body2" style={{ fontWeight: 700 }}>
        Planning and Paying for Your Project
      </Typography>
      <Typography color="secondary" variant="body2">
        By default, all hourly rate projects have one milestone.
      </Typography>
      <Typography color="secondary" variant="body2">
        Once all the work on a milestone is submitted and reviewed, payment is
        made for the actual number of hours completed in the time span of that
        milestone. Work can then begin on the next milestone.
      </Typography>{" "}
      <Typography color="secondary" variant="body2">
        All payments are enabled by Stripe. There is a 3.9% + $0.30 processing
        fee that Stripe charges for each transaction.
      </Typography>{" "}
      <Typography color="secondary" variant="body2">
        Invoices for all your projects will be available on the Payments Reports
        page.
      </Typography>
    </Box>
  );
};
