import { Box } from "@mui/material";
import React, { FC } from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useMediumItalic,
  useHeadlineText,
  useDescriptionText,
  useStepEStyles,
} from "../../../../../../themes/style-hooks";
import { AccountTypeSwitch } from "../../../client/parallax/AccountTypeSwitch";
import { MainActions } from "../../../MainActions";

export const LandingStepE: FC = () => {
  const styles = useStepEStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: COLOR.DARK_BROWN,
    color: "#FFDF00",
    hover: COLOR.DARK_BROWN,
  });
  const mediumItalic = useMediumItalic();
  const { skewedText, transparent } = useHeadlineText({
    primary: "#FFDF00",
    secondary: COLOR.DARK_BROWN,
    background: COLOR.DARK_BROWN,
  });
  const descriptionText = useDescriptionText({
    color: COLOR.DARK_BROWN,
  });

  return (
    <React.Fragment>
      <Box textAlign="left" width="100%">
        <Box className={mediumItalic.root}>
          <Box className={`${transparent} ${styles.headline}`}>
            <span>&nbsp;Professionals can&nbsp;</span>
          </Box>
          <Box className={`${skewedText} ${styles.headline}`}>
            <span>&nbsp;partner&nbsp;</span>
          </Box>
        </Box>
        <br />
        <Box className={mediumItalic.root}>
          <Box className={`${transparent} ${styles.headline} `}>
            <span>&nbsp;to&nbsp;</span>
          </Box>
          <Box className={`${skewedText} ${styles.headline}`}>
            <span>&nbsp;collaborate&nbsp;</span>
          </Box>
          <Box className={`${transparent} ${styles.headline}`}>
            <span>&nbsp;on projects.&nbsp;</span>
          </Box>
        </Box>
      </Box>
      <Box width="100%" pl={8}>
        <Box textAlign="left">
          <Box className={descriptionText.root}>
            Professionals partner with one another to get more projects done for
            our clients, and collaborate to bring their work to another level.
          </Box>
        </Box>
        <Box textAlign="left" pt={8}>
          <Box className={descriptionText.root}>
            We encourage you to do that.
          </Box>
        </Box>
      </Box>
      <MainActions classNames={`${common} ${root}`} />
      {/*<AccountTypeSwitch defaultChecked="professional" />*/}
    </React.Fragment>
  );
};
