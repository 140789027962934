import { Components } from "@mui/material";

export const MuiInputBase: Components["MuiInputBase"] = {
  styleOverrides: {
    root: {
      height: "inherit",
      fontSize: 21,
      backgroundColor: "white",
      borderRadius: 1,
    },
  },
};

// export const MuiInputBase: MuiComponentThemeDefinition<
//   InputBaseClassKey,
//   InputBaseProps
// > = {
//   props: {
//     autoComplete: "off",
//   },
//   override: () => ({
//     root: {
//       height: "inherit",
//       fontSize: 21,
//       backgroundColor: "white",
//       borderRadius: 1,
//     },
//   }),
// };
