import { Box, Divider } from "@mui/material";
import React from "react";
import { useResponsiveness } from "../../../../../common/hooks/utils/useResponsiveness";
import { UnpublishedPastProjectHeaderContent } from "./UnpublishedPastProjectHeaderContent";
import { UnpublishedPastProjectHeaderSummary } from "./UnpublishedPastProjectHeaderSummary";

export const UnpublishedPastProjectHeader: React.FC = () => {
  const { isLarge } = useResponsiveness();

  return (
    <Box
      display="flex"
      height="250"
      flexDirection={isLarge ? "column" : "row"}
      alignItems={isLarge ? "flex-start" : "center"}
      columnGap={isLarge ? 10 : 16}
    >
      <UnpublishedPastProjectHeaderContent />
      {!isLarge && <Divider orientation="vertical" flexItem />}
      <UnpublishedPastProjectHeaderSummary />
    </Box>
  );
};
