import { Box, Skeleton } from "@mui/material";
import React from "react";

export const ClientInvitationsSkeleton: React.FC = () => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" pt={8} width="90%">
        <Box width="40%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="40%"
            height={30}
          />
          <Box mt={8}>
            <Skeleton variant="rectangular" animation="wave" height={40} />
          </Box>
          <Box mt={12} width="40%">
            <Skeleton variant="rectangular" animation="wave" height={80} />
          </Box>
        </Box>

        <Box mt={4} width="30%" justifyContent="space-between">
          <Box mb={2}>
            <Skeleton variant="rectangular" animation="wave" height={20} />
          </Box>
          <Box mb={4}>
            <Skeleton variant="rectangular" animation="wave" height={20} />
          </Box>
          <Box mb={2}>
            <Skeleton variant="rectangular" animation="wave" height={20} />
          </Box>
          <Box mb={2}>
            <Skeleton variant="rectangular" animation="wave" height={20} />
          </Box>
          <Box mb={4}>
            <Skeleton variant="rectangular" animation="wave" height={20} />
          </Box>
          <Box mb={2}>
            <Skeleton variant="rectangular" animation="wave" height={20} />
          </Box>
          <Box>
            <Skeleton variant="rectangular" animation="wave" height={20} />
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" width="60%">
        <Skeleton variant="rectangular" animation="wave" height={50} />
      </Box>

      <Box mt={4}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="30%"
          height={25}
        />
      </Box>

      <Box mt={2}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="20%"
          height={20}
        />
      </Box>

      <Box mt={8}>
        <Box mb={4}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="30%"
            height={25}
          />
        </Box>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="40%"
          height={25}
        />
      </Box>

      <Box display="flex" justifyContent="space-between" mt={8}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="30%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="15%"
          height={25}
        />
      </Box>

      <Box mt={4}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="40%"
          height={10}
        />
      </Box>
      <Box mt={2}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="30%"
          height={10}
        />
      </Box>
      <Box mt={2}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={10}
        />
      </Box>

      <Box pt={10} width="40%">
        <Box display="flex" columnGap={2.5} alignItems="center">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="17.5%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="17.5%"
            height={40}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="17.5%"
            height={40}
          />

          <Box display="flex" columnGap={1} alignItems="center">
            <Skeleton
              variant="circular"
              animation="wave"
              width={16}
              height={16}
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width={16}
              height={16}
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width={16}
              height={16}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
