import { Box, Divider, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { AccountType } from "../../../../../api-types/common";
import { Loader } from "../../../../common/elements/Loader";
import { useJobRequestBids } from "../../../../common/hooks/job-requests/job-request/bids/useJobRequestBids";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../../../../state/hooks";
import { useAddJobBidMessageMutation } from "../hooks";
import { Bid } from "./Bid";

export const Bids: React.FC = () => {
  const { data: bids, isLoading } = useJobRequestBids();
  const { mutate: addBidMessage, isLoading: isAddingMessages } =
    useAddJobBidMessageMutation();
  const { id } = useScopedDowngradedStateValue(useUser());

  const onNewMessageHandler = useCallback(
    (bidId: string, newMessage: string, createdBy: string) => {
      if (isLoading) return;

      addBidMessage({
        id: bidId,
        messages: [
          {
            content: newMessage,
            from: id ?? "",
            to: createdBy ?? "",
          },
        ],
        accountType: AccountType.CLIENT,
      });
    },
    [addBidMessage, id, isLoading]
  );

  if (isLoading || !bids) {
    return <Loader />;
  }

  if (bids.length === 0) {
    return (
      <Box height="50%">
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
            }}
          >
            Bids
          </Typography>
          <Box py={2.5}>
            <Divider />
          </Box>
        </Box>
        <Box textAlign="center">
          <Typography variant="body1">
            No one has applied to this job request yet.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box width="100%">
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
          }}
        >
          Bids
        </Typography>
        <Box py={2.5}>
          <Divider />
        </Box>
      </Box>
      {bids.map((bid) => (
        <Box key={bid._id} pt={2.5}>
          <Bid
            isLoading={isAddingMessages}
            key={bid._id}
            bid={bid}
            onNewMessage={(newMessage: string) =>
              onNewMessageHandler(bid._id, newMessage, bid.createdBy._id)
            }
          />
        </Box>
      ))}
    </Box>
  );
};
