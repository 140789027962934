import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Link,
  Typography,
  Container,
  DialogTitle,
} from "@mui/material";
import { BLUE, LIGHT_BLUE } from "../../../themes/components/utils";
import { JobPostResumeViewer } from "./JobPostResumeViewer";

type PreviewPdfDialogProps = {
  open: boolean;
  onClose: () => void;
  resumeUrl: string;
};

export const PreviewPdfDialog = ({
  open,
  onClose,
  resumeUrl,
}: PreviewPdfDialogProps) => {
  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        height: "100%",
        "& .MuiDialog-paper": {
          height: "90%",
        },
      }}
    >
      <Container
        maxWidth="lg"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <DialogTitle
          style={{
            backgroundColor: BLUE,
            padding: "0 24px",
          }}
        >
          <Box
            display="flex"
            py={4}
            style={{ borderBottom: `1px solid ${LIGHT_BLUE}` }}
          >
            <Typography variant="h6">Resume Preview</Typography>

            <Box display="flex" columnGap={2} ml="auto">
              <Button onClick={onClose} size="small">
                Close Preview
              </Button>
              <Link href={resumeUrl} target="_blank">
                <Button size="small">Download Resume</Button>
              </Link>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent style={{ backgroundColor: BLUE, flexGrow: 1 }}>
          <JobPostResumeViewer resumeUrl={resumeUrl} />
        </DialogContent>
      </Container>
    </Dialog>
  );
};
