import { Box, Checkbox, TableCell, TableRow, Typography } from "@mui/material";
import React, { ChangeEvent, FC, ReactNode, useCallback } from "react";
import { YELLOW } from "../../../../themes/components/utils";
import {
  DueDate,
  Milestone,
} from "../../../client/job-requests/create-job-request-wizard/validation-schema";
import { stringToTitleCase } from "../../../utils";

type FixedPriceTableRowProps = {
  actions?: ReactNode;
  alertChip?: ReactNode;
  firstRow?: boolean;
  hasBorder?: boolean;
  isSelectable?: boolean;
  milestone: Milestone;
  onSelect?: (event: ChangeEvent<HTMLInputElement>) => void;
  selected?: boolean;
  showAsDiff?: boolean;
};

export const FixedPriceTableRow: FC<FixedPriceTableRowProps> = ({
  actions,
  alertChip,
  firstRow,
  hasBorder,
  isSelectable,
  milestone,
  onSelect,
  selected,
  showAsDiff,
}) => {
  const { description, amount, isFunded, status, dueDate } = milestone;

  const dueDateType = useCallback((dueDate: DueDate) => {
    return dueDate.type?.substring(0, dueDate.type?.length - 1);
  }, []);

  return (
    <TableRow
      style={{
        backgroundColor: showAsDiff ? YELLOW : "inherit",
        border: hasBorder ? `1px solid ${YELLOW}` : "none",
        height: 64,
      }}
    >
      <TableCell>
        {isSelectable && (
          <Checkbox
            disabled={isFunded}
            checked={selected || isFunded}
            onChange={onSelect}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          />
        )}
      </TableCell>
      <TableCell>
        <Box display="flex" gap={12}>
          <Typography
            color={showAsDiff ? "secondary" : "inherit"}
            component="span"
            variant="body2"
          >
            {description}
          </Typography>
          {alertChip}
        </Box>
      </TableCell>
      <TableCell>
        <Typography
          color={showAsDiff ? "secondary" : "inherit"}
          component="span"
          variant="body2"
        >
          ${amount}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={showAsDiff ? "secondary" : "inherit"}
          component="span"
          variant="body2"
        >
          {`${dueDate.count} ${dueDateType(dueDate)}(s) from start of ${
            firstRow ? "project" : "milestone"
          }`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={showAsDiff ? "secondary" : "inherit"}
          component="span"
          variant="body2"
        >
          {isFunded ? "Yes" : "No"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          color={showAsDiff ? "secondary" : "inherit"}
          component="span"
          variant="body2"
        >
          {stringToTitleCase(status ?? "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="flex-end" columnGap={2}>
          {actions}
        </Box>
      </TableCell>
    </TableRow>
  );
};
