import { Box, Divider } from "@mui/material";
import React from "react";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { ProfessionalReferralHeaderContent } from "./ProfessionalReferralHeaderContent";
import { ProfessionalReferralHeaderSummary } from "./ProfessionalReferralHeaderSummary";

export const ProfessionalReferralHeader = () => {
  const { isMedium } = useResponsiveness();

  return (
    <Box
      display="flex"
      flexDirection={isMedium ? "column" : "row"}
      columnGap={isMedium ? 0 : 10}
      py={10}
    >
      <ProfessionalReferralHeaderContent />
      {!isMedium && <Divider orientation="vertical" flexItem />}
      <ProfessionalReferralHeaderSummary />
    </Box>
  );
};
