import { Box, Button } from "@mui/material";
import { Formik } from "formik";
import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { object } from "yup";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { Section } from "../../../common/components";
import { useProfileInformation } from "../../../common/hooks/profile-information/useProfileInformation";
import { useUpdateProfileInformationMutation } from "../../../common/hooks/profile-information/useUpdateProfileInformationMutation";
import { EditAccountProgressBar } from "./steps";
import { Projects } from "./steps/Projects";

export const EditProfessionalAccountProjects: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: profileInformation, isLoading: isLoadingProfileInformation } =
    useProfileInformation();

  const { mutate: updateProfileInformation } =
    useUpdateProfileInformationMutation();

  const [searchParams] = useSearchParams();

  const initialProfileInformationValues = {
    ...profileInformation,
    pricing: {
      ...profileInformation?.pricing,
      hourlyRate: profileInformation?.pricing?.hourlyRate ?? 0,
      availabilityPerWeek: {
        ...profileInformation?.pricing?.availabilityPerWeek,
        type:
          profileInformation?.pricing?.availabilityPerWeek?.type ?? "estimate",
        estimate: {
          min:
            profileInformation?.pricing?.availabilityPerWeek?.estimate?.min ??
            0,
          max:
            profileInformation?.pricing?.availabilityPerWeek?.estimate?.max ??
            0,
        },
        fixed: profileInformation?.pricing?.availabilityPerWeek?.fixed ?? 0,
      },
    },
  };

  const validationSchema = object({});

  const onClose = useCallback(() => {
    navigate("/account", { replace: true });
  }, [navigate]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, [location]);

  const isEditing = searchParams.get("isEditing") === "1";

  return (
    <Box height="calc(100% - 100px)">
      <EditAccountProgressBar
        currentStep={Number(searchParams.get("step"))}
        noProgress
      />
      <PageWrapper hideNavbar>
        <Section isLoading={isLoadingProfileInformation}>
          <Formik
            initialValues={initialProfileInformationValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              updateProfileInformation({
                ...values,
              });
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              values,
              isValid,
              submitForm,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box flexGrow={1}>
                  <Projects
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    submitForm={submitForm}
                  />
                </Box>
                {!isEditing && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    py={10}
                    columnGap={2.5}
                  >
                    <Button
                      size="medium"
                      variant="outlined"
                      onClick={() => {
                        onClose?.();
                      }}
                    >
                      Close
                    </Button>
                  </Box>
                )}
              </form>
            )}
          </Formik>
        </Section>
      </PageWrapper>
    </Box>
  );
};
