import { Box, Skeleton } from "@mui/material";
import React from "react";

export const CurrentProjectPaymentsSkeleton: React.FC = () => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" pt={8} width="100%">
        <Box width="50%">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="40%"
            height={30}
          />
          <Box mt={8}>
            <Skeleton variant="rectangular" animation="wave" height={40} />
          </Box>
          <Box display="flex" mt={12}>
            <Box width="20%">
              <Skeleton variant="rectangular" animation="wave" height={80} />
            </Box>
            <Box ml={8} width="40%">
              <Skeleton variant="rectangular" animation="wave" height={80} />
            </Box>
            <Box ml={8} width="20%">
              <Skeleton variant="rectangular" animation="wave" height={80} />
            </Box>
          </Box>
        </Box>

        <Box mt={8} width="37%" justifyContent="space-between">
          <Box mb={4}>
            <Skeleton variant="rectangular" animation="wave" height={40} />
          </Box>
          <Box mb={2}>
            <Skeleton variant="rectangular" animation="wave" height={30} />
          </Box>
          <Box mb={2}>
            <Skeleton variant="rectangular" animation="wave" height={30} />
          </Box>
          <Box mb={2}>
            <Skeleton variant="rectangular" animation="wave" height={30} />
          </Box>
        </Box>
      </Box>

      <Box mb={16} mt={16} display="flex" alignItems="center">
        <Skeleton variant="circular" animation="wave" width={55} height={55} />
        <Box width="40%" ml={4}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="40%"
            height={30}
          />
          <Box pt={2}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="20%"
              height={15}
            />
          </Box>
        </Box>
      </Box>

      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={65}
      />

      <Box mt={20} width="100%">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="30%"
          height={30}
        />
      </Box>

      <Box mt={8} width="100%" display="flex" justifyContent="space-between">
        <Box display="flex" width="50%" justifyContent="space-between">
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="20%"
            height={20}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="20%"
            height={20}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="20%"
            height={20}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="20%"
            height={20}
          />
        </Box>
        <Box width="40%" display="flex" justifyContent="flex-end" columnGap={2}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="20%"
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="20%"
            height={30}
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="20%"
            height={30}
          />
        </Box>
      </Box>

      <Box mt={12} width="100%" columnGap={6} display="flex">
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={25}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="35%"
          height={25}
        />
      </Box>
    </Box>
  );
};
