import { Box, Divider } from "@mui/material";
import React from "react";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { PastProjectHeaderContent } from "./PastProjectHeaderContent";
import { PastProjectHeaderSummary } from "./PastProjectHeaderSummary";

export const PastProjectHeader: React.FC = () => {
  const { isSmall } = useResponsiveness();

  return (
    <Box display="flex" columnGap={10}>
      <PastProjectHeaderContent />
      {!isSmall && (
        <>
          <Divider orientation="vertical" flexItem />
          <PastProjectHeaderSummary />
        </>
      )}
    </Box>
  );
};
