import { Box, Button, Divider, Typography } from "@mui/material";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Message } from "../../../../../api-types/job-requests/common";
import { useInvitationQuery } from "../../../../common/hooks/job-requests/job-request/invites/useInvitationQuery";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../../../../state/hooks";
import { MessagesForm } from "../../../bids/communication/MessagesForm";
import { useAccountInquiryQuery } from "../../hooks/useAccountInquiry";
import { useAddInquiryMessageMutations } from "../../hooks/useAddInquiryMessageMutation";
import { useBidByJobRequestId } from "../../hooks/useBidByProjectId";
import { useCreateInquiryMutation } from "../../hooks/useCreateInquiryMutation";
import { useInquiryByJobRequestId } from "../../hooks/useInquiryByProjectId";
import { BidSection } from "./BidSection";

type CommunicationProps = {};

export const Communication: React.FC<CommunicationProps> = () => {
  const { data: invitation } = useInvitationQuery();

  const [placeBid, setPlaceBid] = useState(false);

  const jobRequestId = invitation?.jobRequestId;

  const accountInquiryId = useInquiryByJobRequestId(jobRequestId);

  const { data: inquiryData, isLoading: isLoadingInquiry } =
    useAccountInquiryQuery(accountInquiryId);

  const { id, accountType } = useScopedDowngradedStateValue(useUser());

  const { mutate: addInquiryMessage, isLoading: isAddingNew } =
    useAddInquiryMessageMutations(undefined, accountInquiryId);

  const { mutate: createInquiry, isLoading: isCreating } =
    useCreateInquiryMutation();

  const onNewMessageHandler = (newMessage: string) => {
    if (isLoadingInquiry) return;

    const messages: Message[] = [
      {
        content: newMessage,
        from: id ?? "",
        to: inquiryData?.jobRequest?.createdBy ?? "",
      },
    ];

    if (!accountInquiryId && jobRequestId) {
      createInquiry({ jobRequestId: jobRequestId, messages });
    } else {
      addInquiryMessage({
        messages,
        accountType,
        jobRequestId: inquiryData?.jobRequest._id,
      });
    }
  };

  const messages = useMemo(() => {
    if (isLoadingInquiry || !inquiryData) return [];

    return inquiryData.messages;
  }, [inquiryData, isLoadingInquiry]);

  const accountBidId = useBidByJobRequestId(jobRequestId);

  const hasPlaceBidButton = useMemo(
    () => !placeBid && !accountBidId,
    [accountBidId, placeBid]
  );

  const handlePlaceBid = useCallback(() => {
    setPlaceBid(true);
  }, []);

  const handleCancelBid = useCallback(() => {
    setPlaceBid(false);
  }, []);

  const hasBid = useMemo(() => {
    if (accountBidId) {
      handleCancelBid();
    }
    return placeBid || accountBidId;
  }, [accountBidId, handleCancelBid, placeBid]);

  return (
    <Box height="100%">
      {hasBid ? (
        <BidSection editing={placeBid} onCancel={handleCancelBid} />
      ) : (
        <Fragment>
          <Box display="flex" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
              }}
            >
              Inquiries and Replies
            </Typography>
            {hasPlaceBidButton && (
              <Button size="small" variant="contained" onClick={handlePlaceBid}>
                Place bid
              </Button>
            )}
          </Box>
          <Box pt={2.5}>
            <Divider />
          </Box>
          <MessagesForm
            messages={messages}
            height={250}
            onNewMessage={onNewMessageHandler}
            isLoading={isCreating || isAddingNew || isLoadingInquiry}
          />
        </Fragment>
      )}
    </Box>
  );
};
