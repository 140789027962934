import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { YELLOW } from "../../../../../themes/components/utils";
import { ConfirmationDialog } from "../../../../common/components";
import { useStartFreeTrialMutationCore } from "../../../../common/hooks/job-requests/job-request/useStartFreeTrialMutation";
import {
  METADATA_QUERY_KEY,
  useMetadataQuery,
} from "../../../../common/hooks/metadata/useMetadataQuery";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useToast } from "../../../../utils/useToast";
import { JobPostPayment } from "../../../job-posts-billing";
import { useJobPackages } from "../../../job-requests/create-job-request-wizard/hooks/useJobPackages";
import { JobPostsCreditSummary } from "../../JobPostsCreditSummary";
import { usePackagesModal } from "../state/hooks";
import { PackagesCards } from "./PackagesCards";

export const Packages = () => {
  const { data: metadata } = useMetadataQuery();
  const { isEligibleForFreeTrial, isFreeTrialExpired } = useJobPackages();
  const navigate = useNavigate();

  const [selectedPackage, setSelectedPackage] = useState<{
    package: string;
    type: "free-trial" | "effective";
  }>({ package: "1-job", type: "free-trial" });
  const [isSelected, setIsSelected] = useState(false);
  const [postJobModal, setPostJobModal] = useState(false);
  const packagesModal = useScopedDowngradedStateValue(usePackagesModal());
  const setPackagesModal = usePackagesModal().set;
  const { mutate: startFreeTrial, isLoading } = useStartFreeTrialMutationCore();
  const { isSmall } = useResponsiveness();

  const handleOpenDialog = () => {
    setPackagesModal({
      open: true,
    });
  };
  const handleCloseDialog = () => {
    setPackagesModal({
      open: false,
    });
  };

  const handleConfirmSelection = () => {
    setIsSelected(true);
    handleCloseDialog();
  };

  const { notifySuccess, notifyError } = useToast();
  const queryClient = useQueryClient();

  const handleStartFreeTrial = useCallback(
    (freeTrialPackage: string) => {
      let freeTrialJobPosts = 1;
      if (freeTrialPackage === "3-jobs") {
        freeTrialJobPosts = 3;
      }
      if (freeTrialPackage === "5-jobs") {
        freeTrialJobPosts = 5;
      }
      if (freeTrialPackage === "10-jobs") {
        freeTrialJobPosts = 10;
      }

      startFreeTrial(
        { freeTrialJobPosts },
        {
          onSuccess() {
            notifySuccess("Great news! Your free trial has started");
            queryClient.invalidateQueries(METADATA_QUERY_KEY);
          },
          onError() {
            notifyError("Something went wrong. Please try again later.");
          },
        }
      );
    },
    [notifyError, notifySuccess, queryClient, startFreeTrial]
  );

  const amount = useMemo(() => {
    if (selectedPackage.package === "3-jobs") {
      return 85 * 3;
    }

    if (selectedPackage.package === "5-jobs") {
      return 75 * 5;
    }

    if (selectedPackage.package === "10-jobs") {
      return 10 * 65;
    }

    return 99;
  }, [selectedPackage]);

  // https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
  const amountIncludingCommission = (amount + 0.3) / (1 - 0.029);
  // const amountIncludingCommission = amount + amount * 0.029 + 0.3;

  const roundedAmount = amount
    ? Math.round((amountIncludingCommission + Number.EPSILON) * 100) / 100
    : 0;

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isSmall ? "column" : "row"}
        rowGap={2}
      >
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 600,
          }}
        >
          Job Posts
        </Typography>
        <JobPostsCreditSummary />
      </Box>
      <Box py={2}>
        <Divider />
      </Box>
      <Box pt={2.5}>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 600,
            paddingBottom: 32,
          }}
        >
          Examples of Job Posts are:
        </Typography>
        <Box
          border="2px solid"
          borderColor={YELLOW}
          display="flex"
          flexDirection={"column"}
          justifyContent="space-evenly"
          height={320}
          alignItems={isSmall ? "flex-start" : "flex-end"}
          px={5}
        >
          {" "}
          {[
            "A permanent, full-time job",
            "A contract job that will not be completed on this platform",
            "A job posted on behalf of another company",
            "An in-person or hybrid job",
          ].map((l) => (
            <Box display="flex" width="fit-content" justifyContent="start">
              <Typography
                variant="subtitle1"
                color={"primary"}
                style={{ fontWeight: 450 }}
              >
                {l}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box py={2.5}>
        <Typography
          variant="subtitle2"
          style={{
            paddingTop: 8,
            textAlign: "right",
          }}
        >
          Freelance projects are free to post.
        </Typography>
        {!isSmall && (
          <Box display="flex" alignItems="baseline" columnGap={3}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 600,
              }}
            >
              Purchase Job Posts{" "}
            </Typography>
            <Typography>|</Typography>
            <Typography variant="subtitle2">
              <b>Card Information</b>
            </Typography>
          </Box>
        )}
        {isSelected ? (
          <Box pt={6}>
            <JobPostPayment
              amount={roundedAmount}
              selectedPackage={selectedPackage.package}
              onSuccess={() => {
                setIsSelected(false);
                setPostJobModal(true);
              }}
            />
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            pt={10}
            justifyContent="space-between"
            flexDirection={isSmall ? "column" : "row"}
            rowGap={4}
          >
            <Box display="flex" flexDirection="column" rowGap={3}>
              <Typography>
                Each job stays posted for <strong>90 days</strong>
              </Typography>
              <Typography>Can post confidentially</Typography>
              <Typography>Unposted jobs never expire</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Box height={isSmall ? 460 : 420} mb={4}>
                <PackagesCards
                  selectLabel={
                    isEligibleForFreeTrial
                      ? "Start 10-Day Free Trial"
                      : "Select"
                  }
                  onSelect={(option) => {
                    if (isEligibleForFreeTrial) {
                      handleStartFreeTrial(option);
                      return;
                    }

                    if (!isFreeTrialExpired) {
                      handleOpenDialog();
                      setSelectedPackage({
                        package: option,
                        type: isEligibleForFreeTrial
                          ? "free-trial"
                          : "effective",
                      });
                      return;
                    }

                    setSelectedPackage({
                      package: option,
                      type: "effective",
                    });
                    setIsSelected(true);
                  }}
                />
              </Box>
              <Box pb={4}>
                <Typography variant="subtitle2">
                  Job post purchases are non-refundable.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box py={10}>
        <Divider />
      </Box>

      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 600,
        }}
      >
        Custom Packages Over 10 Job Posts
      </Typography>
      <Box display="flex" columnGap={6} pt={6} alignItems="center" pb={10}>
        <Typography variant="body1" width="50%">
          If you would like to post more than 10 jobs simultaneously, contact us
          for a custom package.{" "}
        </Typography>
        <Button
          variant="contained"
          size="medium"
          onClick={() => window.location.replace("/contact")}
        >
          Custom Job Packages
        </Button>
      </Box>
      <ConfirmationDialog
        open={packagesModal.open}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmSelection}
        cancelLabel="Cancel"
        confirmLabel="Confirm"
        title="Additional Purchases Are Final"
        message={`You are in a 10-day free trial period with ${metadata?.freeTrial?.freeTrialJobPosts} job post(s). Any additional job post purchases are final and non-refundable.`}
      />

      <ConfirmationDialog
        cancelLabel="Post Later"
        confirmLabel="Post Now"
        onClose={() => setPostJobModal(false)}
        onConfirm={() => {
          setPostJobModal(false);
          navigate("/job-post/new");
        }}
        open={postJobModal}
        title="Congratulations! You Have Successfully Purchased The Job Package"
        message="Would you like to post a job?"
      />
    </Box>
  );
};
