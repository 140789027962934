import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { COLOR } from "../../../../themes/components/utils";
import { SearchJobFiltersFormData } from "../schemas/searchJobFiltersSchema";
import { useSearchJobPostFiltersStyles } from "./useSearchJobPostFiltersStyles";

const jobType = [
  {
    label: "Full Time",
    value: "full-time",
  },
  {
    label: "Contract, 40hrs/wk",
    value: "contract",
  },
  {
    label: "Temp-to-perm",
    value: "temp-to-perm",
  },
  {
    label: "Part-time",
    value: "part-time",
  },
];

export const JobTypeFilter = () => {
  const styles = useSearchJobPostFiltersStyles();
  const { handleChange, values } = useFormikContext<SearchJobFiltersFormData>();

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        className={`${styles.label} ${values.showAll && styles.disabledLabel}`}
        color="secondary"
        variant="subtitle1"
      >
        Job Type
      </Typography>
      {jobType.map((item) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={values.jobType.includes(item.value)}
              className={styles.checkbox}
              data-cy={item.value}
              disabled={values.showAll}
              name="jobType"
              onChange={handleChange}
              style={{
                color: COLOR.BLUE,
              }}
            />
          }
          key={item.value}
          label={
            <Typography color="secondary" variant="subtitle2">
              {item.label}
            </Typography>
          }
          value={item.value}
        />
      ))}
    </Box>
  );
};
