import { Components } from "@mui/material";

export const MuiMenuItem: Components["MuiMenuItem"] = {
  styleOverrides: {
    root: {
      width: "100%",
      fontSize: 18,
    },
  },
};
//
// export const MuiMenuItem: MuiComponentThemeDefinition<
//   MenuItemClassKey,
//   MenuItemProps
// > = {
//   props: {},
//   override: () => ({
//     root: {
//       fontSize: 18,
//     },
//   }),
// };
