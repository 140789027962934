import { Box, Divider } from "@mui/material";
import React from "react";
import { AccountInformation } from "../../../../api-types/account-information";
import { ProfileInformation } from "../../../../api-types/profile-information";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";
import { ProfessionalAccountSummaryContent } from "./ProfessionalAccountSummaryContent";
import { ProfessionalAccountSummaryPanel } from "./ProfessionalAccountSummaryPanel";

type ProfessionalAccountSummaryDescriptionProps = {
  accountInformation?: AccountInformation;
  profileInformation?: ProfileInformation;
  isLoading?: boolean;
  averageRating?: number;
  ratingsCount?: number;
};

export const ProfessionalAccountSummaryDescription: React.FC<
  ProfessionalAccountSummaryDescriptionProps
> = ({
  accountInformation,
  profileInformation,
  isLoading,
  averageRating,
  ratingsCount,
}) => {
  const { isSmall, isLarge } = useResponsiveness();
  return (
    <Box display="flex" width="100%">
      <Box
        width="80%"
        height="100%"
        display="flex"
        marginLeft={isSmall ? 0 : 10}
      >
        <ProfessionalAccountSummaryContent
          accountInformation={accountInformation}
          profileInformation={profileInformation}
          isLoading={isLoading}
        />
        <Box height="100%">
          {!isLarge && <Divider orientation="vertical" />}
        </Box>
      </Box>
      <Box pl={6}>
        <ProfessionalAccountSummaryPanel
          profileInformation={profileInformation}
          averageRating={averageRating}
          ratingsCount={ratingsCount}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};
