import { Box, Divider } from "@mui/material";
import React, { useMemo } from "react";
import useResizeObserver from "use-resize-observer";
import { YELLOW } from "../../../../../../../../themes/components/utils";
import { ImagePreview } from "../../../../../../../client/job-requests/utils";
import { SkillsOutput } from "../../../../../../../common/components/SkillsOutput";
import { useProjectQuery } from "../../../../../../../common/hooks/projects/project/useProjectQuery";
import { Description } from "../../../../../../../common/professionals-search/projects/project/sections/Description";
import { AvailabilityCostBids } from "../../../../../../job-requests/search-job-requests-list/job-request-item/content";

type DetailsProps = {};

export const Details: React.FC<DetailsProps> = () => {
  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  // eslint-disable-next-line no-undef
  const { ref, width = 0 } = useResizeObserver<HTMLDivElement>();

  const skills = useMemo(() => {
    if (project?.skills && !isLoadingProject) {
      return project?.skills.map((skill) => skill.name);
    }

    return [];
  }, [isLoadingProject, project?.skills]);

  return (
    // @ts-ignore
    <Box ref={ref} width="100%">
      <Box pb={8} color={YELLOW}>
        <ImagePreview
          width={width}
          height={600}
          name={project?.client?.name ?? ""}
          title={project?.title}
          isLocalImages={false}
          selectedFiles={project?.images ?? []}
        />
      </Box>
      <React.Fragment>
        <Description label="Description" description={project?.description} />
        <Box pt={2.5}>
          <Divider />
        </Box>
        <Box pt={2.5}>
          <AvailabilityCostBids
            budget={project?.jobRequest?.budget}
            fixedProjectFunding={project?.jobRequest?.fixedProjectFunding}
          />
          <Box pt={2.5}>
            <Divider />
          </Box>
        </Box>{" "}
        {skills.length > 0 && (
          <Box pt={2.5}>
            <SkillsOutput skills={skills} />
            <Box pt={2.5}>
              <Divider />
            </Box>
          </Box>
        )}
      </React.Fragment>
    </Box>
  );
};
