import { Components } from "@mui/material";

export const MuiInputLabel: Components["MuiInputLabel"] = {
  styleOverrides: {
    outlined: {
      fontSize: 21,
      color: "#0F1E4A",
      "&.MuiInputLabel-marginDense": {
        transform: "translate(16px, 29px) scale(1)",
      },
      "&.MuiInputLabel-shrink": {
        fontSize: 14,
        transform: "translate(2px, -16px) scale(1)",
      },
    },
  },
};
//
// export const MuiInputLabel: MuiComponentThemeDefinition<
//   InputLabelClassKey,
//   InputLabelProps
// > = {
//   props: {},
//
//   override: () => ({
//     outlined: {
//       fontSize: 21,
//       color: "#0F1E4A",
//       "&.MuiInputLabel-marginDense": {
//         transform: "translate(16px, 29px) scale(1)",
//       },
//       "&.MuiInputLabel-shrink": {
//         fontSize: 14,
//         transform: "translate(2px, -16px) scale(1)",
//       },
//     },
//   }),
// };
