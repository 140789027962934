import { Box, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { JobRequest } from "../../../../api-types/job-requests/job-request";
import { useTotalEstimatedCostCore } from "../../../client/job-requests/create-job-request-wizard/hooks";

type HourlyRateMilestonesSummaryProps = {
  jobRequest?: JobRequest;
  milestonesCount: number;
};

export const HourlyRateMilestonesSummary: FC<
  HourlyRateMilestonesSummaryProps
> = ({ milestonesCount, jobRequest }) => {
  const timeFrameLabel = useMemo(() => {
    if (!jobRequest) return;

    const type = jobRequest?.budget.timeFrame.type;

    if (type === "fixed") {
      return `${jobRequest?.budget.timeFrame.fixed ?? 0} week(s)`;
    }

    const estimate = jobRequest.budget.timeFrame.estimate;

    return `${estimate?.min ?? 0} - ${estimate?.max ?? 0} week(s)`;
  }, [jobRequest]);

  const totalEstimatedCost = useTotalEstimatedCostCore(
    jobRequest?.hourlyRateProjectFunding?.milestones ?? [],
    jobRequest?.budget
  );

  return (
    <Box display="flex" columnGap={16}>
      <Box>
        <Typography
          component="span"
          variant="body2"
          style={{ fontWeight: 700 }}
        >
          Milestones for the Project
        </Typography>{" "}
        <Typography component="span" variant="body2">
          {milestonesCount}
        </Typography>
      </Box>
      <Box>
        <Typography
          component="span"
          variant="body2"
          style={{ fontWeight: 700 }}
        >
          Timeframe
        </Typography>{" "}
        <Typography component="span" variant="body2">
          {timeFrameLabel}
        </Typography>
      </Box>
      <Box>
        <Typography
          component="span"
          variant="body2"
          style={{ fontWeight: 700 }}
        >
          Estimated Cost
        </Typography>{" "}
        <Typography component="span" variant="body2">
          ${totalEstimatedCost?.min ?? 0} - ${totalEstimatedCost?.max ?? 0}
        </Typography>
      </Box>
      {/*<Box>*/}
      {/*  <Typography*/}
      {/*    component="span"*/}
      {/*    variant="body2"*/}
      {/*    style={{ fontWeight: 700 }}*/}
      {/*  >*/}
      {/*    Payment Schedule*/}
      {/*  </Typography>{" "}*/}
      {/*  <Typography component="span" variant="body2">*/}
      {/*    {jobRequest?.budget?.paymentSchedule?.type}*/}
      {/*  </Typography>*/}
      {/*</Box>*/}
    </Box>
  );
};
