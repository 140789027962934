import { Box, useTheme } from "@mui/material";
import React from "react";
import { TheTalentedLogo } from "../logo";

type NavbarProps = {
  inverse?: boolean;
};

export const MockNavbar: React.FC<NavbarProps> = ({ inverse = true }) => {
  const theme = useTheme();

  return (
    <Box
      bgcolor={theme.palette.primary.main}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height={100}
      px={5}
    >
      <TheTalentedLogo inverse={inverse} />
    </Box>
  );
};
