import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { Section } from "../../../common/components";
import { CollectionNavigationActions } from "../../../common/elements/CollectionNavigationActions";
import { useInvitationQuery } from "../../../common/hooks/job-requests/job-request/invites/useInvitationQuery";
import { useReceivedClientInvitations } from "../../../common/hooks/job-requests/job-request/invites/useReceivedClientInvitations";
import {
  Collection,
  useNavigationHandlers,
} from "../../../common/hooks/navigation";
import { InvitationHeader } from "./client-invitation-header";
import { ClientInvitationsSkeleton } from "./ClientInvitationsSkeleton";
import { Communication } from "./communication";
import { Details } from "./details";
import { useDefaultInvitationPage } from "./hooks";

export const ClientInvitationDetails: React.FC = () => {
  useDefaultInvitationPage();

  const [searchParams] = useSearchParams();
  const { invitationId } = useParams();

  const { data: clientInvitations, isLoading: isLoadingClientInvitations } =
    useReceivedClientInvitations();

  const { data: invitation, isLoading: isLoadingInvitation } =
    useInvitationQuery();
  const isLoading = isLoadingClientInvitations || isLoadingInvitation;

  const collectionConfig = {
    type: Collection.INVITATIONS,
    items: clientInvitations,
  };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(
      collectionConfig,
      isLoadingClientInvitations,
      invitationId
    );

  const errorMessage = useMemo(() => {
    return !isLoading && !invitation
      ? "Clients can invite professionals to work on a job request. All client invitations will be shown here."
      : "";
  }, [invitation, isLoading]);

  const content = useMemo(
    () =>
      searchParams.get("tab") === "replies" ? <Communication /> : <Details />,
    [searchParams]
  );

  const isNavigationHidden =
    searchParams.get("tab") !== "details" || isLoadingClientInvitations;

  return (
    <PageWrapper>
      <Section
        isLoading={isLoading}
        loadingSkeleton={<ClientInvitationsSkeleton />}
        errorMessage={errorMessage}
      >
        <Box
          display="flex"
          height="inherit"
          flexDirection="column"
          flexGrow={1}
        >
          <InvitationHeader />
          <Box flexGrow={1}>{content}</Box>
          <CollectionNavigationActions
            label={"Client Invitation"}
            index={index}
            totalCount={clientInvitations?.length ?? 0}
            handleSelectNext={handleSelectNext}
            handleSelectPrevious={handleSelectPrevious}
            hidden={isNavigationHidden}
          />
        </Box>
      </Section>
    </PageWrapper>
  );
};
