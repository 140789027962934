import { Box, Typography } from "@mui/material";
import { FormikValues } from "formik";
import React, { useMemo } from "react";
import { SkillV2 } from "../../../../../api-types/job-requests/common";
import { SkillsInput } from "../../../../common/components/SkillsInput";
import { BackButton } from "../../../../common/elements/BackButton";
import { useResponsiveness } from "../../../../common/hooks/utils/useResponsiveness";

type SkillsProps = {
  onBack?: () => void;
  handleUpdateSkills: (newSkills: SkillV2[]) => void;
  values: FormikValues;
};

export const Skills: React.FC<SkillsProps> = ({
  onBack,
  handleUpdateSkills,
  values,
}) => {
  const { isSmall, isMedium, isLarge } = useResponsiveness();
  const width = useMemo(() => {
    if (isSmall) {
      return 340;
    }
    if (isMedium) {
      return 570;
    }
    if (isLarge) {
      return 640;
    }
    return 830;
  }, [isSmall, isMedium, isLarge]);
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="column"
      pt={10}
    >
      <BackButton onClick={() => onBack?.()} />
      <Box
        display="flex"
        justifyContent="space-between"
        columnGap={10}
        pt={10}
        flexDirection={isMedium ? "column" : "row"}
      >
        <Box
          display="flex"
          flexDirection="column"
          rowGap={5}
          mb={isMedium ? 12 : 0}
        >
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
              }}
            >
              Select the Skills That Apply
            </Typography>
          </Typography>
          <Typography variant="subtitle2">
            Adding these skills helps in finding a good fit for your project.
          </Typography>
        </Box>
        <Box width="65%">
          <SkillsInput
            width={width}
            handleChange={handleUpdateSkills}
            initialSkills={values.skills?.map(
              (skill: { name: string }) => skill.name
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
