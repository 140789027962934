import { Box, Typography } from "@mui/material";
import { Field, FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { BackButton } from "../../elements/BackButton";
import { CustomCheckbox } from "../../elements/CustomCheckbox";
import { useResponsiveness } from "../../hooks/utils/useResponsiveness";

type SignUpProfessionalServicesProps = {
  onBack?: () => void;
  values: FormikValues;
  handleChange: (e: ChangeEvent) => void;
};

export const initialServicesValues = {
  services: {
    designing: false,
    marketing: false,
    development: false,
    writing: false,
  },
};

export const SignUpProfessionalServices: React.FC<
  SignUpProfessionalServicesProps
> = ({ onBack, values, handleChange }) => {
  const { designing, marketing, development, writing } = values.services;

  const { isSmall, isMedium } = useResponsiveness();

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="space-between"
      alignItems={isSmall ? "center" : "flex-start"}
      flexDirection="column"
      pt={10}
    >
      <Box alignSelf="flex-start">
        <BackButton onClick={() => onBack?.()} />
      </Box>
      <Box
        display="flex"
        pt={10}
        width={isSmall ? "100%" : "80%"}
        height="100%"
        justifyContent="space-between"
        columnGap={3}
        flexDirection={isSmall ? "column" : "row"}
      >
        <Box maxWidth={isSmall ? "100%" : "35%"}>
          <Typography variant="subtitle1">
            Select the categories that best fit your skills.
          </Typography>
        </Box>
        <Box
          maxWidth={"50%"}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="column"
            rowGap={5}
            pt={isSmall ? 5 : 0}
          >
            <Field
              width={isMedium ? 320 : 500}
              component={CustomCheckbox}
              label="Design & Creative"
              name="services.designing"
              value={designing}
              onChange={handleChange}
            />
            <Field
              width={isMedium ? 320 : 500}
              component={CustomCheckbox}
              label="Marketing & Strategy"
              name="services.marketing"
              value={marketing}
              onChange={handleChange}
            />
            <Field
              width={isMedium ? 320 : 500}
              component={CustomCheckbox}
              label="Web, Mobile & Software Development"
              name="services.development"
              value={development}
              onChange={handleChange}
            />
            <Field
              width={isMedium ? 320 : 500}
              component={CustomCheckbox}
              label="Writing & Content Creation"
              name="services.writing"
              value={writing}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
