import { Box, Button, Typography } from "@mui/material";
import { noop } from "lodash";
import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import {
  ConfirmationDialog,
  LoadingTypography,
} from "../../../../common/components";
import { useEndFreeTrialMutation } from "../../../../common/hooks/job-requests/job-request/useEndFreeTrialMutation";
import {
  METADATA_QUERY_KEY,
  useMetadataQuery,
} from "../../../../common/hooks/metadata/useMetadataQuery";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useJobPackages } from "../../../job-requests/create-job-request-wizard/hooks/useJobPackages";
import { useFreeTrialModal } from "../state/hooks";

export const FreeTrial = () => {
  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();

  const freeTrialModal = useScopedDowngradedStateValue(useFreeTrialModal());
  const setFreeTrialModal = useFreeTrialModal().set;
  const { mutate: endFreeTrial } = useEndFreeTrialMutation();

  const { daysPassedSinceFreeTrialStarted, isFreeTrialExpired } =
    useJobPackages();

  const handleOpenDialog = () => {
    setFreeTrialModal({
      open: true,
    });
  };

  const handleCloseDialog = () => {
    setFreeTrialModal({
      open: false,
    });
  };

  const queryClient = useQueryClient();
  const handleEndFreeTrial = useCallback(() => {
    endFreeTrial(noop(), {
      onSuccess() {
        queryClient.invalidateQueries(METADATA_QUERY_KEY);
        setFreeTrialModal({ open: false });
      },
    });
  }, [endFreeTrial, queryClient, setFreeTrialModal]);

  return (
    <Box>
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 600,
        }}
      >
        Job Posts Free Trial Period
      </Typography>
      {isFreeTrialExpired ? (
        <LoadingTypography
          width={"100%"}
          lineHeight={1.5}
          isLoading={isLoadingMetadata}
          style={{
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 4,
          }}
          typographyProps={{
            color: "white",
          }}
        >
          Your 10-day free trial has expired. Jobs Posted during this period are
          on hold, buy a Job Post packages in order to post and activate your
          jobs posts.
        </LoadingTypography>
      ) : (
        <LoadingTypography
          width={"100%"}
          lineHeight={1.5}
          isLoading={isLoadingMetadata}
          style={{
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 4,
          }}
          typographyProps={{
            color: "white",
          }}
        >
          You are currently on day <b>{daysPassedSinceFreeTrialStarted}</b> of
          your <b>10-day</b> free trial period with{" "}
          <b>{metadata?.freeTrial?.freeTrialJobPosts}</b> job post(s). After
          10-day free trial period is completed for the job posts you selected
          payment is mandatory.
        </LoadingTypography>
      )}
      <Box pt={2} display="flex" justifyContent="flex-end">
        {isFreeTrialExpired ? (
          <Button
            disabled
            variant="contained"
            size="medium"
            onClick={handleOpenDialog}
          >
            Free Trial Expired
          </Button>
        ) : (
          <Button
            variant="contained"
            size="medium"
            onClick={handleOpenDialog}
            data-cy-btn="end-trial"
          >
            End Trial Period
          </Button>
        )}
      </Box>
      <ConfirmationDialog
        open={freeTrialModal.open}
        onClose={handleCloseDialog}
        onConfirm={handleEndFreeTrial}
        cancelLabel="Cancel"
        confirmLabel="Confirm"
        title="Are You Sure You Want To End Your Free Trial?"
        message={`You currently have ${metadata?.freeTrial?.freeTrialJobPosts} job(s) posted. Canceling the trial will close the job once the 10-day trial period ends.`}
      />
    </Box>
  );
};
