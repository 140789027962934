import { Box, Divider, FormControlLabel, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Field, FormikErrors, FormikValues } from "formik";
import React, { ChangeEvent, Fragment, useMemo } from "react";
import { SkillV2 } from "../../../../../api-types/job-requests/common";
import { LIGHT_BLUE } from "../../../../../themes/components/utils";
import { SkillsInput } from "../../../../common/components/SkillsInput";
import { CustomInput, CustomTextArea } from "../../../../common/elements";
import { useRequiredFieldLabel } from "../../../../common/hooks/utils";
import { JobRequestFormValues } from "../../create-job-request-wizard/freelance-job-request/FreelanceJobRequestWizard";
import {
  useTotalEstimatedCost,
  useUpdateBudgetTimeFrame,
  useUpdateFixedProjectFunding,
} from "../../create-job-request-wizard/hooks";
import {
  BudgetSchema,
  ProjectDueDate,
} from "../../create-job-request-wizard/validation-schema";
import {
  ImageComponent,
  JobRequestFixedPriceMilestoneTable,
  JobRequestHourlyRateMilestoneTable,
  RadioGroupContainer,
  RadioWithInput,
} from "../../utils";
import { TextBox } from "../../utils/TextBox";

type EditDetailsFormProps = {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikErrors<
    Pick<JobRequestFormValues, "description" | "skills"> & BudgetSchema
  >;
  onUpdateImages: (images: any[]) => void;
  onUpdateSkills: (newSkills: SkillV2[]) => void;
  withTitle?: boolean;
};

const useStyles = makeStyles({
  label: {
    "& .MuiTypography-body1": {
      fontSize: "21px !important",
    },
  },
  textAreaNotice: {
    fontSize: 14,
  },
  titleTextArea: {
    height: "46px",
    fontSize: 21,
    background: "none",
    color: "white",
    border: "2px solid",
    borderColor: LIGHT_BLUE,
  },
  textAreaSubtitle: {
    fontWeight: "normal",
  },
  errorBox: {
    color: "red !important",
    marginTop: "10px",
  },
});

export const EditDetailsForm: React.FC<EditDetailsFormProps> = ({
  handleBlur,
  handleChange,
  values,
  errors,
  onUpdateImages,
  onUpdateSkills,
  withTitle = false,
}) => {
  const styles = useStyles();
  const requiredFieldLabel = useRequiredFieldLabel();
  useUpdateBudgetTimeFrame();
  useUpdateFixedProjectFunding();

  const totalEstimatedCost = useTotalEstimatedCost();

  const timeFrameContent = useMemo(() => {
    const timeFrameType = values.budget.timeFrame.type;
    switch (timeFrameType) {
      case "fixed":
        return (
          <Typography variant="body2">
            {values.budget.timeFrame.fixed ?? 0} week(s)
          </Typography>
        );
      case "estimate":
      case "ongoing":
      case "notSure":
        return (
          <Typography variant="body2">
            Between {values.budget.timeFrame.estimate.min ?? 0} and{" "}
            {values.budget.timeFrame.estimate.max ?? 0} weeks
          </Typography>
        );
      default:
        return null;
    }
  }, [
    values.budget.timeFrame.estimate.max,
    values.budget.timeFrame.estimate.min,
    values.budget.timeFrame.fixed,
    values.budget.timeFrame.type,
  ]);

  const { weeks, days, hours } = useMemo(
    () => ({
      weeks: (values?.fixedProjectFunding.dueDate as ProjectDueDate).weeks,
      days: (values?.fixedProjectFunding.dueDate as ProjectDueDate).days,
      hours: (values?.fixedProjectFunding.dueDate as ProjectDueDate).hours,
    }),
    [values?.fixedProjectFunding.dueDate]
  );

  const hasWeeks = weeks > 0;

  const hasDays = days > 0;

  const hasHours = hours > 0;

  return (
    <React.Fragment>
      {withTitle && (
        <Box width="100%" display="flex" flexDirection="column">
          <Field
            component={CustomInput}
            name="title"
            placeholder={requiredFieldLabel("Title")}
            className={styles.titleTextArea}
            value={values.title}
            error={errors.description}
          />
        </Box>
      )}
      <Box width="100%" pt={12} display="flex" flexDirection="column">
        <Field
          component={CustomTextArea}
          name="description"
          placeholder={requiredFieldLabel("Description")}
          value={values.description}
          error={errors.description}
        />
        <TextBox
          text="2000 characters maximum"
          variant="h4"
          classes={styles.textAreaNotice}
          boxProps={{ pt: 4, alignSelf: "flex-end" }}
        />
      </Box>

      <TextBox
        text="Upload Images related to the Job Request"
        variant="subtitle1"
        boxProps={{ pt: 4 }}
        // classes={boldStyles.root}
      />

      <TextBox
        text="Upload up to 5 images that help to describe what you are looking to
            get done, whether they are sources of inspiration or starting points
            for your project."
        variant="body1"
        classes={styles.textAreaSubtitle}
        boxProps={{ pt: 2, pb: 10 }}
      />

      <ImageComponent
        images={values?.images ?? []}
        handleChangeFileUpload={onUpdateImages}
        maxFiles={5}
      />

      <TextBox
        text="Designer"
        variant="subtitle1"
        // classes={boldStyles.root}
        boxProps={{ py: 2 }}
      />
      <Divider />
      <Box display="flex" columnGap={16} justifyContent="space-between">
        <Box pt={8} width="30%">
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
            }}
          >
            Select the Skills That Apply
          </Typography>
          <br />
          <Typography variant="body1">
            It’s helpful but not necessary to add these skills. You can also add
            them later.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" width="60%" pt={2}>
          <SkillsInput
            initialSkills={values.skills}
            handleChange={onUpdateSkills}
            width="100%"
          />
          {errors.skills && (
            <span className={styles.errorBox}>{errors.skills}</span>
          )}
        </Box>
      </Box>
      {values?.budget?.type === "hourly" && (
        <Fragment>
          <Box
            display="flex"
            justifyContent="space-between"
            columnGap={3}
            pt={10}
          >
            <Box maxWidth="30%">
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Budget
              </Typography>
            </Box>
            <Box width="60%">
              <RadioGroupContainer
                title={requiredFieldLabel("Set Hourly Rate")}
              >
                <Box>
                  <FormControlLabel
                    control={
                      <RadioWithInput
                        colorRadio={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`budget.hourlyRate`}
                        minValue={values?.budget.hourlyRate.estimate.min}
                        maxValue={values?.budget.hourlyRate.estimate.max}
                        checked={values?.budget.hourlyRate.type === "estimate"}
                        radioValue="estimate"
                        isMinMaxInput
                        leftLabel="Between $"
                        betweenInputsLabel="/hr and $"
                        minError={errors.budget?.hourlyRate?.estimate?.min}
                        maxError={errors.budget?.hourlyRate?.estimate?.max}
                      />
                    }
                    label="/hr"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <RadioWithInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        leftLabel="Exactly $"
                        colorRadio={true}
                        name={`budget.hourlyRate`}
                        value={values?.budget.hourlyRate.fixed}
                        checked={values?.budget.hourlyRate.type === "fixed"}
                        radioValue="fixed"
                        error={errors.budget?.hourlyRate?.fixed}
                      />
                    }
                    label="/hr"
                  />
                </Box>
              </RadioGroupContainer>

              <RadioGroupContainer
                subTitle="Estimate the hours you’d like dedicated to this project per week"
                title={requiredFieldLabel("Hours Dedicated to This Project")}
              >
                <Box>
                  <FormControlLabel
                    control={
                      <RadioWithInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        colorRadio={true}
                        width="small"
                        name={`budget.hours`}
                        minValue={values?.budget.hours.estimate.min}
                        maxValue={values?.budget.hours.estimate.max}
                        checked={values?.budget.hours.type === "estimate"}
                        radioValue="estimate"
                        isMinMaxInput
                        leftLabel="Between"
                        betweenInputsLabel="and"
                        minError={errors.budget?.hours?.estimate?.min}
                        maxError={errors.budget?.hours?.estimate?.max}
                      />
                    }
                    label="hours per week"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <RadioWithInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        leftLabel="Exactly"
                        colorRadio={true}
                        width="small"
                        name={`budget.hours`}
                        value={values?.budget.hours.fixed}
                        checked={values?.budget.hours.type === "fixed"}
                        radioValue="fixed"
                        error={errors.budget?.hours?.fixed}
                      />
                    }
                    label="hours per week"
                  />
                </Box>
              </RadioGroupContainer>

              {/* <RadioGroupContainer
                subTitle="Estimate how long you think the project will last"
                title={requiredFieldLabel("Estimate Timeframe")}
              >
                <FormControlLabel
                  control={
                    <RadioWithInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      colorRadio={true}
                      width="small"
                      name={`budget.timeFrame`}
                      minValue={values?.budget.timeFrame.estimate.min}
                      maxValue={values?.budget.timeFrame.estimate.max}
                      checked={values?.budget.timeFrame.type === "estimate"}
                      radioValue="estimate"
                      isMinMaxInput
                      leftLabel="Between"
                      betweenInputsLabel="and"
                    />
                  }
                  label="week"
                />
                <FormControlLabel
                  control={
                    <RadioWithInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      leftLabel="Exactly"
                      colorRadio={true}
                      width="small"
                      name={`budget.timeFrame`}
                      value={values?.budget.timeFrame.fixed}
                      checked={values?.budget.timeFrame.type === "fixed"}
                      radioValue="fixed"
                    />
                  }
                  label="week(s)"
                />
                <Field
                  as={FormControlLabel}
                  name="budget.timeFrame.type"
                  value="ongoing"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  control={
                    <Radio
                      checked={values?.budget.timeFrame.type === "ongoing"}
                    />
                  }
                  label="On-going"
                />
                <Field
                  as={FormControlLabel}
                  name="budget.timeFrame.type"
                  value="notSure"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  control={
                    <Radio
                      checked={values?.budget.timeFrame.type === "notSure"}
                    />
                  }
                  label="I'm not sure"
                />
              </RadioGroupContainer> */}

              {/* <RadioGroupContainer
                subTitle="How often would you like to schedule payments?"
                title="Payment Schedule"
              >
                <Field
                  as={FormControlLabel}
                  name="budget.paymentSchedule.type"
                  value="weekly"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  control={
                    <Radio
                      checked={
                        values?.budget?.paymentSchedule?.type === "weekly"
                      }
                    />
                  }
                  label="Weekly"
                />
                <Field
                  as={FormControlLabel}
                  name="budget.paymentSchedule.type"
                  value="biWeekly"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  control={
                    <Radio
                      checked={
                        values?.budget?.paymentSchedule?.type === "biWeekly"
                      }
                    />
                  }
                  label="Every two weeks"
                />
              </RadioGroupContainer> */}
            </Box>
          </Box>
          <Box pb={5}>
            <Divider />

            <Box display="flex" flexWrap="wrap" columnGap={10} pt={5}>
              <Box display="flex" alignItems="center" columnGap={3}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  variant="body2"
                >
                  Milestones for the Project:
                </Typography>
                <Typography variant="body2">
                  {values.hourlyRateProjectFunding.milestones?.length ?? 0}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" columnGap={2}>
                <Box mr={4}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                    component="span"
                    variant="body2"
                  >
                    Timeframe
                  </Typography>
                </Box>
                {timeFrameContent}
              </Box>
              <Box display="flex" alignItems="center" columnGap={2}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  component="span"
                  variant="body2"
                >
                  Estimated Cost:
                </Typography>
                <Typography variant="body2">
                  {values?.budget?.timeFrame?.type === "fixed" &&
                  values?.budget?.hours?.type === "fixed" &&
                  values?.budget?.hourlyRate?.type === "fixed"
                    ? `$${totalEstimatedCost?.min ?? 0}`
                    : `$${totalEstimatedCost?.min ?? 0} - $${
                        totalEstimatedCost?.max ?? 0
                      }`}
                </Typography>
              </Box>
            </Box>
          </Box>
          <JobRequestHourlyRateMilestoneTable />
        </Fragment>
      )}
      {values.budget.type === "fixed" && (
        <Fragment>
          <Box pt={10}>
            <Divider />

            <Box display="flex" flexWrap="wrap" columnGap={10} py={4}>
              <Box display="flex" alignItems="center" columnGap={3} width="40%">
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  variant="body2"
                >
                  Fixed Price for Complete Project
                </Typography>
                <Typography variant="body2">
                  ${values?.fixedProjectFunding.totalPrice}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center">
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  variant="body2"
                >
                  Due Date
                </Typography>
                {hasWeeks && (
                  <Box display="flex" pl={2.5}>
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {weeks}&nbsp;
                    </Typography>
                    <Typography variant="body2">week(s)</Typography>
                  </Box>
                )}
                {hasDays && (
                  <Fragment>
                    {hasWeeks && (
                      <Typography variant="body2">,&nbsp;</Typography>
                    )}
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {days}&nbsp;
                    </Typography>
                    <Typography variant="body2">day(s)</Typography>
                  </Fragment>
                )}
                {hasHours && (
                  <Fragment>
                    {(hasDays || hasWeeks) && (
                      <Typography variant="body2">,&nbsp;</Typography>
                    )}
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {hours}&nbsp;
                    </Typography>
                    <Typography variant="body2">hour(s)</Typography>
                  </Fragment>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <JobRequestFixedPriceMilestoneTable />
          </Box>
        </Fragment>
      )}
    </React.Fragment>
  );
};
