import { Components } from "@mui/material";
import { BLUE, LIGHT_BLUE, RED, YELLOW } from "./utils";

export const MuiTypography: Components["MuiTypography"] = {
  styleOverrides: {
    root: {
      color: "white",
    },
    // colorPrimary: {
    //   color: YELLOW,
    // },
    // colorSecondary: {
    //   color: BLUE,
    // },
    // colorError: {
    //   color: RED,
    // },
    // colorTextPrimary: {
    //   color: LIGHT_BLUE,
    // },
    // colorTextSecondary: {
    //   color: RED,
    // },
  },
};
//
// export const MuiTypography: MuiComponentThemeDefinition<
//   TypographyClassKey,
//   TypographyProps
// > = {
//   props: {},
//   override: () => ({
//     root: {
//       color: "white",
//     },
//     colorPrimary: {
//       color: YELLOW,
//     },
//     colorSecondary: {
//       color: BLUE,
//     },
//     colorError: {
//       color: RED,
//     },
//     colorTextPrimary: {
//       color: BLUE,
//     },
//     colorTextSecondary: {
//       color: RED,
//     },
//   }),
// };
