import { Box, Skeleton } from "@mui/material";
import React from "react";

export const JobPostsDashboardSkeleton: React.FC = () => {
  return (
    <Box pt={15} display="flex" flexDirection="column" rowGap={6}>
      <Box display="flex" justifyContent="space-between">
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="20%"
          height={50}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="20%"
          height={50}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="20%"
          height={50}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="15%"
          height={50}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="20%"
          height={50}
        />
      </Box>
      <Skeleton animation="wave" variant="rectangular" height={60} />
      <Skeleton animation="wave" variant="rectangular" height={60} />
      <Skeleton animation="wave" variant="rectangular" height={60} />
      <Skeleton animation="wave" variant="rectangular" height={60} />
      <Skeleton animation="wave" variant="rectangular" height={60} />
    </Box>
  );
};
