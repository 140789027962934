import { Box } from "@mui/material";
import React from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useMediumItalic,
  useHeadlineText,
  useMobileStyles,
  useStepBStyles,
} from "../../../../../../themes/style-hooks";

export const LandingStepBHeadline = () => {
  const styles = useStepBStyles();
  const mediumItalic = useMediumItalic();
  const { mobileHeadlineWrapper } = useMobileStyles();
  const { skewedText, transparent } = useHeadlineText({
    primary: COLOR.PINK,
    secondary: COLOR.WHITE_GREEN,
    background: COLOR.WHITE_GREEN,
  });

  return (
    <Box className={mobileHeadlineWrapper}>
      <Box className={mediumItalic.root}>
        <Box className={`${transparent} ${styles.headline} `}>
          <span>&nbsp;Apply to&nbsp;</span>
        </Box>
        <Box className={`${skewedText} ${styles.headline} `}>
          <span>&nbsp;freelance&nbsp;</span>
        </Box>
      </Box>
      <br />
      <Box
        className={mediumItalic.root}
        style={{
          whiteSpace: "nowrap",
        }}
      >
        <Box className={`${skewedText} ${styles.headline} `}>
          <span>&nbsp;projects&nbsp;</span>
        </Box>
        <Box className={`${transparent} ${styles.headline} `}>
          <span>&nbsp;and&nbsp;</span>
        </Box>
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;jobs.&nbsp;</span>
        </Box>
      </Box>
      <Box
        className={mediumItalic.root}
        style={{
          whiteSpace: "nowrap",
        }}
      >
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;All in one place.&nbsp;</span>
        </Box>
      </Box>
    </Box>
  );
};
