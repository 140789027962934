import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useCallback, ChangeEventHandler, useState } from "react";
import { uploadResume } from "../../../api-services/upload-files/uploadFiles";
import { useToast } from "../../utils/useToast";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../job-requests/hooks/feedbackErrors";
import { ApplyByEmailFormData } from "./ApplyByEmailDialog";

type UploadResumeProps = {
  jobPostId: string;
};

export const UploadResume = ({ jobPostId }: UploadResumeProps) => {
  const { notifyError } = useToast();
  const { setFieldValue, values } = useFormikContext<ApplyByEmailFormData>();
  const [resumeName, setResumeName] = useState<string>();
  const [isUploading, setIsUploading] = useState(false);

  // eslint-disable-next-line no-undef
  const onUploadResume = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (event) => {
      const file = event.target.files?.item(0);
      if (!file) return;

      try {
        setIsUploading(true);
        const resume = await uploadResume(file, jobPostId);
        setFieldValue("resume", resume.location);
        setResumeName(resume.key.replace(`resumes/${jobPostId}/`, ""));
        setIsUploading(false);
      } catch (error) {
        notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
        setIsUploading(false);
      }
    },
    [jobPostId, notifyError, setFieldValue]
  );

  return values.resume ? (
    <Box alignItems="center" display="flex" columnGap={2}>
      <Typography color="secondary">{resumeName}</Typography>
      <Button
        color="secondary"
        onClick={() => {
          setResumeName("");
          setFieldValue("resume", "");
        }}
        size="small"
        variant="contained"
      >
        Delete
      </Button>
    </Box>
  ) : (
    <Box>
      <input
        accept="application/pdf .doc .docx"
        hidden
        id="upload-resume"
        onChange={onUploadResume}
        type="file"
        data-cy="upload-resume"
      />
      <label htmlFor="upload-resume">
        <Button
          color="secondary"
          component="span"
          size="small"
          variant="contained"
          style={{ minWidth: 140 }}
        >
          {isUploading ? <CircularProgress size={16} /> : " Upload Resume"}
        </Button>
      </label>
    </Box>
  );
};
