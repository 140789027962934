import { Formik, FormikValues } from "formik";
import React, { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { array, boolean, number, object, ref, string } from "yup";
import { PageWrapper } from "../../../app/container/PageWrapper";
import {
  useJobPostQuery,
  useJobPostUpdateMutationCore,
} from "../../../common/hooks/job-posts";
import { jobPostKey } from "../../../common/hooks/job-posts/job-post/useJobPostQuery";
import { JOB_POSTS_QUERY_KEY } from "../../../common/hooks/job-posts/useJobPosts";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../utils/useToast";
import { initialJobPostValues } from "../../job-requests/create-job-request-wizard/job-post/JobPostWizard";
import { parseJobPostForm } from "../../job-requests/create-job-request-wizard/utils";
import { EditJobPostForm } from "./EditJobPostForm";

export const editJobPostValidationSchema = object().shape({
  step: number(),
  jobTitle: string().required(),
  companyName: string(),
  isConfidential: boolean(),
  aboutCompany: string(),
  description: string().required(),
  responsibilities: string(),
  jobLevel: string().required().default("intern"),
  yearsOfExperience: object({
    estimate: object({
      min: number(),
      max: number(),
    }).when("type", {
      is: "estimate",
      then: object().shape({
        min: number()
          .min(
            0,
            "Minimum years of experience must be greater than or equal to zero"
          )
          .lessThan(
            ref("max"),
            "Minimum years of experience must be less than maximum years of experience"
          )
          .required("Minimum years of experience is required"),
        max: number()
          .min(1, "Maximum years of experience must be greater than zero")
          .required("Maximum years of experience is required"),
      }),
    }),
    fixed: number().when("type", {
      is: "fixed",
      then: number()
        .min(0, "Years of experience must be greater than or equal to zero")
        .required("Years of experience is required"),
    }),
  }).default({}),
  salaryPerYear: object({
    estimate: object({
      min: number(),
      max: number(),
    }).when("type", {
      is: "estimate",
      then: object().shape({
        min: number()
          .min(1, "Minimum salary per year must be greater than zero")
          .lessThan(
            ref("max"),
            "Minimum salary per year must be less than maximum salary per year"
          )
          .required("Minimum salary per year is required"),
        max: number()
          .min(1, "Maximum salary per year must be greater than zero")
          .required("Maximum salary per year is required"),
      }),
    }),
    fixed: number().when("type", {
      is: "fixed",
      then: number()
        .min(1, "Salary per year must be greater than zero")
        .required("Salary per year is required"),
    }),
  }).default({}),
  ratePerHour: object({
    estimate: object({
      min: number(),
      max: number(),
    }).when("type", {
      is: "estimate",
      then: object().shape({
        min: number()
          .min(1, "Minimum rate per hour must be greater than zero")
          .lessThan(
            ref("max"),
            "Minimum rate per hour must be less than maximum rate per hour"
          )
          .required("Minimum rate per hour is required"),
        max: number()
          .min(1, "Maximum rate per hour must be greater than zero")
          .required("Maximum rate per hour is required"),
      }),
    }),
    fixed: number().when("type", {
      is: "fixed",
      then: number()
        .min(1, "Rate per hour must be greater than zero")
        .required("Rate per hour is required"),
    }),
  }).default({}),
  daysRequiredInOffice: object({
    estimate: object({
      min: number(),
      max: number(),
    }).when("type", {
      is: "estimate",
      then: object().shape({
        min: number()
          .min(
            0,
            "Minimum days required in office must be greater than or equal to zero"
          )
          .lessThan(
            ref("max"),
            "Minimum days required in office must be less than maximum days required in office"
          )
          .required("Minimum days required in office is required"),
        max: number()
          .min(1, "Maximum days required in office must be greater than zero")
          .required("Maximum days required in office is required"),
      }),
    }),
    fixed: number().when("type", {
      is: "fixed",
      then: number(),
    }),
  }).default({}),
  jobType: string().required().default("full-time"),
  jobLocation: string().required().default("remote"),
  educationLevel: string().default("none"),
  collegeAdditionalInformation: string(),
  postCollegeAdditionalInformation: string(),
  skills: array(),
  location: string()
    .when("jobLocation", {
      is: (loc: string) => loc === "on-site" || loc === "hybrid",
      then: string().required(),
    })
    .default(""),
  qualifications: string(),
  perks: string(),
  statusRequirement: string(),
  other: string().when("statusRequirement", {
    is: "other",
    then: string().required(),
  }),
  hoursPerWeek: string().when("jobType", {
    is: "part-time",
    then: string().required(),
  }),
  notes: string(),
  companyUrl: string(),
  externalWebsite: string().when("contact", {
    is: (contact: string) => contact === "external",
    then: string().required(),
  }),
  externalEmail: string().when("contact", {
    is: (contact: string) => contact === "email",
    then: string().required(),
  }),
  monthsBeforeTransfer: string().when("jobType", {
    is: (jobType: string) => jobType === "temp-to-perm",
    then: string().required(),
  }),
  contact: string().required(),
});

export const EditJobPost = () => {
  const { data: jobPost, isLoading: isLoadingJobPost } = useJobPostQuery();
  const { jobPostId } = useParams();
  const navigate = useNavigate();
  const { notifySuccess } = useToast();

  const initialValues = useMemo(() => {
    if (jobPost && !isLoadingJobPost) return jobPost;

    return initialJobPostValues;
  }, [jobPost, isLoadingJobPost]);

  const { mutate: updateJobPostMutation } = useJobPostUpdateMutationCore({
    onSuccess() {
      queryClient.invalidateQueries(jobPostKey(jobPostId));
    },
  });
  const queryClient = useQueryClient();

  const updateJobPost = useCallback(
    (values: FormikValues, isDraft: boolean = false) => {
      updateJobPostMutation(
        parseJobPostForm({
          ...values,
          isDraft,
          _id: jobPostId!,
        }),
        {
          onSuccess() {
            notifySuccess(feedbackMessage("job post", FEEDBACK_TYPE.UPDATE));
            queryClient.invalidateQueries(JOB_POSTS_QUERY_KEY);
            navigate("/job-posts");
          },
        }
      );
    },
    [jobPostId, navigate, notifySuccess, queryClient, updateJobPostMutation]
  );

  const handleUpdateJobPost = useCallback(
    (values: FormikValues, isDraft = false) => {
      updateJobPost(values, isDraft);
    },
    [updateJobPost]
  );

  return (
    <PageWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          handleUpdateJobPost(values);
        }}
        validationSchema={editJobPostValidationSchema}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          setFieldValue,
          isValid,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <EditJobPostForm
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              handleUpdateSkills={(newSkills: string[]) => {
                setFieldValue(
                  "skills",
                  newSkills.map((skill) => ({ name: skill }))
                );
              }}
              setFieldValue={setFieldValue}
              errors={errors}
              isValid={isValid}
            />
          </form>
        )}
      </Formik>
    </PageWrapper>
  );
};
