import { alpha, DialogClassKey, DialogProps, Components } from "@mui/material";
import { MuiComponentThemeDefinition } from "../utils";
import { LIGHT_BLUE, YELLOW } from "./utils";

export const MuiDialog: Components["MuiDialog"] = {
  styleOverrides: {
    root: {
      backgroundColor: alpha(LIGHT_BLUE, 0.9),
    },
    container: {
      position: "relative",
      zIndex: 233,
    },
    paper: {
      background: YELLOW,
      borderWidth: 0,
    },
    paperWidthSm: {
      height: 320,
      width: "100%",
      maxWidth: 1440,
    },
  },
};
//
// export const MuiDialog: MuiComponentThemeDefinition<
//   DialogClassKey,
//   DialogProps
// > = {
//   props: {
//     transitionDuration: 100,
//   },
//   override: ({ zIndex }) => ({
//     root: {
//       backgroundColor: alpha(LIGHT_BLUE, 0.9),
//     },
//     container: {
//       position: "relative",
//       zIndex: zIndex.modal,
//     },
//     paper: {
//       background: YELLOW,
//       borderWidth: 0,
//     },
//     paperWidthSm: {
//       height: 320,
//       width: "100%",
//       maxWidth: 1440,
//     },
//   }),
// };
