import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../app/container/PageWrapper";
import { useDefaultProjectPage } from "../../client/projects/hooks";
import { Section } from "../../common/components";
import { CollectionNavigationActions } from "../../common/elements/CollectionNavigationActions";
import {
  Collection,
  useNavigationHandlers,
} from "../../common/hooks/navigation";
import { useProjectQuery } from "../../common/hooks/projects/project/useProjectQuery";
import { useProjectsQuery } from "../../common/hooks/projects/useProjectsQuery";
import { Communication } from "./communication";
import { Details } from "./details/";
import { Payments } from "./payments";
import { ProjectHeader } from "./sections/header";

export const ProjectDetails: React.FC = () => {
  useDefaultProjectPage();

  const [searchParams] = useSearchParams();

  const { projectId } = useParams();

  const { data: projects, isLoading: isLoadingProjects } = useProjectsQuery();
  const { data: project, isLoading: isLoadingProject } = useProjectQuery();
  const isContentLoading = isLoadingProjects || isLoadingProject;

  const collectionConfig = { type: Collection.PROJECTS, items: projects };

  const { index, handleSelectPrevious, handleSelectNext } =
    useNavigationHandlers(collectionConfig, isLoadingProjects, projectId);

  const errorMessage = useMemo(() => {
    return !isContentLoading && !project
      ? "Your on-going projects will be shown here. Search job requests to apply to opportunities that match your skills."
      : "";
  }, [isContentLoading, project]);

  const content = useMemo(() => {
    if (searchParams.get("tab") === "payments") return <Payments />;

    if (searchParams.get("tab") === "replies") return <Communication />;

    return <Details />;
  }, [searchParams]);

  const currentTab = searchParams.get("tab");

  const isNavigationHidden = currentTab !== "details" || isLoadingProjects;

  return (
    <PageWrapper>
      <Section errorMessage={errorMessage} isLoading={isContentLoading}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box display="flex" flexDirection="column" height="100%">
            <ProjectHeader />
            <Box flexGrow={1}>{content}</Box>
          </Box>
          <CollectionNavigationActions
            label={"Project"}
            index={index}
            totalCount={projects?.length ?? 0}
            handleSelectNext={handleSelectNext}
            handleSelectPrevious={handleSelectPrevious}
            hidden={isNavigationHidden}
          />
        </Box>
      </Section>
    </PageWrapper>
  );
};
