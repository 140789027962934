import makeStyles from "@mui/styles/makeStyles";

export const useStepEStyles = makeStyles({
  headline: {
    fontSize: 112,
    fontWeight: 500,
    ["@media screen and (max-width: 2050px)"]: {
      fontSize: "5.5vw",
    },
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: "6.5vw",
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      fontSize: "10vw",
    },
    ["@media screen and (max-aspect-ratio: 5/4)"]: {
      ["@media screen and (max-width: 850px)"]: {
        fontSize: "11.4vw",
      },
      ["@media screen and (max-width: 700px)"]: {
        fontSize: "11.2vw",
      },
      ["@media screen and (max-width: 550px)"]: {
        fontSize: "10.9vw",
      },
      ["@media screen and (max-width: 450px)"]: {
        fontSize: "10.7vw",
      },
      ["@media screen and (max-width: 350px)"]: {
        fontSize: "10.5vw",
      },
      ["@media screen and (max-width: 280px)"]: {
        fontSize: "29px",
      },
    },
    ["@media screen and (min-aspect-ratio: 5/4)"]: {
      ["@media screen and (max-height: 650px)"]: {
        fontSize: "11.7vh",
      },
      ["@media screen and (max-height: 500px)"]: {
        fontSize: "11.3vh",
      },
      ["@media screen and (max-height: 400px)"]: {
        fontSize: "10.3vh",
      },
      ["@media screen and (max-height: 280px)"]: {
        fontSize: "29px",
      },
    },
  },
});
