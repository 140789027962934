import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";
import React, { useMemo } from "react";
import { useSavedJobPosts } from "../../job-posts/hooks";
import { useSavedJobRequests } from "../hooks";

type SavedJobRequestHighlightsProps = {};

export const SavedJobRequestHighlights: React.FC<
  SavedJobRequestHighlightsProps
> = () => {
  const { data: savedJobRequests, isLoading: isLoadingJobRequests } =
    useSavedJobRequests();
  const { data: savedJobPosts, isLoading: isLoadingJobPosts } =
    useSavedJobPosts();

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const savedJobRequestsCount = useMemo(
    () => savedJobRequests?.length ?? 0,
    [savedJobRequests?.length]
  );

  const savedJobPostsCount = useMemo(
    () => savedJobPosts?.length ?? 0,
    [savedJobPosts?.length]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      pt={10}
      rowGap={3}
    >
      <Typography
        variant={isMd ? "body1" : "subtitle1"}
        sx={{
          fontWeight: "bold",
        }}
      >
        Saved Job Requests & Posts
      </Typography>
      <Box fontSize={isMd ? 14 : 18}>
        <Link
          href="/job-requests/saved"
          underline="hover"
          style={
            !savedJobRequestsCount
              ? {
                  pointerEvents: "none",
                  textDecoration: "none",
                  color: "white",
                }
              : {}
          }
        >
          {savedJobRequestsCount} Job Request(s)
        </Link>
      </Box>
      <Box fontSize={isMd ? 14 : 18}>
        <Link
          href="/job-posts/saved"
          underline="hover"
          style={
            !savedJobPostsCount
              ? {
                  pointerEvents: "none",
                  textDecoration: "none",
                  color: "white",
                }
              : {}
          }
          data-cy="saved-jobs"
        >
          {savedJobPostsCount} Job Post(s)
        </Link>
      </Box>
    </Box>
  );
};
