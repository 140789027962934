import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { truncText } from "../../../utils/string";
import { Option } from "../HeaderDropdown";

type OptionsDropdownProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  selectedOptionId: string;
  onOptionSelect: (index: number) => void;
  options: Option[];
  minWidth?: string;
};

export const OptionsDropdown: React.FC<OptionsDropdownProps> = ({
  anchorEl,
  onClose,
  selectedOptionId,
  onOptionSelect,
  options,
  minWidth = "100px",
}) => {
  return (
    <Menu
      id="columns-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
    >
      {options.map((option, index) => (
        <MenuItem
          data-cy={`dropdown-item-${option.id}`}
          disableTouchRipple
          sx={{
            minWidth: minWidth,
            "&.Mui-selected": {
              backgroundColor: "#B5CCDB",
            },
            "&.Mui-selected:hover": {
              backgroundColor: "#B5CCDB",
            },
          }}
          key={option.id}
          selected={option?.id === selectedOptionId}
          onClick={() => onOptionSelect(index)}
        >
          {truncText(option.title, 65)}
        </MenuItem>
      ))}
    </Menu>
  );
};
