import { Box, useTheme, Pagination } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { ChangeEvent, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { useResponsiveness } from "../hooks/utils/useResponsiveness";

export type OnPageChange = (event: ChangeEvent<unknown>, page: number) => void;

export type PaginationControlsProps = {
  rowsPerPage?: number;
  totalPages: number;
  currentPage: number;
  onPageChange: OnPageChange;
  color?: "primary" | "secondary";
};

const useStyles = makeStyles({
  root: {
    "& .MuiPaginationItem-ellipsis": {
      color: (props: { color: "primary" | "secondary" }) =>
        `${props.color === "primary" ? YELLOW : BLUE}`,
    },

    "& .MuiPaginationItem-icon": {
      color: (props: { color: "primary" | "secondary" }) =>
        `${props.color === "primary" ? YELLOW : BLUE}`,
    },

    "& .MuiPaginationItem-textPrimary:not(.Mui-selected)": {
      color: (props: { color: "primary" | "secondary" }) =>
        `${props.color === "primary" ? YELLOW : BLUE}`,
    },
  },
});

export const PaginationControls: React.FC<PaginationControlsProps> = ({
  totalPages,
  currentPage,
  onPageChange,
  color = "primary",
}) => {
  const { spacing } = useTheme();
  const styles = useStyles({ color });

  const [searchParams, setSearchParams] = useSearchParams();

  const { isSmall } = useResponsiveness();

  const onPageChangeHandler = useCallback(
    (event: ChangeEvent<unknown>, page: number) => {
      onPageChange(event, page);
      searchParams.set("page", page.toString());
      setSearchParams(searchParams);
    },
    [onPageChange, searchParams, setSearchParams]
  );

  if (totalPages < 2) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ gap: spacing(2) }}
      className={styles.root}
      ml={-3}
    >
      <Pagination
        size={isSmall ? "small" : "medium"}
        color={color}
        count={totalPages}
        page={currentPage}
        onChange={onPageChangeHandler}
      />
    </Box>
  );
};
