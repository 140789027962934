import { Box, RadioGroup, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

type RadioGroupContainerProps = {
  subTitle?: string;
  title: ReactNode;
};

export const RadioGroupContainer: FC<RadioGroupContainerProps> = ({
  children,
  subTitle,
  title,
}) => {
  return (
    <Box pb={10}>
      <Box pb={4}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
      </Box>
      {subTitle && (
        <Box pb={2}>
          <Typography variant="body2">{subTitle}</Typography>
        </Box>
      )}

      <RadioGroup style={{ rowGap: 12 }}>{children}</RadioGroup>
    </Box>
  );
};
