import { Box, Button, Divider, Link, Typography } from "@mui/material";
import { Formik, Field } from "formik";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { string } from "yup";
import { SignInData } from "../../../api-types/sign-in";
import { CustomInput } from "../elements";
import { useRequiredFieldLabel } from "../hooks/utils";

const initialValues = {
  email: "",
  password: "",
};

type SignInFormProps = {
  formRef: any;
  onSignIn?: (values: SignInData) => void;
};

export const SignInForm: React.FC<SignInFormProps> = ({
  formRef,
  onSignIn,
}) => {
  const requiredFieldLabel = useRequiredFieldLabel();
  const validationSchema = Yup.object({
    email: string()
      .required("Email address is required")
      .email("Valid email required"),
    password: string().required("Password is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSignIn?.(values);
      }}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        isValid,
        handleChange,
        handleBlur,
        values,
        errors,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box display="flex" flexDirection="column" rowGap={12.5}>
            <Field
              component={CustomInput}
              fullWidth
              variant="outlined"
              placeholder={requiredFieldLabel("Enter email address")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              name="email"
              id="email"
              error={errors.email}
            />
            <Field
              component={CustomInput}
              fullWidth
              variant="outlined"
              placeholder={requiredFieldLabel("Enter password")}
              name="password"
              id="password"
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={errors.password}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            rowGap={2.5}
            pt={5}
          >
            <Link
              component={RouterLink}
              data-cy="forgot-password"
              state={{ from: "the-talented" }}
              to="/password-reset"
            >
              <Typography
                color="primary"
                style={{
                  fontSize: 16,
                }}
              >
                Forgot password?
              </Typography>
            </Link>
            <Button
              size="large"
              variant="contained"
              type="submit"
              disabled={!isValid}
              data-cy-sign-in
            >
              Sign In
            </Button>
          </Box>
          <Box py={5}>
            <Divider />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            rowGap={2.5}
          >
            <Typography
              style={{
                fontSize: 16,
              }}
            >
              Don't have an account?
            </Typography>
            {/*<Link*/}
            {/*  underline="hover"*/}
            {/*  component={RouterLink}*/}
            {/*  state={{ from: "the-talented" }}*/}
            {/*  to="/sign-up"*/}
            {/*>*/}
            <Button
              onClick={() => {
                window.location.replace("/sign-up");
              }}
              variant="text"
              size="large"
              color="primary"
            >
              Sign Up
            </Button>
            {/*</Link>*/}
          </Box>
        </form>
      )}
    </Formik>
  );
};
