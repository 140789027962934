import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Box, Input, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Field } from "formik";
import React, { ChangeEvent, Fragment, useCallback } from "react";
import { COLOR, RED, YELLOW } from "../../../../themes/components/utils";
import { CustomTooltip, Radio } from "../../../common/components";

type RadioWithInputProps = {
  className?: string;
  leftLabel?: string;
  betweenInputsLabel?: string;
  colorRadio: boolean;
  colorInput?: boolean;
  width?: string;
  onChange?: (e: ChangeEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  onFocus?: (field: string, value: string) => void;
  value?: string | number;
  minValue?: string | number;
  maxValue?: string | number;
  error?: string;
  minError?: string;
  maxError?: string;
  name?: string;
  radioValue?: string;
  checked?: boolean;
  isMinMaxInput?: boolean;
  max?: number;
};

const useStyles = makeStyles({
  radio: {
    "& .MuiSvgIcon-root": {
      width: 48,
      height: 48,
      fill: "white",
    },
  },
  radioColor: {
    "& .MuiSvgIcon-root": {
      fill: YELLOW,
    },
  },
  input: {
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: COLOR.WHITE,
    borderRadius: 2,
    marginRight: 12,
    color: COLOR.WHITE,
    fontSize: 16,
    width: 80,
    height: 32,
    "& input": {
      padding: "0px 12px",
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },

  inputColor: {
    borderColor: YELLOW,
    color: "white",
  },
  inputWidthSmall: {
    width: 68,
  },
  leftLabel: {
    paddingRight: 12,
  },
  inputError: {
    border: `2px solid ${RED}`,
  },
});

export const RadioWithInput: React.FC<RadioWithInputProps> = ({
  leftLabel,
  betweenInputsLabel,
  colorRadio,
  colorInput,
  width,
  onChange,
  onBlur,
  onFocus,
  value,
  minValue,
  maxValue,
  radioValue,
  name,
  checked,
  isMinMaxInput,
  error,
  minError,
  maxError,
  className,
  max = 999,
}) => {
  const styles = useStyles();

  const handleFocus = useCallback(() => {
    if (!name || !radioValue) return;

    onFocus?.(`${name}.type`, radioValue);
  }, [name, onFocus, radioValue]);

  return (
    <Box display="flex" alignItems="center">
      <Field
        as={Radio}
        className={`${className} ${styles.radio} ${
          colorRadio && styles.radioColor
        }`}
        name={`${name}.type`}
        value={radioValue}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        data-cy={`${name}.type`}
      />

      {!isMinMaxInput ? (
        <Fragment>
          {leftLabel && (
            <Typography variant="body1" className={styles.leftLabel}>
              {leftLabel}
            </Typography>
          )}
          <Field
            as={Input}
            disableUnderline
            className={`${styles.input} ${colorInput && styles.inputColor} ${
              width === "small" && styles.inputWidthSmall
            } ${!error ? "" : styles.inputError}`}
            value={value}
            name={`${name}.${radioValue}`}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={handleFocus}
            style={{
              paddingRight: 4,
            }}
            type="number"
            inputProps={{ min: 0, max }}
            data-cy={`${name}.${radioValue}`}
            endAdornment={
              error && (
                <CustomTooltip title={error} type="error">
                  <ErrorRoundedIcon
                    color="error"
                    fontSize="small"
                    data-cy-error={`${name}.${radioValue}`}
                    style={{ cursor: "initial" }}
                  />
                </CustomTooltip>
              )
            }
          />
        </Fragment>
      ) : (
        <Fragment>
          {leftLabel && (
            <Typography variant="body1" className={styles.leftLabel}>
              {leftLabel}
            </Typography>
          )}
          <Field
            as={Input}
            disableUnderline
            className={`${styles.input} ${colorInput && styles.inputColor} ${
              width === "small" && styles.inputWidthSmall
            } ${!minError ? "" : styles.inputError}`}
            value={minValue}
            name={`${name}.${radioValue}.min`}
            onChange={onChange}
            onBlur={onBlur}
            style={{
              paddingRight: 4,
            }}
            onFocus={handleFocus}
            type="number"
            inputProps={{ min: 0, max }}
            data-cy={`${name}.${radioValue}.min`}
            endAdornment={
              minError && (
                <CustomTooltip title={minError} type="error">
                  <ErrorRoundedIcon
                    color="error"
                    fontSize="small"
                    data-cy-error={`${name}.${radioValue}.min`}
                    style={{ cursor: "initial" }}
                  />
                </CustomTooltip>
              )
            }
          />

          {betweenInputsLabel && (
            <Typography variant="body1" className={styles.leftLabel}>
              {betweenInputsLabel}
            </Typography>
          )}

          <Field
            as={Input}
            disableUnderline
            className={`${styles.input} ${colorInput && styles.inputColor} ${
              width === "small" && styles.inputWidthSmall
            }  ${!maxError ? "" : styles.inputError}`}
            value={maxValue}
            name={`${name}.${radioValue}.max`}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={handleFocus}
            type="number"
            style={{
              paddingRight: 4,
            }}
            inputProps={{ min: 0, max }}
            data-cy={`${name}.${radioValue}.max`}
            endAdornment={
              maxError && (
                <CustomTooltip title={maxError} type="error">
                  <ErrorRoundedIcon
                    color="error"
                    fontSize="small"
                    data-cy-error={`${name}.${radioValue}.max`}
                    style={{ cursor: "initial" }}
                  />
                </CustomTooltip>
              )
            }
          />
        </Fragment>
      )}
    </Box>
  );
};
