import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { range } from "lodash";
import React, { useMemo } from "react";
import Carousel from "react-material-ui-carousel";
import { SkillV2 } from "../../../api-types/job-requests/common";
import { BLUE, LIGHT_BLUE, YELLOW } from "../../../themes/components/utils";
import { useResponsiveness } from "../hooks/utils/useResponsiveness";
import { CustomTooltip } from "./CustomTooltip";

type SkillsOutputProps = {
  skills?: SkillV2[];
  color?: string;
  isHovering?: boolean;
};

const useStyles = makeStyles({
  root: {
    transitionDuration: "0s",
    "& .css-16gfpfb": {
      columnGap: 10,
    },
    "& svg": {
      width: 16,
      height: 16,
    },
  },
});

export const SkillsOutput: React.FC<SkillsOutputProps> = ({
  skills = [],
  color = "white",
  isHovering = false,
}) => {
  const styles = useStyles();

  const { isExtraSmall, isMedium, isLarge } = useResponsiveness();

  const pack = useMemo(() => {
    if (isMedium) {
      return 2;
    }

    if (isLarge) {
      return 4;
    }

    return 5;
  }, [isLarge, isMedium]);

  const items = useMemo(() => {
    const groupCount = Math.min(Math.ceil((skills?.length ?? 0) / pack), 3);

    return range(groupCount).map((stack) => (
      <Box
        display="flex"
        columnGap={1.5}
        width={90 * pack}
        height={40}
        key={stack}
      >
        {range(stack * pack, stack * pack + pack).map((index) => (
          <CustomTooltip
            placement="top"
            title={skills[index] ?? ""}
            key={index}
          >
            <Box
              bgcolor={skills[index] ? color : "transparent"}
              border={skills[index] ? "1px solid white" : "none"}
              px={2}
              width={86}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="body2"
                color="inherit"
                style={{
                  color: color === "white" ? BLUE : "white",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                noWrap
              >
                {skills[index]}
              </Typography>
            </Box>
          </CustomTooltip>
        ))}
      </Box>
    ));
  }, [color, pack, skills]);

  if (isExtraSmall) {
    return null;
  }

  return (
    <Carousel
      sx={{
        height: "45px",
        width: pack * (isMedium ? 120 : 100),
        display: "flex",
        alignItems: "center",
        zIndex: 0,
      }}
      className={styles.root}
      animation="fade"
      autoPlay={false}
      navButtonsAlwaysInvisible
      indicators={items?.length > 1}
      indicatorContainerProps={{
        style: {
          display: "flex",
          columnGap: 0.5,
          width: "fit-content",
          padding: 0,
          margin: 0,
        },
      }}
      indicatorIconButtonProps={{
        style: {
          color: BLUE,
          backgroundColor: isHovering ? YELLOW : LIGHT_BLUE,
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          width: 16,
          color: isHovering ? YELLOW : LIGHT_BLUE,
          backgroundColor: isHovering ? YELLOW : LIGHT_BLUE,
        },
      }}
    >
      {items}
    </Carousel>
  );
};
