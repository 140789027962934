import { Box, Skeleton } from "@mui/material";
import React from "react";

export const ProfessionalAccountSummaryContentSkeleton: React.FC = () => {
  return (
    <Box pt={4} display="flex" flexDirection="column" rowGap={5}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="90%"
        height={25}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="90%"
        height={25}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="90%"
        height={25}
      />
      <Box
        pt={2}
        width="90%"
        display="flex"
        columnGap={2.5}
        alignItems="center"
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="17.5%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="17.5%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="17.5%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="17.5%"
          height={40}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="17.5%"
          height={40}
        />
        <Box display="flex" columnGap={1} alignItems="center">
          <Skeleton
            variant="circular"
            animation="wave"
            width={16}
            height={16}
          />
          <Skeleton
            variant="circular"
            animation="wave"
            width={16}
            height={16}
          />
          <Skeleton
            variant="circular"
            animation="wave"
            width={16}
            height={16}
          />
        </Box>
      </Box>
    </Box>
  );
};
