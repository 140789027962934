import { ImageNotSupportedOutlined } from "@mui/icons-material";
import { Box, SvgIconProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { S3Data } from "../../../../api-types/s3";
import { ImagePreviewFooter } from "./ImagePreviewFooter";

type ImagePreviewProps = {
  children?: ReactNode;
  selectedFiles: S3Data[];
  isLocalImages?: boolean;
  index?: number;
  width?: number;
  height?: number;
  title?: string;
  name?: string;
  arrowColor?: SvgIconProps["color"];
  color?: string;
  onClickHandler?: () => void;
  handleImageDescriptionChange?: (e: SyntheticEvent, index: number) => void;
  handleFocus?: (field: string, value: string) => void;
  showEmpty?: boolean;
};

const useStyles = makeStyles({
  aliceCarousel: {
    "& .alice-carousel, & .alice-carousel div ": {
      height: "100%",
    },
    "& .alice-carousel__dots": {
      margin: 0,
    },
    "& .alice-carousel__stage-item": {
      height: "100% !important",
      backgroundColor: "#FFF5F2",
      width: "100% !important",
    },

    "& img": {
      height: "100%",
      width: "auto",
      margin: "0 auto",
      maxWidth: "100%",
      maxHeight: "100%",
      display: "flex",
    },

    "& ..alice-carousel__wrapper": {
      height: "100% !important",
    },
  },
});

export const ImagePreview = ({
  width,
  height = 320,
  index = 0,
  selectedFiles,
  title,
  name,
  arrowColor,
  color,
  onClickHandler,
  handleImageDescriptionChange,
  handleFocus,
  showEmpty,
}: ImagePreviewProps) => {
  const styles = useStyles();
  const [currentIndex, setCurrentIndex] = useState(index);

  const imageName = `${!title?.length ? "" : title}${
    (title?.length ?? 0) > 0 && (name?.length ?? 0) > 0 ? " | " : ""
  }${name?.substring(0, 24) ?? ""}`;

  const hasAdditionalInfo = Boolean(imageName);

  const handleDragStart = useCallback(
    (e: React.DragEvent<HTMLImageElement>) => {
      e.preventDefault();
    },
    []
  );

  useEffect(() => {
    if (index !== currentIndex) {
      setCurrentIndex(index);
    }
    // only update the internal state when the external index is being updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const items = useMemo(() => {
    if (selectedFiles.length > 0) {
      return selectedFiles.map((file) => (
        <img
          key={file.location}
          width={width}
          height={height}
          style={{
            objectFit: "scale-down",
          }}
          src={file.location}
          onDragStart={handleDragStart}
          alt=""
        />
      ));
    }

    if (showEmpty) {
      return [
        <Box
          width={width}
          height={height}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            fontWeight: 500,
          }}
          columnGap={2}
        >
          <ImageNotSupportedOutlined /> No Image
        </Box>,
      ];
    }
  }, [selectedFiles, showEmpty, width, height, handleDragStart]);

  const onPrevHandler = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    },
    [currentIndex]
  );

  const onNextHandler = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (currentIndex + 1 < selectedFiles?.length) {
        setCurrentIndex(currentIndex + 1);
      }
    },
    [currentIndex, selectedFiles?.length]
  );

  const hasImageDescription = Boolean(handleImageDescriptionChange);

  return (
    <Box width={width} className={styles.aliceCarousel}>
      <Box
        onClick={onClickHandler}
        style={{ cursor: "pointer" }}
        height={height}
      >
        <AliceCarousel
          innerWidth={width}
          animationType="fadeout"
          animationDuration={800}
          disableButtonsControls
          disableDotsControls
          infinite
          activeIndex={currentIndex}
          items={items}
          mouseTracking
          autoHeight={true}
        />
      </Box>
      <ImagePreviewFooter
        index={currentIndex}
        name={imageName}
        selectedFiles={selectedFiles}
        onNext={onNextHandler}
        onPrev={onPrevHandler}
        hasImageDescription={hasImageDescription}
        arrowColor={arrowColor}
        color={color}
        hasAdditionalInfo={hasAdditionalInfo}
        handleImageDescriptionChange={handleImageDescriptionChange}
        handleFocus={handleFocus}
      />
    </Box>
  );
};
