import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Popover, Box, Button, useTheme, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Fragment,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { BLUE } from "../../../themes/components/utils";

const useStyles = makeStyles(() => ({
  feeButton: {
    "& .MuiButton-endIcon": {
      marginLeft: 0,
    },
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  clearApplyButtons: {
    "&:hover": {
      backgroundColor: BLUE,
    },
  },
}));

type FilterDropdownCoreProps = {
  buttonLabel: ReactNode;
  onApply: () => void;
  onClear?: () => void;
};

export const FilterDropdownCore = ({
  buttonLabel,
  children,
  onApply,
  onClear,
}: PropsWithChildren<FilterDropdownCoreProps>) => {
  const { palette } = useTheme();
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const feePopoverOpen = Boolean(anchorEl);

  const onCloseFeePopover = () => {
    setAnchorEl(null);
  };

  const onApplyHandler = useCallback(() => {
    onApply();
    onCloseFeePopover();
  }, [onApply]);

  return (
    <Fragment>
      <Button
        className={styles.feeButton}
        endIcon={feePopoverOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
        variant="text"
        disableRipple
        data-cy-btn="filter-menu"
      >
        {buttonLabel}
      </Button>
      <Popover
        open={feePopoverOpen}
        anchorEl={anchorEl}
        onClose={onCloseFeePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            backgroundColor: palette.common.white,
            borderRadius: 0,
          },
        }}
      >
        {children}
        <Divider style={{ borderColor: BLUE }} />
        <Box display="flex" columnGap={3} justifyContent="flex-end" padding={3}>
          {onClear && (
            <Button
              className={styles.clearApplyButtons}
              color="secondary"
              onClick={onClear}
              size="small"
              variant="contained"
              data-cy-btn="clear"
            >
              Clear
            </Button>
          )}
          <Button
            className={styles.clearApplyButtons}
            color="secondary"
            onClick={onApplyHandler}
            size="small"
            variant="contained"
            data-cy-btn="apply"
          >
            Apply
          </Button>
        </Box>
      </Popover>
    </Fragment>
  );
};
