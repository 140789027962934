import { Components } from "@mui/material";
import { BLUE } from "./utils";

export const MuiPagination: Components["MuiPagination"] = {
  styleOverrides: {
    root: {
      color: BLUE,
    },
  },
};
//
// export const MuiPagination: MuiComponentThemeDefinition<
//   PaginationClassKey,
//   PaginationProps
// > = {
//   props: {},
//   override: () => ({
//     root: {
//       color: BLUE,
//     },
//   }),
// };
