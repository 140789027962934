import { Box } from "@mui/material";
import { PageWrapper } from "../../app/container/PageWrapper";
import { SavedJobRequestsList, SavedMainbar } from "./saved-job-posts-list";

export const SavedJobPostsPage = () => {
  return (
    <PageWrapper>
      <Box display="flex" flexDirection="column" height="100%">
        <SavedMainbar />
        <Box flexGrow={1} pt={1}>
          <SavedJobRequestsList />
        </Box>
      </Box>
    </PageWrapper>
  );
};
