import makeStyles from "@mui/styles/makeStyles";
import { BLUE, COLOR, LIGHT_BLUE, YELLOW } from "../components/utils";

export const useRadioStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      width: 48,
      height: 48,
      fill: YELLOW,
    },
  },
});
