import { Box, Divider } from "@mui/material";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { useJobPackages } from "../../job-requests/create-job-request-wizard/hooks/useJobPackages";
import { FreeTrial, Packages } from "./components";

export const JobsPackagesDashboard = () => {
  const { isFreeTrialExpired } = useJobPackages();

  return (
    <PageWrapper>
      <Box
        display="flex"
        flexDirection="column"
        rowGap={12}
        pt={10}
        height="100%"
      >
        {!isFreeTrialExpired && (
          <Box>
            <FreeTrial />{" "}
            <Box py={2.5}>
              <Divider />
            </Box>
          </Box>
        )}
        <Packages />
      </Box>
    </PageWrapper>
  );
};
